import React from 'react'
import {Input} from '@thryvlabs/maverick'

function SliderRange({
  maxValue,
  range,
  product,
  handleOnChange,
  isDisabled,
  symbolTemplate = '',
  includeProduct,
  resetToLastValidValue = false,
  setResetToLastValidValue,
  lastValidValue,
}) {
  const maxItems = maxValue / range
  const step = 100 / maxItems
  const [value, setValue] = React.useState(step)
  const [displayValue, setDisplayValue] = React.useState(range)

  React.useEffect(() => {
    if (includeProduct) {
      setValue(step)
      setDisplayValue(range)
    }
  }, [step, range, includeProduct])

  const handleChange = e => {
    setValue(e.target.value)
    setDisplayValue(e.target.value * (range / step))

    const quantity = e.target.value / step
    if (product.name === 'Additional Video Views') {
      handleOnChange(quantity * 100, product)
    } else {
      handleOnChange(quantity, product)
    }
  }

  React.useEffect(() => {
    if (resetToLastValidValue) {
      setTimeout(() => {
        setValue(lastValidValue * step)
        setDisplayValue(lastValidValue)
        setResetToLastValidValue(false)
      }, 1000)
    }
    // eslint-disable-next-line
  }, [resetToLastValidValue])

  return (
    <Input
      key={product.id}
      variant="range-slider"
      min={0}
      max={maxItems}
      step={step}
      value={value}
      onChange={handleChange}
      name="range-input"
      rangeWholeNumber
      disabled={isDisabled}
      noRangeTextbox
      rangeSliderThumbValue={
        symbolTemplate
          ? `${symbolTemplate.replace('{0}', displayValue)}`
          : '' + displayValue
      }
    />
  )
}

export {SliderRange}
