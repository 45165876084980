import React from 'react'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {formatPrice} from '../../utils/currency-format'

function getPreviewSubtotal(item, isAusNZ) {
  let subtotal
  if (item.planCode === 'mc_pro_enh-5-thryv-m2m') {
    subtotal = item.subtotal - item.discount
  } else {
    subtotal = !isAusNZ ? item.subtotal : item.subtotal + item.tax
  }
  return formatPrice(subtotal)
}

function ProductItem({
  currentItem,
  showIncentive = false,
  incentiveCoupon,
  isOneTimeFee = false,
  upgradePaymentPreview,
  country,
  crossedOut,
  isCohort,
  orderCouponCodes,
}) {
  const {frontendListingsBcMc} = useFlags()

  let previewItem = upgradePaymentPreview?.centers?.find(
    ({planCode}) => planCode === currentItem.code,
  )

  if (!previewItem) {
    previewItem = upgradePaymentPreview?.apps?.find(
      ({planCode}) => planCode === currentItem.code,
    )
  }

  if (!previewItem) {
    previewItem = upgradePaymentPreview?.oneTimes?.find(
      ({planCode}) => planCode === currentItem.code,
    )
  }
  const isAusNZ = ['AU', 'NZ'].includes(country)

  const getItemName = () => {
    if (!isOneTimeFee) return currentItem.name
    return `${currentItem.name} ${
      currentItem.quantity > 1 ? `(${currentItem.quantity}x)` : ''
    }`
  }

  const getAmount = () => {
    const isInArray = arr =>
      arr?.some(({planCode, addonCode}) => {
        let planType
        if (
          currentItem.code === 'seobase-5-thryv-m2m' ||
          currentItem.code === 'addon-5-seokey-m2m' ||
          currentItem.code === 'addon-5-seopb-m2m'
        ) {
          planType = 'app'
        } else {
          planType = currentItem.planType
        }

        if (planType !== 'app') return currentItem.code === planCode
        return currentItem.code === addonCode
      })
    const isNewCenter = isInArray(upgradePaymentPreview?.centers)
    const isNewApp = isInArray(upgradePaymentPreview?.apps)

    if (upgradePaymentPreview) {
      let isAlphaSeoUpgrade = false
      const alphaSeoPreview = upgradePaymentPreview.apps.find(
        ({planCode, addonCode}) =>
          !addonCode && planCode === 'seobase-5-thryv-m2m',
      )
      if (currentItem.code === 'seobase-5-thryv-m2m' && alphaSeoPreview) {
        isAlphaSeoUpgrade = true
      }

      if (!isNewCenter && !isNewApp && !isOneTimeFee && !isAlphaSeoUpgrade) {
        return 'Included'
      }
      if (isNewCenter) {
        const center = upgradePaymentPreview.centers.find(
          ({planCode}) => planCode === currentItem.code,
        )
        return center && `${getPreviewSubtotal(center, isAusNZ)}`
      }
      if (!isOneTimeFee) {
        const app =
          upgradePaymentPreview.apps.find(
            ({addonCode, planCode}) =>
              (addonCode || planCode) === currentItem.code,
          ) ||
          upgradePaymentPreview.centers.find(
            ({planCode}) => planCode === currentItem.code,
          )
        return app ? `${getPreviewSubtotal(app, isAusNZ)}` : '$0.00'
      } else {
        const oneTimeFee = upgradePaymentPreview.oneTimes.find(
          ({planCode}) => planCode === currentItem.code,
        )
        return oneTimeFee && `${getPreviewSubtotal(oneTimeFee, isAusNZ)}`
      }
    }
    return formatPrice(
      crossedOut ? 0 : currentItem.amount,
      currentItem.quantity,
      currentItem.name,
    )
  }
  const getDiscountedAmount = () => {
    if (upgradePaymentPreview) {
      return previewItem?.discount
    } else {
      let couponAmount = 0
      for (const coupon of orderCouponCodes) {
        if (coupon.appliesToPlans?.includes(currentItem.code)) {
          if (coupon.discountAmount > 0) couponAmount += coupon.discountAmount
          else if (coupon.discountPercent > 0)
            couponAmount +=
              (currentItem.quantity || 1) *
              currentItem.amount *
              (coupon.discountPercent / 100)
        } else if (coupon.appliesToAllPlans === true) {
          if (coupon.discountAmount > 0) couponAmount += coupon.discountAmount
          else if (coupon.discountPercent > 0)
            couponAmount +=
              (currentItem.quantity || 1) *
              currentItem.amount *
              (coupon.discountPercent / 100)
        }
      }
      if (couponAmount > (currentItem.quantity || 1) * currentItem.amount) {
        return (currentItem.quantity || 1) * currentItem.amount
      } else return couponAmount
    }
  }

  const renderIncentive = !!(
    (!isCohort && showIncentive && getDiscountedAmount() > 0) ||
    (previewItem?.discount &&
      currentItem.code !== 'mc_pro_enh-5-thryv-m2m' &&
      getDiscountedAmount() > 0)
  )

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {!isOneTimeFee && (
            <img
              alt={currentItem.name}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '5px',
                marginRight: '0.5rem',
              }}
              src={currentItem.img}
            />
          )}
          <p
            style={{
              color: crossedOut && frontendListingsBcMc ? '#808080' : '',
              textDecorationLine:
                crossedOut && frontendListingsBcMc ? 'line-through' : 'none',
            }}
          >
            {getItemName()}
          </p>
        </div>
        <p className="mr-2">{getAmount()}</p>
      </div>
      {renderIncentive && (
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            {incentiveCoupon?.code === 'nci'
              ? 'One Month Free'
              : 'Total Incentive'}
          </div>
          <p className="mr-2">-{formatPrice(getDiscountedAmount())}</p>
        </div>
      )}
    </>
  )
}

export {ProductItem}
