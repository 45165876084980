import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'
import {ResultsLayoutHeader} from './results-layout'

export const ScansHeader = styled(ResultsLayoutHeader)`
  & .scans-search-tips {
    display: flex;
    min-width: 250px;
    display: inline-block;
  }
  @media screen and ${device.mobileL} {
    & .scans-search-tips {
      width: 96%;
      margin: 10px;
    }
  }
  p {
    grid-area: description;
    color: #adb5bd;
  }
`
