import React, {useContext, useEffect} from 'react'
import {thryvGuyExcited} from '../assets'
import {ParagraphText, Button, Header, Icon} from '@thryvlabs/maverick'
import {useNavigate} from 'react-router'
import {headerContext} from '../context/header-context'

const TSSConfirmation = () => {
  const {setIsHeaderVisible} = useContext(headerContext)
  const navigate = useNavigate()

  useEffect(() => {
    setIsHeaderVisible(true)
  }, [setIsHeaderVisible])

  return (
    <div className="w-100 d-flex flex-column justify-content-center align-items-center py-5">
      <Header fontWeight="medium" variant="h1">
        Thank you for purchasing Thryv
      </Header>
      <img
        src={thryvGuyExcited}
        alt="thryv guy excited"
        style={{width: '150px'}}
        className="py-4"
      />
      <ParagraphText variant="lg">
        You have successfully upgraded the client&apos;s account!
      </ParagraphText>
      <Button
        variant="primary"
        className="d-flex align-items-center"
        style={{fontSize: '15px'}}
        onClick={() => navigate('/all-accounts')}
      >
        Back to All Accounts{' '}
        <Icon
          variant="arrowRight"
          type="solid"
          className="pl-2"
          height="13"
          color="#FFFFFF"
        />
      </Button>
    </div>
  )
}

export {TSSConfirmation}
