import styled from '@emotion/styled'

export const UpdateAddressModalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 30px;

  & .reset-button:hover {
    cursor: pointer;
  }
`
