import {useAuth0} from '@auth0/auth0-react'
import {useFlags} from 'launchdarkly-react-client-sdk'
import * as Sentry from '@sentry/react'

function useUserRoles() {
  const {user} = useAuth0()
  const flags = useFlags()

  if (flags.frontendRoleMimic) {
    console.log('use LD:  ' + flags.frontendRoleMimic)
    Sentry.setUser({useLD: flags.frontendRoleMimic})
    return {roles: [flags.frontendRoleMimic]}
  }

  if (user) {
    const {user_roles: userRoles} = user
    Sentry.setUser({useUser: userRoles})
    return {
      roles: userRoles,
    }
  }
  return {roles: []}
}

export {useUserRoles}
