import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'

export const InputsContainer = styled.section`
  display: flex;
  justify-content: center;
  @media screen and ${device.laptop} {
    display: grid;
    grid-template-columns: 55% 45%;
  }
  @media screen and ${device.mobileL} {
    grid-template-columns: 100%;
  }
`
export const AdminContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background: #ffffff;
  & .roles-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 250px;
  }
`
