import styled from '@emotion/styled'

export const Sidebar = styled.div`
  grid-area: sidebar;
  border: lightgray 1px solid;
  position: fixed;
  height: 100vh;
  background: white;
  z-index: 2;

  @media (max-width: 835px) {
    display: none;
  }

  @media (min-width: 836px) {
    display: fixed;
  }

  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }

  .toggle-button {
    display: inline-block;
    width: 0px;
    height: 0px;
    margin-right: 50px;
  }

  .toggle-button:hover .visible {
    display: none;
  }

  .toggle-button:hover .hidden {
    display: block;
    cursor: pointer;
  }
`

export const MobileSidebar = styled.div`
  border: lightgray 1px solid;
  position: absolute;
  height: 100vh;
  background: white;
  z-index: 999;

  @media (min-width: 836px) {
    display: none;
  }

  .toggle-button {
    display: inline-block;
    width: 0px;
    height: 0px;
    margin-right: 50px;
    &:hover {
      cursor: pointer;
    }
  }
`

export const SidebarNavigationMenu = styled.div`
  height: 100%;
  font-size: 16px;
  font-family: Montserrat, sans-serif !important;
  font-weight: 500;
  & li {
    list-style-type: none;
  }
  .sidebar-open {
    width: 100%;
  }
  .sidebar-collapse {
  }
  .menu-item {
    text-decoration: none !important;
    gap: 4%;
  }
  & .nav-link.menu-item.active {
    position: relative;
    background-color: rgba(255, 80, 0, 0.2);
  }
  .menu-item:hover {
    color: black !important;
  }
  & .nav-item.show {
    display: flex;
    position: absolute;
    margin-left: -40px;
  }
  & .nav-item.hide {
    display: none;
  }
`

export const TabOptionsContainer = styled.div`
  margin-top: 6px;
  margin-left: 10px;
  margin-bottom: 6px;
  .menu-item {
    text-decoration: none !important;
    gap: 4%;
    padding 4px 10px !important;
    padding-bottom: 8.5px !important;
  }
  & .nav-link.menu-item.active {
    position: relative;
    background-color: rgba(255, 80, 0, 0.2);
  }
  .menu-item:hover {
    color: black !important;
  }
  & .nav-item.show {
    display: flex;
    position: absolute;
    margin-left: -40px;
  }
  & .nav-item.hide {
    display: none;
  }
  .active {
    color: black !important;
    border-bottom: 2px solid #ff5000;
    border-radius: 2px;
  }
`
