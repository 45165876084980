import React from 'react'
import {ModalTitle, Timeline, Icon} from '@thryvlabs/maverick'
import {Spinner} from './loader-spinner'
import dayjs from 'dayjs'

const LoadingTimelineModal = () => {
  const [timelineEvents, setTimelineEvents] = React.useState([
    {
      eventTitle: <p>Checking Account Details</p>,
      eventSubtitle: dayjs().format('MMMM D [at] h:mm A'),
      icon: <Icon variant="lock" type="solid" color="white" />,
      theme: 'primary',
    },
    {
      eventTitle: <p>...loading</p>,
      eventSubtitle: '',
      icon: <Icon variant="calendar" type="solid" color="#A7A7A7" />,
      theme: 'neutral',
    },
    {
      eventTitle: <p>...loading</p>,
      eventSubtitle: '',
      icon: <Icon variant="calendar" type="solid" color="#A7A7A7" />,
      theme: 'neutral',
    },
    {
      eventTitle: <p>...loading</p>,
      eventSubtitle: '',
      icon: <Icon variant="calendar" type="solid" color="#A7A7A7" />,
      theme: 'neutral',
    },
  ])

  let timeLineStep = 1

  const newTitles = {
    1: <p>Creating Free Command Center</p>,
    2: <p>Stocking the Product Catalog</p>,
    3: <p>Time to Shop!</p>,
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (timeLineStep >= 4) {
        clearInterval(interval)
        return
      }

      setTimelineEvents(prevTimelineEvents => {
        const copyOfTimeline = prevTimelineEvents.map(event => ({...event}))
        copyOfTimeline[timeLineStep].eventTitle = newTitles[timeLineStep]
        copyOfTimeline[timeLineStep].eventSubtitle =
          dayjs().format('MMMM D [at] h:mm A')
        copyOfTimeline[timeLineStep].icon = (
          <Icon variant="lock" type="solid" color="white" />
        )
        copyOfTimeline[timeLineStep].theme = 'primary'
        return copyOfTimeline
      })

      timeLineStep++
    }, 9000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6">
        <div className="flex gap-4">
          <div className="whitespace-nowrap m-auto">
            <ModalTitle variant="primary">Creating Account</ModalTitle>
          </div>
          <Spinner />
        </div>
        <Timeline events={timelineEvents} />
      </div>
    </div>
  )
}

export {LoadingTimelineModal}
