import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'

export const MainContainer = styled.section`
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`
export const WizardContainer = styled.div`
  width: 80vw;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  @media screen and ${device.laptop} {
    width: 95vw;
  }
`

export const StepsContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  margin: 0;
  padding: 20px 10px;
`
