import React from 'react'
import {Icon} from '@thryvlabs/maverick'
import unlimitedPlanIcon from '../assets/unlimitedPlanIcon.png'
import professionalPlanIcon from '../assets/professionalPlanIcon.png'
import syncPlanIcon from '../assets/syncPlanIcon.png'

const check = <Icon type="solid" variant="check" color="#FF5000" />
const star = <Icon type="solid" variant="star" color="#057AFF" />
const x = <Icon type="regular" variant="x" color="#BABABA" />

const FEATURES = [
  {
    icon: unlimitedPlanIcon,
    package_title: 'Unlimited',
    package_details:
      '$499/month + $250 Set-Up Fee or $2,884.00/Semi-Annually (Save 5%)',
    package_list: [
      {
        label: 'Service',
        features: [
          {
            name: 'Software Onboarding',
            value: check,
          },
          {
            name: '24/7 Support & Tools',
            value: check,
          },
        ],
      },
      {
        label: 'Software Features',
        features: [
          {
            name: '10 Users',
            value: check,
          },
          {
            name: 'CRM - Unlimited',
            value: check,
          },
          {
            name: 'Inbox',
            value: check,
          },
          {
            name: 'Scheduling & Appointments - Unlimited',
            value: check,
          },
          {
            name: 'Payments - Unlimited',
            value: check,
          },
          {
            name: 'Online Listings Management',
            value: check,
          },
          {
            name: 'Reserve with Google - Available',
            value: check,
          },
          {
            name: '2-Way Text - Unlimited',
            value: check,
          },
          {
            name: 'Document Storage - Unlimited',
            value: check,
          },
        ],
      },
      {
        label: 'Clients',
        features: [
          {
            name: 'Customizable CRM',
            value: check,
          },
          {
            name: 'Contact Tags & Filters',
            value: check,
          },
          {
            name: 'Data Enrichment',
            value: check,
          },
        ],
      },
      {
        label: 'Sales & Payments:',
        features: [
          {
            name: 'Estimates & Invoices - Unlimited',
            value: check,
          },
          {
            name: 'Online Payments',
            value: check,
          },
          {
            name: 'Coupons',
            value: check,
          },
          {
            name: 'Mobile QuickPay',
            value: check,
          },
          {
            name: 'Payment Processing: ThryvPay, Square, Stripe, Braintree/PayPal and/or PayPal - Available',
            value: check,
          },
        ],
      },
      {
        label: 'Reputation Management:',
        features: [
          {
            name: 'Review Monitoring',
            value: check,
          },
          {
            name: 'Review Generation',
            value: check,
          },
          {
            name: 'Review Response Networks: Google, Facebook, Yelp',
            value: check,
          },
          {
            name: 'Competitor Watch',
            value: check,
          },
          {
            name: 'Review Acknowledgement Service',
            value: check,
          },
        ],
      },
      {
        label: 'Marketing Automation:',
        features: [
          {
            name: 'Announcements - Unlimited Messages',
            value: check,
          },
          {
            name: 'Automated Campaigns - Unlimited Messages',
            value: check,
          },
          {
            name: 'Customized Auto Campaign - Unlimited Messages',
            value: check,
          },
          {
            name: 'Marketing Libraries',
            value: check,
          },
          {
            name: 'Multi-Channel, Personalization & Action Oriented',
            value: check,
          },
          {
            name: 'In-Depth Analytics',
            value: check,
          },
        ],
      },
      {
        label: 'Customer Communication:',
        features: [
          {
            name: 'Client Communication Inbox',
            value: check,
          },
          {
            name: 'Follow-up Reminders',
            value: check,
          },
          {
            name: '2-Way SMS Text from Business Number',
            value: check,
          },
          {
            name: 'Read Receipts',
            value: check,
          },
        ],
      },
      {
        label: 'Scheduling & Appointments:',
        features: [
          {
            name: 'Calendar & Online Scheduling - Unlimited',
            value: check,
          },
          {
            name: 'Appointment Booking Payments - Available',
            value: check,
          },
          {
            name: 'Flexible Services - Unlimited',
            value: check,
          },
          {
            name: 'Events and Classes - Unlimited',
            value: check,
          },
          {
            name: 'Automatic Follow-ups',
            value: check,
          },
          {
            name: 'Calendar Syncing',
            value: check,
          },
        ],
      },
      {
        label: 'Online Presence:',
        features: [
          {
            name: 'Client Portal',
            value: check,
          },
          {
            name: 'Client Zone',
            value: check,
          },
          {
            name: 'Editable Widgets',
            value: check,
          },
          {
            name: '60+ Publishing Networks',
            value: check,
          },
          {
            name: 'Professionally Designed Website (Requires $149 Design Fee)',
            value: check,
          },
        ],
      },
      {
        label: 'Document Storage & Sharing:',
        features: [
          {
            name: 'Request & Share Documents - Unlimited',
            value: check,
          },
          {
            name: 'Privately Link to Clients',
            value: check,
          },
        ],
      },
      {
        label: 'Integrations:',
        features: [
          {
            name: 'App Market - Available',
            value: check,
          },
          {
            name: 'Zapier Integration (Over 1,100 Integrations) - Available',
            value: check,
          },
        ],
      },
      {
        label: 'Social:',
        features: [
          {
            name: 'Profile Management - Facebook, Twitter, Instagram, LinkedIn, GMB',
            value: check,
          },
          {
            name: 'Content Posting & Cloning',
            value: check,
          },
          {
            name: 'Content Library',
            value: check,
          },
          {
            name: 'Social Conversations',
            value: check,
          },
          {
            name: 'Network Insights',
            value: check,
          },
          {
            name: 'Full Service Social - FB, Twitter - 12 posts p/mo',
            value: check,
          },
          {
            name: 'Social Boosting - Includes $50/mo',
            value: check,
          },
        ],
      },
      {
        label: 'Video:',
        features: [
          {
            name: '1 - 30 Sec Auto Generated Video',
            value: check,
          },
          {
            name: '3 - 60 Sec Custom On-Site Videos',
            value: check,
          },
          {
            name: '2 - 15 Sec Video Ad Views + 10K Gtd Views',
            value: check,
          },
          {
            name: '4 - 15 Sec Facebook Video Ads',
            value: check,
          },
          {
            name: '1 - Email Motion Gif',
            value: check,
          },
          {
            name: '1 - 15 Sec Background Website Video',
            value: check,
          },
        ],
      },
      {
        id: 1,
        label: 'Available Enhancement Add-Ons:',
        features: [
          {
            name: 'Thryv Leads (by proposal only - monthly)',
            value: star,
          },
          {
            name: 'Additional Users',
            value: star,
          },
          {
            name: 'HIPAA Secure - $29 monthly investment',
            value: star,
          },
          {
            name: "Social: Add'l FB Boosting (increments) - $100 monthly",
            value: star,
          },
          {
            name: "Add'l Video Ad Views - 25K (increments) - $100 monthly investment",
            value: star,
          },
          {
            name: 'Photo Package - 30 Professional Photos - $399 one-time investment',
            value: star,
          },
          {
            name: 'Enhanced Accessibility Website – Full Managed Service + Toolkit - $49 per month',
            value: star,
          },
          {
            name: [
              <p key={0}>Search Engine Optimization (SEO)</p>,
              <ul key={1}>
                <li>*only with purchase of Professional Website</li>
                <li>*SEO MBG available</li>
              </ul>,
            ],
            value: star,
          },
          {
            name: [
              <p key={0}>
                5 Pages Additional Copywriting (increments) - $299 one-time
              </p>,
              <ul key={1}>
                <li>*only with purchase of Professional Website</li>
              </ul>,
            ],
            value: star,
          },
          {
            name: 'Business Logo - $99 one-time',
            value: star,
          },
        ],
      },
    ],
  },
  {
    icon: professionalPlanIcon,
    package_title: 'Professional',
    package_details:
      '$349/month + $250 Set-Up Fee or $1,989.00/Semi-Annually (Save 5%)',
    package_list: [
      {
        label: 'Service:',
        features: [
          {
            name: 'Software Onboarding',
            value: check,
          },
          {
            name: '24/7 Support & Tools',
            value: check,
          },
        ],
      },
      {
        label: 'Software Features:',
        features: [
          {
            name: '5 Users',
            value: check,
          },
          {
            name: 'CRM - Unlimited',
            value: check,
          },
          {
            name: 'Inbox',
            value: check,
          },
          {
            name: 'Scheduling & Appointments - Unlimited',
            value: check,
          },
          {
            name: 'Payments - Unlimited',
            value: check,
          },
          {
            name: 'Online Listings Management',
            value: check,
          },
          {
            name: 'Reserve with Google - Available',
            value: check,
          },
          {
            name: '2-Way Text - Unlimited',
            value: check,
          },
          {
            name: 'Document Storage - 50 GB',
            value: check,
          },
        ],
      },
      {
        label: 'Clients:',
        features: [
          {
            name: 'Customizable CRM',
            value: check,
          },
          {
            name: 'Contact Tags & Filters',
            value: check,
          },
          {
            name: 'Data Enrichment',
            value: check,
          },
        ],
      },
      {
        label: 'Sales & Payments:',
        features: [
          {
            name: 'Estimates & Invoices - Unlimited',
            value: check,
          },
          {
            name: 'Online Payments',
            value: check,
          },
          {
            name: 'Coupons',
            value: check,
          },
          {
            name: 'Mobile QuickPay',
            value: check,
          },
          {
            name: 'Payment Processing: ThryvPay, Square, Stripe, Braintree/PayPal and/or PayPal - Available',
            value: check,
          },
        ],
      },
      {
        label: 'Reputation Management:',
        features: [
          {
            name: 'Review Monitoring',
            value: check,
          },
          {
            name: 'Review Generation',
            value: check,
          },
          {
            name: 'Review Response Networks: Google, Facebook, Yelp',
            value: check,
          },
          {
            name: 'Competitor Watch',
            value: check,
          },
          {
            name: 'Review Acknowledgement Service',
            value: check,
          },
        ],
      },
      {
        label: 'Marketing Automation:',
        features: [
          {
            name: 'Announcements - 10,000 p/mo ($0.01 each overage)',
            value: check,
          },
          {
            name: 'Automated Campaigns - 10,000 p/mo ($0.01 each overage)',
            value: check,
          },
          {
            name: 'Customized Auto Campaign - 10,000 p/mo ($0.01 each overage)',
            value: check,
          },
          {
            name: 'Marketing Libraries',
            value: check,
          },
          {
            name: 'Multi-Channel, Personalization & Action Oriented',
            value: check,
          },
          {
            name: 'In-Depth Analytics',
            value: check,
          },
        ],
      },
      {
        label: 'Customer Communication:',
        features: [
          {
            name: 'Client Communication Inbox',
            value: check,
          },
          {
            name: 'Follow-up Reminders',
            value: check,
          },
          {
            name: '2-Way SMS Text from Business Number',
            value: check,
          },
          {
            name: 'Read Receipts',
            value: check,
          },
        ],
      },
      {
        label: 'Scheduling & Appointments:',
        features: [
          {
            name: 'Calendar & Online Scheduling - Unlimited',
            value: check,
          },
          {
            name: 'Appointment Booking Payments - Available',
            value: check,
          },
          {
            name: 'Flexible Services - Unlimited',
            value: check,
          },
          {
            name: 'Events and Classes - Unlimited',
            value: check,
          },
          {
            name: 'Automatic Follow-ups',
            value: check,
          },
          {
            name: 'Calendar Syncing',
            value: check,
          },
        ],
      },
      {
        label: 'Online Presence:',
        features: [
          {
            name: 'Client Portal',
            value: check,
          },
          {
            name: 'Client Zone',
            value: check,
          },
          {
            name: 'Editable Widgets',
            value: check,
          },
          {
            name: '60+ Publishing Networks',
            value: check,
          },
          {
            name: 'Professionally Designed Website (Requires $149 Design Fee)',
            value: check,
          },
        ],
      },
      {
        label: 'Document Storage & Sharing:',
        features: [
          {
            name: 'Request & Share Documents - 50 GB',
            value: check,
          },
          {
            name: 'Privately Link to Clients',
            value: check,
          },
        ],
      },
      {
        label: 'Integrations:',
        features: [
          {
            name: 'App Market - Available',
            value: check,
          },
          {
            name: 'Zapier Integration (Over 1,100 Integrations) - Available',
            value: check,
          },
        ],
      },
      {
        label: 'Social:',
        features: [
          {
            name: 'Profile Management - Facebook, Twitter, Instagram, LinkedIn, GMB',
            value: check,
          },
          {
            name: 'Content Posting & Cloning',
            value: check,
          },
          {
            name: 'Content Library',
            value: check,
          },
          {
            name: 'Social Conversations',
            value: check,
          },
          {
            name: 'Network Insights',
            value: check,
          },
          {
            name: 'Full Service Social - FB, Twitter - 12 posts p/mo',
            value: check,
          },
          {
            name: 'Social Boosting - Includes $50/mo',
            value: x,
          },
        ],
      },
      {
        label: 'Video:',
        features: [
          {
            name: '1 - 30 Sec Auto Generated Video',
            value: check,
          },
          {
            name: '3 - 60 Sec Custom On-Site Videos',
            value: x,
          },
          {
            name: '2 - 15 Sec Video Ad Views + 10K Gtd Views',
            value: x,
          },
          {
            name: '4 - 15 Sec Facebook Video Ads',
            value: x,
          },
          {
            name: '1 - Email Motion Gif',
            value: x,
          },
          {
            name: '1 - 15 Sec Background Website Video',
            value: x,
          },
        ],
      },
      {
        id: 2,
        label: 'Available Enhancement Add-Ons:',
        features: [
          {
            name: 'Thryv Leads (by proposal only - monthly)',
            value: star,
          },
          {
            name: 'Additional Users - $10 per user monthly',
            value: star,
          },
          {
            name: 'HIPAA Secure - $29 monthly investment',
            value: star,
          },
          {
            name: "Social: Add'l FB Boosting (increments)",
            value: x,
          },
          {
            name: "Add'l Video Ad Views - 25K (increments)",
            value: x,
          },
          {
            name: 'Photo Package - 30 Professional Photos - $399 one-time investment',
            value: star,
          },
          {
            name: 'Enhanced Accessibility Website – Full Managed Service + Toolkit - $49 per month',
            value: star,
          },
          {
            name: [
              <p key={0}>Search Engine Optimization (SEO)</p>,
              <ul key={1}>
                <li>*only with purchase of Professional Website</li>
                <li>*SEO MBG available</li>
              </ul>,
            ],
            value: star,
          },
          {
            name: [
              <p key={0}>
                5 Pages Additional Copywriting (increments) - $299 one-time
              </p>,
              <ul key={1}>
                <li>*only with purchase of Professional Website</li>
              </ul>,
            ],
            value: star,
          },
          {
            name: 'Business Logo - $99 one-time',
            value: star,
          },
        ],
      },
    ],
  },
  {
    icon: syncPlanIcon,
    package_title: 'Plus',
    package_details:
      '$199/month + $250 Set-Up Fee or $1,134.00/Semi-Annually (Save 5%)',
    package_list: [
      {
        label: 'Service:',
        features: [
          {
            name: 'Software Onboarding',
            value: check,
          },
          {
            name: '24/7 Support & Tools',
            value: check,
          },
        ],
      },
      {
        label: 'Software Features:',
        features: [
          {
            name: '2 Users',
            value: check,
          },
          {
            name: 'CRM - Unlimited',
            value: check,
          },
          {
            name: 'Inbox',
            value: check,
          },
          {
            name: 'Scheduling & Appointments - Unlimited',
            value: check,
          },
          {
            name: 'Payments - Unlimited',
            value: check,
          },
          {
            name: 'Online Listings Management',
            value: check,
          },
          {
            name: 'Reserve with Google - Available',
            value: check,
          },
          {
            name: '2-Way Text - Unlimited',
            value: check,
          },
          {
            name: 'Document Storage - 20 GB',
            value: check,
          },
        ],
      },
      {
        label: 'Clients:',
        features: [
          {
            name: 'Customizable CRM',
            value: check,
          },
          {
            name: 'Contact Tags & Filters',
            value: check,
          },
          {
            name: 'Data Enrichment',
            value: check,
          },
        ],
      },
      {
        label: 'Sales & Payments:',
        features: [
          {
            name: 'Estimates & Invoices - Unlimited',
            value: check,
          },
          {
            name: 'Online Payments',
            value: check,
          },
          {
            name: 'Coupons',
            value: check,
          },
          {
            name: 'Mobile QuickPay',
            value: check,
          },
          {
            name: 'Payment Processing: ThryvPay, Square, Stripe, Braintree/PayPal and/or PayPal - Available',
            value: check,
          },
        ],
      },
      {
        label: 'Reputation Management:',
        features: [
          {
            name: 'Review Monitoring',
            value: check,
          },
          {
            name: 'Review Generation',
            value: check,
          },
          {
            name: 'Review Response Networks: Google, Facebook, Yelp',
            value: check,
          },
          {
            name: 'Competitor Watch',
            value: check,
          },
          {
            name: 'Review Acknowledgement Service',
            value: x,
          },
        ],
      },
      {
        label: 'Marketing Automation:',
        features: [
          {
            name: 'Announcements - 5,000 p/mo ($0.01 each overage)',
            value: check,
          },
          {
            name: 'Automated Campaigns - 5,000 p/mo ($0.01 each overage)',
            value: check,
          },
          {
            name: 'Customized Auto Campaign - 10,000 p/mo ($0.01 each overage)',
            value: x,
          },
          {
            name: 'Marketing Libraries',
            value: check,
          },
          {
            name: 'Multi-Channel, Personalization & Action Oriented',
            value: check,
          },
          {
            name: 'In-Depth Analytics',
            value: check,
          },
        ],
      },
      {
        label: 'Customer Communication:',
        features: [
          {
            name: 'Client Communication Inbox',
            value: check,
          },
          {
            name: 'Follow-up Reminders',
            value: check,
          },
          {
            name: '2-Way SMS Text from Business Number',
            value: check,
          },
          {
            name: 'Read Receipts',
            value: check,
          },
        ],
      },
      {
        label: 'Scheduling & Appointments:',
        features: [
          {
            name: 'Calendar & Online Scheduling - Unlimited',
            value: check,
          },
          {
            name: 'Appointment Booking Payments - Available',
            value: check,
          },
          {
            name: 'Flexible Services - Unlimited',
            value: check,
          },
          {
            name: 'Events and Classes - Unlimited',
            value: check,
          },
          {
            name: 'Automatic Follow-ups',
            value: check,
          },
          {
            name: 'Calendar Syncing',
            value: check,
          },
        ],
      },
      {
        label: 'Online Presence:',
        features: [
          {
            name: 'Client Portal',
            value: check,
          },
          {
            name: 'Client Zone',
            value: check,
          },
          {
            name: 'Editable Widgets',
            value: check,
          },
          {
            name: '60+ Publishing Networks',
            value: check,
          },
          {
            name: 'Professionally Designed Website (Requires $149 Design Fee)',
            value: x,
          },
        ],
      },
      {
        label: 'Document Storage & Sharing:',
        features: [
          {
            name: 'Request & Share Documents - 20 GB',
            value: check,
          },
          {
            name: 'Privately Link to Clients',
            value: check,
          },
        ],
      },
      {
        label: 'Integrations:',
        features: [
          {
            name: 'App Market - Available',
            value: check,
          },
          {
            name: 'Zapier Integration (Over 1,100 Integrations) - Available',
            value: check,
          },
        ],
      },
      {
        label: 'Social:',
        features: [
          {
            name: 'Profile Management - Facebook, Twitter, Instagram, LinkedIn, GMB',
            value: check,
          },
          {
            name: 'Content Posting & Cloning',
            value: check,
          },
          {
            name: 'Content Library',
            value: check,
          },
          {
            name: 'Social Conversations',
            value: check,
          },
          {
            name: 'Network Insights',
            value: check,
          },
          {
            name: 'Full Service Social - FB, Twitter ',
            value: x,
          },
          {
            name: 'Social Boosting - Includes $50/mo',
            value: x,
          },
        ],
      },
      {
        label: 'Video:',
        features: [
          {
            name: '1 - 30 Sec Auto Generated Video',
            value: x,
          },
          {
            name: '3 - 60 Sec Custom On-Site Videos',
            value: x,
          },
          {
            name: '2 - 15 Sec Video Ad Views + 10K Gtd Views',
            value: x,
          },
          {
            name: '4 - 15 Sec Facebook Video Ads',
            value: x,
          },
          {
            name: '1 - Email Motion Gif',
            value: x,
          },
          {
            name: '1 - 15 Sec Background Website Video',
            value: x,
          },
        ],
      },
      {
        id: 3,
        label: 'Available Enhancement Add-Ons:',
        features: [
          {
            name: 'Thryv Leads (by proposal only - monthly)',
            value: star,
          },
          {
            name: 'Additional Users - $10 per user monthly',
            value: star,
          },
          {
            name: 'HIPAA Secure - $29 monthly investment',
            value: star,
          },
          {
            name: "Social: Add'l FB Boosting (increments)",
            value: x,
          },
          {
            name: "Add'l Video Ad Views - 25K (increments)",
            value: x,
          },
          {
            name: 'Photo Package - 30 Professional Photos',
            value: x,
          },
          {
            name: 'Professional Designed Website - $99 monthly (Requires $149 Design Fee)',
            value: star,
          },
          {
            name: 'Enhanced Accessibility Website – Full Managed Service + Toolkit - $49 per month',
            value: star,
          },
          {
            name: [
              <p key={0}>Search Engine Optimization (SEO)</p>,
              <ul key={1}>
                <li>*only with purchase of Professional Website</li>
              </ul>,
            ],
            value: star,
          },
          {
            name: [
              <p key={0}>
                5 Pages Additional Copywriting (increments) - $299 one-time
              </p>,
              <ul key={1}>
                <li>*only with purchase of Professional Website</li>
              </ul>,
            ],
            value: star,
          },
          {
            name: 'Business Logo - $99 one-time',
            value: star,
          },
        ],
      },
    ],
  },
  {
    icon: syncPlanIcon,
    package_title: 'Sync',
    package_list: [
      {
        label: 'Service:',
        features: [
          {
            name: 'In-App Help',
            value: check,
          },
          {
            name: 'Software Onboarding',
            value: x,
          },
        ],
      },
      {
        label: 'Sync Features:',
        features: [
          {
            name: 'CRM - up to 50 Contacts',
            value: check,
          },
          {
            name: 'Customer Communication Inbox',
            value: check,
          },
          {
            name: 'Online Listings Management',
            value: check,
          },
          {
            name: '60+ Publishing Networks',
            value: check,
          },
          {
            name: 'Users',
            value: x,
          },
          {
            name: 'Customizable CRM & Data Enrichment',
            value: x,
          },
          {
            name: 'Appointment Booking & Online Scheduling',
            value: x,
          },
          {
            name: 'Payments, Invoices, Estimates',
            value: x,
          },
          {
            name: 'Review Generation & Response',
            value: x,
          },
          {
            name: 'Review Monitoring',
            value: x,
          },
          {
            name: 'Competitor Watch',
            value: x,
          },
          {
            name: 'Marketing Automation & Announcements',
            value: x,
          },
          {
            name: 'Reserve with Google',
            value: x,
          },
          {
            name: '2-Way Text',
            value: x,
          },
          {
            name: 'Document Storage',
            value: x,
          },
          {
            name: 'Client Portal',
            value: x,
          },
          {
            name: 'Zapier Integration (Over 1,100 Integrations)',
            value: x,
          },
          {
            name: 'Social Management, Posting & Content Library',
            value: x,
          },
          {
            name: 'Full Service Social + Boosting',
            value: x,
          },
          {
            name: 'Auto and Custom Video Package',
            value: x,
          },
        ],
      },
      {
        id: 4,
        label: [
          <p key={0}>
            Available Enhancement Add-Ons:
            <br />
            Must Select: Minimum 1 Add-On
          </p>,
        ],
        features: [],
      },
      {
        id: 5,
        label: 'Thryv Leads:',
        features: [
          {
            name: 'Thryv Leads (by proposal only - monthly)',
            value: star,
          },
        ],
      },
      {
        id: 6,
        label: 'Website:',
        features: [
          {
            name: [
              <ul key={0}>
                <li>
                  Professional Designed Website + Enhanced Accessibility Toolkit
                  - $99 + $399 one-time design fee
                </li>
                <li>Pages - Up to 15 pages</li>
                <li>Copywriting - Up to 5 pages</li>
                <li>Custom Domain</li>
                <li>Stock Photography</li>
                <li>In-Depth Analytics</li>
                <li>Website Personalization Technology</li>
              </ul>,
            ],
            value: [
              <ul key={0} style={{alignContent: 'left'}} className={'ml-0'}>
                <li className="mb-4">
                  <Icon type="solid" variant="star" color="#057AFF" />
                </li>
                <li className="mb-2">
                  <Icon type="solid" variant="check" color="#FF5000" />
                </li>
                <li className="mb-3">
                  <Icon type="solid" variant="check" color="#FF5000" />
                </li>
                <li className="mb-2">
                  <Icon type="solid" variant="check" color="#FF5000" />
                </li>
                <li className="mb-2">
                  <Icon type="solid" variant="check" color="#FF5000" />
                </li>
                <li className="mb-2">
                  <Icon type="solid" variant="check" color="#FF5000" />
                </li>
                <li className="mb-2">
                  <Icon type="regular" variant="x" color="#BABABA" />
                </li>
              </ul>,
            ],
          },
          {
            id: 7,
            name: [
              <p key={0}>
                5 Pages Additional Copywriting (increments) - $299 one-time
              </p>,
              <ul key={1}>
                <li>*with Web Purchase</li>
                <li>*only with purchase of Professional Website</li>
              </ul>,
            ],
            value: star,
          },
          {
            id: 8,
            name: 'HIPAA Secure - $29 monthly investment',
            value: star,
          },
          {
            id: 9,
            name: [
              <p key={0}>Search Engine Optimization (SEO)</p>,
              <ul key={1}>
                <li>*only with purchase of Professional Website</li>
              </ul>,
            ],
            value: star,
          },
          {
            name: 'Business Logo - $99 one-time',
            value: star,
          },
        ],
      },
    ],
  },
]
export {FEATURES}
