import React from 'react'
import {Badge} from '@thryvlabs/maverick'

const HealthScore = ({score}) => {
  let color = 'negative',
    text = 'no activity'
  if (score === 'DAILY') {
    color = 'positive'
    text = 'daily'
  } else if (score === 'WEEKLY') {
    color = 'warning'
    text = 'weekly'
  } else if (score === 'MONTHLY') {
    color = 'neutral'
    text = 'monthly'
  }
  return <Badge variant={color} badgeText={text} />
}
export {HealthScore}
