import React from 'react'
import {Button, Header, Icon, Input, Select, Modal} from '@thryvlabs/maverick'
import {toast} from 'react-toastify'
import {useAsync} from '../utils/use-async'
import {useAuthClient} from '../utils/use-auth-client'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'

const COUNTRIES = [
  {name: 'United States', value: 'US'},
  {name: 'Australia', value: 'AU'},
  {name: 'Cayman Islands', value: 'KY'},
  {name: 'Barbados', value: 'BB'},
  {name: 'Canada', value: 'CA'},
]

const DEFAULT_VALUES = {
  prospectEmail: '',
  prospectFirstName: '',
  prospectLastName: '',
}

const SCHEMA = yup.object().shape({
  prospectEmail: yup
    .string()
    .email('Invalid email')
    .required('Email is required'),
  prospectFirstName: yup.string().required('First name is required'),
  prospectLastName: yup.string().required('Last name is required'),
  prospectCountry: yup.object().shape({
    name: yup.string().required('Country is Required'),
    value: yup.string().required('Country is Required'),
  }),
  prospectCompany: yup.string(),
  prospectPhone: yup.string(),
})

function Container({
  children,
  frontendSideNav,
  isInviteModalOpen,
  setIsInviteModalOpen,
}) {
  if (frontendSideNav) {
    return (
      <Modal
        width="520px"
        onClose={() => setIsInviteModalOpen(false)}
        openOnLoad={isInviteModalOpen}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children}
        </div>
      </Modal>
    )
  }
  return children
}

function CreateInviteModal({
  close,
  getRecords,
  frontendSideNav,
  isInviteModalOpen,
  setIsInviteModalOpen,
}) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedCountry, setSelectedCountry] = React.useState({
    name: '',
    value: '',
  })
  const {run} = useAsync({
    status: 'pending',
  })
  const client = useAuthClient()
  const {
    register,
    formState: {errors},
    control,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(SCHEMA),
  })

  const createInvite = async formData => {
    let filteredData = Object.fromEntries(
      Object.entries(formData).filter(([_, v]) => v !== ''),
    )
    const DATA = {
      ...filteredData,
      prospectCountry: filteredData.prospectCountry.value,
    }
    try {
      setIsLoading(true)
      await run(client('invites', {data: DATA}))
      toast.success('Form submitted successfully')
      setSelectedCountry({value: '', name: ''})
      reset()

      if (!frontendSideNav) close()
      getRecords({getAllRecords: true})
      setIsLoading(false)
    } catch (responseError) {
      setIsLoading(false)
      toast.error(responseError.message)
    }
  }

  return (
    <Container
      frontendSideNav={frontendSideNav}
      setIsInviteModalOpen={setIsInviteModalOpen}
      isInviteModalOpen={isInviteModalOpen}
    >
      <form>
        <div
          style={{
            width: '520px',
            height: '400px',
            marginLeft: '8px',
            marginRight: '8px',
          }}
        >
          <div className="w-100 d-flex align-items-center justify-content-between">
            <Header variant="h2" fontWeight="semibold">
              New Invite
            </Header>
            {!frontendSideNav && (
              <Icon
                style={{cursor: 'pointer', float: 'right'}}
                onClick={close}
                type="regular"
                variant="x"
                height="18"
                width="18"
                color="#A3A5A7"
              />
            )}
          </div>
          <div className="mb-4 mt-4">
            <Input
              className="w-100"
              type="text"
              register={register}
              placeholder="Email"
              aria-label="Email"
              name="prospectEmail"
              withLabel
              labelType="floating"
              variant="default"
              errors={errors}
              required
            />
          </div>
          <div className="mb-4">
            <Input
              className="w-100"
              type="text"
              register={register}
              placeholder="First Name"
              aria-label="First Name"
              name="prospectFirstName"
              withLabel
              labelType="floating"
              variant="default"
              errors={errors}
              required
            />
          </div>
          <div className="mb-4">
            <Input
              className="w-100"
              type="text"
              register={register}
              placeholder="Last Name"
              aria-label="Last Name"
              name="prospectLastName"
              withLabel
              labelType="floating"
              variant="default"
              errors={errors}
              required
            />
          </div>
          <div className="mb-4">
            <Controller
              control={control}
              name="prospectCountry"
              render={({field: {onChange, name}}) => (
                <Select
                  options={COUNTRIES}
                  width="full"
                  selectLabel="Country*"
                  selectedOption={selectedCountry}
                  errors={errors}
                  onChange={onChange}
                  name={name}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <Input
              className="w-100"
              type="text"
              register={register}
              placeholder="Company Name"
              aria-label="Company Name"
              name="prospectCompany"
              withLabel
              labelType="floating"
              errors={errors}
              variant="default"
            />
          </div>
          <div className="mb-4">
            <Input
              className="w-100"
              type="text"
              register={register}
              placeholder="Phone"
              aria-label="Phone"
              name="prospectPhone"
              withLabel
              labelType="floating"
              errors={errors}
              variant="default"
            />
            <div style={{float: 'right', marginTop: '30px'}}>
              <Button
                variant="text"
                textVariant="light"
                level={1}
                style={{marginRight: '15px'}}
                onClick={() => setIsInviteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={isLoading}
                onClick={handleSubmit(createInvite)}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Container>
  )
}

export {CreateInviteModal}
