import React from 'react'
import {RatingLabel} from './rating-label'
import {RadialBarChart, PolarAngleAxis, RadialBar} from 'recharts'
import {Header, ParagraphText} from '@thryvlabs/maverick'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {ReputationContainer} from './reputation-container'
import {OutreachContainer} from './outreach-container'

import {getScoreConfig} from '../../utils/get-score-config'

import {
  CompanyResultsLoader,
  CompanyResultsContainer,
  ScoreContainer,
  GraphContainer,
  RatingContainer,
} from '../ui/business-results'

import {thryvPeek, thryvPointLeft} from '../../assets'
import {PresenceContainer} from './presence-container'

const CIRCLE_SIZE = 230

function CompanyResults(props) {
  const {
    isLoading,
    componentRefs,
    companyInfo: {
      averageScore = 0,
      outReachOverallScore,
      presenceOverallScore,
      reputationOverallScore,
      website = true,
      yext,
      reputationScore,
      overallPhrases,
      silktideJson,
      yextJson,
      listingAnalysisItems,
      revenueDetailInfo,
      revenue,
    },
  } = props
  const companyName = props.companyInfo?.companyInfo?.company
  const companyInfo = props.companyInfo?.companyInfo
  const {address, city, phone, state, zip, cwebsite} = companyInfo || {}
  const [scoreText, scoreColor] = getScoreConfig(averageScore)
  const hiddenPic =
    (averageScore < 61 && averageScore > 0) || averageScore === 0
  const ratings = [
    {
      score: reputationOverallScore,
      rating: 'Reputation',
    },
    {
      score: presenceOverallScore,
      rating: 'Presence',
    },
    {
      score: outReachOverallScore,
      rating: 'Outreach',
    },
  ]

  if (isLoading) {
    return (
      <CompanyResultsLoader data-testid="results-container-loader">
        <SkeletonTheme color="#e6e6e6" highlightColor="#fff">
          <Skeleton height="100%" width="100%" borderRadius="1rem" />
        </SkeletonTheme>
      </CompanyResultsLoader>
    )
  }
  return (
    <CompanyResultsContainer>
      <ScoreContainer data-test="component-score" className="component-score">
        <GraphContainer>
          <RadialBarChart
            width={CIRCLE_SIZE}
            height={CIRCLE_SIZE}
            cx={CIRCLE_SIZE / 2}
            cy={CIRCLE_SIZE / 2}
            innerRadius={80}
            outerRadius={110}
            barSize={15}
            data={[{name: 'Score', value: averageScore || 0}]}
            startAngle={90}
            endAngle={-270}
          >
            <PolarAngleAxis
              type="number"
              domain={[0, 100]}
              angleAxisId={0}
              tick={false}
            />
            <RadialBar
              background
              clockWise
              dataKey="value"
              cornerRadius={CIRCLE_SIZE / 2}
              fill={scoreColor}
            />
            <text
              x={CIRCLE_SIZE / 2}
              y={CIRCLE_SIZE / 2}
              textAnchor="middle"
              dominantBaseline="middle"
              id="score-percentage"
              data-testid="score-percentage"
            >
              {averageScore || '0'}
            </text>
          </RadialBarChart>
          <p> out of 100</p>
        </GraphContainer>
        <div className="print-only">
          <Header fontWeight="semibold" variant="h2" className="mt-3">
            <span>{companyName}</span>
          </Header>
          <ParagraphText variant="reg" color="thryv-night">
            <span>{`${address || '-'}, ${city || '-'}, ${state || '-'} - ${
              zip || '-'
            }`}</span>
          </ParagraphText>
          <ParagraphText variant="reg" color="thryv-night">
            <span data-test="company-phone">{phone || '-'}</span>
          </ParagraphText>
          {cwebsite && (
            <ParagraphText variant="reg" color="thryv-night">
              <span data-test="company-website-info">{cwebsite}</span>
            </ParagraphText>
          )}
          <div className="mb-4"></div>
        </div>
        <Header fontWeight="bold" variant="h5">
          Your score:{' '}
          <span data-test="score-text" style={{color: scoreColor}}>
            {scoreText}
          </span>
        </Header>
        <ParagraphText variant="reg" color="thryv-black-400">
          We would love to help you. Interested in learning about all things
          Thryv can do to support your current success? Learn more below.
        </ParagraphText>
      </ScoreContainer>
      <RatingContainer hidde={hiddenPic} className="component-rating-with-icon">
        {ratings.map(({rating, score}) => (
          <RatingLabel key={rating} label={rating} score={score} size="h6" />
        ))}
        <img src={hiddenPic ? thryvPeek : thryvPointLeft} alt="thryv icon" />
      </RatingContainer>
      <ReputationContainer
        score={reputationOverallScore}
        yext={yext}
        reputationScore={reputationScore}
        companyName={companyName}
        overallPhrases={overallPhrases}
        revenueDetailInfo={{...revenueDetailInfo, ...yext}}
        revenue={revenue}
        refProp={componentRefs.reputationRef}
      />
      {website && (
        <PresenceContainer
          score={presenceOverallScore}
          silktideJson={silktideJson}
          yextJson={yextJson}
          listingAnalysisItems={listingAnalysisItems}
          refProp={componentRefs.presenceRef}
        />
      )}
      <OutreachContainer
        score={outReachOverallScore}
        facebook={silktideJson?.facebook}
        twitter={silktideJson?.twitter}
        instagram={silktideJson?.instagram}
        refProp={componentRefs.outReachRef}
      />
    </CompanyResultsContainer>
  )
}

export {CompanyResults}
