import React, {useState} from 'react'
import {CartContainer} from '../ui/cartProducts'
import {Icon} from '@thryvlabs/maverick'
import Skeleton from 'react-loading-skeleton'
import {formatPrice} from '../../utils/currency-format'

function CartDrawer({
  children,
  title,
  totalPrice,
  defaultOpen = false,
  loading,
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen)

  return (
    <CartContainer
      style={{
        border: '1px solid black',
        padding: '10px',
        width: '100%',
        marginLeft: '20px',
        marginTop: '20px',
        borderRadius: '3px',
      }}
    >
      {loading ? (
        <Skeleton height={'600px'} />
      ) : (
        <div style={{paddingLeft: '10px'}}>
          <div style={{padding: '5px'}}>
            <button
              className="w-full"
              onClick={() => setIsOpen(prevVal => !prevVal)}
              aria-describedby={`Click to access cart content below.`}
            >
              <div
                className={`w-full flex row flex-row-reverse justify-content-between items-center cursor-pointer border-thryv-gray-light-400`}
                style={{borderBottom: '2px solid rgba(128, 128, 128, 0.2)'}}
              >
                <div className="flex flex-row-reverse items-center text-center gap-4">
                  <Icon
                    type={'solid'}
                    variant={`caretDown`}
                    className={`fill-current text-thryv-black-500 -rotate-90 ${
                      isOpen && 'rotate-0'
                    } transition-all duration-300 ease-in-out cursor-pointer`}
                    height={'18'}
                    width={'18'}
                  />
                  <h6
                    className={`text-accordion-title font-montserrat font-semibold text-thryv-black-500
                      text-card-title-h6 font-montserrat font-semibold text-thryv-black-500`}
                  >
                    {totalPrice > 0 ? formatPrice(totalPrice) : '0.00'}
                  </h6>
                </div>
                <h6
                  className={`text-accordion-title font-montserrat font-semibold text-thryv-black-500
                      text-card-title-h6 font-montserrat font-semibold text-thryv-black-500`}
                >
                  {title}
                </h6>
              </div>
            </button>
            <div
              className={`${
                !isOpen
                  ? 'hidden opacity-0 scale-0 transition-all duration-500 ease-in-out '
                  : 'visible opacity-100 scale-100 transition-all duration-700 ease-in-out '
              } mt-[18px]`}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </CartContainer>
  )
}

export {CartDrawer}
