import React from 'react'
import {Input, ParagraphText} from '@thryvlabs/maverick'
import {EaidValidation} from '../catalog-components/eaid-validation'

const ERROR_MESSAGES = ['This field is required', 'Please enter a valid domain']

const AddOnMc = ({
  children,
  mcIsOnCart,
  mcDomainConfig,
  setMcDomainConfig,
  isMCInCart,
  isMCProInCart,
  isMCProEnhancedInCart,
  isMCProEnhanced,
  cohortEaid,
  isEaidFound,
  setAllowInvoice,
  setIsCohort,
  setCohortEaid,
  setIsEaidFound,
  setIsLegacyUpgradeAllowed,
  thryvId,
}) => {
  const [errorMessageConfig, setErrorMessageConfig] = React.useState({
    showMessage: false,
    message: '',
  })
  const isValidDomain = domainString => {
    const regex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z.]{2,6}|(yellowpages\.com(\.au)?|yelp\.com(\.au)?)\/[a-zA-Z0-9\-/?=.]+)$/
    return regex.test(domainString)
  }
  const handleErrorValidation = ({value, isValid}) => {
    if (value.length === 0) {
      setErrorMessageConfig({showMessage: true, message: ERROR_MESSAGES[0]})
      return
    }
    if (value.length >= 3) {
      if (isValid) {
        setErrorMessageConfig({showMessage: false, message: ''})
        return
      }
      setErrorMessageConfig({showMessage: true, message: ERROR_MESSAGES[1]})
      return
    }
  }
  const handlemcDomainValue = ({target: {value}}) => {
    const isValid = isValidDomain(value)
    handleErrorValidation({value, isValid})
    setMcDomainConfig(prevState => ({...prevState, value, isValid}))
  }

  React.useEffect(() => {
    const shouldValidate =
      (isMCInCart || isMCProInCart) && !isMCProEnhancedInCart
    setMcDomainConfig(prevState => ({
      ...prevState,
      shouldValidate,
    }))
  }, [
    mcIsOnCart,
    setMcDomainConfig,
    isMCInCart,
    isMCProInCart,
    isMCProEnhancedInCart,
  ])

  return (
    <div style={{borderBottom: '1px solid #eee'}}>
      {children}
      {mcIsOnCart && !isMCProEnhancedInCart && (
        <div className="ml-4 mb-4 pl-2" style={{width: '98%'}}>
          <EaidValidation
            cohortEaid={cohortEaid}
            isEaidFound={isEaidFound}
            isOptional={isMCProEnhanced ? false : true}
            setAllowInvoice={setAllowInvoice}
            setIsCohort={setIsCohort}
            setCohortEaid={setCohortEaid}
            setIsEaidFound={setIsEaidFound}
            isMCProEnhanced={isMCProEnhanced}
            setIsLegacyUpgradeAllowed={setIsLegacyUpgradeAllowed}
            thryvId={thryvId}
          />
        </div>
      )}
      {mcIsOnCart && !isMCProEnhancedInCart && !mcDomainConfig?.hideDomain && (
        <div className="ml-4 mb-4 pl-2">
          <ParagraphText variant="reg" className="font-weight-normal">
            <strong className="text-danger">*</strong> A website is required in
            order to purchase the Marketing Center add-on, please enter your
            website domain to be used during set-up
          </ParagraphText>
          <Input
            style={{width: '30%'}}
            type="text"
            value={mcDomainConfig.value}
            onChange={handlemcDomainValue}
            placeholder="Enter your website domain, YP, or Yelp URL"
            aria-label="Domain"
            name="mcDomain"
            withLabel
            labelType="floating"
            variant="default"
          />
          {errorMessageConfig.showMessage && (
            <ParagraphText
              variant="sm"
              className="font-weight-normal text-danger mt-2"
            >
              {errorMessageConfig.message}
            </ParagraphText>
          )}
        </div>
      )}
    </div>
  )
}

export {AddOnMc}
