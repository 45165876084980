import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'
import {App} from './App'
import {AppProviders} from './context'
import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk'
import reportWebVitals from './reportWebVitals'

import './bootstrap'
;(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  })

  Sentry.init({
    integrations: [new BrowserTracing()],
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    beforeSend: event => {
      if (process.env.REACT_APP_ENV === 'localhost') {
        console.error(event)
        return null
      }
      return event
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })

  ReactDOM.render(
    <LDProvider>
      <React.StrictMode>
        <AppProviders>
          <App />
        </AppProviders>
      </React.StrictMode>
    </LDProvider>,
    document.getElementById('root'),
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
})()
