import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'
import {Header} from '@thryvlabs/maverick'

export const CategoryContainer = styled.div`
  margin: auto;
  padding: 10px;
  border-spacing: 10px;
  max-width: 70vw;
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: center;
  @media screen and ${device.laptopL} {
    grid-template-columns: repeat(3, auto);
  }
  @media screen and ${device.laptop} {
    grid-template-columns: repeat(2, auto);
  }
  @media screen and ${device.tablet} {
    grid-template-columns: repeat(1, auto);
  }
`
export const ContainerTitle = styled(Header)`
  color: #666;
  font-size: 36px;
  font-weight: 400;
`

export const ToolItemContainer = styled.div`
  p {
    color: #666;
    font-size: 16px;
    font-weight: 300;
  }
`

export const IndustryCategoryType = styled.div`
  margin: 30px;
  padding-left: 150px;
  padding-right: 150px;
`

export const IndustryCategoryItem = styled.div`
  margin: 30px;
  width: 233px;
  height: 263px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  &:hover {
    border-color: #ff5000;
  }
  & > .image {
    display: flex;
    justify-content: center;
  }
  & > .image .image-solution {
    position: absolute;
    width: 60px;
    padding-top: 25px;
  }
  & > .image .image-sync {
    width: 175px;
    height: 200px;
  }
  & > .image span {
    display: block;
    padding-top: 95px;
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
  }
  & > .category-description {
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    padding-top: 15px;
    color: #292a2e;
    margin-left: 5px;
    margin-right: 5px;
    mix-blend-mode: normal;
    opacity: 0.52;
    height: 70px;
  }
  & > .order-button {
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    border: white;
    margin: 25px 58px;
    background-color: Transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #ff5000;
    display: flex;
    align-items: center;
  }
  & > .order-button svg {
    margin-left: 10px;
  }
`
