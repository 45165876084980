import React, {useContext} from 'react'
import {ResultsTable} from '../components/results-table'
import {useAsync} from '../utils/use-async'
import {
  Button,
  Modal,
  Select,
  Timeline,
  withModal,
  DatePicker,
  Icon,
} from '@thryvlabs/maverick'
import {SearchButton} from '../components/search-button'
import {useAuthClient} from '../utils/use-auth-client'
import {ResultsLayoutContainer} from '../components/ui/results-layout'
import {DemoRequestsHeader} from '../components/ui/demo-requests'
import {toast} from 'react-toastify'
import Pagination from '../components/pagination'
import {StatusText} from '../components/ui/invitations'
import {CreateInviteModal} from '../components/create-invite-modal'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {inviteModalContext} from '../context/invite-modal-context'
import Moment from 'moment'

const status = [
  {name: 'All', value: 'All'},
  {name: 'Active', value: 'Active'},
  {name: 'Expired', value: 'Expired'},
  {name: 'Converted', value: 'Converted'},
]

function CreateInviteButton({showModal}) {
  return (
    <Button variant="primary" onClick={showModal}>
      New Invite
    </Button>
  )
}

const CreateInviteButtonWithModal = withModal(
  CreateInviteButton,
  CreateInviteModal,
)

function Header({
  frontendSideNav,
  setSelectedStatus,
  selectedStatus,
  handleSearch,
  getRecords,
  isInviteModalOpen,
  setIsInviteModalOpen,
  frontendInviteSorting,
  to,
  from,
  setTo,
  setFrom,
  setInitialDatesChanged,
  setShouldFilterByDate,
}) {
  if (frontendSideNav) {
    return (
      <DemoRequestsHeader frontendSideNav={frontendSideNav}>
        <div className="mr-4">
          {isInviteModalOpen && (
            <CreateInviteModal
              getRecords={getRecords}
              frontendSideNav={frontendSideNav}
              isInviteModalOpen={isInviteModalOpen}
              setIsInviteModalOpen={setIsInviteModalOpen}
            />
          )}
        </div>
        <div
          className="d-flex"
          style={frontendInviteSorting ? {alignItems: 'end'} : {}}
        >
          <button
            onClick={() => {
              setFrom(Moment())
              setTo(Moment())
              setShouldFilterByDate(false)
              setInitialDatesChanged(false)
            }}
            className="btn-clear-input mr-2"
            aria-label="clear"
          >
            <Icon type="regular" variant="circleX" height="20" />
          </button>
          {frontendInviteSorting && (
            <div style={{marginBottom: '-12px', marginRight: '20px'}}>
              <DatePicker
                variant="singleRange"
                from={from}
                to={to}
                setFrom={setFrom}
                setTo={setTo}
                name="range"
                toLabel="End Date"
                fromLabel="Start Date"
                format=""
              />
            </div>
          )}
          <Select
            options={status}
            width="md"
            selectLabel="Select Status"
            setSelectedOption={setSelectedStatus}
            selectedOption={selectedStatus}
            className="select-time mr-4"
          />
          <SearchButton
            placeholder={`Search Invitations`}
            searchOnUsserType={true}
            onSearch={handleSearch}
          />
        </div>
      </DemoRequestsHeader>
    )
  }
  return (
    <DemoRequestsHeader>
      <div
        className="d-flex"
        style={frontendInviteSorting ? {alignItems: 'end'} : {}}
      >
        {frontendInviteSorting && (
          <div style={{marginBottom: '-12px', marginRight: '20px'}}>
            <DatePicker
              variant="singleRange"
              from={from}
              to={to}
              setFrom={setFrom}
              setTo={setTo}
              name="range"
              toLabel="End Date"
              fromLabel="Start Date"
              format=""
            />
          </div>
        )}
        <Select
          options={status}
          width="md"
          selectLabel="Select Status"
          setSelectedOption={setSelectedStatus}
          selectedOption={selectedStatus}
          className="select-time"
        />
        <div className="mr-4 ml-4">
          <CreateInviteButtonWithModal
            modalProps={{
              getRecords,
            }}
          />
        </div>
        <SearchButton
          placeholder={`Search Invitations`}
          searchOnUsserType={true}
          onSearch={handleSearch}
        />
      </div>
    </DemoRequestsHeader>
  )
}

function Invitations() {
  const [allRecords, setAllRecords] = React.useState([])
  const [currentRecords, setCurrentRecords] = React.useState([])
  const [paginatedRecords, setPaginatedRecords] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [currentPage, setCurrentPage] = React.useState(1)
  const [recordsPerPage, setRecordsPerPage] = React.useState(10)
  const [from, setFrom] = React.useState(Moment())
  const [to, setTo] = React.useState(Moment())
  const [initialDatesChanged, setInitialDatesChanged] = React.useState(null)
  const [shouldFilterByDate, setShouldFilterByDate] = React.useState(null)
  const [selectedStatus, setSelectedStatus] = React.useState({
    name: 'All',
    value: 'All',
  })
  const [sortColumns, setSortColumns] = React.useState({
    Email: {
      fieldName: 'prospect_email_address',
      sorted: false,
      sortType: 'desc',
    },
    'Full Name': {
      fieldName: 'prospect_first_name',
      sorted: false,
      sortType: 'desc',
    },
    Status: {
      fieldName: 'status_code',
      sorted: false,
      sortType: 'desc',
    },
    Source: {
      fieldName: 'invite_source',
      sorted: false,
      sortType: 'desc',
    },
    'Date Created': {
      fieldName: 'create_date',
      sorted: false,
      sortType: 'desc',
    },
  })
  const {isInviteModalOpen, setIsInviteModalOpen} =
    useContext(inviteModalContext)
  const {run, data, isLoading} = useAsync({
    status: 'pending',
  })
  const client = useAuthClient()
  const {frontendSideNav, frontendInviteSorting} = useFlags()

  const getRecords = async ({term, getAllRecords = false}) => {
    let _term = term || setTerm(searchTerm)
    _term = getAllRecords ? '' : _term
    setLoading(true)
    const records = await run(
      client(`${'invites?'}${_term ? `${_term}` : ''}`, {
        method: 'GET',
      }),
    )
    setAllRecords(records)
    setLoading(false)
  }

  const lastPostIndex = currentPage * recordsPerPage
  const firstPostIndex = lastPostIndex - recordsPerPage

  React.useEffect(() => {
    if (currentRecords.length > 0) {
      setPaginatedRecords(currentRecords.slice(firstPostIndex, lastPostIndex))
    }
  }, [currentRecords, firstPostIndex, lastPostIndex])

  React.useEffect(() => {
    if (initialDatesChanged && !shouldFilterByDate) {
      setShouldFilterByDate(true)
    } else if (!initialDatesChanged) {
      setInitialDatesChanged(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to, from, frontendInviteSorting])

  React.useEffect(() => {
    const handleStatusChange = () => {
      let records = [...allRecords]
      if (frontendInviteSorting && to && from && shouldFilterByDate) {
        records = [
          ...allRecords.filter(
            r =>
              !Moment(r.create_date).isBefore(from) &&
              !Moment(r.create_date).isAfter(to),
          ),
        ]
      }

      if (selectedStatus.value === 'All') {
        setCurrentRecords(records)
      } else {
        setCurrentRecords(
          records.filter(record => record.status_code === selectedStatus.value),
        )
      }
    }
    handleStatusChange()
  }, [
    allRecords,
    selectedStatus.value,
    to,
    from,
    frontendInviteSorting,
    shouldFilterByDate,
  ])

  const handleSearch = value => {
    const query = value.trim()
    const term = setTerm(query)
    if (term) {
      getRecords({term})
    } else {
      getRecords({term, getAllRecords: true})
    }
  }

  const setTerm = term => {
    if (!term && !searchTerm) {
      return
    }
    if (term === searchTerm) {
      return `term=${term}`
    }
    setSearchTerm(term)
    if (term) {
      return `term=${term}`
    }
    return
  }

  const sendReminder = async data => {
    try {
      await run(client('invites', {data, method: 'PATCH'}))
      toast.success('Successfully sent reminder')
    } catch (responseError) {
      toast.error(responseError.message)
    }
    getRecords(searchTerm)
  }

  const sortRecords = sortColumn => {
    const sortedRecords = allRecords.sort((a, b) => {
      if (Moment(a[sortColumn.fieldName]).isValid()) {
        if (sortColumn.sortType === 'asc') {
          return (
            new Date(a[sortColumn.fieldName]) -
            new Date(b[sortColumn.fieldName])
          )
        } else {
          return (
            new Date(b[sortColumn.fieldName]) -
            new Date(a[sortColumn.fieldName])
          )
        }
      } else {
        if (sortColumn.sortType === 'asc') {
          return a[sortColumn.fieldName].localeCompare(b[sortColumn.fieldName])
        } else {
          return b[sortColumn.fieldName].localeCompare(a[sortColumn.fieldName])
        }
      }
    })
    setAllRecords([...sortedRecords])
  }

  return (
    <ResultsLayoutContainer>
      <Header
        frontendSideNav={frontendSideNav}
        setSelectedStatus={setSelectedStatus}
        selectedStatus={selectedStatus}
        handleSearch={handleSearch}
        getRecords={getRecords}
        isInviteModalOpen={isInviteModalOpen}
        setIsInviteModalOpen={setIsInviteModalOpen}
        setCurrentRecords={setCurrentRecords}
        allRecords={allRecords}
        setLoading={setLoading}
        frontendInviteSorting={frontendInviteSorting}
        to={to}
        from={from}
        setTo={setTo}
        setFrom={setFrom}
        setShouldFilterByDate={setShouldFilterByDate}
        setInitialDatesChanged={setInitialDatesChanged}
      />
      <ResultsTable
        isLoading={isLoading || loading}
        cols={
          frontendInviteSorting
            ? ['Email', 'Full Name', 'Status', 'Source', 'Date Created']
            : ['Email', 'Full Name', 'Status', 'Date Created']
        }
        hasRecords={currentRecords?.length > 0}
        sortColumns={sortColumns}
        setSortColumns={setSortColumns}
        sortRecords={sortRecords}
      >
        {paginatedRecords &&
          paginatedRecords.map(
            (
              {
                invite_id,
                prospect_business_name,
                prospect_first_name,
                prospect_last_name,
                prospect_phone_number,
                prospect_email_address,
                prospect_country_code,
                create_date,
                status_code,
                invite_source,
                created_by,
                reminder_sent_date,
                contact_email_address,
                converted,
                conversion_date,
                initial_invite_sent,
                initial_invite_sent_date,
              },
              index,
            ) => (
              <tr key={invite_id}>
                <td>
                  <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                    {prospect_email_address}
                  </span>
                </td>
                <td>
                  <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                    <strong>
                      {prospect_first_name || '-'} {prospect_last_name || '-'}
                    </strong>
                  </span>
                </td>
                <td>
                  <StatusText
                    className={
                      status_code === 'Active'
                        ? 'text-active'
                        : status_code === 'Converted'
                        ? 'text-converted'
                        : 'text-expired'
                    }
                  >
                    {status_code || '-'}
                  </StatusText>
                </td>
                {frontendInviteSorting && (
                  <td>
                    <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                      {invite_source}
                    </span>
                  </td>
                )}
                <td>
                  <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                    {create_date}
                  </span>
                </td>
                <td>
                  <div className="d-flex gap-3">
                    <Modal
                      variant="default"
                      btnText="View Details"
                      btnType="secondary"
                      btnActionText="Yes"
                      width="450px"
                      title="View Details"
                    >
                      <div className="container">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-column ml-1 align-items-start">
                            <span className="mb-1">
                              <strong>Email Address</strong>
                            </span>
                            <span className="mb-1">
                              <strong>Full Name</strong>
                            </span>
                            <span className="mb-1">
                              <strong>Country</strong>
                            </span>
                            <span className="mb-1">
                              <strong>Company Name</strong>
                            </span>
                            <span className="mb-1">
                              <strong>Phone Number</strong>
                            </span>
                            <span className="mb-1">
                              <strong>Status</strong>
                            </span>
                            <span className="mb-1">
                              <strong>Created By</strong>
                            </span>
                            <span className="mb-1">
                              <strong>Source</strong>
                            </span>
                            <span className="mb-1">
                              <strong>Timeline</strong>
                            </span>
                          </div>

                          <div className="d-flex flex-column align-items-end">
                            <span className="mb-1">
                              {prospect_email_address}
                            </span>
                            <span className="mb-1">
                              {prospect_first_name} {prospect_last_name}
                            </span>
                            <span className="mb-1">
                              {prospect_country_code === 'null'
                                ? '-'
                                : prospect_country_code}
                            </span>
                            <span className="mb-1">
                              {prospect_business_name === '[NULL]'
                                ? '-'
                                : prospect_business_name}
                            </span>
                            <span className="mb-1">
                              {prospect_phone_number === 'null'
                                ? '-'
                                : prospect_phone_number}
                            </span>{' '}
                            <StatusText
                              className={
                                status_code === 'Active'
                                  ? 'text-active'
                                  : status_code === 'Converted'
                                  ? 'text-converted'
                                  : 'text-expired'
                              }
                            >
                              {status_code || '-'}
                            </StatusText>
                            <span className="mb-1">{created_by}</span>
                            <span className="mb-1">
                              {' '}
                              {invite_source === 'null' ? '-' : invite_source}
                            </span>
                          </div>
                        </div>

                        <div className="d-flex flex-column mt-4">
                          <Timeline
                            variant="small"
                            events={[
                              {
                                eventTitle: (
                                  <p>Command Center Invite Created</p>
                                ),
                                eventSubtitle: `${create_date}`,
                                theme:
                                  created_by === null ? 'neutral' : 'primary',
                              },
                              {
                                eventTitle: <p>Invite Sent Successfully</p>,
                                eventSubtitle:
                                  initial_invite_sent_date !== null &&
                                  initial_invite_sent_date !== undefined &&
                                  initial_invite_sent_date,
                                theme:
                                  initial_invite_sent_date !== null &&
                                  initial_invite_sent_date !== undefined
                                    ? 'primary'
                                    : 'neutral',
                              },
                              ...(reminder_sent_date
                                ? [
                                    {
                                      eventTitle: (
                                        <p>Reminder Sent Successfully</p>
                                      ),
                                      eventSubtitle:
                                        reminder_sent_date !== null &&
                                        reminder_sent_date,
                                      theme:
                                        reminder_sent_date === null
                                          ? 'neutral'
                                          : 'primary',
                                    },
                                  ]
                                : []),
                              {
                                eventTitle: (
                                  <p>Command Center Setup Complete</p>
                                ),
                                eventSubtitle:
                                  conversion_date !== null && conversion_date,
                                theme:
                                  conversion_date === null
                                    ? 'neutral'
                                    : 'primary',
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </Modal>
                    <Modal
                      variant="default"
                      btnText="Send Reminder"
                      btnType="secondary"
                      footer
                      action
                      footerCancel
                      btnAction={() => {
                        sendReminder({inviteId: String(invite_id)})
                      }}
                      actionClose
                      btnActionText="Yes"
                      width="500px"
                      disableModal={reminder_sent_date !== null}
                    >
                      <p>
                        Are you sure you want to send the Command Center
                        Invitation reminder to{' '}
                        <strong>
                          {prospect_first_name} {prospect_last_name},{' '}
                          {prospect_email_address}
                        </strong>
                        ?
                      </p>
                    </Modal>
                  </div>
                </td>
              </tr>
            ),
          )}
      </ResultsTable>
      {data && (
        <Pagination
          totalRecords={data.length}
          recordsPerPage={recordsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setRecordsPerPage={setRecordsPerPage}
          totalPages={Math.ceil(data.length / recordsPerPage)}
        />
      )}
    </ResultsLayoutContainer>
  )
}
export {Invitations}
