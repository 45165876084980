import React from 'react'
import {blankError} from '../assets'

function ErrorPage({httpError}) {
  const {message, status, code} = httpError
  return (
    <section className="d-flex justify-content-center m-4 p-3 mt-5">
      <div
        className="d-flex flex-column justify-content-center align-items-center p-5"
        style={{
          backgroundColor: '#fffff',
          borderRadius: '25px',
          minWidth: '450px',
          maxWidth: '600px',
          boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.25)',
        }}
      >
        <h1
          className="font-montserrat text-secondary text-center pb-4"
          style={{borderBottom: '1px solid rgba(0, 0, 0, 0.1)'}}
        >
          {message}
        </h1>
        <div className="position-relative d-flex justify-content-center align-items-center">
          <img src={blankError} alt="Error page" width="250px" height="250px" />
          <h1
            className="font-montserrat display-2 font-weight-normal text-center position-absolute pt-5"
            style={{color: '#fdc63d'}}
          >
            {status}
          </h1>
        </div>
        <h1 className="font-montserrat text-secondary text-center text-capitalize">
          {code?.toLowerCase()}
        </h1>
      </div>
    </section>
  )
}

export default ErrorPage
