import React from 'react'
import {Icon, withModal, Button, RadioGroup} from '@thryvlabs/maverick'
import dayjs from 'dayjs'
import Calendar from 'react-calendar'
import {ModalContent} from '../ui/new-demo'
import {AddonsDatePickerContainer} from '../ui/addons-datepicker-container'

const RADIO_OPTIONS = [
  {
    name: 'No',
    value: false,
  },
  {
    name: 'Yes',
    value: true,
  },
]

function AddOnsDatePickerModal({
  setSelectedDate = () => {},
  itemName,
  maxDate = 33,
}) {
  const [showModalButton, setShowModalButton] = React.useState(false)
  const [placeHolder, setPlaceHolder] = React.useState('Billing Start Date')
  const getMaxDate = () => {
    let defaultMaxDate = new Date()
    defaultMaxDate.setDate(defaultMaxDate.getDate() + maxDate)
    return defaultMaxDate
  }

  const onSelectedDay = (selectedCalendarDay, close) => {
    const formattedDatetime = dayjs(selectedCalendarDay).format(
      'YYYY-MM-DD hh:mm:ss',
    )
    setSelectedDate(formattedDatetime)
    setPlaceHolder(dayjs(selectedCalendarDay).format('MM/DD/YYYY'))
    close()
  }

  const handleIncludeDate = e => {
    setShowModalButton(e.value)
    if (!e.value) {
      setSelectedDate(null)
      setPlaceHolder('Billing Start Date')
    }
  }

  const clear = close => {
    setSelectedDate(null)
    setPlaceHolder('Billing Start Date')
    close()
  }

  const today = close => {
    setSelectedDate(new Date())
    setPlaceHolder(dayjs().format('MM/DD/YYYY'))
    close()
  }

  const Modal = ({close}) => (
    <ModalContent>
      <AddonsDatePickerContainer>
        <div>
          <div className="calendar">
            <Calendar
              style={{marginBottom: '0px'}}
              minDate={new Date()}
              maxDate={getMaxDate()}
              defaultValue={new Date()}
              calendarType="US"
              next2Label={null}
              prev2Label={null}
              nextLabel={
                <Icon type="solid" variant="arrowRight" className="m-auto" />
              }
              prevLabel={
                <Icon type="solid" variant="arrowLeft" className="m-auto" />
              }
              tileClassName="calendar-tile"
              onClickDay={e => onSelectedDay(e, close)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <Button variant="secondary" onClick={() => today(close)}>
            Today
          </Button>
          <Button variant="secondary" onClick={() => clear(close)}>
            Clear
          </Button>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </div>
      </AddonsDatePickerContainer>
    </ModalContent>
  )

  const DateInput = ({showModal}) => (
    <Button
      variant={placeHolder === 'Billing Start Date' ? 'primary' : 'secondary'}
      onClick={showModal}
    >
      {placeHolder}
    </Button>
  )

  const DateModal = withModal(DateInput, Modal)
  return (
    <div className="d-flex align-items-center">
      <div className="row ml-3 ">
        <RadioGroup
          data={RADIO_OPTIONS}
          value={showModalButton ? RADIO_OPTIONS[1] : RADIO_OPTIONS[0]}
          row
          onChange={handleIncludeDate}
          layoutStyles={{
            position: 'relative',
            top: '-18px',
            gap: '10px',
          }}
        />
      </div>
      <div className="ml-5">{showModalButton && <DateModal />}</div>
    </div>
  )
}

export {AddOnsDatePickerModal}
