import React from 'react'
import * as yup from 'yup'
import {useForm} from 'react-hook-form'
import {AddOnItem} from './add-on-item'
import {Header, Input, Button} from '@thryvlabs/maverick'
import {yupResolver} from '@hookform/resolvers/yup'

import {ADD_ON} from '../../utils/addons-data'
import {ADDONS_TYPES, BILLING_PREFERENCES} from '../../utils/constants'
import {useAuthClient} from '../../utils/use-auth-client'
import {useAsync} from '../../utils/use-async'

import {SelectionAdds, ItemContainer} from '../ui/addons-main-components'
import {useUserRoles} from '../../utils/use-user-roles'
import dayjs from 'dayjs'

const NO_WEBSITE_ENT_PLANS = [
  'sync-4-dexyp-m2m',
  'plus-4-dexyp-m2m',
  'addon-4-mc-m2m',
]

const SCHEMA = yup.object().shape({
  domain: yup
    .string()
    .required('This field is required')
    .min(5, 'Must be at least 5 characters')
    .max(120, 'Must be at most 120 characters')
    .test(
      'validate-domain-format',
      'Incorrect domain format',
      function (value) {
        return value.match(
          /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
        )
      },
    ),
})

function WebsiteItem({
  item,
  description,
  radioButtonOptions,
  handleAddProduct,
  onDomainChange,
  isDisabled,
  isTSS,
  currentAddOns,
}) {
  const client = useAuthClient()
  const {run, isLoading} = useAsync()
  const [displayFirstSection, setDisplayFirstSection] = React.useState(false)
  const [displayCheckInput, setDisplayCheckInput] = React.useState(false)
  const [validDomainResponse, setValidDomainResponse] = React.useState({})

  const {
    register,
    formState: {errors},
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(SCHEMA),
  })

  const handleDisplayFirstSection = data => {
    handleAddProduct(data)
    setDisplayFirstSection(data.value === 'Yes' || false)

    if (data.value === 'No') {
      setValidDomainResponse({})
      setDisplayCheckInput(false)
    }
  }

  const handleDisplayCheckInput = data => {
    setDisplayCheckInput(data.value === 'Yes' || false)
  }

  const validateDomain = async ({domain}) => {
    const response = await run(client(`orders/validate-website/${domain}`))
    setValidDomainResponse(response)
    if (response.status === 'valid') {
      onDomainChange(domain)
    }
  }

  return (
    <AddOnItem
      key={item.id}
      productId={item.id}
      title={item.name}
      code={item.code}
      description={description}
      options={radioButtonOptions}
      setState={handleDisplayFirstSection}
      addonType={ADDONS_TYPES['ENTITLEMENTS']}
      isDisabled={isDisabled}
      currentAddOns={currentAddOns}
    >
      {displayFirstSection && (
        <AddOnItem
          title="Do you have an existing published Sensis or Vivial website you wish to transfer?"
          options={radioButtonOptions}
          setState={handleDisplayCheckInput}
          addonType={ADDONS_TYPES['ENTITLEMENTS']}
        >
          {displayCheckInput && (
            <div className="row ml-2 mb-4">
              <div className="col-lg-9">
                <Input
                  className="w-100 mr-4"
                  type="text"
                  placeholder="yourdomain.com"
                  variant="default"
                  name="domain"
                  disabled={isLoading}
                  register={register}
                  errors={errors}
                />

                {validDomainResponse.statusMessage && (
                  <p
                    className="mt-2"
                    style={{
                      color:
                        validDomainResponse.status === 'valid'
                          ? '#5cb85c'
                          : '#d9534f',
                    }}
                  >
                    {validDomainResponse.statusMessage}
                  </p>
                )}
              </div>
              <div className="col-lg-3">
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleSubmit(validateDomain)}
                  disabled={isLoading}
                >
                  Check
                </Button>
              </div>
            </div>
          )}
        </AddOnItem>
      )}
    </AddOnItem>
  )
}

function SocialContentGenerationItem({
  item,
  description,
  radioButtonOptions,
  handleAddProduct,
  socialBoostItem,
  isEntSelected,
  isSocialBoostSelected,
  defaultRadioButtonOptions,
  defaultYesRadioButtonOptions,
  isDisabled,
  currentAddOns,
}) {
  const [displayFirstSection, setDisplayFirstSection] = React.useState(
    isEntSelected ? true : false,
  )

  const handleDisplayFirstSection = data => {
    handleAddProduct(data)
    setDisplayFirstSection(data.value === 'Yes' || false)
  }

  return (
    <AddOnItem
      key={item.id}
      productId={item.id}
      title={item.name}
      code={item.code}
      description={description}
      options={radioButtonOptions}
      setState={handleDisplayFirstSection}
      addonType={ADDONS_TYPES['ENTITLEMENTS']}
      isDisabled={isDisabled}
      currentAddOns={currentAddOns}
    >
      {displayFirstSection && socialBoostItem.id && (
        <AddOnItem
          key={socialBoostItem.id}
          code={socialBoostItem.code}
          productId={socialBoostItem.id}
          title={socialBoostItem.name}
          description={socialBoostItem.description}
          options={
            isSocialBoostSelected
              ? defaultYesRadioButtonOptions
              : defaultRadioButtonOptions
          }
          setState={handleAddProduct}
          addonType={ADDONS_TYPES['ENTITLEMENTS']}
          currentAddOns={currentAddOns}
        />
      )}
    </AddOnItem>
  )
}

function Entitlements({
  data,
  addProduct,
  removeProduct,
  listOfProducts,
  currency,
  availableGmailSeats,
  updateAvailableGmailSeats,
  currentAddOns,
  totalGmailSeats,
  isWebsiteIncluded,
  selectedCountry,
  selectedPlan,
  unlimitedUpgrade,
  currentGmailItems,
  listOfAddons,
  setListOfAddons,
  currentPlan,
  planCreationDate,
}) {
  const client = useAuthClient()
  const {run} = useAsync()
  const [domainName, setDomainName] = React.useState(null)
  const [wasSelected, setWasSelected] = React.useState(false)
  const [shouldDefaultGMB, setShouldDefaultGMB] = React.useState(false)
  const [unlimitedPlanGMBSelected, setUnlimitedPlanGMBSelected] =
    React.useState(false)
  const {roles} = useUserRoles()
  const isTSS = roles.includes('TSS')
  const isMarketingCenter = listOfProducts.some(
    product =>
      product.type === 'selectedPlan' &&
      ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(
        product.planCode,
      ),
  )
  const isCountryUSA = selectedCountry === 'usa'
  const isCaymanIsland = selectedCountry === 'cayman'
  const isBarbados = selectedCountry === 'barbados'
  const radioButtonOptions = [
    {
      label: 'No',
      value: 'No',
      default: true,
    },
    {
      label: 'Yes',
      value: 'Yes',
    },
  ]
  const defaultYesRadioButtonOptions = [
    {
      label: 'No',
      value: 'No',
    },
    {
      label: 'Yes',
      value: 'Yes',
      default: true,
    },
  ]
  const yesRadioButtonOption = [{label: 'Yes', value: 'Yes', default: true}]
  const handleDomainChange = domain => {
    setDomainName(domain)
    removeProduct(null, ({product}) => product !== 'One Time Design Fee')
  }
  const hasUpgradeEntGmail = currentGmailItems?.some(
    ({name}) => name === 'Gmail Standard Free',
  )
  const handleAddProduct = (item, isGMBDefault) => {
    const product = data.find(x => x.name === item.name)
    if (item.value === 'Yes') {
      const price = product.currencies.find(x => x.currency === currency)
      let products = [
        {
          id: product.id,
          product: item.name,
          type: ADDONS_TYPES['ENTITLEMENTS'],
          price: `$${price.unitAmount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
          planCode: item.planCode,
        },
      ]
      if (price.setupFee && !isWebsiteIncluded) {
        products.push({
          id: product.id,
          product:
            ADD_ON.entitlements[product.name]?.setupFeeLabel || item.name,
          type: ADDONS_TYPES['ADD_ON'],
          price: `$${price.setupFee
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
          hightlighted: true,
          billingPreference: BILLING_PREFERENCES[2],
          planCode: item.planCode,
        })
      }
      addProduct(products)
      if (
        item.name === ADD_ON.entitlements['Standard Domain Based Gmail'].label
      ) {
        updateAvailableGmailSeats(1)
      }
      if (
        item.name ===
          ADD_ON.entitlements['Google My Business Optimization'].label &&
        !isGMBDefault
      ) {
        setUnlimitedPlanGMBSelected(true)
      }
    } else {
      if (
        product.name === ADD_ON.entitlements['Social Content Generation'].label
      ) {
        const socialBoostItem = data.find(
          x => x.name === ADD_ON.entitlements['Social Boost'].label,
        )
        if (socialBoostItem) {
          removeProduct([product.id, socialBoostItem.id])
        } else {
          removeProduct([product.id])
        }
      } else {
        if (shouldDefaultGMB || unlimitedUpgrade) {
          const gmbAddOn = listOfProducts.filter(
            ({product, type}) =>
              product ===
                ADD_ON.add_ons['Google My Business Optimization'].label &&
              type === 'addon',
          )
          removeProduct(gmbAddOn.length > 0 ? [gmbAddOn[0].id] : [product.id])
        }
        removeProduct([product.id])
        if (
          item.name === ADD_ON.entitlements['Standard Domain Based Gmail'].label
        ) {
          updateAvailableGmailSeats(-1)
        }
      }
      if (
        item.name ===
          ADD_ON.entitlements['Google My Business Optimization'].label &&
        !isGMBDefault
      ) {
        setUnlimitedPlanGMBSelected(false)
      }
    }
  }
  const isStandardDomainBasedGmail = listOfProducts.some(
    ({id}) => id === 'o8jwyl1azs43',
  )
  const websiteEntWithMCProPlans = NO_WEBSITE_ENT_PLANS.includes(
    selectedPlan.planCode,
  )

  const removeGMB = () => {
    const product = data.find(
      entitlement =>
        entitlement.name ===
        ADD_ON.entitlements['Google My Business Optimization'].label,
    )
    const productInCart = listOfProducts.find(
      product =>
        product.product ===
        ADD_ON.entitlements['Google My Business Optimization'].label,
    )
    if (productInCart && (shouldDefaultGMB || unlimitedUpgrade)) {
      handleAddProduct(
        {
          value: 'No',
          name: productInCart.product,
          planCode: productInCart.planCode,
        },
        true,
      )
      return product
    }
    if (productInCart && !unlimitedPlanGMBSelected) {
      handleAddProduct(
        {
          value: 'No',
          name: product.name,
          planCode: product.code,
        },
        true,
      )
    }
    return product
  }

  React.useEffect(() => {
    const setDefaultGMB = async () => {
      const isMCProEnhancedInCart = listOfProducts.some(({planCode}) =>
        ADD_ON.add_ons['Marketing Center Pro Enhanced'].addOnCodes.includes(
          planCode,
        ),
      )
      if (isMCProEnhancedInCart) return
      if (shouldDefaultGMB) {
        if (
          !data.some(
            entitlement =>
              entitlement.name ===
              ADD_ON.entitlements['Google My Business Optimization'].label,
          )
        ) {
          const response = await run(client('plans/list/all'))
          const gmbEnt = response.entitlements.find(
            ({name}) =>
              name ===
              ADD_ON.entitlements['Google My Business Optimization'].label,
          )
          const newEntitlements = [...listOfAddons.entitlements, gmbEnt]
          setListOfAddons({...listOfAddons, entitlements: newEntitlements})
        }
        if (
          listOfProducts.some(
            product =>
              product.product ===
              ADD_ON.entitlements['Google My Business Optimization'].label,
          ) &&
          !unlimitedUpgrade
        ) {
          return
        }
        if (
          !ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(
            selectedPlan.planCode,
          )
        ) {
          const item = data.find(
            entitlement =>
              entitlement.name ===
              ADD_ON.entitlements['Google My Business Optimization'].label,
          )
          removeGMB()
          if (item) {
            handleAddProduct(
              {
                value: 'Yes',
                name: item.name,
                planCode: item.code,
              },
              true,
            )
          }
        }
      } else {
        removeGMB()
      }
    }
    setDefaultGMB()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDefaultGMB, data, listOfAddons])

  React.useEffect(() => {
    const GMBAddons = [
      ADD_ON.add_ons['Thryv Leads'].label,
      ADD_ON.add_ons['Marketing Center Pro'].label,
      ADD_ON.add_ons['Marketing Center Pro Enhanced'].label,
    ]
    if (
      listOfProducts.some(({product}) => GMBAddons.includes(product)) ||
      unlimitedUpgrade
    ) {
      setShouldDefaultGMB(true)
    } else {
      setShouldDefaultGMB(false)
    }
  }, [listOfProducts, selectedPlan, unlimitedUpgrade])

  React.useEffect(() => {
    listOfProducts.forEach(product => {
      if (product.product === 'Website') {
        product['sensisSiteDomain'] = domainName
      }
    })
  }, [domainName]) //eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    data.forEach(({name, code}) => {
      const isWebsite = name === ADD_ON.entitlements['Website'].label
      const isMCProInCart = listOfProducts.some(({planCode}) =>
        ADD_ON.add_ons['Marketing Center Pro'].addOnCodes.includes(planCode),
      )
      const isMCProEnhancedInCart = listOfProducts.some(({planCode}) =>
        ADD_ON.add_ons['Marketing Center Pro Enhanced'].addOnCodes.includes(
          planCode,
        ),
      )
      if (
        isWebsite &&
        !isMCProInCart &&
        !isMCProEnhancedInCart &&
        websiteEntWithMCProPlans
      )
        return

      const isEntSelected = currentAddOns?.some(
        ({addOnCode, name: addOnName}) =>
          addOnCode === code || addOnName === name,
      )
      const isEntInProductCart = listOfProducts?.some(({product, type}) => {
        return product === name && type === ADDONS_TYPES['ENTITLEMENTS']
      })

      if (!isEntSelected || wasSelected) return

      if (isEntSelected && !isEntInProductCart) {
        handleAddProduct({value: 'Yes', name, planCode: code})
        setWasSelected(true)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAddOns, data, listOfProducts, websiteEntWithMCProPlans])

  React.useEffect(() => {
    data.forEach(({name}) => {
      const isWebsite = name === ADD_ON.entitlements['Website'].label
      const isMCProInCart = listOfProducts.some(({planCode}) =>
        ADD_ON.add_ons['Marketing Center Pro'].addOnCodes.includes(planCode),
      )
      const isMCProEnhancedInCart = listOfProducts.some(({planCode}) =>
        ADD_ON.add_ons['Marketing Center Pro Enhanced'].addOnCodes.includes(
          planCode,
        ),
      )
      const isWebsiteAddonInCart = listOfProducts.some(
        ({product}) => product === ADD_ON.add_ons['Website'].label,
      )

      if (
        websiteEntWithMCProPlans &&
        isWebsite &&
        !isMCProInCart &&
        !isMCProEnhancedInCart &&
        wasSelected &&
        isWebsiteAddonInCart
      ) {
        setWasSelected(false)
      }
    })
  }, [
    data,
    listOfProducts,
    selectedPlan.planCode,
    wasSelected,
    websiteEntWithMCProPlans,
  ])

  const setItem = item => {
    const isWebsite = item.name === ADD_ON.entitlements['Website'].label
    const isMCProInCart = listOfProducts.some(({planCode}) =>
      ADD_ON.add_ons['Marketing Center Pro'].addOnCodes.includes(planCode),
    )
    const isMCProEnhancedInCart = listOfProducts.some(({planCode}) =>
      ADD_ON.add_ons['Marketing Center Pro Enhanced'].addOnCodes.includes(
        planCode,
      ),
    )

    if (
      websiteEntWithMCProPlans &&
      isWebsite &&
      !isMCProInCart &&
      !isMCProEnhancedInCart
    )
      return
    if (item.name === 'Custom Video' && (isBarbados || isCaymanIsland)) return
    if (!isCountryUSA) {
      if (item.name === 'Custom Video') {
        item.name = 'QuickStart Custom Video'
      }
      ADD_ON.entitlements['Custom Video'].label = 'QuickStart Custom Video'
      ADD_ON.entitlements['Custom Video'].description = [
        <p key={0}>
          Thryv®️ QuickStart Custom Video option is a way to create a customised
          video experience for your business. Videos are created in 7 business
          days* and ready to upload, including to your Website, Client Portal
          and social media.
        </p>,
      ]
    }
    const _item = ADD_ON.entitlements[item.name]

    const isEntSelected = currentAddOns?.some(
      ({addOnCode, name: addOnName}) =>
        addOnCode === item.code || addOnName === item.name,
    )
    const isDisabled = isEntSelected
    const isProfessionalSelected = [
      'professional-4-dexyp-semi',
      'professional-4-dexyp-m2m',
    ].includes(selectedPlan.planCode)
    const isCurrentProfessional = [
      'professional-4-dexyp-semi',
      'professional-4-dexyp-m2m',
    ].includes(currentPlan)
    const isSCG =
      item.name === ADD_ON.entitlements['Social Content Generation'].label
    const purchasedBeforeCutoff = dayjs(planCreationDate).isBefore('02-08-2024')

    if (
      isSCG &&
      isProfessionalSelected &&
      isCurrentProfessional &&
      !purchasedBeforeCutoff
    )
      return
    if (isSCG && isProfessionalSelected && !isCurrentProfessional) return
    if (item.name === ADD_ON.entitlements['Social Boost'].label) return
    if (item.name === ADD_ON.entitlements['Website'].label)
      return (
        <WebsiteItem
          key={item.id}
          item={item}
          description={_item.description}
          radioButtonOptions={
            isEntSelected ? defaultYesRadioButtonOptions : radioButtonOptions
          }
          handleAddProduct={handleAddProduct}
          onDomainChange={handleDomainChange}
          isDisabled={isDisabled}
          isTSS={isTSS}
          currentAddOns={currentAddOns}
        />
      )
    if (
      item.name === ADD_ON.entitlements['Social Content Generation'].label &&
      data.find(x => x.name === 'Social Boost')
    ) {
      const socialBoostItem = data.find(
        x => x.name === ADD_ON.entitlements['Social Boost'].label,
      )
      const isSocialBoostSelected = currentAddOns?.some(
        ({addOnCode}) => addOnCode === socialBoostItem.code,
      )
      socialBoostItem.description =
        ADD_ON.entitlements[socialBoostItem?.name]?.description
      return (
        <SocialContentGenerationItem
          item={item}
          key={item.id}
          description={_item.description}
          radioButtonOptions={
            isEntSelected ? defaultYesRadioButtonOptions : radioButtonOptions
          }
          handleAddProduct={handleAddProduct}
          socialBoostItem={socialBoostItem}
          removeProduct={removeProduct}
          isEntSelected={isEntSelected}
          isSocialBoostSelected={isSocialBoostSelected}
          defaultRadioButtonOptions={radioButtonOptions}
          defaultYesRadioButtonOptions={defaultYesRadioButtonOptions}
          isDisabled={isDisabled}
          currentAddOns={currentAddOns}
        />
      )
    }
    if (
      item.name === ADD_ON.entitlements['Standard Domain Based Gmail'].label
    ) {
      return (
        <AddOnItem
          key={item.id}
          productId={item.id}
          title={item.name}
          code={item.code}
          description={[
            ..._item.description,
            <p key={1} style={{fontWeight: 'bold'}}>
              Based on seats, you have {totalGmailSeats} available email
              accounts to add.
            </p>,
          ]}
          options={
            hasUpgradeEntGmail
              ? defaultYesRadioButtonOptions
              : radioButtonOptions
          }
          radioButtonDisable={
            availableGmailSeats === 0 && !isStandardDomainBasedGmail
          }
          setState={handleAddProduct}
          listOfProducts={listOfProducts}
          addonType={ADDONS_TYPES['ENTITLEMENTS']}
          isDisabled={isDisabled}
          currentAddOns={currentAddOns}
        />
      )
    }
    if (
      item.name ===
        ADD_ON.entitlements['Google My Business Optimization'].label &&
      selectedPlan.name !== 'unlimited' &&
      !shouldDefaultGMB
    ) {
      return
    }

    if (
      (item.name ===
        ADD_ON.entitlements['Google My Business Optimization'].label &&
        shouldDefaultGMB) ||
      item.code === 'ent-4-mktproeditor-m2m' ||
      item.code === 'ent-4-signaturesbcunlimited-m2m'
    ) {
      return (
        <AddOnItem
          key={item.id}
          productId={item.id}
          title={item.name}
          code={item.code}
          description={_item?.description}
          options={radioButtonOptions}
          setState={handleAddProduct}
          listOfProducts={listOfProducts}
          addonType={ADDONS_TYPES['ENTITLEMENTS']}
          currentAddOns={currentAddOns}
        />
      )
    }
    return (
      <AddOnItem
        key={item.id}
        productId={item.id}
        title={item.name}
        code={item.code}
        description={_item?.description}
        options={
          isMarketingCenter
            ? yesRadioButtonOption
            : isEntSelected
            ? defaultYesRadioButtonOptions
            : radioButtonOptions
        }
        setState={handleAddProduct}
        listOfProducts={listOfProducts}
        addonType={ADDONS_TYPES['ENTITLEMENTS']}
        isDisabled={isDisabled}
        currentAddOns={currentAddOns}
      />
    )
  }
  return (
    <SelectionAdds>
      <Header fontWeight="bold" variant="h5" className="ml-3 mt-3">
        Entitlements
      </Header>
      <ItemContainer>{data?.map(item => setItem(item))}</ItemContainer>
    </SelectionAdds>
  )
}

export {Entitlements}
