const apiURL = process.env.REACT_APP_API_URL

async function client(
  endpoint,
  {data, token, headers: customHeaders, ...customConfig} = {},
) {
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      'Content-Type': data ? 'application/json' : undefined,
      ...customHeaders,
    },
    ...customConfig,
  }

  return window.fetch(`${apiURL}/${endpoint}`, config).then(async response => {
    if (response.status === 401) {
      // TODO: logout()
      // refresh the page for them
      // window.location.assign(window.location)
      return Promise.reject({message: 'Please re-authenticate.'})
    }

    if (response.status === 404) {
      const errObjectString = await response.text()
      if (errObjectString === 'Not Found') {
        return Promise.reject({message: 'Not found', status: 404})
      }
      const errObject = JSON.parse(errObjectString)
      return Promise.reject({
        message: errObject?.message || 'Not found',
        status: 404,
      })
    }

    let ignoreInviteData
    try {
      ignoreInviteData = await response.clone().json()
    } catch {
      ignoreInviteData = await response.clone().text()
    }
    if (ignoreInviteData?.message === 'ignore invite error') {
      return
    }

    let data
    if (response.status === 201) {
      try {
        data = await response.json()
      } catch {
        data = undefined
      }
    }
    if (response.status !== 204 && response.status !== 201) {
      if (
        ['application/pdf', 'image/png'].includes(
          response.headers.get('Content-Type'),
        )
      ) {
        data = await response.blob()
      } else {
        data = await response.json()
      }
    }
    if (response.ok) {
      // eslint-disable-next-line no-prototype-builtins
      if (data && data.hasOwnProperty('sfToken')) {
        sessionStorage.setItem('sfToken', JSON.stringify(data.sfToken))
        delete data.sfToken
      }
      return data
    } else {
      return Promise.reject(data)
    }
  })
}

export {client}
