import React from 'react'
import {ScansHeader} from '../components/ui/business-scans'
import {Button} from '@thryvlabs/maverick'
import {useNavigate} from 'react-router-dom'

const Support = () => {
  const navigate = useNavigate()
  const onHandleNewOrder = () => {
    navigate('/order/new')
  }
  return (
    <div>
      <ScansHeader>
        <div className="scans-search-tips"></div>
        <div className="d-flex">
          <Button
            variant="primary"
            className="mr-4 mt-3 mr-5"
            onClick={onHandleNewOrder}
          >
            New Order
          </Button>
        </div>
      </ScansHeader>
      <section className="container mt-5">
        <h1
          className="text-center font-open-sans font-normal mb-4 pt-5"
          style={{
            fontSize: '30px',
            fontWeight: '600',
            color: 'rgb(111, 111, 111)',
          }}
        >
          You have questions. We have answers.
        </h1>
        <div
          className="text-center font-open-sans font-normal mb-4 pt-5"
          style={{fontSize: '20px'}}
        >
          <i className="fa fa-light fa-phone fa-lg mb-3"></i>
          <br />
          Call us at <span style={{color: '#EE7B45'}}>888.231.2190</span>
          <br />
          <i className="fa fa-solid fa-envelope fa-lg mt-5 mb-3"></i>
          <br />
          Email us at{' '}
          <a
            href="mailto: SolutionCenter@thryv.com"
            target="_blank"
            rel="noreferrer"
          >
            SolutionCenter@thryv.com
          </a>
        </div>
      </section>
    </div>
  )
}

export {Support}
