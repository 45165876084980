import React from 'react'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {toast} from 'react-toastify'
import {Controller, useForm} from 'react-hook-form'
import rawCountryStates from 'iso3166-2-db/i18n/en'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'

import {
  Select,
  ParagraphText,
  Input,
  Button,
  RadioGroup,
  Modal,
} from '@thryvlabs/maverick'
import {useAuthClient} from '../utils/use-auth-client'
import {useAsync} from '../utils/use-async'
import AsyncSelect from 'react-select/async'

import {
  MainContainer,
  SectionContainer,
} from '../components/ui/new-order-short-cut'

const COUNTRIES = [
  {name: 'United States', value: 'usa', isoValue: 'US'},
  {name: 'Canada', value: 'can', isoValue: 'CA'},
  {name: 'Australia', value: 'aus', isoValue: 'AU'},
  {name: 'Barbados', value: 'barbados', isoValue: 'BB'},
  {name: 'Cayman Islands', value: 'cayman', isoValue: 'KY'},
]

const CURRENCYS = {
  usa: 'USD',
  can: 'CAD',
  aus: 'AUD',
  barbados: 'USD',
  cayman: 'USD',
}

const THRYV_PRODUCTS = [
  {name: 'Industry Solution', value: 'industrySolution'},
  {name: 'Sync', value: 'sync'},
]

const BILLING_PREFERENCES = [
  {name: 'Monthly', value: 'Monthly'},
  {name: 'Semi-Annually', value: 'Semi-Annually'},
]

const SYNC_PRODUCTS = [
  {name: 'Sync', value: 'sync'},
  {name: 'Leads + Sync', value: 'sync_leads'},
  {name: 'Website + Sync', value: 'sync_website'},
  {name: 'Google + Sync', value: 'sync_gmb'},
  {name: 'ESS + Sync', value: 'sync_ess'},
]

const DIFFERENT_SALES_REP_OPTIONS = [
  {name: 'Yes', value: true},
  {name: 'No', value: false},
]

const REQUIRED_FIELD_MESSAGE = 'This field is required'
const REQUIRED_OPTION_MESSAGE = 'Please select an option'
const MIN_CHARACTERS_REQUIRED = 'Must be at least 3 characters'
const MAX_CHARACTERS_REQUIRED = 'Must be at most 120 characters'
const SALES_REP_INFO_NOT_FOUND = 'Sales rep not Found.'

const SCHEMA = yup.object().shape({
  firstName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  lastName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  email: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .email('Must be a valid email'),
  company: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  leadID: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  postCode: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(5, 'Must be at least 5 characters')
    .max(6, 'Must be at most 6 characters'),
  streetNumber: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  address: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  city: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  country: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
  state: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
  selectPlan: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
  industry: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
  mobile: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      'validate-phone-format',
      'Incorrect phone number format',
      function (value) {
        return (
          value?.match(/^(0[23478]\d{2})[\s.-]?\d{3}[\s.-]?\d{3}$/) ||
          value?.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
        )
      },
    ),
  companyPhone: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      'validate-phone-format',
      'Incorrect phone number format',
      function (value) {
        return (
          value?.match(/^(0[23478]\d{2})[\s.-]?\d{3}[\s.-]?\d{3}$/) ||
          value?.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
        )
      },
    ),
  differentSalesRep: yup
    .object()
    .shape({
      name: yup.string().required(REQUIRED_OPTION_MESSAGE),
      value: yup.string().required(REQUIRED_OPTION_MESSAGE),
    })
    .optional(),
  salesRepCode: yup.string().when('differentSalesRep', {
    is: differentSalesRep => differentSalesRep.value === 'true',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
  abn: yup.string().when('country', {
    is: country => country.value === 'aus',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
  salesRepInfo: yup.string().when('differentSalesRep', {
    is: differentSalesRep => differentSalesRep.value === 'true',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
})

const DEFAULT_VALUES = {
  country: COUNTRIES[0],
  thryvProduct: THRYV_PRODUCTS[0],
  syncProduct: SYNC_PRODUCTS[0],
  differentSalesRep: DIFFERENT_SALES_REP_OPTIONS[1],
  billingPreference: BILLING_PREFERENCES[1],
}

function NewOrderShortCut() {
  const [isLoading, setIsLoading] = React.useState(true)
  const [listOfIndustries, setListOfIndustries] = React.useState([])
  const [listOfStates, setListOfStates] = React.useState([])
  const [selectedSalesRep, setSelectedSalesRep] = React.useState(null)
  const [shouldResetStates, setShouldResetStates] = React.useState(false)
  const [listOfPlans, setListOfPlans] = React.useState({
    plans: [],
    additionalData: [],
  })
  const [listOfPlansPrices, setListOfPlansPrices] = React.useState([])
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    React.useState(false)
  const [emailRegistered, setEmailRegistered] = React.useState(true)
  const [showAccountExistsModal, setShowAccountExistsModal] =
    React.useState(false)
  const navigate = useNavigate()

  const client = useAuthClient()
  const {run} = useAsync({
    status: 'pending',
  })

  const {
    register,
    formState: {errors},
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(SCHEMA),
  })

  const [
    country,
    thryvProduct,
    billingPreference,
    differentSalesRep,
    syncProduct,
    salesRepInfo,
    email,
  ] = watch([
    'country',
    'thryvProduct',
    'billingPreference',
    'differentSalesRep',
    'syncProduct',
    'salesRepInfo',
    'salesRepCode',
    'email',
  ])

  const fetchIndustries = React.useCallback(
    async country => {
      let url = `industries/${country}`
      if (
        country === COUNTRIES[0].value ||
        country === COUNTRIES[3].value ||
        country === COUNTRIES[4].value
      ) {
        url = 'industries/usa'
      } else {
        url = `industries/${country}`
      }
      const response = await run(client(url))
      const fetchedIndustries = response.map(({name, template}) => ({
        name,
        value: template,
      }))
      setListOfIndustries(fetchedIndustries)
    },
    [run, client],
  )

  const fetchPlans = React.useCallback(async () => {
    let url = 'plans/prices/countries'
    const response = await client(url)
    setListOfPlansPrices(response)
    setIsLoading(false)
  }, [client])

  const getPlansPrices = React.useCallback(() => {
    if (!listOfPlansPrices[country.isoValue]) {
      return
    }
    const interval =
      billingPreference?.value === 'm2m' ? 'monthly' : 'semiAnnually'
    if (
      !listOfPlansPrices[country.isoValue] ||
      !listOfPlansPrices[country.isoValue][interval]
    ) {
      setListOfPlans({
        plans: [],
        additionalData: [],
      })
      return
    }
    const listPlans = listOfPlansPrices[country.isoValue][interval]
    const plans = listPlans.map(({planName}) => ({
      name: planName,
      value: planName,
    }))
    setListOfPlans({
      plans,
      additionalData: listPlans.map(({amount, onboarding}, i) => (
        <span className="plans_price" key={i}>
          <strong>$</strong> {amount}{' '}
          {onboarding && (
            <>
              + <strong>$</strong>
              {onboarding} onboarding fee.
            </>
          )}
        </span>
      )),
    })
    setValue('selectPlan', undefined)
  }, [listOfPlansPrices, billingPreference, country.isoValue, setValue])

  const fetchNameOptions = async (inputValue, countrySelected) => {
    if (!countrySelected) {
      countrySelected = 'US'
    }
    const sftokenData = await client(
      `salesforce/services/fetchSalesforceToken?country=${countrySelected}`,
    )
    const sftoken = sftokenData.sftoken
    if (!countrySelected) {
      countrySelected = 'US'
    }
    const data = await client(
      `salesforce/services/fetchUsers?token=${sftoken}&searchTerm=${inputValue}&country=${countrySelected}`,
    )
    const filterData = data.data.filter(item =>
      item.Name.toLowerCase().includes(inputValue.toLowerCase()),
    )
    return filterData.map(item => ({
      label: item.Name,
      value: item.Name,
      salesRepInfo: item,
    }))
  }

  const loadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length >= 3) {
      fetchNameOptions(inputValue, country.isoValue).then(options => {
        callback(options)
      })
    } else if (!inputValue) {
      callback([])
    }
  }
  const handleSalesRepOptionChange = selectedOption => {
    setSelectedSalesRep(selectedOption)
    const salesRepInfo = selectedOption.salesRepInfo
    try {
      setValue('salesRepCode', salesRepInfo.FederationIdentifier)

      setValue('salesRepInfo', `${salesRepInfo.Name}`)
      setValue('behalfFirst', salesRepInfo.FirstName)
      setValue('behalfLast', salesRepInfo.LastName)
      setValue('behalfEmail', salesRepInfo.Email)
    } catch (error) {
      if (error.status === 404) {
        setValue('salesRepInfo', SALES_REP_INFO_NOT_FOUND)
      }
    }
    trigger('salesRepInfo')
  }

  const getStates = countryIso => {
    const states = rawCountryStates[countryIso].regions
      .map(({iso, name}) => ({
        name,
        value: iso,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1))
    setListOfStates(states)
  }

  const submitForm = async formData => {
    const data = {
      ...formData,
      behalf: formData.salesRepCode,
      country: formData.country.value,
      state: formData.state.value,
      industry: formData.industry.value,
      software: thryvProduct.value,
      package: `${formData.selectPlan.value}-4-dexyp-${formData.billingPreference.value}`,
    }
    try {
      await run(client('orders/', {data}))
      toast.success('Form submitted successfully')
      navigate('/orders')
    } catch (responseError) {
      if (responseError.status !== 400) {
        throw responseError
      }
      responseError.errors.forEach(({message}) => toast.error(message))
    }
  }

  const handleEmailValidation = async () => {
    if (email) {
      const response = await run(client(`orders/validate-email/${email}`))
      setEmailRegistered(response.isValidEmail)
      setShowAccountExistsModal(response.isValidEmail)
    }
  }

  React.useEffect(() => {
    if (country?.value) {
      fetchIndustries(country.value)
      getStates(country.isoValue)
      setShouldResetStates(true)
      setValue('curr', CURRENCYS[country.value])
      setValue('state', undefined)
      trigger(['curr'])
    }
  }, [country, fetchIndustries, trigger, setValue])

  React.useEffect(() => {
    if (thryvProduct.value === 'industrySolution') {
      setValue('syncProduct', undefined)
      trigger(['syncProduct'])
    } else {
      setValue('industry', undefined)
      trigger(['industry'])
    }
  }, [thryvProduct, trigger, setValue])

  React.useEffect(() => {
    getPlansPrices()
  }, [getPlansPrices])

  React.useEffect(() => {
    fetchPlans()
  }, [fetchPlans])

  React.useEffect(() => {
    if (differentSalesRep.value === true) {
      if (salesRepInfo === SALES_REP_INFO_NOT_FOUND || !salesRepInfo) {
        setIsSubmitButtonDisabled(true)
      } else {
        setIsSubmitButtonDisabled(false)
      }
    } else {
      setIsSubmitButtonDisabled(false)
    }
  }, [salesRepInfo, differentSalesRep])

  return (
    <>
      {showAccountExistsModal && (
        <Modal
          altBtn={<></>}
          openOnLoad
          variant="default"
          footer
          action
          btnAction={() => setShowAccountExistsModal(false)}
          onClose={() => setShowAccountExistsModal(false)}
          btnActionText="Ok"
          hideX
        >
          An account with this email already exists. <br />
          Please enter a different email address.
        </Modal>
      )}
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <SkeletonTheme color="#e6e6e6" highlightColor="#fff">
            <Skeleton
              containerTestId="new-order-loader"
              height="70vh"
              width="70vw"
            />
          </SkeletonTheme>
        </div>
      ) : (
        <MainContainer className="p-5">
          <form>
            <div className="select_container">
              <Controller
                control={control}
                name="country"
                render={({field: {onChange, name}}) => (
                  <Select
                    options={COUNTRIES}
                    width="full"
                    className="select"
                    selectLabel="Where are you selling Thryv?"
                    selectedOption={country}
                    withLabel={true}
                    onChange={onChange}
                    errors={errors}
                    name={name}
                  />
                )}
              />
            </div>
            <div className="select_container">
              <Controller
                control={control}
                name="syncProduct"
                render={({field: {onChange, name}}) => (
                  <Select
                    width="full"
                    className="select mt-5"
                    options={SYNC_PRODUCTS}
                    selectedOption={syncProduct}
                    selectLabel="Select Sync Product"
                    withLabel={true}
                    onChange={onChange}
                    errors={errors}
                    name={name}
                  />
                )}
              />
            </div>
            <div className="select_container">
              <Controller
                control={control}
                name="industry"
                render={({field: {onChange, name}}) => (
                  <Select
                    options={listOfIndustries}
                    width="full"
                    className="select mt-5"
                    selectLabel="Select Industry"
                    withLabel={true}
                    onChange={onChange}
                    errors={errors}
                    name={name}
                  />
                )}
              />
            </div>
            <Controller
              control={control}
              name="billingPreference"
              className="mb-3"
              render={({field: {onChange, name}}) => (
                <RadioGroup
                  data={BILLING_PREFERENCES}
                  value={billingPreference}
                  label="Billing Preferences"
                  row
                  layoutStyles={{
                    gap: '10px',
                  }}
                  onChange={onChange}
                  errors={errors}
                  name={name}
                />
              )}
            />
            <Controller
              control={control}
              name="selectPlan"
              render={({field: {onChange, name}}) => (
                <RadioGroup
                  data={listOfPlans.plans}
                  additionalContent={listOfPlans.additionalData}
                  label="Select Plan"
                  row
                  layoutStyles={{
                    display: 'grid',
                    gridTemplateColumns: 'auto',
                    gap: '10px',
                  }}
                  column={true}
                  onChange={onChange}
                  errors={errors}
                  name={name}
                />
              )}
            />
            <SectionContainer className="basic_info_section">
              <ParagraphText
                variant="reg"
                className="mb-4 text-thryv-steel section_title--basic_info"
              >
                Basic Info
              </ParagraphText>
              <div />
              <div className="input_container_email_address">
                <Input
                  className="w-100"
                  type="text"
                  placeholder="Email Address"
                  aria-label="Email Address"
                  name="email"
                  withLabel
                  labelType="floating"
                  variant="default"
                  register={register}
                  errors={errors}
                />
                <Button
                  variant="primary"
                  type="button"
                  className="mr-4 validate-email-button"
                  onClick={handleEmailValidation}
                >
                  Validate
                </Button>
              </div>

              <div className="input_container">
                <Input
                  className="w-100"
                  type="text"
                  placeholder="Mobile number"
                  aria-label="Mobile number"
                  name="mobile"
                  withLabel
                  labelType="floating"
                  variant="default"
                  register={register}
                  errors={errors}
                  disabled={emailRegistered}
                />
              </div>
              <div className="input_container">
                <Input
                  className="w-100"
                  type="text"
                  placeholder="First Name"
                  aria-label="First Name"
                  name="firstName"
                  withLabel
                  labelType="floating"
                  variant="default"
                  register={register}
                  errors={errors}
                  disabled={emailRegistered}
                />
              </div>
              <div className="input_container">
                <Input
                  className="w-100"
                  type="text"
                  placeholder="Last Name"
                  aria-label="Last Name"
                  name="lastName"
                  withLabel
                  labelType="floating"
                  variant="default"
                  register={register}
                  errors={errors}
                  disabled={emailRegistered}
                />
              </div>
              <div className="input_container">
                <Input
                  className="w-100"
                  type="text"
                  placeholder="Company"
                  aria-label="Company"
                  name="company"
                  withLabel
                  labelType="floating"
                  variant="default"
                  register={register}
                  errors={errors}
                  disabled={emailRegistered}
                />
              </div>
              <div className="input_container">
                <Input
                  className="w-100"
                  type="text"
                  placeholder={
                    country.isoValue === 'AU'
                      ? 'Sensis Salesforce Customer ID'
                      : 'Salesforce Customer ID'
                  }
                  aria-label={
                    country.isoValue === 'AU'
                      ? 'Sensis Salesforce Customer ID'
                      : 'Salesforce Customer ID'
                  }
                  name="salesforceCustomerID"
                  withLabel
                  labelType="floating"
                  variant="default"
                  register={register}
                  errors={errors}
                  disabled={emailRegistered}
                />
              </div>
              <div className="input_container">
                <Input
                  className="w-100"
                  type="text"
                  placeholder="Lead ID"
                  aria-label="Lead ID"
                  name="leadID"
                  withLabel
                  labelType="floating"
                  variant="default"
                  register={register}
                  errors={errors}
                  disabled={emailRegistered}
                />
              </div>
              {country.isoValue === 'AU' && (
                <div className="input_container">
                  <Input
                    className="w-100"
                    type="text"
                    placeholder="Australian Business Number (ABN)"
                    aria-label="Australian Business Numberbn"
                    name="abn"
                    withLabel
                    labelType="floating"
                    variant="default"
                    register={register}
                    errors={errors}
                    disabled={emailRegistered}
                  />
                </div>
              )}
            </SectionContainer>
            <SectionContainer className="business_address_section">
              <ParagraphText
                variant="reg"
                className="mb-4 text-thryv-steel section_title--business_address"
              >
                Business Address
              </ParagraphText>
              <div />
              <div className="span-cols ">
                <div className="input_container">
                  <Input
                    className="w-100"
                    type="text"
                    placeholder="Customer Address"
                    aria-label="Customer Address"
                    name="address"
                    withLabel
                    labelType="floating"
                    variant="default"
                    register={register}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="select_container">
                <Controller
                  control={control}
                  name="state"
                  render={({field: {onChange, name}}) => (
                    <Select
                      options={listOfStates}
                      width="full"
                      className="select mt-0"
                      selectLabel="Select state"
                      withLabel={true}
                      onChange={onChange}
                      errors={errors}
                      cleared={shouldResetStates}
                      setCleared={setShouldResetStates}
                      name={name}
                    />
                  )}
                />
              </div>
              <div className="input_container">
                <Input
                  className="w-100 custom-mt"
                  type="text"
                  placeholder="City"
                  aria-label="City"
                  name="city"
                  withLabel
                  labelType="floating"
                  variant="default"
                  register={register}
                  errors={errors}
                  disabled={emailRegistered}
                />
              </div>
              <div className="input_container">
                <Input
                  className="w-100"
                  type="text"
                  placeholder="Street Number"
                  aria-label="Street Number"
                  name="streetNumber"
                  withLabel
                  labelType="floating"
                  variant="default"
                  register={register}
                  errors={errors}
                  disabled={emailRegistered}
                />
              </div>
              <div className="input_container">
                <Input
                  className="w-100"
                  type="text"
                  placeholder="Postal Code"
                  aria-label="Postal Code"
                  name="postCode"
                  withLabel
                  labelType="floating"
                  variant="default"
                  register={register}
                  errors={errors}
                  disabled={emailRegistered}
                />
              </div>
              <div className="input_container">
                <Input
                  className="w-100"
                  type="text"
                  placeholder="Business Phone"
                  aria-label="Business Phone"
                  name="companyPhone"
                  withLabel
                  labelType="floating"
                  variant="default"
                  register={register}
                  errors={errors}
                  disabled={emailRegistered}
                />
              </div>
            </SectionContainer>

            <Controller
              control={control}
              name="differentSalesRep"
              render={({field: {onChange, name}}) => (
                <RadioGroup
                  data={DIFFERENT_SALES_REP_OPTIONS}
                  value={DIFFERENT_SALES_REP_OPTIONS[1]}
                  label="Is this sale on behalf of another sales rep or partner?"
                  row
                  layoutStyles={{
                    gap: '10px',
                  }}
                  onChange={onChange}
                  errors={errors}
                  name={name}
                />
              )}
            />
            {differentSalesRep?.value && (
              <>
                <div className="input_container">
                  <AsyncSelect
                    cacheOptions
                    placeholder="Search by Name"
                    loadOptions={loadOptions}
                    onChange={handleSalesRepOptionChange}
                    value={selectedSalesRep}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: 'silver',
                        primary: 'black',
                      },
                    })}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <ParagraphText variant="reg" className="mb-0 text-thryv-stee">
                    {salesRepInfo}
                  </ParagraphText>
                </div>
              </>
            )}
            <div className="d-flex justify-content-end">
              <Button
                disabled={isSubmitButtonDisabled || emailRegistered}
                variant="primary"
                type="submit"
                className="new-order-button d-flex justify-content-around align-items-center mr-4"
                onClick={handleSubmit(submitForm)}
              >
                Save
              </Button>
            </div>
          </form>
        </MainContainer>
      )}
    </>
  )
}

export {NewOrderShortCut}
