import React from 'react'
import {ParagraphText, Icon} from '@thryvlabs/maverick'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {useFlags} from 'launchdarkly-react-client-sdk'

import {TableContainer} from './ui/results-table'

const ResultsTable = ({
  children,
  cols,
  isLoading,
  hasRecords,
  noDataFoundMessage = '',
  sortColumns,
  setSortColumns,
  sortRecords,
}) => {
  const {frontendInviteSorting} = useFlags()

  const setTableBody = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={cols.length}>
            <SkeletonTheme color="#e6e6e6" highlightColor="#fff">
              <Skeleton
                height="300px"
                width="100%"
                borderRadius="1rem"
                containerTestId="results-table-loader"
              />
            </SkeletonTheme>
          </td>
        </tr>
      )
    }
    if (!hasRecords) {
      return (
        <tr>
          <td colSpan={cols.length}>
            <ParagraphText variant="reg" className="m-0">
              {noDataFoundMessage || 'Sorry, we could not find any results.'}
            </ParagraphText>
          </td>
        </tr>
      )
    }
    return children
  }

  const handleSorting = col => {
    sortColumns[col].sorted = true
    for (const x in sortColumns) {
      if (x === col) {
        sortColumns[col].sorted = true
        sortColumns[col].sortType =
          sortColumns[col].sortType === 'asc' ? 'desc' : 'asc'
      } else {
        sortColumns[x].sorted = false
        sortColumns[x].sortType = 'desc'
      }
    }
    setSortColumns({...sortColumns})
    sortRecords(sortColumns[col])
  }

  return (
    <TableContainer>
      <table className="table table-hover table-small">
        <thead>
          <tr>
            {cols.map((col, index) => (
              <th scope="col" key={index}>
                <h6
                  className={`text-thryv-steel font-semibold font-montserrat text-[14px] capitalize ${
                    frontendInviteSorting && 'd-flex'
                  }`}
                >
                  {col}
                  {frontendInviteSorting && sortColumns && sortColumns[col] && (
                    <Icon
                      type={sortColumns[col].sorted ? 'solid' : 'regular'}
                      variant={
                        sortColumns[col].sortType === 'asc' &&
                        sortColumns[col].sorted
                          ? 'chevronUp'
                          : 'chevronDown'
                      }
                      color="#808080"
                      width="10"
                      className="ml-2"
                      onClick={() => handleSorting(col)}
                      style={{cursor: 'pointer'}}
                    />
                  )}
                </h6>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="w-100 p-3">{setTableBody()}</tbody>
      </table>
    </TableContainer>
  )
}

export {ResultsTable}
