import React, {useState, useEffect, useRef} from 'react'
import ToolsContainer from '../components/tools-page/tools-container'
import {useFlags} from 'launchdarkly-react-client-sdk'

const ToolsPage = () => {
  const {frontendSideNav} = useFlags()
  const repId = useRef('')
  const tRef = useRef('')
  const create = useRef('createTool')
  const tool = useRef('')
  const savetool = useRef('')
  const [repIdValue, setRepIdValue] = useState('')

  useEffect(() => {
    const url = new URL(window.location.href)
    const repid = url.searchParams.get('rep_id')
    setRepIdValue(repid)
  }, [setRepIdValue])

  return (
    <div
      className={`wrapper py-0 w-100 h-100 pt-5 mt-5 ${
        frontendSideNav && 'd-flex justify-content-center'
      }`}
      style={{paddingTop: '0 !important'}}
    >
      <ToolsContainer
        repId={repId}
        repIdValue={repIdValue}
        tRef={tRef}
        tool={tool}
        create={create}
        savetool={savetool}
      />
    </div>
  )
}

export {ToolsPage}
