import React from 'react'
import {Header, RadioGroup, Select} from '@thryvlabs/maverick'

import {useUserRoles} from '../utils/use-user-roles'
import {NavBar, ShowcaseIframe} from '../components/ui/product-showcase'

const PRODUCT_TYPES = [
  {name: 'Thryv', value: 'Thryv'},
  {name: 'Marketing Center', value: 'Marketing Center'},
]

const PRODUCT_TYPES_LEGACY = [
  {name: 'Thryv 5.0', value: 'Thryv'},
  {name: 'Thryv 4.0 / Legacy', value: 'Legacy'},
]

const THRYV_COUNTRIES = [
  {name: 'United States / Canada', value: 'United States'},
  {name: 'Australia / New Zealand', value: 'Australia'},
]

const ProductShowcase = () => {
  const [selectedCountry, setSelectedCountry] = React.useState(
    THRYV_COUNTRIES[0],
  )
  const [productShowcase, setProductShowcase] = React.useState({
    name: 'Thryv',
    value: 'Thryv',
  })
  const {roles} = useUserRoles()
  const formattedRoles = roles.map(role =>
    role.toLowerCase().replace(/\s/g, ''),
  )
  const IS_AUSTRALIA_USER = formattedRoles.includes('australia')

  const onProductChange = value => {
    setProductShowcase(value)
  }

  if (IS_AUSTRALIA_USER) {
    return (
      <>
        <ShowcaseIframe
          title="ausProductShowcase"
          className="mt-3"
          id="productType"
          src="https://aus-collateral.thryv.info/"
        ></ShowcaseIframe>
      </>
    )
  }
  return (
    <>
      <NavBar>
        <Header fontWeight="normal" variant="h1">
          Product Showcase
        </Header>

        <div className="showcase-selection">
          <RadioGroup
            onChange={value => onProductChange(value)}
            data={PRODUCT_TYPES_LEGACY}
            value={PRODUCT_TYPES[0]}
            row
            layoutStyles={{
              position: 'relative',
              top: '-35px',
              gap: '15px',
            }}
          />
        </div>

        {productShowcase.value === 'Thryv' && (
          <div className="showcase-selection">
            <Select
              width="full"
              options={THRYV_COUNTRIES}
              selectLabel="Country"
              selectedOption={selectedCountry}
              setSelectedOption={setSelectedCountry}
              className="select-country"
            />
          </div>
        )}
      </NavBar>

      {productShowcase.value === 'Thryv' &&
        selectedCountry.value === 'United States' && (
          <ShowcaseIframe
            title="usThryvProductShowcase"
            id="productType"
            src="https://collateral.thryv.info/"
          ></ShowcaseIframe>
        )}
      {productShowcase.value === 'Thryv' &&
        selectedCountry.value === 'Australia' && (
          <ShowcaseIframe
            title="ausThryvProductShowcase"
            id="productType"
            src="https://aus-collateral.thryv.info/"
          ></ShowcaseIframe>
        )}
      {productShowcase.value === 'Legacy' && (
        <ShowcaseIframe
          title="thryvLegacyProductShowcase"
          id="productType"
          src="https://legacycollateral.thryvdemo.com/"
        ></ShowcaseIframe>
      )}
      {productShowcase.value === 'Marketing Center' && (
        <ShowcaseIframe
          title="marketingCenterProductShowcase"
          id="productType"
          src="https://collateral.thryv.info/thryv-marketing-center"
        ></ShowcaseIframe>
      )}
    </>
  )
}

export {ProductShowcase}
