import styled from '@emotion/styled'

export const SelectionAdds = styled.div`
  width: 90%;
  display: grid;
  flex-flow: row wrap;
  align-content: center;
  padding: 2% 0;
`
export const ItemContainer = styled.div`
  margin-left: -1em;
  margin-bottom: 0.5rem;
`
