import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'
import {SCORE_COLORS} from '../../utils/constants'

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 500px auto;
  grid-template-areas:
    'side content'
    'footer footer';
  grid-gap: 10px;
  background-color: #eef2ff;
  max-width: 100vw;
  padding-top: 2.5rem;
  & .top {
    grid-area: top;
    margin-bottom: 100px;
  }
  & .side {
    grid-area: side;
    position: relative;
    & .company__results__resume__container {
      position: sticky;
      top: 80px;
      margin: auto;
    }
  }
  & .content {
    grid-area: content;
  }
  & .footer {
    grid-area: footer;
  }
  @media screen and ${device.laptopL} {
    grid-template-columns: 450px 1fr;
  }
  @media screen and ${device.laptop} {
    grid-template-columns: 100%;
    grid-template-areas:
      'top'
      'content'
      'side'
      'footer';

    & .side {
      & .company__results__resume__container {
        position: relative;
        margin: 20px auto;
      }
    }
    & .top {
      margin-bottom: 10px;
    }
  }
  @media screen and ${device.tablet} {
    & .side {
      & .company__results__resume__container {
        top: 20px;
      }
    }
  }
  @media print {
    grid-template-columns: 100%;
    grid-template-areas: 'content';
    & .side,
    .top,
    .footer {
      display: none;
    }
    & .content > section {
      width: 100%;
      max-height: 100%;
      padding: 0 40px;
      border-radius: 0px;
    }
    & .break-page-before--print-style {
      page-break-before: always !important;
    }
    & .break-page-before-avoid--print-style {
      page-break-before: avoid !important;
      margin-top: 50px;
    }
    & .mb--print-style {
      margin-bottom: 100px;
    }
  }
`
export const ScoreResumeMainContainer = styled.div`
  width: 100%;
  max-width: 400px;
  height: auto;
  background: #ffffff;
  padding: 40px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  @media screen and ${device.laptopL} {
    padding: 20px 40px;
  }
  @media screen and ${device.mobileL} {
    width: 90vw;
  }
`
export const ScoreResumeContainer = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-areas: 'icon score' 'icon scoreItems';
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-top: 25px;
  & svg {
    grid-area: icon;
  }
  & .score__percentage {
    grid-area: score;
  }
  & .score__items {
    grid-area: scoreItems;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #4d4d4d;
    & li {
      cursor: pointer;
      max-width: 50%;
    }
  }
  @media screen and ${device.laptopL} {
    padding-bottom: 0px;
    margin-top: 15px;
  }
`
export const ScoreBar = styled.div`
  margin-bottom: 10px;
  width: 90%;
  height: 5px;
  border-radius: 6px;
  background-color: #e3e6e8;
  & > div {
    width: ${({score}) => score || 0}%;
    height: 5px;
    background-color: ${({color}) => color};
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
`
export const ActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: 'button button' 'share download';
  margin: 30px 0px;
  & .share {
    grid-area: share;
  }
  & .download {
    grid-area: download;
  }
  & > div,
  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    & p {
      margin: 0 5px;
    }
  }
  & .button--link {
    grid-area: button;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    & .button {
      width: 100%;
    }
  }
`
export const CompanyResultsResumeLoader = styled.div`
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  height: 800px;
  @media screen and ${device.tablet} {
    width: 500px;
  }
  @media screen and ${device.mobileL} {
    width: 300px;
  }
`
export const CompanyInfoContainer = styled.div`
  & img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
  }
  & .company__info {
    padding-bottom: 30px;
    font-family: 'Open Sans', 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #4d4d4d;
    border-bottom: 1px solid #cccccc;
    & p {
      display: flex;
      align-items: center;
      margin: 5px 0;
    }
    & span {
      margin-left: 10px;
    }
  }
  @media screen and ${device.laptopL} {
    & img {
      width: 90px;
      height: 90px;
    }
  }
`
export const FooterContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
  padding: 50px 0px;
  background-color: #5378fc;
  text-align: center;
  & h2 {
    color: #fff;
    margin-bottom: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 58px;
  }
  & p {
    max-width: 50vw;
    margin-bottom: 20px;
    font-family: 'Open Sans' 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
  }
  @media screen and ${device.mobileL} {
    padding: 50px 10px;
    & p {
      max-width: 90vw;
    }
  }
`
export const CompanyResultsLoader = styled.div`
  margin: 0 auto;
  height: 800px;
  width: 800px;
  max-width: 100%;
  @media screen and ${device.tablet} {
    width: 600px;
  }
  @media screen and ${device.mobileL} {
    width: 400px;
  }
  @media screen and ${device.mobileM} {
    width: 300px;
  }
`
export const CompanyResultsContainer = styled.section`
  width: 80%;
  margin: auto;
  padding: 40px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  box-sizing: border-box;
  & .company-info-component {
    display: none;
  }
  @media screen and ${device.laptopL} {
    width: 95%;
    padding: 0px 40px;
  }
  @media screen and ${device.laptop} {
    padding: 40px 25px;
    width: 90vw;
  }
  @media screen and ${device.tablet} {
    padding: 40px 10px;
    width: 90vw;
  }
  @media print {
    & .score__container--print-style {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      page-break-after: always;
      padding: 0;
      margin: 0;
      & .company-info-component {
        display: initial;
        text-align: center;
        margin-bottom: 50px;
        & img {
          width: 150px;
          height: 150px;
          margin-bottom: 25px;
        }
        & h2 {
          font-size: 32px;
        }
        & .company__info {
          border-bottom: none;
        }
        & .company__info p {
          font-size: 22px;
          justify-content: center;
        }
      }
      & #score-percentage {
        font-size: 5.5rem;
      }
      & .component-score {
        margin-bottom: 25%;
        & .recharts-wrapper {
          width: 400px !important;
          height: 400px !important;
          & svg {
            width: 400px;
            height: 400px;
          }
        }
        & div > p {
          font-size: 24px;
          margin-top: -145px;
          margin-bottom: 90px;
        }
        & h5 {
          margin-top: 40px;
          font-size: 32px;
        }
        & p {
          font-size: 20px;
          margin-top: 20px;
        }
      }
      & .component-rating-with-icon {
        padding-right: 0px;
        position: absolute;
        bottom: 0;
        & h6,
        h4 {
          font-size: 32px;
        }
        & img {
          display: none;
        }
      }
    }
  }
`
export const ScoreContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & p {
    text-align: center;
    max-width: 80%;
  }
  & .print-only {
    display: none;
  }
  @media print {
    & .print-only {
      display: contents;
    }
  }
`
export const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  & #score-percentage {
    margin-top: 15px;
    font-size: 5rem;
    font-weight: 600;
  }
  & > p {
    margin-top: -90px;
    margin-bottom: 10px;
  }
  @media screen and ${device.laptopL} {
    min-height: 200px;
    margin-bottom: 50px;
  }
`
export const RatingContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 33%);
  align-items: center;
  justify-items: center;
  position: relative;
  width: 100%;
  margin: 100px 0;
  padding-bottom: 0;
  border-bottom: 2px solid #5378fc;
  padding-right: 50px;
  & img {
    width: 150px;
    height: 200px;
    position: absolute;
    top: ${({hidde}) => (hidde ? '-74' : '-95')}px;
    right: ${({hidde}) => (hidde ? '-8' : '-15')}px;
  }
  & > div {
    align-items: center;
    margin-bottom: 10px;
  }
  & > div > h4 {
    margin-bottom: 0px;
  }
  @media screen and ${device.laptopL} {
    margin: 30px 0 50px 0;
  }
  @media screen and ${device.tablet} {
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 50px 50px;
    grid-gap: 10px;
    padding: 0;
    & img {
      top: ${({hidde}) => (hidde ? '-2' : '-23')}px;
      right: ${({hidde}) => (hidde ? '-5' : '-10')}px;
    }
  }
  @media screen and ${device.mobileL} {
    grid-gap: 10px;
    & img {
      top: ${({hidde}) => (hidde ? '-2' : '-23')}px;
      right: ${({hidde}) => (hidde ? '-5' : '-15')}px;
    }
  }
`
export const ThryvAdviceContainer = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  padding: 12px 21px;
  background-color: #fdf2ec;
  font-family: 'Open Sans', 'sans-serif';
  border-radius: 5px;
  & p {
    margin-left: 170px;
  }
  & img {
    position: absolute;
    top: -30px;
    width: 150px;
    height: 145px;
  }
  @media screen and ${device.mobileL} {
    flex-direction: column;
    & p {
      margin-left: 0;
    }
    & img {
      position: relative;
      width: 150px;
      height: 145px;
    }
  }
  @media print {
    /* position: absolute;
    bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px; */
    & img {
      page-break-inside: avoid;
    }
  }
`
export const ProTipContainer = styled.div`
  display: grid;
  grid-template-columns: 10% 90%;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  padding: 12px 21px;
  font-family: 'Open Sans', 'sans-serif';
  background-color: rgba(83, 120, 252, 0.2);
  border-radius: 5px;
  & p {
    margin: 0;
  }
  & img {
    margin: 20px;
    width: 25px;
    height: 20px;
  }
  @media screen and ${device.mobileL} {
    flex-direction: column;
  }
  @media screen and ${device.tablet} {
    grid-template-columns: 20% 80%;
  }
`
export const CriteriaMainContainer = styled.div`
  max-width: 100%;
  padding-bottom: 70px;
  margin-bottom: 70px;
  border-bottom: 2px solid #d9d9d9;
  & ul {
    margin: 0;
    padding: 0;
  }
  @media print {
    position: relative;
    page-break-before: always;
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
`
export const CriteriaDescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: top;
  & p {
    margin: 0;
  }
`
export const CriteriaScoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`
export const DropdownContent = styled.div`
  display: block;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s;

  &.active {
    max-height: 100%;
  }
  & ul {
    margin: 0;
    padding: 0;
  }

  @media print {
    max-height: 100%;
  }
`

export const RatingTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  border-top: 1px solid #e3e6e8;
  padding-bottom: 30px;
  border-bottom: 1px solid #e3e6e8;
  margin-bottom: 15px;
  & .dropdown__tittle__description {
    display: flex;
    align-items: center;
    & > button {
      margin: 0 0 0 5px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 14px;
    }
    & span {
      transition: transform 0.2s ease;
      transform: rotate(0deg);
    }
    & span.active {
      transform: rotate(90deg);
    }
  }

  & :hover {
    cursor: pointer;
  }
`
export const RatingTitleSectionContainer = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid #e3e6e8;
  margin-bottom: 15px;
  display: flex;
  & div div {
    gap: 0.1rem;
  }
  & .shadow-thryv {
    box-shadow: none;
    padding: 0px;
  }
  & h6 {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: auto;
  }
  & h6 div {
    display: flex;
    align-items: center;
  }
  & h6:first-child {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
  }
`

export const ReviewActivityItemContainer = styled.li`
  display: grid;
  grid-template-columns: 95% 5%;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #eceef1;
  & > div {
    display: flex;
    margin: 0;
    justify-content: flex-start;
    align-items: center;
    & > span {
      margin-right: 15px;
    }
  }
`
export const SentimentAnalysisContainer = styled.li`
  @media print {
    /* page-break-before: always; */
  }
`
export const SentimentAnalysisTabs = styled.div`
  & > ul {
    display: grid;
    padding: 25px 20px;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-gap: 15px;
    grid-auto-flow: column;

    & .positive {
      grid-column: 1;
    }
    & .negative {
      grid-row-start: auto;
      grid-column: 2;
    }
    & .none-info {
      color: #d3d4d6;
      font-size: 18px;
      grid-row: 1;
      text-align: center;
    }
  }
  @media screen and ${device.mobileL} {
    & > div {
      grid-template-columns: 100%;
      padding: 25px 10px;
    }
  }
`
export const SentimentAnalysisItemContainer = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 15px;
  border: 1px solid
    ${({status}) => (status ? SCORE_COLORS[0] : SCORE_COLORS[2])};
  box-sizing: border-box;
  border-radius: 4px;

  & > h6 {
    margin: 0px;
    margin-left: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
  }
  & svg {
    min-width: 25px;
  }
`
export const OutreachMainContainer = styled.ul`
  max-width: 100vw;
  overflow-x: auto;
  & li {
    min-width: 600px;
  }
`
export const OutreachItemContainer = styled.li`
  display: grid;
  grid-template-columns: 20% 25% 25% 25% 5%;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e3e6e8;
  & .site__item {
    display: grid;
    grid-template-columns: 70% 30%;
    justify-content: center;
    align-items: center;
    & svg {
      min-width: 25px;
    }
  }
  & p {
    margin: 0;
  }
  & .w-full {
    grid-column: 2/5;
  }
  @media screen and ${device.mobileL} {
    & svg {
      height: 15px;
      width: 15px;
    }
  }
`
export const WebsiteAnalysisContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas:
    'website responsive'
    'organicSearch paidSearch'
    'organicSearch local'
    'accessibility accessibility';
  & .website {
    grid-area: website;
  }
  & .responsive {
    grid-area: responsive;
  }
  & .organicSearch {
    grid-area: organicSearch;
  }
  & .paidSearch {
    grid-area: paidSearch;
  }
  & .local {
    grid-area: local;
  }
  & .accessibility {
    grid-area: accessibility;
  }
  @media screen and ${device.tablet} {
    grid-template-columns: 100%;
    grid-template-areas:
      'website'
      'responsive'
      'paidSearch'
      'organicSearch'
      'local'
      'accessibility';
    grid-gap: 10px;
  }
  @media print {
    grid-template-columns: 100%;
    grid-template-areas:
      'website'
      'responsive'
      'paidSearch'
      'organicSearch'
      'local'
      'accessibility';
    grid-gap: 10px;
  }
`
export const WebsiteAnalysisItemContainer = styled.article`
  /* display: grid; */
  & > h6 {
    background: #f8f9fb;
    border: 1px solid #e3e6e8;
    box-sizing: border-box;
    padding: 5px 0px 5px 20px;
    max-height: 30px;
    margin: 0;
  }
  & ul {
    margin: 0;
    padding: 0;
  }
`
export const WebsiteAnalysisRow = styled.li`
  display: grid;
  grid-template-columns: 5% 55% 35% 5%;
  align-items: center;
  justify-items: center;
  padding: 5px;
  & p {
    margin: 0;
    justify-self: start;
  }
  & .status__description {
    justify-self: end;
  }
`
export const ListingAnalysisContainer = styled.div`
  max-width: 100vw;
  max-height: 600px;
  overflow: auto;
  & table {
    table-layout: fixed;
    width: 100%;
    min-width: 800px;
    & th {
      padding: 15px 0px 30px 0;
      color: #808080;
    }
    & tr:nth-of-type() td {
      border-top: 1px solid #dddddd;
    }
  }
  @media print {
    margin-bottom: 150px;
    max-height: 100%;
    min-height: 100%;
  }
`
export const ListingAnalysisItemRow = styled.tr`
  font-size: 14px;
  & td {
    max-width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #dddddd;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
export const ListingAnalysisItemCellContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  & svg {
    min-width: 25px;
  }
  & p {
    margin: 0;
  }
`
export const RevenueContainer = styled.li`
  @media print {
    height: 100%;
    width: 100%;
    /* page-break-before: always; */
  }
`
export const RevenueGraphTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  background: #f8f9fb;
  border: 1px solid #e3e6e8;
  box-sizing: border-box;
  & > h6 {
    margin: 0;
  }
`
export const RevenueGraphContainer = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-areas: 'title graph' 'description description';
  position: relative;
  text-align: center;
  width: 100%;
  height: 400px;
  margin: 20px 0px;
  & .rotate {
    grid-area: title;
  }
  & .rotate h6 {
    position: absolute;
    top: 40%;
    left: -50px;
    transform: rotate(270deg);
  }
  & .graph {
    grid-area: graph;
  }
  & .description {
    grid-area: description;
  }
`
