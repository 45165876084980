import React from 'react'
import {
  Input,
  ParagraphText,
  Button,
  RadioGroup,
  Select,
  Icon,
} from '@thryvlabs/maverick'
import {BasicInfoContainer, MainContainer} from '../ui/account-information'
import {getAuthByRole} from '../../utils/get-screen-auth-by-role'
import {toast} from 'react-toastify'
import {Controller} from 'react-hook-form'
import {useAsync} from '../../utils/use-async'
import {useAuthClient} from '../../utils/use-auth-client'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {CancelOrderModal} from '../cancel-order-modal'
import {useCallback, useState} from 'react'
import AsyncSelect from 'react-select/async'
// import {client} from '../../utils/api-client'

const DIFFERENT_SALES_REP_OPTIONS_USER_SPECIFIC = [
  {name: 'Sales Rep', value: true},
  {name: 'No', value: 'NO'},
]

let DIFFERENT_SALES_REP_OPTIONS = [
  {name: 'Sales Rep', value: true},
  {name: 'Partner', value: false},
  {name: 'No', value: 'NO'},
]

const OVERRIDE_SST_OPTIONS = [
  {name: 'Yes', value: true},
  {name: 'No', value: false},
]
const FORM_INPUT_AUTHORIZED_ROLES = {
  leadId: ['premisepilot', 'leadconverter'],
}
const SALES_REP_INFO_NOT_FOUND = 'Sales rep not Found.'
const SST_SALES_REP_INFO_NOT_FOUND = 'Sales rep not Found.'

const AccountInformationForm = ({
  register,
  errors,
  listOfStates,
  selectedCountry,
  salesRepInfo,
  overrideRepInfo,
  differentSalesRep,
  overrideRep,
  validatedEmail,
  email,
  emailRegistered,
  setEmailRegistered,
  setValidatedEmail,
  setExistingThryvId,
  setExistingSubscriptionId,
  setIsV5Upgrade,
  setShowUpgradeAccountModal,
  setShowAccountExistsModal,
  setIsInvoiceBilled,
  setValue,
  salesRepCode,
  overrideRepCode,
  trigger,
  isUS,
  roles,
  control,
  state,
  order,
  user,
  setThryvId,
  isSubmitButtonDisabled,
  handleSubmit,
  submitForm,
  handleNextStepData,
  showXpCode,
  setShowXpCode,
  showRepList,
  setShowRepList,
}) => {
  const [selectedSalesRep, setSelectedSalesRep] = useState(null)
  const [selectedOverrideRep, setSelectedOverridRep] = useState(null)
  const {frontendInvoiceCc, frontendOverrideSst, frontendUserError} = useFlags()
  const {run} = useAsync({
    status: 'pending',
  })
  const client = useAuthClient()

  const styleInput = {
    opacity: emailRegistered ? '0.3' : '1.0',
  }

  const isAu = selectedCountry === 'AU'

  const getSalesRepValues = () => {
    const roleAuth =
      roles.includes('PremisePilot') ||
      roles.includes('LeadConverter') ||
      roles.includes('Reseller') ||
      roles.includes('Admin')
    if (roleAuth) {
      return order.accountInformation &&
        order.accountInformation.behalf !== null &&
        order.accountInformation.behalf !== ''
        ? DIFFERENT_SALES_REP_OPTIONS[0]
        : order.accountInformation &&
          order.accountInformation.xpCode !== null &&
          order.accountInformation.xpCode !== ''
        ? DIFFERENT_SALES_REP_OPTIONS[1]
        : DIFFERENT_SALES_REP_OPTIONS[2]
    } else {
      return order.accountInformation &&
        order.accountInformation.behalf !== null &&
        order.accountInformation.behalf !== ''
        ? DIFFERENT_SALES_REP_OPTIONS_USER_SPECIFIC[0]
        : DIFFERENT_SALES_REP_OPTIONS_USER_SPECIFIC[1]
    }
  }

  const differentSalesRepValue = getSalesRepValues()

  const handleEmailValidation = async () => {
    const emailRegex = /^(?!.*\+)[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (emailRegex.test(email)) {
      const isThryvEmail = email.includes('@thryv.com')
      if (isThryvEmail === false) {
        try {
          const response = await run(client(`orders/validate-email/${email}`))
          if (response.orderCanContinue) {
            setValidatedEmail(email)
            setEmailRegistered(false)
          } else if (!response.orderCanContinue && response.accountCanUpgrade) {
            setExistingThryvId(response.thryvId)
            setExistingSubscriptionId(response.subscriptionId)
            setIsV5Upgrade(response?.v5upgrade || false)
            setShowUpgradeAccountModal(true)
          } else if (
            !response.orderCanContinue &&
            !response.accountCanUpgrade
          ) {
            if (response.isInvoiceBilled && frontendInvoiceCc) {
              setThryvId(response.thryvId)
              setIsInvoiceBilled(true)
            } else {
              setShowAccountExistsModal(true)
            }
          }
        } catch (err) {
          if (err.message === 'Recurly account not found') {
            toast.error(
              frontendUserError
                ? 'Looks like this email exists in one of our systems. Please contact support to have this resolved.'
                : 'Account not found in billing system. Please contact support.',
            )
          } else {
            toast.error(err.message)
          }
        }
      } else {
        toast.error('Thryv addresses are not allowed')
      }
    } else {
      toast.error('Valid email address required')
    }
  }

  const handleOverrideRepOptionChange = selectedOption => {
    setSelectedOverridRep(selectedOption)
    const overrideRepInfo = selectedOption.salesRepInfo
    try {
      setValue('overrideRepInfo', `${overrideRepInfo.Name}`)
      setValue('overrideRepCode', overrideRepInfo.FederationIdentifier)
      setValue('overrideRepFirst', overrideRepInfo.FirstName)
      setValue('overrideRepLast', overrideRepInfo.LastName)
      setValue('overrideRepEmail', overrideRepInfo.Email)
    } catch (error) {
      if (error.status === 404) {
        setValue('overrideRepInfo', SST_SALES_REP_INFO_NOT_FOUND)
      }
    }
    trigger('overrideRepInfo')
  }

  const resetOnBehalf = async () => {
    setValue('salesRepInfo', '')
    setValue('salesRepCode', '')
    // setSelectedSalesRep(null)
    setSelectedSalesRep({label: '', name: ''})
  }

  const resetSstOnBehalf = async () => {
    setValue('overrideRepInfo', '')
    setValue('overrideRepCode', '')
    // setSelectedOverridRep('')
    setSelectedOverridRep({label: '', name: ''})
  }

  const preventUnvalidatedEmail = () => {
    if (validatedEmail !== email && !emailRegistered) {
      setEmailRegistered(true)
      setValidatedEmail('')
    }
  }
  const fetchNameOptions = useCallback(
    async (inputValue, country) => {
      let sftoken = sessionStorage.getItem('sfToken') || ''
      if (!country) {
        country = 'US'
      }
      if (sftoken.length > 0) {
        sftoken = JSON.parse(sftoken)
        sftoken = country === 'AU' ? sftoken.ausToken : sftoken.nonAusToken
      }
      const data = await client(
        `salesforce/services/fetchUsers?token=${sftoken}&searchTerm=${inputValue}&country=${country}`,
      )
      const filterData = data.data.filter(item =>
        item.Name.toLowerCase().includes(inputValue.toLowerCase()),
      )
      return filterData.map(item => ({
        label: item.Name,
        value: item.Name,
        salesRepInfo: item,
      }))
    },
    [client],
  )

  const loadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length >= 3) {
      fetchNameOptions(inputValue, selectedCountry).then(options => {
        callback(options)
      })
    } else if (!inputValue) {
      callback([])
    }
  }
  const handleSalesRepOptionChange = useCallback(
    selectedOption => {
      setSelectedSalesRep(selectedOption)
      const salesRepInfo = selectedOption.salesRepInfo
      try {
        setValue('salesRepCode', salesRepInfo.FederationIdentifier)

        setValue('salesRepInfo', `${salesRepInfo.Name}`)
        setValue('behalfFirst', salesRepInfo.FirstName)
        setValue('behalfLast', salesRepInfo.LastName)
        setValue('behalfEmail', salesRepInfo.Email)
      } catch (error) {
        if (error.status === 404) {
          setValue('salesRepInfo', SALES_REP_INFO_NOT_FOUND)
        }
      }
      trigger('salesRepInfo')
    },
    [setValue, trigger],
  )

  React.useEffect(() => {
    if (order.accountInformation && order.accountInformation.salesRepName) {
      fetchNameOptions(
        order.accountInformation.salesRepName,
        selectedCountry,
      ).then(options => {
        handleSalesRepOptionChange(options[0])
      })
    }
  }, [
    fetchNameOptions,
    handleSalesRepOptionChange,
    order,
    selectedCountry,
    setSelectedSalesRep,
  ])

  return (
    <>
      <MainContainer
        style={{
          gridColumnStart: 1,
          gridColumnEnd: 3,
          padding: '0 30px',
        }}
      >
        <BasicInfoContainer style={{maxWidth: '99%'}}>
          <ParagraphText
            variant="reg"
            className="text-thryv-steel section_title"
          >
            Basic Info
          </ParagraphText>
          <div className="input_container_email_address">
            <Input
              className="w-100"
              type="text"
              placeholder="Email Address"
              aria-label="Email Address"
              name="email"
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
              onChange={preventUnvalidatedEmail()}
            />
            <Button
              variant="primary"
              type="button"
              name="Validate"
              onClick={handleEmailValidation}
            >
              Validate
            </Button>
          </div>
          <Input
            className="w-100"
            type="text"
            placeholder="Mobile Number"
            aria-label="Mobile Number"
            name="mobile"
            maxLength={18}
            withLabel
            labelType="floating"
            variant="default"
            register={register}
            errors={errors}
            disabled={emailRegistered}
            style={styleInput}
          />
          <Input
            className="w-100"
            type="text"
            placeholder="First Name"
            aria-label="First Name"
            name="firstName"
            withLabel
            labelType="floating"
            variant="default"
            register={register}
            errors={errors}
            disabled={emailRegistered}
            style={styleInput}
          />
          <Input
            className="w-100"
            type="text"
            placeholder="Last Name"
            aria-label="Last Name"
            name="lastName"
            withLabel
            labelType="floating"
            variant="default"
            register={register}
            errors={errors}
            disabled={emailRegistered}
            style={styleInput}
          />
          <Input
            className="w-100"
            type="text"
            placeholder="Company"
            aria-label="Company"
            name="company"
            withLabel
            labelType="floating"
            variant="default"
            register={register}
            errors={errors}
            disabled={emailRegistered}
            style={styleInput}
          />

          <Input
            className="w-100"
            type="text"
            placeholder="Salesforce Primary Contact Email"
            aria-label="Salesforce Primary Contact Email"
            name="salesforceEmail"
            withLabel
            labelType="floating"
            variant="default"
            register={register}
            errors={errors}
            disabled={emailRegistered}
            style={styleInput}
          />

          {getAuthByRole(FORM_INPUT_AUTHORIZED_ROLES.leadId, roles) && (
            <Input
              className="w-100"
              type="text"
              placeholder="Lead ID"
              aria-label="Lead ID"
              name="leadID"
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
              disabled={emailRegistered}
              style={styleInput}
            />
          )}
          {['AU', 'NZ'].includes(selectedCountry) && (
            <div className="input_container">
              <Input
                className="w-100 mb-2"
                type="text"
                placeholder={
                  isAu
                    ? 'Australian Business Number (ABN)'
                    : 'New Zealand Business Number (NZBN)'
                }
                customPlaceholder={isAu ? 'ABN' : 'NZBN'}
                aria-label="Australian Business Numberbn"
                name="abn"
                withLabel
                labelType="floating"
                variant="default"
                register={register}
                errors={errors}
                disabled={emailRegistered}
                style={styleInput}
              />
              <a
                href={
                  isAu
                    ? 'https://abr.business.gov.au/'
                    : 'https://www.nzbn.govt.nz/'
                }
                target="_blank"
                rel="noreferrer"
                className="abn-link"
                style={styleInput}
              >
                {isAu ? 'ABN' : 'NZBN'} Lookup
              </a>
            </div>
          )}
        </BasicInfoContainer>
        <div className="mt-4 mb-5">
          <ParagraphText
            variant="reg"
            className="text-thryv-steel section_title"
            style={styleInput}
          >
            Business Address
          </ParagraphText>
          <div className="input-container">
            <Input
              className="w-100"
              type="text"
              placeholder="Customer Address"
              aria-label="Customer Address"
              name="address"
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
              disabled={emailRegistered}
              style={styleInput}
            />
          </div>
          <div className="input-container address_container">
            <Input
              className="w-100 custom-mt"
              type="text"
              placeholder="City"
              aria-label="City"
              name="city"
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
              disabled={emailRegistered}
              style={styleInput}
            />
            {!emailRegistered && (
              <Controller
                control={control}
                name="state"
                render={({field: {onChange, name}}) => (
                  <Select
                    options={listOfStates}
                    width="full"
                    className="select mt-0"
                    selectLabel="Select State"
                    selectedOption={state}
                    onChange={onChange}
                    errors={errors}
                    name={name}
                    hidden
                    style={styleInput}
                  />
                )}
              />
            )}
            <Input
              className="w-100"
              type="text"
              placeholder="Postal Code"
              aria-label="Postal Code"
              name="postCode"
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
              disabled={emailRegistered}
              style={styleInput}
            />
          </div>
          <div className="input-container">
            <Input
              className="w-100"
              type="text"
              placeholder="Business Phone"
              aria-label="Business Phone"
              name="companyPhone"
              maxLength={18}
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
              disabled={emailRegistered}
              style={styleInput}
            />
          </div>
        </div>

        {!emailRegistered && (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{width: frontendOverrideSst ? '100%' : '50%'}}>
              <Controller
                control={control}
                name="differentSalesRep"
                render={({field: {onChange, name}}) => (
                  <RadioGroup
                    data={
                      roles.includes('PremisePilot') ||
                      roles.includes('LeadConverter') ||
                      roles.includes('Reseller') ||
                      roles.includes('Admin')
                        ? DIFFERENT_SALES_REP_OPTIONS
                        : DIFFERENT_SALES_REP_OPTIONS_USER_SPECIFIC
                    }
                    value={differentSalesRepValue}
                    label="Is this sale on behalf of another sales rep or partner?"
                    row
                    layoutStyles={{
                      gap: '10px',
                    }}
                    onChange={onChange}
                    name={name}
                  />
                )}
              />
              <div className="d-flex mt-2">
                {differentSalesRep?.value &&
                  differentSalesRep?.value !== 'NO' &&
                  showRepList && (
                    <>
                      <div
                        className="input_container mt-4"
                        style={{width: '300px'}}
                      >
                        <div className="w-[300px]">
                          <AsyncSelect
                            cacheOptions
                            placeholder="Search by Name"
                            loadOptions={loadOptions}
                            onChange={handleSalesRepOptionChange}
                            value={selectedSalesRep}
                            theme={theme => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: 'silver',
                                primary: 'black',
                              },
                            })}
                          />
                        </div>
                      </div>
                      {salesRepInfo ? (
                        <>
                          <Icon
                            className="reset-button"
                            type="regular"
                            variant="circleX"
                            color="#FF5000"
                            style={{
                              width: '18px',
                              height: '18px',
                              marginTop: '30px',
                              marginLeft: '10px',
                              marginRight: '10px',
                              alignSelf: 'center',
                            }}
                            onClick={resetOnBehalf}
                          />
                        </>
                      ) : null}
                    </>
                  )}
                {showXpCode && (
                  <div className="mt-2">
                    <p>Please enter XP Code</p>
                    <Input
                      className="w-100"
                      type="text"
                      placeholder="XP Code"
                      aria-label="XP Code"
                      name="xpCode"
                      withLabel
                      labelType="floating"
                      variant="default"
                      register={register}
                      errors={errors}
                      // disabled={emailRegistered}
                      style={styleInput}
                    />
                  </div>
                )}
              </div>
            </div>
            {frontendOverrideSst && (
              <div style={{width: '50%'}}>
                <Controller
                  control={control}
                  name="overrideRep"
                  render={({field: {onChange, name}}) => (
                    <RadioGroup
                      data={OVERRIDE_SST_OPTIONS}
                      value={OVERRIDE_SST_OPTIONS[1]}
                      label="Do you want to override SST on this order?"
                      row
                      layoutStyles={{
                        gap: '10px',
                      }}
                      onChange={onChange}
                      name={name}
                    />
                  )}
                />
                <div className="d-flex mt-2">
                  {/* {overrideRep?.value && (
                    <>
                      <div
                        className="input_container mt-4"
                        style={{width: '300px'}}
                      >
                        <Input
                          className="w-100"
                          type="text"
                          placeholder="Search by ID"
                          aria-label="Search by ID"
                          name="overrideRepCode"
                          withLabel
                          labelType="static"
                          variant="default"
                          disabled={overrideRepInfo}
                          register={register}
                          errors={errors}
                        />
                      </div>
                      {overrideRepInfo ? (
                        <>
                          <Icon
                            className="reset-button"
                            type="regular"
                            variant="circleX"
                            color="#FF5000"
                            style={{
                              width: '18px',
                              height: '18px',
                              marginTop: '30px',
                              marginLeft: '10px',
                              marginRight: '10px',
                              alignSelf: 'center',
                            }}
                            onClick={resetSstOnBehalf}
                          />
                          <ParagraphText
                            variant="reg"
                            className="mb-0 text-thryv-stee"
                            style={{marginTop: '29px'}}
                          >
                            {overrideRepInfo}
                          </ParagraphText>
                        </>
                      ) : (
                        <div className="d-flex align-items-center">
                          <Button
                            data-testid="validate-email-button"
                            variant="primary"
                            className="new-order-button d-flex justify-content-around align-items-center mr-3 mt-4 ml-2"
                            onClick={validateSstOnBehalf}
                          >
                            Validate
                          </Button>
                        </div>
                      )}
                    </>
                  )} */}
                  {overrideRep?.value && (
                    <>
                      <div
                        className="input_container mt-4"
                        style={{width: '300px'}}
                      >
                        <AsyncSelect
                          cacheOptions
                          placeholder="Search by Name"
                          loadOptions={loadOptions}
                          onChange={handleOverrideRepOptionChange}
                          value={selectedOverrideRep}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: 'silver',
                              primary: 'black',
                            },
                          })}
                        />
                      </div>
                      {selectedOverrideRep &&
                      selectedOverrideRep.label &&
                      selectedOverrideRep.label.length ? (
                        <>
                          <Icon
                            className="reset-button"
                            type="regular"
                            variant="circleX"
                            color="#FF5000"
                            style={{
                              width: '18px',
                              height: '18px',
                              marginTop: '25px',
                              marginLeft: '10px',
                              marginRight: '10px',
                              alignSelf: 'center',
                            }}
                            onClick={resetSstOnBehalf}
                          />
                        </>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </MainContainer>
      <div className="button__container mt-5" style={{columnGap: '10px'}}>
        <CancelOrderModal cancelUrl="/orders" />
        {!order.savedOrder && (
          <Button
            disabled={isSubmitButtonDisabled || emailRegistered}
            variant="primary"
            type="submit"
            className="new-order-button d-flex justify-content-around align-items-center"
            onClick={handleSubmit(submitForm)}
          >
            Save
          </Button>
        )}
        <Button
          disabled={isSubmitButtonDisabled || emailRegistered}
          variant="primary"
          type="submit"
          className="new-order-button d-flex justify-content-around align-items-center"
          onClick={handleSubmit(handleNextStepData)}
          name="continue"
          data-testid={'continue-button'}
        >
          Continue
        </Button>
      </div>
    </>
  )
}

export {AccountInformationForm}
