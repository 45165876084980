import React from 'react'
import {Button} from '@thryvlabs/maverick'

const ThryvLeadsModal = ({close}) => {
  const goTo = link => {
    window.open(link, '_blank')
  }

  const requestNewProposalLink = `https://thryv.jotform.com/211044041218035`
  const submitEscalationLink = `https://thryv.jotform.com/211234077295050`

  return (
    <>
      <div className="row d-flex justify-content-center">
        <h2>What do you want to do?</h2>
      </div>

      <div className="d-flex flex-column">
        <div className="p-2">
          <Button
            className="col-8"
            style={{padding: '5%', textAlign: 'left'}}
            variant="primary"
            onClick={() => goTo(requestNewProposalLink)}
          >
            Request a New Proposal &nbsp;
            <i
              className="fa fa-external-link-square-alt fa-fw"
              aria-hidden="true"
            />
          </Button>
        </div>

        <div className="p-2">
          <Button
            className="col-8"
            style={{padding: '5%', textAlign: 'left'}}
            variant="primary"
            onClick={() => {
              goTo('https://dexyp.ldex.co/thryv-leads/sso/thryv')
            }}
          >
            View My Proposals & Campaigns &nbsp;
            <i
              className="fa fa-external-link-square-alt fa-fw"
              aria-hidden="true"
            />
          </Button>
        </div>

        <div className="p-2">
          <Button
            className="col-8"
            style={{padding: '5%', textAlign: 'left'}}
            variant="primary"
            onClick={() => goTo(submitEscalationLink)}
          >
            Submit Escalation, Change Request or Product Questions &nbsp;
            <i
              className="fa fa-external-link-square-alt fa-fw"
              aria-hidden="true"
            />
          </Button>
        </div>

        <div className="p-2">
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </div>
      </div>
    </>
  )
}

export default ThryvLeadsModal
