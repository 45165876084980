import styled from '@emotion/styled'

export const NavBar = styled.div`
  padding-right: 20px;
  padding-left: 20px;
  display: flex;

  & .select-country {
    min-width: 200px;
  }

  & .showcase-selection {
    margin-left: 15px;
  }
`

export const ShowcaseIframe = styled.iframe`
  width: 95%;
  height: 900px;
  position: relative;
  top: -35px;
  display: block;
  margin: 0 auto;
`
