import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'
import {ResultsLayoutHeader} from './results-layout'

export const AppointmentsHeader = styled(ResultsLayoutHeader)`
  & .select-time {
    display: flex;
    min-width: 250px;
    display: inline-block;
  }
  @media screen and ${device.mobileL} {
    & .select-time {
      width: 96%;
      margin: 10px;
    }
  }
`
