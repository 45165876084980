import React, {useState, useEffect} from 'react'
import CCDesktop from './cc-desktop'
import OldFlowDesktop from './old-flow-desktop'

const TOTAL_STEPS = 2

const DesktopLayout = ({
  paymentState,
  setPaymentState,
  isLoading,
  calculateTaxes,
  calculatingTaxes,
  setCalculatingTaxes,
  handleChangePaymentMethod,
  paymentMethods,
  checkBoxesValues,
  handleOnCheckBoxChange,
  uuid,
  savePaymentContinue,
  paymentRequestState,
  is5Order,
  isAddressUpdated,
  setIsAddressUpdated,
  updateParentAddress,
}) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [name, setName] = useState('')
  const [addressInformation, setAddressInformation] = React.useState({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    postalCode: '',
    state: '',
  })
  const {
    monthlyAmount,
    semiAnnuallyAmount,
    oneTimeAmount,
    monthlyAddons,
    semiAnnuallyAddons,
    oneTimeAddons,
    paymentInfo,
    calculatedTaxes,
  } = paymentState
  const todayItems = [...oneTimeAddons]
  if (semiAnnuallyAmount > 0) {
    todayItems.push(...semiAnnuallyAddons)
  } else {
    todayItems.push(...monthlyAddons)
  }
  const monthlyTotalDue = oneTimeAmount + monthlyAmount
  const semiAnnuallyTotalDue = oneTimeAmount + semiAnnuallyAmount
  let totalNoTaxes =
    semiAnnuallyAmount > 0 ? semiAnnuallyTotalDue : monthlyTotalDue

  let couponDiscountAmount = 0
  paymentInfo?.coupons?.forEach(coupon => {
    const selectedPlanCodes = [...monthlyAddons, ...oneTimeAddons]
    if (!is5Order) {
      selectedPlanCodes.push({
        code: paymentInfo?.plan?.planCode,
        name: paymentInfo?.plan?.name,
        currency: {unitAmount: paymentInfo?.plan?.currency.amount},
      })
    }
    if (coupon.discountAmount) {
      couponDiscountAmount += coupon.discountAmount
    } else if (coupon.isPercentDiscount && coupon.appliesToAllPlans) {
      selectedPlanCodes.forEach(addon => {
        if (!addon.name.includes('Setup Fee')) {
          couponDiscountAmount +=
            addon.currency.unitAmount *
            (addon.quantity || 1) *
            (coupon.discountPercent / 100)
        }
      })
    } else {
      selectedPlanCodes.forEach(addon => {
        const appliesToPlan = coupon?.appliesToPlans?.includes(addon.code)
        const noSetupFee = !addon.name.includes('Setup Fee')
        const isTransitionIncentive = coupon.code === 'transition75'

        if (appliesToPlan && noSetupFee) {
          if (
            !isTransitionIncentive ||
            (isTransitionIncentive &&
              (!is5Order || (is5Order && addon?.transitionApplies)))
          ) {
            couponDiscountAmount +=
              addon.currency.unitAmount *
              (addon.quantity || 1) *
              (coupon.discountPercent / 100)
          }
        }
      })
    }
  })

  const nextStep = () => {
    let _currentStep = currentStep
    if (currentStep >= TOTAL_STEPS - 1) {
      _currentStep = TOTAL_STEPS
    } else {
      _currentStep = currentStep + 1
    }
    setCurrentStep(_currentStep)
  }

  const handleCompleteOrder = () => {
    nextStep()
    savePaymentContinue()
  }

  const isUpdatePaymentButtonDisabled = () => {
    if (paymentRequestState.hasErrors && paymentRequestState.requested) {
      return true
    }

    if (paymentState.paymentInfo.isInvoiceBilled) {
      return true
    }

    return !checkBoxesValues.terms || !isAddressUpdated
  }

  useEffect(() => {
    function replaceCoupons() {
      if (
        paymentInfo.coupons.some(
          x =>
            x.code === '25month1' ||
            x.code === '25month2' ||
            x.code === '25month3',
        )
      ) {
        const transitionDisplay = {
          name: 'Transition Incentive',
          isValid: true,
          description: 'Transition Incentive',
          type: 'percent',
          isPercentDiscount: true,
          discountPercent: 75,
          code: 'transition75',
          appliesToAllPlans: false,
          appliesToPlans: [
            'professional-4-dexyp-m2m',
            'unlimited-4-dexyp-m2m',
            'plus-4-dexyp-m2m',
            'unlimited-4-barbados-m2m',
            'professional-4-barbados-m2m',
            'plus-4-barbados-m2m',
            'plus-4-caymanislands-m2m',
            'professional-4-caymanislands-m2m',
            'unlimited-4-caymanislands-m2m',
            'addon-4-mc-m2m',
            'cc_plus-5-thryv-m2m',
            'cc_pro-5-thryv-m2m',
            'bc_plus-5-thryv-m2m',
            'bc_pro-5-thryv-m2m',
            'bc_unlm-5-thryv-m2m',
            'mc_pro-5-thryv-m2m',
            'mc_plus-5-thryv-m2m',
          ],
        }
        const newPaymentInfo = {...paymentInfo}
        newPaymentInfo.coupons = newPaymentInfo.coupons.slice(
          0,
          newPaymentInfo.coupons.length - 3,
        )
        newPaymentInfo.coupons.push(transitionDisplay)

        setPaymentState({
          ...paymentState,
          paymentInfo: newPaymentInfo,
        })
      }
    }
    if (
      paymentInfo?.coupons.some(
        x =>
          x.code === '25month1' ||
          x.code === '25month2' ||
          x.code === '25month3',
      ) &&
      is5Order
    ) {
      replaceCoupons()
    }
  }, [paymentInfo, is5Order, paymentState, setPaymentState])

  useEffect(() => {
    window.addEventListener('beforeunload', ev => {
      ev.preventDefault()
      return (ev.returnValue = 'Are you sure you want to leave this page?')
    })
  }, [])

  if (!is5Order) {
    return (
      <OldFlowDesktop
        currentStep={currentStep}
        paymentState={paymentState}
        isLoading={isLoading}
        todayItems={todayItems}
        handleChangePaymentMethod={handleChangePaymentMethod}
        paymentMethods={paymentMethods}
        calculateTaxes={calculateTaxes}
        calculatingTaxes={calculatingTaxes}
        setCalculatingTaxes={setCalculatingTaxes}
        checkBoxesValues={checkBoxesValues}
        handleOnCheckBoxChange={handleOnCheckBoxChange}
        setName={setName}
        setIsAddressUpdated={setIsAddressUpdated}
        isAddressUpdated={isAddressUpdated}
        uuid={uuid}
        couponDiscountAmount={couponDiscountAmount}
        totalNoTaxes={totalNoTaxes}
        calculatedTaxes={calculatedTaxes}
        isUpdatePaymentButtonDisabled={isUpdatePaymentButtonDisabled}
        handleCompleteOrder={handleCompleteOrder}
        name={name}
        addressInformation={addressInformation}
        setAddressInformation={setAddressInformation}
        updateParentAddress={updateParentAddress}
      />
    )
  }
  return (
    <CCDesktop
      isLoading={isLoading}
      currentStep={currentStep}
      paymentState={paymentState}
      handleChangePaymentMethod={handleChangePaymentMethod}
      paymentMethods={paymentMethods}
      is5Order={is5Order}
      calculateTaxes={calculateTaxes}
      calculatingTaxes={calculatingTaxes}
      setCalculatingTaxes={setCalculatingTaxes}
      checkBoxesValues={checkBoxesValues}
      handleOnCheckBoxChange={handleOnCheckBoxChange}
      setName={setName}
      setIsAddressUpdated={setIsAddressUpdated}
      isAddressUpdated={isAddressUpdated}
      uuid={uuid}
      todayItems={todayItems}
      totalNoTaxes={totalNoTaxes}
      couponDiscountAmount={couponDiscountAmount}
      isUpdatePaymentButtonDisabled={isUpdatePaymentButtonDisabled}
      handleCompleteOrder={handleCompleteOrder}
      name={name}
      addressInformation={addressInformation}
      setAddressInformation={setAddressInformation}
      updateParentAddress={updateParentAddress}
    />
  )
}

export {DesktopLayout}
