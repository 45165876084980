import * as React from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {client} from './api-client'

function useAuthClient() {
  const {getAccessTokenSilently} = useAuth0()

  return React.useCallback(
    async (endpoint, config) => {
      const token = await getAccessTokenSilently()
      return client(endpoint, {...config, token})
    },
    // getAccessTokenSilently won't never change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}

export {useAuthClient}
