import React from 'react'
import {
  BenefitsContainer,
  CategorySelection,
  MainContainer,
  SelectionContainer,
  TextSideBar,
} from '../ui/industry-content'
import {Button, Icon} from '@thryvlabs/maverick'
import INDUSTRIES from '../../utils/industry-content.js'
import {CancelOrderModal} from '../cancel-order-modal'

function CategorySelect({
  logo,
  name,
  onCategorySelect,
  industry,
  activeIndustryId,
}) {
  return (
    <CategorySelection
      className="cirBox"
      onClick={() => {
        onCategorySelect(industry)
      }}
    >
      <figure
        className={`categoryLogo-container ${
          industry.id === activeIndustryId ? 'active' : ''
        }`}
      >
        <img
          className={`categoryLogo ${
            industry.id === activeIndustryId ? 'active' : ''
          }`}
          src={logo}
          alt={logo}
        />
      </figure>
      <p className="caption">{name}</p>
    </CategorySelection>
  )
}
function SideContainerText({subtitle, description}) {
  return (
    <TextSideBar>
      <div className="sideBarSubTitle">
        <i className="fa fa-check mr-2"></i>
        {subtitle}
      </div>
      <div className="sideBarDescription mb-3">{description}</div>
      <br />
    </TextSideBar>
  )
}
function SideBar({sidebarImage, name, sidebar, industry, nextStep, template}) {
  return (
    <div className="sideBarImg mb-4">
      <img className="industryImg" src={sidebarImage} alt={name} />
      <div className="textSidebar">
        <div className="sideBarTitle">Thryv for {name}</div>
        {sidebar.map(({subtitle, description}, i) => (
          <SideContainerText
            key={i}
            subtitle={subtitle}
            description={description}
          />
        ))}
        <div className="button__container" style={{columnGap: '10px'}}>
          <div className="d-flex align-items-end">
            <CancelOrderModal cancelUrl="/orders" />
          </div>
          <Button
            data-testid={'confirm-start-order'}
            variant="primary"
            className="mt-4"
            onClick={() =>
              nextStep({
                selectedIndustryCategory: industry.id,
                template: template,
              })
            }
          >
            Confirm & Start Order
          </Button>
        </div>
      </div>
    </div>
  )
}

function IndustryContent({
  nextStep,
  prevStep,
  selectedIndustry,
  selectedCountry,
}) {
  const [showSidebar, setShowSidebar] = React.useState(false)
  const defaultIndustry = INDUSTRIES.filter(
    x => x.categoryId === selectedIndustry,
  )[0]
  const [activeIndustryId, setActiveIndustryId] = React.useState(
    defaultIndustry.id,
  )
  const [currentIndustry, setCurrentIndustry] = React.useState(defaultIndustry)
  React.useEffect(() => {
    setShowSidebar(true)
  }, [])

  return (
    <MainContainer>
      <Icon
        type="solid"
        variant="arrowLeft"
        className="back__arrow mb-5"
        onClick={prevStep}
      />
      <SelectionContainer>
        <div className="category--selection-left">
          <img
            className="logo-img"
            src={defaultIndustry.thryvLogo}
            alt={defaultIndustry.name}
          />
          <div className="category--selection-container">
            {INDUSTRIES.filter(x => x.categoryId === selectedIndustry).map(
              (industry, i) => (
                <CategorySelect
                  key={industry.id}
                  logo={industry.industryLogo}
                  name={industry.name}
                  industry={industry}
                  onCategorySelect={industry => {
                    setCurrentIndustry(industry)
                    setActiveIndustryId(industry.id)
                    setShowSidebar(true)
                  }}
                  activeIndustryId={activeIndustryId}
                />
              ),
            )}
          </div>
        </div>
        <BenefitsContainer>
          {showSidebar && (
            <SideBar
              sidebar={currentIndustry.sidebar}
              name={currentIndustry.name}
              sidebarImage={currentIndustry.sidebarImage}
              industry={currentIndustry}
              nextStep={nextStep}
              template={
                currentIndustry.template[selectedCountry.isoValue] ||
                currentIndustry.template['US']
              }
            />
          )}
        </BenefitsContainer>
      </SelectionContainer>
    </MainContainer>
  )
}
export {IndustryContent}
