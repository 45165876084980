import React from 'react'

import {notFoundPageImage} from '../assets'

function NotFoundPage() {
  return (
    <section className="d-flex justify-content-center m-4 p-3 mt-5">
      <div
        className="d-flex flex-column justify-content-center align-items-center p-5"
        style={{
          backgroundColor: '#fffff',
          borderRadius: '25px',
          minWidth: '450px',
          maxWidth: '600px',
          boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.25)',
        }}
      >
        <h1
          className="font-montserrat text-secondary text-center pb-4"
          style={{borderBottom: '1px solid rgba(0, 0, 0, 0.1)'}}
        >
          Sorry, page not found!
        </h1>
        <img
          src={notFoundPageImage}
          alt="Not found page"
          width="250px"
          height="250px"
        />
      </div>
    </section>
  )
}

export default NotFoundPage
