import React from 'react'
import {Button, Icon, ButtonGroup, ParagraphText} from '@thryvlabs/maverick'

function ConfirmationModal({
  close,
  title,
  subtitle,
  height = '244px',
  handleAction,
}) {
  return (
    <div
      style={{width: '500px', height}}
      className="flex flex-column justify-content-between"
    >
      <div>
        <div className="w-100 d-flex align-items-center justify-content-between">
          <h2
            className="text-left"
            style={{
              fontFamily: 'montserrat',
              fontWeight: '600',
              fontSize: '26px',
              lineHeight: '32px',
              whiteSpace: 'pre-wrap',
            }}
          >
            {title}
          </h2>
          <Icon
            style={{cursor: 'pointer', marginBottom: '35px'}}
            onClick={close}
            type="regular"
            variant="x"
            height="18"
            width="18"
            color="#A3A5A7"
          />
        </div>
        <ParagraphText
          variant="reg"
          className="text-left"
          style={{marginTop: '24px'}}
        >
          {subtitle}
        </ParagraphText>
      </div>
      <ButtonGroup
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-end',
          columnGap: '16px',
        }}
      >
        <Button variant="text" level={1} textVariant="light" onClick={close}>
          NO
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleAction()
            close()
          }}
        >
          Yes
        </Button>
      </ButtonGroup>
    </div>
  )
}

export {ConfirmationModal}
