import React from 'react'
import * as yup from 'yup'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {Button, Input, Radio} from '@thryvlabs/maverick'

import {AddOnItem} from './add-on-item'

import {useAuthClient} from '../../utils/use-auth-client'
import {useAsync} from '../../utils/use-async'

const SCHEMA = yup.object().shape({
  domain: yup
    .string()
    .required('This field is required')
    .min(5, 'Must be at least 5 characters')
    .max(120, 'Must be at most 120 characters')
    .test(
      'validate-domain-format',
      'Incorrect domain format',
      function (value) {
        return value.match(
          /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
        )
      },
    ),
})

const defaultNoRadioButtons = [
  {
    label: 'No',
    value: 'No',
    default: true,
  },
  {
    label: 'Yes',
    value: 'Yes',
  },
]

function WebsiteItem({
  item,
  description,
  radioButtonOptions,
  itemConfig,
  handleAddProduct,
  handlePlanSelection,
  onDomainChange,
  includeProduct,
  billingPreference,
  isAddOnSelected,
  isDisabled,
  isTSS,
  code,
  listOfProducts,
  currentAddOns,
}) {
  const client = useAuthClient()
  const {run, isLoading} = useAsync()
  const [displayFirstSection, setDisplayFirstSection] = React.useState(
    isAddOnSelected ? true : false,
  )
  const [displayCheckInput, setDisplayCheckInput] = React.useState(false)
  const [validDomainResponse, setValidDomainResponse] = React.useState({})

  const {
    register,
    formState: {errors},
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(SCHEMA),
  })

  const handleDisplayFirstSection = data => {
    handleAddProduct(data)
    setDisplayFirstSection(data.value === 'Yes' || false)

    if (data.value === 'No') {
      setValidDomainResponse({})
      setDisplayCheckInput(false)
    }
  }

  const handleDisplayCheckInput = data => {
    setDisplayCheckInput(data.value === 'Yes' || false)
  }

  const validateDomain = async ({domain}) => {
    const response = await run(client(`orders/validate-website/${domain}`))
    setValidDomainResponse(response)
    if (response.status === 'valid') {
      onDomainChange(domain)
    }
  }
  return (
    <AddOnItem
      key={item.id}
      productId={item.id}
      code={code || item.code}
      listOfProducts={listOfProducts}
      title={item.name}
      description={description}
      options={radioButtonOptions}
      setState={handleDisplayFirstSection}
      isDisabled={isDisabled}
      currentAddOns={currentAddOns}
    >
      {itemConfig.planSelection && (
        <div
          className="row ml-3 mb-4"
          style={!includeProduct ? {opacity: '.4', pointerEvents: 'none'} : {}}
        >
          {billingPreference === 'semiAnnually' && (
            <>
              <Radio
                hasLabel
                id={`${item.name}-monthly`}
                name={`${item.name}-enhanced-accessibility`}
                value="monthly"
                label="Monthly"
                toggled={false}
                handleOnChange={handlePlanSelection}
              />
              <Radio
                className="ml-5"
                hasLabel
                id={`${item.name}-semiAnnually`}
                name={`${item.name}-enhanced-accessibility`}
                value="semiAnnually"
                label="Semi-Annually"
                toggled={true}
                handleOnChange={handlePlanSelection}
              />
            </>
          )}
        </div>
      )}
      {displayFirstSection && !isTSS && (
        <AddOnItem
          title="Do you have an existing published Sensis or Vivial website you wish to transfer?"
          options={isAddOnSelected ? defaultNoRadioButtons : radioButtonOptions}
          setState={handleDisplayCheckInput}
        >
          {displayCheckInput && (
            <div className="row ml-2 mb-4" data-testid="container-test">
              <div className="col-lg-9">
                <Input
                  className="w-100 mr-4"
                  type="text"
                  placeholder="yourdomain.com"
                  variant="default"
                  name="domain"
                  aria-label="domain"
                  disabled={isLoading}
                  register={register}
                  errors={errors}
                />

                {validDomainResponse.statusMessage && (
                  <p
                    className="mt-2"
                    style={{
                      color:
                        validDomainResponse.status === 'valid'
                          ? '#5cb85c'
                          : '#d9534f',
                    }}
                  >
                    {validDomainResponse.statusMessage}
                  </p>
                )}
              </div>
              <div className="col-lg-3">
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleSubmit(validateDomain)}
                  disabled={isLoading}
                >
                  Check
                </Button>
              </div>
            </div>
          )}
        </AddOnItem>
      )}
    </AddOnItem>
  )
}

export {WebsiteItem}
