/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Input, Button, ParagraphText, Header, Radio} from '@thryvlabs/maverick'
// import {toast} from 'react-toastify'
let options = [
  {
    label: 'No',
    value: 'No',
    default: true,
  },
  {
    label: 'Yes',
    value: 'Yes',
  },
]

function AlphaSEO({
  alphaProposalId,
  alphaDomain,
  alphaAmount,
  keywordCount,
  powerboostCount,
  handleChange,
  validateProposalId,
  diableValidateAndProposalInput,
  incrementKeyword,
  decrementKeyword,
  incrementPowerboost,
  decrementPowerboost,
  isExternalDomain,
  setIsExternalDomain,
  resetSEOData,
  listOfProducts,
  removeProduct,
  addProduct,
}) {
  const [isSEOOptionEnabled, setSEOOptionEnabledStatus] = useState(false)
  const [isDomainWarningVisible, setIsDomainWarningVisible] = useState(false)
  const defaultOption = options.find(x => x.default)
  const [optionValue, setOptionValue] = React.useState(defaultOption.value)
  const keyWordIncrementDisabled = keywordCount >= 3 ? true : false
  const keyWordDecrementDisabled = keywordCount === 0 ? true : false
  const powerboostIncrementDisabled = powerboostCount >= 3 ? true : false
  const powerboostDecrementDisabled = powerboostCount === 0 ? true : false

  useEffect(() => {
    checkSeoOptionDisableStyle()
  }, [alphaProposalId, isExternalDomain])

  const handleDomainTypeChange = function (e) {
    console.log('conditioned added', e.target.value)
    if (e.target.value === 'yes') {
      // toast.warn(
      //   'Please Note: External site requires the client to provide website content management system & hosting access at onboarding. SEO order is subject to cancellation if access is not provided at onboarding to avoid billing client for inactive SEO campaign',
      // )
      setIsDomainWarningVisible(true)
    } else {
      setIsDomainWarningVisible(false)
    }
    setIsExternalDomain(e.target.value)
  }

  const checkSeoOptionDisableStyle = () => {
    if (
      alphaProposalId &&
      alphaProposalId.length &&
      isExternalDomain !== null
    ) {
      setSEOOptionEnabledStatus(true)
    } else {
      setSEOOptionEnabledStatus(false)
    }
  }

  const handleOnChange = e => {
    setOptionValue(e.target.value)
    if (e.target.value === 'Yes') {
      let oldSeoPlan = listOfProducts.find(item =>
        ['addon-4-seombg-m2m', 'addon-4-seo-m2m'].includes(item.planCode),
      )
      if (oldSeoPlan) {
        console.log({oldSeoPlan})
      }
    } else {
      resetSEOData()
      let newSEO = listOfProducts.filter(item =>
        [
          'addon-4-seobase-m2m',
          'addon-4-seokey-m2m',
          'addon-4-seopb-m2m',
        ].includes(item.planCode),
      )
      if (newSEO.length > 0) {
        removeProduct([...newSEO.map(item => item.id)])
      }
    }
  }
  const disableStyle = {
    pointerEvents: 'none',
    opacity: 0.5,
  }

  return (
    <div className="ml-3" style={{borderBottom: '1px solid #eee'}}>
      <div
        className="row ml-0"
        style={{marginTop: '1rem', alignItems: 'center'}}
      >
        <div className="col-lg-6">
          <div className="float-left d-flex" style={{position: 'relative'}}>
            <Header className="mr-2" fontWeight="normal" variant="h4">
              {'SEO'}
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  color: 'rgb(163, 165, 167)',
                  fontStyle: 'normal',
                  fontFamily: 'open-sans',
                }}
              >{`$${alphaAmount || 0}`}</p>
            </Header>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="float-right" style={{position: 'relative'}}>
            <div className="row">
              {options?.map((option, key) => (
                <Radio
                  key={key}
                  className="ml-6"
                  hasLabel
                  id={`SEO`}
                  name={`SEO`}
                  value={option.value}
                  label={option.label}
                  toggled={optionValue === option.value}
                  handleOnChange={handleOnChange}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-column ml-3"
        style={
          optionValue !== 'Yes'
            ? {...disableStyle, rowGap: '10px'}
            : {rowGap: '10px'}
        }
      >
        <div className="d-flex">
          <div className="d-flex" style={{columnGap: '20px'}}>
            <Input
              required
              type="text"
              placeholder="Enter Proposal ID"
              name="proposalId"
              withLabel
              labelType="static"
              variant="input-isolated"
              onChange={handleChange}
              value={optionValue === 'Yes' ? alphaProposalId : ''}
              disabled={diableValidateAndProposalInput}
            />
            <Button
              variant="primary"
              disabled={diableValidateAndProposalInput}
              onClick={validateProposalId}
            >
              Validate
            </Button>
          </div>
        </div>
        <div className="w-50" style={{marginTop: '10px'}}>
          <ParagraphText variant="reg" className="text-left">
            Domain
          </ParagraphText>
          <Input
            aria-label="Domain"
            required
            type="text"
            placeholder=""
            name="domain"
            withLabel
            labelType="static"
            variant="input-isolated"
            value={alphaDomain}
            disabled={true}
          />
        </div>
        <div style={{marginTop: '10px'}}>
          <ParagraphText variant="reg" className="text-left">
            {'External Domain?'}
          </ParagraphText>
          <div className="d-flex gap-3">
            <Radio
              hasLabel
              id="yes"
              name="isExternalDomain"
              value="yes"
              label="Yes"
              toggled={isExternalDomain === 'yes'}
              handleOnChange={handleDomainTypeChange}
            />
            <Radio
              hasLabel
              id="no"
              name="isExternalDomain"
              value="no"
              label="No"
              toggled={isExternalDomain === 'no'}
              handleOnChange={handleDomainTypeChange}
            />
          </div>
          {isDomainWarningVisible && (
            <div>
              <ParagraphText variant="reg" className="text-left">
                <strong>Please Note:</strong>
                {
                  ' External site requires the client to provide website content management system & hosting access at onboarding. SEO order is subject to cancellation if access is not provided at onboarding to avoid billing client for inactive SEO campaign'
                }
              </ParagraphText>
            </div>
          )}
        </div>
        <div className="w-50" style={!isSEOOptionEnabled ? disableStyle : {}}>
          <div
            key={'keyword'}
            className="d-flex align-items-center  justify-content-between"
          >
            <div>
              <ParagraphText
                variant="reg"
                className="text-left"
                style={{marginTop: '24px'}}
              >
                {'Keywords:'}
              </ParagraphText>
            </div>
            <div className="d-flex align-items-center ">
              <Button
                disabled={keyWordDecrementDisabled}
                variant="primary"
                buttonType="outlined"
                onClick={decrementKeyword}
                className="fontSizeUpdate"
              >
                -
              </Button>
              &nbsp;
              {Number(keywordCount)}
              &nbsp;
              <Button
                disabled={keyWordIncrementDisabled}
                variant="primary"
                buttonType="outlined"
                onClick={incrementKeyword}
                className="fontSizeUpdate"
              >
                +
              </Button>
            </div>
          </div>

          <div
            key={'Powerboost'}
            className="d-flex align-items-center justify-content-between"
          >
            <div>
              <ParagraphText
                variant="reg"
                className="text-left"
                style={{marginTop: '24px'}}
              >
                {'Powerboost:'}
              </ParagraphText>
            </div>
            <div className="d-flex align-items-center ">
              <Button
                disabled={powerboostDecrementDisabled}
                variant="primary"
                buttonType="outlined"
                onClick={decrementPowerboost}
                className="fontSizeUpdate"
              >
                -
              </Button>
              &nbsp;
              {Number(powerboostCount)}
              &nbsp;
              <Button
                disabled={powerboostIncrementDisabled}
                variant="primary"
                buttonType="outlined"
                onClick={incrementPowerboost}
                className="fontSizeUpdate"
              >
                +
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AlphaSEO}
