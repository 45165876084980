const getTodayPreviewItems = ({paymentState, todayItems}) => {
  const {centers, apps, oneTimes} = paymentState.paymentInfo.paymentPreview
  const previewItems = [...centers, ...apps, ...oneTimes]
  const todayPreviewItems = previewItems.map(item => {
    const {addonCode, planCode, name, quantity, subtotal, discount, tax} = item
    const relativeItem = todayItems.find(
      todayItem => todayItem.code === planCode || todayItem.code === addonCode,
    )
    const isAusNZ = ['AU', 'NZ'].includes(paymentState.paymentInfo.country)

    return {
      ...relativeItem,
      code: planCode === 'appmarket-5-thryv-m2m' ? addonCode : planCode,
      name: name || relativeItem.name,
      quantity,
      currency: {
        unitAmount: !isAusNZ ? subtotal : subtotal + tax,
        currency: relativeItem.currency.currency,
      },
      discount,
      transitionApplies: discount > 0 ? true : false,
    }
  })

  return todayPreviewItems
}

export {getTodayPreviewItems}
