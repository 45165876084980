import React from 'react'
import {Header} from '@thryvlabs/maverick'
import {getScoreColorClassName} from '../../utils/get-score-config'

function ScorePercentage({score, size = 'h4', scoreColorClassName = null}) {
  const scoreClassName = getScoreColorClassName(score)
  return (
    <Header fontWeight="semibold" variant={size}>
      <span
        data-test="component-score-percentage"
        className={`font-bold ${scoreColorClassName || scoreClassName}`}
      >
        {score ? `${score}%` : '-'}
      </span>
    </Header>
  )
}

export {ScorePercentage}
