import React, {useEffect} from 'react'
import {CopyTextContainer} from './ui/payment-details'
import {ParagraphText, Checkbox, Button, Icon, Radio} from '@thryvlabs/maverick'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {LinkPrompt} from './ui/payment-details'
import {toast} from 'react-toastify'
import {useUserRoles} from '../utils/use-user-roles'

const PaymentLink = ({
  contactMethod,
  setContactMethod,
  mobile,
  email,
  currentPrice,
  totalCcPrice,
  totalPrice,
  bypassSms,
  showEmailStatusLinkOption,
  setBypassSms,
  isLinkSent,
  handleSendLink,
  handleResendLink,
  handleCheckSendGridData,
  showSmsOption,
  setShwoSmsOption,
  sendLinkError,
  shortenedUrl,
  children,
  is5Order,
}) => {
  const {roles} = useUserRoles()
  const {
    frontendTssLinkBypass,
    frontendOrdersShowSendLink,
    frontendSendLinkVia,
  } = useFlags()

  // setShowEmailStatusLinkOption
  // const [showEmailStatusLinkOption] = useState(false)

  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(shortenedUrl)
    toast.success('Link copied.')
  }

  const handleContactMethodChange = e => {
    setContactMethod(e.target.value)
  }

  useEffect(() => {
    if (frontendSendLinkVia === 'choice') {
      setContactMethod('sms')
      return
    }
    setContactMethod(frontendSendLinkVia)
  }, [frontendSendLinkVia, setContactMethod])

  const displayedMethod = contactMethod === 'sms' ? 'SMS' : contactMethod
  const showRadioButtons = frontendSendLinkVia === 'choice' || showSmsOption
  const isLinkBypassCheckboxVisible =
    (totalCcPrice && currentPrice >= totalCcPrice) ||
    (totalPrice && currentPrice >= parseFloat(totalPrice))

  return (
    <div>
      {children}
      <ParagraphText variant="reg" className="mb-1">
        The payment link will be sent via {displayedMethod} to
      </ParagraphText>
      <ParagraphText variant="reg" className="font-weight-bold">
        {contactMethod === 'sms' ? mobile : email}
      </ParagraphText>
      {showRadioButtons && (
        <div className="d-flex gap-3">
          <Radio
            hasLabel
            id="sms"
            name="contactMethods"
            value="sms"
            label="SMS"
            toggled={contactMethod === 'sms'}
            handleOnChange={handleContactMethodChange}
          />
          <Radio
            hasLabel
            id="email"
            name="contactMethods"
            value="email"
            label="email"
            toggled={contactMethod === 'email'}
            handleOnChange={handleContactMethodChange}
          />
        </div>
      )}
      {frontendTssLinkBypass && !!isLinkBypassCheckboxVisible && (
        <Checkbox
          hasLabel
          name="bypassNumber"
          label="Bypass sending Payment Link"
          isChecked={bypassSms}
          onChange={() => setBypassSms(!bypassSms)}
        />
      )}
      {isLinkSent && !bypassSms ? (
        <Button
          variant="primary"
          type="button"
          onClick={handleResendLink}
          className="mt-3 mr-2"
        >
          Send Again
        </Button>
      ) : (
        <Button
          variant="primary"
          type="button"
          onClick={handleSendLink}
          className="mt-3 mr-2"
        >
          {bypassSms ? 'Process Order' : 'Send Link'}
        </Button>
      )}
      {showEmailStatusLinkOption && !roles.includes('Admin') && (
        <Button
          variant="primary"
          type="button"
          onClick={handleCheckSendGridData}
          className="mt-3"
        >
          Check Email Status
        </Button>
      )}
      {shortenedUrl && frontendOrdersShowSendLink && (
        <CopyTextContainer className="m-2">
          <p className="mt-3 mb-3 mr-2 ml-2">{shortenedUrl}</p>
          <button onClick={handleCopyToClipBoard}>
            <strong>Copy</strong>
          </button>
        </CopyTextContainer>
      )}
      {!bypassSms && (isLinkSent || sendLinkError) && (
        <LinkPrompt success={!sendLinkError}>
          <div style={{margin: '5px 8px 0 0'}}>
            <Icon
              variant="infoCircle"
              type="regular"
              color={!sendLinkError ? '#057AFF' : '#DF2A2A'}
              height="16"
              width="16"
            />
          </div>
          <ParagraphText variant="reg" className="m-0" style={{width: '292px'}}>
            {!sendLinkError
              ? 'Thryv has sent you a payment link. Click the link to review order, enter payment and agree to the terms.'
              : 'Failed to send payment link. Click Resend to try again.'}
          </ParagraphText>
        </LinkPrompt>
      )}
    </div>
  )
}

export default PaymentLink
