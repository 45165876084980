import React from 'react'
import * as yup from 'yup'
import validator from 'validator'
import {toast} from 'react-toastify'
import {usePlaidLink} from 'react-plaid-link'
import {Controller, useForm} from 'react-hook-form'
import rawCountryStates from 'iso3166-2-db/i18n/en'
import {yupResolver} from '@hookform/resolvers/yup'
import {Button, Input, Select, Checkbox} from '@thryvlabs/maverick'

import {client} from '../../utils/api-client'
import {FormContainer} from '../ui/ach-payment-form'
import {AutopayTerms} from './autopay-terms'
import {useFlags} from 'launchdarkly-react-client-sdk'

const REQUIRED_FIELD_MESSAGE = 'This field is required'
const REQUIRED_OPTION_MESSAGE = 'Please select an option'
const MIN_CHARACTERS_REQUIRED = 'Must be at least 3 characters'
const MAX_CHARACTERS_REQUIRED = 'Must be at most 120 characters'

const SCHEMA = yup.object().shape({
  firstName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  lastName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  postalCode: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      'validate-postCode-format',
      'Incorrect post code format',
      function (value) {
        if (!value) {
          return false
        }
        const country = this.options.parent.country.value
        if (country === 'BB') {
          return value.match(/^[A-Za-z]{2}\d{5}$/)
        }
        if (country === 'KY') {
          return value.match(/^[A-Za-z]{2}\d-?\d{4}$/)
        }
        return validator.isPostalCode(value, country)
      },
    ),
  address: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  city: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  country: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
  state: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
})

const LIST_OF_COUNTRIES = Object.entries(rawCountryStates)
  .map(country => ({
    name: country[1].name,
    value: country[1].iso,
  }))
  .sort((a, b) => (a.name > b.name ? 1 : -1))

const AchPaymentForm = ({
  uuid,
  calculateTaxes,
  setCalculatingTaxes,
  checkBoxesValues,
  handleOnCheckBoxChange,
  businessAddress,
  setName,
  setIsAddressUpdated,
  isMobile,
  is5Order,
}) => {
  const [linkToken, setLinkToken] = React.useState(null)
  const [billingInfo, setBillingInfo] = React.useState(null)
  const [plaidInfo, setPlaidInfo] = React.useState(null)
  const [listOfStates, setListOfStates] = React.useState([])
  const {useBusinessAddressInfo} = checkBoxesValues
  const [addressInformation] = React.useState({...businessAddress})
  const {frontendUpdatedTerms} = useFlags()

  const handleTaxCalculation = () => {
    const {public_token, metadata} = plaidInfo
    setCalculatingTaxes(true)
    calculateTaxes({public_token, metadata, billingInfo})
    if (!isMobile) setIsAddressUpdated(true)
  }

  const {open, ready} = usePlaidLink({
    token: linkToken,
    onSuccess: (public_token, metadata) => {
      setPlaidInfo({public_token, metadata})
    },
  })

  const {
    register,
    formState: {errors},
    control,
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      firstName: '',
      lastName: '',
      address: '',
      country: {
        name: 'United States',
        value: 'US',
      },
      state: {
        name: '',
        value: '',
      },
      city: '',
      postalCode: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(SCHEMA),
  })

  const [country, state] = watch(['country', 'state'])

  const openPlaidScreen = () => {
    if (ready && linkToken) {
      open()
    }
  }

  const submitForm = data => {
    setBillingInfo(data)
    openPlaidScreen()
    setName(data.firstName)
  }

  const setCountry = value => {
    getStates(value.value)
    setValue('country', {...value})
    setValue('state', {
      name: '',
      value: '',
    })
    trigger(['country', 'state'])
  }

  const getStates = countryIso => {
    const states = rawCountryStates[countryIso].regions
      .map(({iso, name}) => ({
        name,
        value: iso || name,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1))
    setListOfStates(states)
  }

  React.useEffect(() => {
    const getPlaidLinkToken = async () => {
      try {
        const {linkToken} = await client(
          `orders/process/plaid-link-token/${uuid}`,
        )
        setLinkToken(linkToken)
      } catch (error) {
        if (error.status !== 400) {
          toast.error(error.message || 'Something went wrong.')
          throw error
        }
        error.errors.forEach(({message}) => toast.error(message))
      }
    }
    getPlaidLinkToken()
  }, [uuid])
  React.useEffect(() => {
    getStates(country.value)
  }, [country])

  React.useEffect(() => {
    if (!plaidInfo) return
    handleTaxCalculation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plaidInfo])

  React.useEffect(() => {
    if (useBusinessAddressInfo) {
      const state = listOfStates.find(
        ({value}) => value === businessAddress?.state,
      )
      setValue('firstName', addressInformation.firstName)
      setValue('lastName', addressInformation.lastName)
      setValue('address', addressInformation.address)
      setValue('city', addressInformation.city)
      setValue('postalCode', addressInformation.postalCode)
      setValue('state', state)
      if (checkBoxesValues.updateBillingAddress) {
        let fakeEvent = {target: {name: 'updateBillingAddress'}}
        handleOnCheckBoxChange(fakeEvent)
      }
    } else {
      setValue('firstName', '')
      setValue('lastName', '')
      setValue('address', '')
      setValue('city', '')
      setValue('postalCode', '')
      setValue('state', {
        name: '',
        value: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useBusinessAddressInfo, businessAddress, listOfStates])

  return (
    <FormContainer className="d-flex flex-column w-100 p-2">
      <form>
        <div className="d-flex align-items-center w-100 justify-content-around">
          <div className="input-container m-0 p-2 w-100">
            <Input
              className="w-100"
              type="text"
              placeholder="First Name"
              aria-label="First Name"
              name="firstName"
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
            />
          </div>
          <div className="input-container m-0 p-2 w-100">
            <Input
              className="w-100"
              type="text"
              placeholder="Last Name"
              aria-label="Last Name"
              name="lastName"
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
            />
          </div>
        </div>
        <div className="input-container">
          <Controller
            control={control}
            name="country"
            render={({field: {onChange, name}}) => (
              <Select
                options={LIST_OF_COUNTRIES}
                width="full"
                selectLabel="Select Country"
                selectedOption={country}
                setSelectedOption={setCountry}
                errors={errors}
                onChange={onChange}
                name={name}
              />
            )}
          />
        </div>
        <div className="input-container">
          <Input
            className="w-100"
            type="text"
            placeholder="Street Address"
            aria-label="Street Address"
            name="address"
            withLabel
            labelType="floating"
            variant="default"
            register={register}
            errors={errors}
          />
        </div>
        <div className="d-flex align-items-center w-100 justify-content-around">
          <div className="input-container w-100 m-0 mt-2 p-2">
            <Input
              className="w-100 m-0 p-0"
              type="text"
              placeholder="City"
              aria-label="City"
              name="city"
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
            />
          </div>
          <div className="input-container w-100 m-0 p-2">
            <Controller
              control={control}
              name="state"
              render={({field: {onChange, name}}) => (
                <Select
                  options={listOfStates}
                  width="full"
                  className="m-0 p-0"
                  selectLabel="Select state"
                  selectedOption={state}
                  onChange={onChange}
                  errors={errors}
                  name={name}
                  hidden
                />
              )}
            />
          </div>
        </div>
        <div className="input-container w-100 m-0 mt-3 p-2">
          <Input
            className="w-100 m-0 p-0"
            type="text"
            placeholder="Postal Code"
            aria-label="Postal Code"
            name="postalCode"
            withLabel
            labelType="floating"
            variant="default"
            register={register}
            errors={errors}
          />
        </div>
      </form>
      <div className={`mt-4 ${!frontendUpdatedTerms && 'mb-2'}`}>
        <Checkbox
          hasLabel
          name="useBusinessAddressInfo"
          value={checkBoxesValues.useBusinessAddressInfo}
          defaultChecked={checkBoxesValues.useBusinessAddressInfo}
          onChange={handleOnCheckBoxChange}
          label="Same as my business address"
        />
      </div>
      {frontendUpdatedTerms && <AutopayTerms styles={{marginTop: '10px'}} />}
      <div
        className={`d-flex align-items-center w-100 justify-content-center ${
          is5Order && frontendUpdatedTerms ? 'mt-2' : 'mt-5'
        }`}
      >
        <Button
          variant="primary"
          type="button"
          className="step__container__button"
          onClick={handleSubmit(submitForm)}
        >
          Continue
        </Button>
      </div>
    </FormContainer>
  )
}

export {AchPaymentForm}
