// eslint-disable-line

function countryIsoCodeAlpha2(country) {
  const countryCodeMap = {
    usa: 'US',
    aus: 'AU',
    can: 'CA',
    barbados: 'BB',
    cayman: 'KY',
    US: 'US',
    AU: 'AU',
    CA: 'CA',
    BB: 'BB',
    KY: 'KY',
  }
  return countryCodeMap[country]
}

function countryIsoCodeAlpha3(country) {
  const countryCodeMap = {
    US: 'usa',
    AU: 'aus',
    CA: 'can',
    BB: 'barbados',
    KY: 'cayman',
    usa: 'usa',
    aus: 'aus',
    can: 'can',
    cayman: 'cayman',
    barbados: 'barbados',
  }
  return countryCodeMap[country] || null
}

export {countryIsoCodeAlpha2, countryIsoCodeAlpha3}
