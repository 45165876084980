import thryvAnimalServices from '../assets/industries/animal-services/thryvAnimalServices.png'
import sidebarAnimalServices from '../assets/industries/animal-services/animal-services.jpeg'
import sidebarVeterinarians from '../assets/industries/animal-services/veterinarians.jpeg'
import sidebarAutoServices from '../assets/industries/automotive-services/auto-services.jpeg'
import sidebarChildcareServices from '../assets/industries/childcare-services/childcare-services.jpeg'
import sidebarCatering from '../assets/industries/events/catering.jpeg'
import sidebarDJ from '../assets/industries/events/dj.jpeg'
import sidebarFlorist from '../assets/industries/events/florist.jpeg'
import sidebarPhotography from '../assets/industries/events/photography.jpeg'
import sidebarSpecialEventVenue from '../assets/industries/events/special-event-venue.jpeg'
import sidebarWeddingServices from '../assets/industries/events/wedding-services.jpeg'
import sidebarDentist from '../assets/industries/health-services/dentist.jpeg'
import sidebarHealthcareProvider from '../assets/industries/health-services/healthcare-providers.jpeg'
import sidebarCleaningServices from '../assets/industries/home-services/cleaning-services.jpeg'
import sidebarElectrical from '../assets/industries/home-services/electrical.jpeg'
import sidebarHomeImprovement from '../assets/industries/home-services/home-improvement-and-maintenance.jpeg'
import sidebarHVAC from '../assets/industries/home-services/hvac.jpeg'
import sidebarLandscaping from '../assets/industries/home-services/landscaping.jpeg'
import sidebarLocksmith from '../assets/industries/home-services/locksmith.jpeg'
import sidebarMovingAndStorage from '../assets/industries/home-services/moving-and-storage.jpeg'
import sidebarPestControl from '../assets/industries/home-services/pest-control.jpeg'
import sidebarPlumber from '../assets/industries/home-services/plumber.jpeg'
import sidebarRoofer from '../assets/industries/home-services/roofer.jpeg'
import sidebarLegal from '../assets/industries/legal-services/legal.jpeg'
import sidebarRealEstate from '../assets/industries/real-estate/real-estate.jpeg'
import sidebarBeautySalon from '../assets/industries/salons/beauty-salon.jpeg'
import sidebarHealthAndWellness from '../assets/industries/wellness/health-and-wellness.jpeg'
import sidebarBusinessServices from '../assets/industries/thryv/business-services.jpeg'
import sidebarGeneric from '../assets/industries/thryv/generic.jpeg'
import sidebarInsurance from '../assets/industries/thryv/insurance.jpeg'
import sidebarGovernmentNonProfit from '../assets/industries/thryv/nonprofit-government.jpeg'
import sidebarOtherHomeService from '../assets/industries/home-services/sidebar-other-home-service.jpg'
import thryvAutomotiveServices from '../assets/industries/automotive-services/thryvAutomotiveServices.png'
import thryvChildcareServices from '../assets/industries/childcare-services/thryvChildcare.png'
import thryvEvents from '../assets/industries/events/thryvEvents.png'
import thryvHealthServices from '../assets/industries/health-services/thryvHealthServices.png'
import thryvHomeServices from '../assets/industries/home-services/thryvHomeServices.png'
import thryvLegalServices from '../assets/industries/legal-services/thryvLegalServices.png'
import thryvRealEstate from '../assets/industries/real-estate/thryvRealEstate.png'
import thryvSalons from '../assets/industries/salons/thryvSalons.png'
import thryvWellness from '../assets/industries/wellness/thryvWellness.png'
import thryv from '../assets/industries/thryv/thryv_logo.png'
import logoAnimalServices from '../assets/industries/animal-services/IndustrySubIcons_AnimalServices.png'
import logoVeterinarians from '../assets/industries/animal-services/IndustrySubIcons_Veterinarians.png'
import logoAutoServices from '../assets/industries/automotive-services/IndustrySubIcons_AutoServices.png'
import logoChildcareServices from '../assets/industries/childcare-services/IndustrySubIcons_ChildCare.png'
import logoCatering from '../assets/industries/events/IndustrySubIcons_Catering.png'
import logoDJ from '../assets/industries/events/IndustrySubIcons_DJ.png'
import logoFlorist from '../assets/industries/events/IndustrySubIcons_Florist.png'
import logoPhotography from '../assets/industries/events/IndustrySubIcons_Photography.png'
import logoSpecialEventVenue from '../assets/industries/events/IndustrySubIcons_SpecialEventVenue.png'
import logoWeddingServices from '../assets/industries/events/IndustrySubIcons_WeddingConsultant.png'
import logoDentist from '../assets/industries/health-services/IndustrySubIcons_Dentists.png'
import logoHealthcareProvider from '../assets/industries/health-services/IndustrySubIcons_HealthcareProviders.png'
import logoCleaningServices from '../assets/industries/home-services/IndustrySubIcons_CleaningServices.png'
import logoElectrical from '../assets/industries/home-services/IndustrySubIcons_ElectricalContractor.png'
import logoHomeImprovement from '../assets/industries/home-services/IndustrySubIcons_HomeImprovementandMaintenance.png'
import logoHVAC from '../assets/industries/home-services/IndustrySubIcons_HVACServices.png'
import logoLandscaping from '../assets/industries/home-services/IndustrySubIcons_LawnCare.png'
import logoLocksmith from '../assets/industries/home-services/IndustrySubIcons_LocksandLocksmiths.png'
import logoMovingAndStorage from '../assets/industries/home-services/IndustrySubIcons_MovingandStorage.png'
import logoPestControl from '../assets/industries/home-services/IndustrySubIcons_PestControlServices.png'
import logoPlumber from '../assets/industries/home-services/IndustrySubIcons_Plumbing.png'
import logoRoofer from '../assets/industries/home-services/IndustrySubIcons_Contractors.png'
import logoLegal from '../assets/industries/legal-services/IndustrySubIcons_LegalServices.png'
import logoRealEstate from '../assets/industries/real-estate/IndustrySubIcons_RealEstate.png'
import logoBeautySalon from '../assets/industries/salons/IndustrySubIcons_BeautySalon.png'
import logoHealthAndWellness from '../assets/industries/wellness/IndustrySubIcons_HealthandWellness.png'
import logoBusinessServices from '../assets/industries/thryv/IndustrySubIcons_BusinessServices.png'
import logoGeneric from '../assets/industries/thryv/IndustrySubIcons_Generic.png'
import logoInsurance from '../assets/industries/thryv/IndustrySubIcons_Insurance.png'
import logoGovernmentNonProfit from '../assets/industries/thryv/IndustrySubIcons_GovernementNonProfit.png'
import logoOtherHomeServices from '../assets/industries/home-services/IndustrySubIcons_OtherHomeServices.png'

const INDUSTRIES = [
  {
    id: 1,
    template: {
      US: 'fpdwyu4e6ly3txw0',
      CA: 'm61m8ysjadqk7ou1',
      AU: 'm61m8ysjadqk7ou1',
      NZ: 'qyr1fbay0y0jjdr8',
    },
    categoryId: 1,
    name: 'Animal Services',
    thryvLogo: thryvAnimalServices,
    industryLogo: logoAnimalServices,
    sidebarImage: sidebarAnimalServices,
    sidebar: [
      {
        subtitle: 'Online Appointments',
        description:
          'Spend more time with wagging tails by allowing pet parents to book grooming appointments, training classes and more online. Manage your day and staff all in one place.',
      },
      {
        subtitle: 'Pet Management',
        description:
          'Organize multiple pets under their owner and keep detailed information such as medications, training schedules and grooming notes in one place. Manage each pet with a personalized experience documenting information for future reference.',
      },
      {
        subtitle: 'Announcements',
        description:
          'Send Happy Birthday messages, grooming specials, vaccine reminders and encourage pet owners to return with timely promotions and calls to action.',
      },
    ],
  },
  {
    id: 2,
    template: {
      US: 's8rdmqrdi1y85btu',
      CA: 'evrxrl412malwmqt',
      AU: 'evrxrl412malwmqt',
      NZ: 'zjeu2l2zgp2eo6rj',
    },
    categoryId: 1,
    name: 'Veterinarians',
    thryvLogo: thryvAnimalServices,
    industryLogo: logoVeterinarians,
    sidebarImage: sidebarVeterinarians,
    sidebar: [
      {
        subtitle: 'Online Appointments',
        description:
          'Spend more time with furry friends by allowing pet parents to book vaccinations, check-ups and more online. Manage your day and Staff all in one place.',
      },
      {
        subtitle: 'Pet Management',
        description:
          'Organise multiple pets under their owner and keep detailed information such as medications, vaccinations and pet notes in one place. Manage each pet and their essential details with custom fields to provide a personalised experience.',
      },
      {
        subtitle: 'Announcements',
        description:
          'Send Happy Birthdays, vaccine reminders and encourage pet owners to return with timely promotions and calls to action. Send immediately or based on a timeframe for right time messaging.',
      },
    ],
  },
  {
    id: 3,
    template: {
      US: 'xxasn7rcgupo3nls',
      CA: 'ka52084367pxqv0z',
      AU: 'ka52084367pxqv0z',
      NZ: '0t7m343rhsuk8p2v',
    },
    categoryId: 2,
    name: 'Auto Services',
    thryvLogo: thryvAutomotiveServices,
    industryLogo: logoAutoServices,
    sidebarImage: sidebarAutoServices,
    sidebar: [
      {
        subtitle: 'Invoices & Payments',
        description:
          'Create custom invoices with parts and labor prices in one place. Allow customers to pay in person, or online and spend less time at the desk and more time under the hood.',
      },
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Manage all your mechanics and auto specialists in one place and stay on top of all your appointments. Track jobs and appointments to specific mechanics and their expertise to provide top-notch customer service.',
      },
      {
        subtitle: 'Communication',
        description:
          'Automated notifications allow service teams to focus on the job while providing updates to customers. Personalize your customer’s experience by sending real-time updates via text/email, based on their preferred method of communication.',
      },
    ],
  },
  {
    id: 4,
    template: {
      US: 'crec9ry5fk41cl4o',
      CA: 'u5uww8644ah36a4d',
      AU: 'u5uww8644ah36a4d',
      NZ: 'n00oft6hebhq2uib',
    },
    categoryId: 3,
    name: 'Child Care Services',
    thryvLogo: thryvChildcareServices,
    industryLogo: logoChildcareServices,
    sidebarImage: sidebarChildcareServices,
    sidebar: [
      {
        subtitle: 'Student Management',
        description:
          'Create custom records for each child, including important information such as dietary restrictions, medications, emergency contact info, documents and special care instructions. Communicate often with parents through text and email, keeping all conversations in one place.',
      },
      {
        subtitle: 'Client Portal',
        description:
          'Provide convenience to busy parents offering a secure Client Portal to book appointments, upload documents, message you, make payments and more. A great place to communicate and connect.',
      },
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Allow recurring or one-off bookings for daily, weekly and monthly childcare services. Manage your capacity and Staff more efficiently by establishing services and scheduling by rooms, grade and age, or teacher.',
      },
    ],
  },
  {
    id: 5,
    template: {
      US: 'sdcf1kp11548b8l7',
      CA: 'aq0qc98agsgb762y',
      AU: 'aq0qc98agsgb762y',
      NZ: 'qozi7x9joy3zpacw',
    },
    categoryId: 4,
    name: 'Catering',
    thryvLogo: thryvEvents,
    industryLogo: logoCatering,
    sidebarImage: sidebarCatering,
    sidebar: [
      {
        subtitle: 'Invoices & Payments',
        description:
          'Offer clients Payment flexibility, including partial payments, at time of booking, via Invoice, or through the secure Client Portal. Connect to ThryvPay and offset costs with convenience fees and accept tips.',
      },
      {
        subtitle: 'Documents',
        description:
          'Store event details, including contracts, menus, photos and more for easy retrieval and sharing. Share specific documents with individual clients, or for all clients based on preferences.',
      },
      {
        subtitle: 'Announcements',
        description:
          'Automatically stay connected and notify clients of new menu items, special events and promotions or send thank you’s after events. Share monthly newsletters with recipes and meal plans to stay top of mind.',
      },
    ],
  },
  {
    id: 6,
    template: {
      US: 'nc6hfrfhpvw231nm',
      CA: 'tj250c9a19z9txb7',
      AU: 'tj250c9a19z9txb7',
      NZ: 'payrt2107xf6nlc3',
    },
    categoryId: 4,
    name: 'DJ',
    thryvLogo: thryvEvents,
    industryLogo: logoDJ,
    sidebarImage: sidebarDJ,
    sidebar: [
      {
        subtitle: 'Documents',
        description:
          'Upload playlists to Client Portal making it easy for clients to access, review and download. Store requests like song titles in notes for easy retrieval and to save time searching through the playlist.',
      },
      {
        subtitle: 'Invoices & Payments',
        description:
          'Create Estimates and Invoices you can edit and update at any time. Allow clients to make partial payments over time and get notified after payment. Connect with ThryvPay!',
      },
      {
        subtitle: 'Event Management',
        description:
          'Create custom fields to organize and manage client information. Track the party date, location, and requested songs or theme. Easily store notes from the consultation you’ll need for the day of the event.',
      },
    ],
  },
  {
    id: 7,
    template: {
      US: '8ixe2zkouypw43ff',
      CA: 'bq0ls98x7ngs9qvw',
      AU: 'bq0ls98x7ngs9qvw',
      NZ: 'pnbu7zp2sz8jfne2',
    },
    categoryId: 4,
    name: 'Florist',
    thryvLogo: thryvEvents,
    industryLogo: logoFlorist,
    sidebarImage: sidebarFlorist,
    sidebar: [
      {
        subtitle: 'Social Media',
        description:
          'Create a Social presence, posting and sharing that engage your customers and build new fans. Schedule floral tips, show off your floral arrangements and offer promotions across top Social networks.',
      },
      {
        subtitle: 'Online Presence',
        description:
          'Get found and selected with Google My Business and over 40+ online sites, locked and synced business info. Ensure searchers always have the most up-to-date and accurate information, find answers and ultimately choose your business over competitors.',
      },
      {
        subtitle: 'Communication',
        description:
          'Personalize your conversations and let customers know you value their business and loyalty. Whether you’re having a 1:1 conversation or want to send announcements to all customers, send via text or email based on their preference.',
      },
    ],
  },
  {
    id: 8,
    template: {
      US: 'c29txs2s19o1zdbz',
      CA: 'es91nx3ijxki9dzc',
      AU: 'es91nx3ijxki9dzc',
      NZ: 'aq9enxqvio3y40pq',
    },
    categoryId: 4,
    name: 'Special Event Venue',
    thryvLogo: thryvEvents,
    industryLogo: logoSpecialEventVenue,
    sidebarImage: sidebarSpecialEventVenue,
    sidebar: [
      {
        subtitle: 'Invoices & Payments',
        description:
          'Create Estimates and Invoices, confirming event details and event terms, easy to update at any time. Allow clients to make partial payments over time and get notified after payment. Connect with ThryvPay!',
      },
      {
        subtitle: 'Documents',
        description:
          'Upload event contracts and event details to Client Portal making it easy for clients to access, review and download. Store requested menus or vendors in notes for easy retrieval and to save time.',
      },
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Never double book! Keep events organized on the Thryv calendar and view by day, week, or month. Allow Staff to see upcoming events to prepare the venue as needed.',
      },
    ],
  },
  {
    id: 9,
    template: {
      US: 'xdkswkwnc4r6bmw1',
      CA: 'x7lsd30ed8uh9tv1',
      AU: 'x7lsd30ed8uh9tv1',
      NZ: 'tfgbyanain0h0l93',
    },
    categoryId: 4,
    name: 'Photography',
    thryvLogo: thryvEvents,
    industryLogo: logoPhotography,
    sidebarImage: sidebarPhotography,
    sidebar: [
      {
        subtitle: 'Documents',
        description:
          'Upload contracts to Client Portal making it easy for clients to access, review and download. Store requested photoshoot list or preferred site locations in notes for easy retrieval and to save time.',
      },
      {
        subtitle: 'Invoices & Payments',
        description:
          'Create Estimates and Invoices you can edit and update at any time. Allow clients to make partial payments over time and get notified after payment. Connect with ThryvPay!',
      },
      {
        subtitle: 'Event Management',
        description:
          'Create custom fields and set up tags to organize and manage client and event information. Track essential details like photoshoot date, location and other client preferences in one place to easier manage your day and Staff.',
      },
    ],
  },
  {
    id: 10,
    template: {
      US: 'gqo89w44i6kroo3j',
      CA: 'yl9z5phgkqb9eqqz',
      AU: 'yl9z5phgkqb9eqqz',
      NZ: 'j0srxq3jhk6bwb9f',
    },
    categoryId: 4,
    name: 'Wedding Consultant',
    thryvLogo: thryvEvents,
    industryLogo: logoWeddingServices,
    sidebarImage: sidebarWeddingServices,
    sidebar: [
      {
        subtitle: 'Documents',
        description:
          'Upload special day details and contracts to the secure Client Portal, making it easy for clients to access, review and download. Store requested and essential big day details in notes for easy retrieval that saves time.',
      },
      {
        subtitle: 'Client Management',
        description:
          'Create custom fields to organize and manage event information like bride, groom, wedding party details. Track essential details like wedding date, location and other client preferences in one place to easier manage your day and Staff.',
      },
      {
        subtitle: 'Invoices & Payments',
        description:
          'Create Estimates and Invoices, confirming event details and event terms, easy to update at any time. Allow clients to make partial payments over time and get notified after payment. Connect with ThryvPay!',
      },
    ],
  },
  {
    id: 11,
    template: {
      US: '52k10f2ml8m1f41u',
      CA: 'npw7yasl1nk7kogj',
      AU: 'npw7yasl1nk7kogj',
      NZ: 'bh3vzq2z46wlcsel',
    },
    categoryId: 5,
    name: 'Dentists',
    thryvLogo: thryvHealthServices,
    industryLogo: logoDentist,
    sidebarImage: sidebarDentist,
    sidebar: [
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Allow patients to self-book online, reschedule, cancel, and maintain control utilizing the Calendar to manage appointments internally. Send automatic reminders and follow-ups to help minimize missed appointments, especially cleanings scheduled every 6 months.',
      },
      {
        subtitle: 'Patient Management',
        description:
          'Review patient history, notes, past conversations and stored documents all in one place. Keep track of crowns, cavities and cleanings to provide exceptional service.',
      },
      {
        subtitle: 'Reputation Management',
        description:
          'Encourage Reviews after service from happy customers and increase customer referrals. Easily monitor and respond to Reviews inside Thryv, increasing customer satisfaction and overall ratings.',
      },
    ],
  },
  {
    id: 12,
    template: {
      US: 'e8cgl2m7jp221sx7',
      CA: 'yogzktym2ij35n5d',
      AU: 'yogzktym2ij35n5d',
      NZ: '3w8dld1x2tpechoy',
    },
    categoryId: 5,
    name: 'Healthcare Providers',
    thryvLogo: thryvHealthServices,
    industryLogo: logoHealthcareProvider,
    sidebarImage: sidebarHealthcareProvider,
    sidebar: [
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Manage all your patient Appointments and Staff schedules in one place. Create your customized Services with assigned Staff, making it easy for your customers to book online 24/7.',
      },
      {
        subtitle: 'Patient Management',
        description:
          'Organize patient information and create custom fields to capture and store essential details in your CRM. Quickly review and share patient history, notes, conversations and stored documents with assigned Staff and your patients. HIPAA add-on is a great way to ensure all your patient data has extra privacy and security safeguards.',
      },
      {
        subtitle: 'Reputation Management',
        description:
          'Generate automatic referrals and build customer loyalty by requesting patient reviews for your business after their exam or appointment. Get notified instantly and respond to reviews inside Thryv from Google, FB, Yelp and Thryv.',
      },
    ],
  },
  {
    id: 13,
    template: {
      US: 'v9dgfuw8qo0fjnsv',
      CA: '58fmox5pt1n9lalw',
      AU: '58fmox5pt1n9lalw',
      NZ: 'n3nw3951a1k76jjn',
    },
    categoryId: 6,
    name: 'HVAC',
    thryvLogo: thryvHomeServices,
    industryLogo: logoHVAC,
    sidebarImage: sidebarHVAC,
    sidebar: [
      {
        subtitle: 'Estimates & Invoices',
        description:
          'Secure more jobs by creating, editing and sending customized Estimates and Invoices right from mobile. Always have the latest Estimate or Invoice available whether you’re on-site, in the truck, or at the office.',
      },
      {
        subtitle: 'Payments',
        description:
          'Get paid faster and easier and be more profitable. Offer installment plans for big jobs or recurring payments for ongoing maintenance when connected to ThryvPay.',
      },
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Manage your Appointments for customers and technicians all in one calendar. Maximize time and schedules, keeping technicians and customers updated with job details, appointment confirmations, “on the way” messages and more.',
      },
    ],
  },
  {
    id: 14,
    template: {
      US: 'bzef16u5a065c5lg',
      CA: 'ncbtbz4cre558ehh',
      AU: 'ncbtbz4cre558ehh',
      NZ: 'c5zxkqv2e2tzgcyj',
    },
    categoryId: 6,
    name: 'Plumbers',
    thryvLogo: thryvHomeServices,
    industryLogo: logoPlumber,
    sidebarImage: sidebarPlumber,
    sidebar: [
      {
        subtitle: 'Estimates & Invoices',
        description:
          'Secure more jobs by creating, editing and sending customized Estimates and Invoices right from your mobile phone. Always have the latest Estimate or Invoice available whether you’re on-site, in the truck, or at the office.',
      },
      {
        subtitle: 'Payments',
        description:
          'Get paid faster and easier and be more profitable. Offer installment plans for big jobs or recurring payments for ongoing maintenance when connected to ThryvPay.',
      },
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Manage your Appointments for customers and plumbers all in one calendar. Maximize time and schedules, keeping plumbers and customers updated with job details, appointment confirmations, “on the way” messages and more.',
      },
    ],
  },
  {
    id: 15,
    template: {
      US: 'kzb9gib3np9cmxsu',
      CA: 'pnn2fy92y3a842cf',
      AU: 'pnn2fy92y3a842cf',
      NZ: 'ofkcotgpt72hyq3n',
    },
    categoryId: 6,
    name: 'Home Improvement',
    thryvLogo: thryvHomeServices,
    industryLogo: logoHomeImprovement,
    sidebarImage: sidebarHomeImprovement,
    sidebar: [
      {
        subtitle: 'Estimates & Invoices',
        description:
          'Create and send customized estimates and invoices from the palm of your hand. Always have the latest Invoice available whether you’re on-site or in the truck and easily edit existing invoices when needed.',
      },
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Keep your Calendar updated and with you wherever you go. Never miss an appointment with automated reminders and quickly notify clients that you are on your way.',
      },
      {
        subtitle: 'Reputation Management',
        description:
          'Increase your reviews online with automated review requests after every job. Respond to reviews on major sites such as Google and Yelp and show your customers and potential customers you value their business.',
      },
    ],
  },
  {
    id: 16,
    template: {
      US: 'fu565pkxxw3k7rwq',
      CA: '5nt4iu0xwt5ni75d',
      AU: '5nt4iu0xwt5ni75d',
      NZ: 'h8jsb06vd31t3186',
    },
    categoryId: 6,
    name: 'Landscaping',
    thryvLogo: thryvHomeServices,
    industryLogo: logoLandscaping,
    sidebarImage: sidebarLandscaping,
    sidebar: [
      {
        subtitle: 'Invoices & Payments',
        description:
          'Send Invoices from anywhere and get paid faster, making it easy for customers to pay online. Save credit card and bank information on file for quick and easy recurring payments, saving time and money. Connect payment processing to ThryvPay.',
      },
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'View Scheduled Appointments by day, week, or month to better manage and organize your day. Schedule repeat appointments for loyal customers with automated reminders to let them know you’re on your way.',
      },
      {
        subtitle: 'Job Management',
        description:
          'Build stronger relationships with your customers and save time on every visit documenting all personal lawn care preferences. Create custom fields to track important details such as yard size, job type, maintenance plan and any other specific preferences.',
      },
    ],
  },
  {
    id: 17,
    template: {
      US: 'xcsrhimx28l6qufq',
      CA: 'r3m0jp00e701wjhf',
      AU: 'r3m0jp00e701wjhf',
      NZ: 'sxc5usxab8m3m7pz',
    },
    categoryId: 6,
    name: 'Electrical',
    thryvLogo: thryvHomeServices,
    industryLogo: logoElectrical,
    sidebarImage: sidebarElectrical,
    sidebar: [
      {
        subtitle: 'Estimates & Invoices',
        description:
          'Get Paid faster and make it easy for your customers to pay online and on time, on the job, in the truck or at the office. Turn approved Estimates to Invoices on-site, set up automatic payments with Card on File, and more connected to ThryvPay.',
      },
      {
        subtitle: 'Online Presence',
        description:
          'Get found and selected with Google My Business and over 40+ online sites, locked and synced business info. Ensure searchers always have the most up-to-date and accurate information, find answers and ultimately choose your business over competitors.',
      },
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Never get your wires or your appointments crossed! Manage your customer Appointments and Staff in one place and take charge of your day.',
      },
    ],
  },
  {
    id: 18,
    template: {
      US: '3xkutp246xlvuc40',
      CA: 'y7d6obu8c20ud1b0',
      AU: 'y7d6obu8c20ud1b0',
      NZ: 'uiyuxww47v5k5da3',
    },
    categoryId: 6,
    name: 'Roofer',
    thryvLogo: thryvHomeServices,
    industryLogo: logoRoofer,
    sidebarImage: sidebarRoofer,
    sidebar: [
      {
        subtitle: 'Estimates & Invoices',
        description:
          'Secure more jobs by creating, editing and sending customized Estimates and Invoices right from your mobile device. Always have the latest Estimate or Invoice available whether you’re on the job, in the truck, or at the office.',
      },
      {
        subtitle: 'Payments',
        description:
          'Get paid faster and easier and be more profitable. Offer installment plans for big jobs like roof replacement or quick payment links for smaller repair jobs when connected to ThryvPay.',
      },
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Manage your Appointments for customers and technicians crew all in one calendar. Maximize time and schedules, keeping your crew and customers updated with job details, appointment confirmations, “on the way” messages and more.',
      },
    ],
  },
  {
    id: 19,
    template: {
      US: 'cersh11m2m8b7473',
      CA: 'nxqx50105gz5h9qx',
      AU: 'nxqx50105gz5h9qx',
      NZ: 'dq3ojbgxcl7vkrxu',
    },
    categoryId: 6,
    name: 'Locks & Locksmiths',
    thryvLogo: thryvHomeServices,
    industryLogo: logoLocksmith,
    sidebarImage: sidebarLocksmith,
    sidebar: [
      {
        subtitle: 'Payments',
        description:
          'Get paid faster and easier taking on-the-job credit card payments, especially for emergency lock out services when customers don’t have cash on hand. Text to Pay makes for easy custom amounts when connected to ThryvPay.',
      },
      {
        subtitle: 'Online Presence',
        description:
          'Get found and selected with Google My Business and over 40+ online sites, locked and synced business info. Ensure searchers always have the most up-to-date and accurate information, find answers and ultimately choose your business over competitors.',
      },
      {
        subtitle: 'Review Generation',
        description:
          'Generate reviews from satisfied customers without the manual hassle. Automate review requests with each completed appointment and increase your reputation online with increased reviews.',
      },
    ],
  },
  {
    id: 20,
    template: {
      US: 'vladzim3iuzqvb22',
      CA: 'pcufx88zjtaio8ls',
      AU: 'pcufx88zjtaio8ls',
      NZ: 'k7i85sg4dybfrjjm',
    },
    categoryId: 6,
    name: 'Moving & Storage',
    thryvLogo: thryvHomeServices,
    industryLogo: logoMovingAndStorage,
    sidebarImage: sidebarMovingAndStorage,
    sidebar: [
      {
        subtitle: 'Estimates & Invoices',
        description:
          'Create customized Estimates and Invoices with detailed line items. Turn approved Estimates into Invoices and Get Paid before your crew starts the job. Connect with ThryvPay!',
      },
      {
        subtitle: 'Online Presence',
        description:
          'Get found and selected with Google My Business and over 40+ online sites, locked and synced business info. Ensure searchers always have the most up-to-date and accurate information, find answers and ultimately choose your business over competitors.',
      },
      {
        subtitle: 'Review Generation',
        description:
          'Generate reviews from satisfied customers without the manual hassle. Automate review requests with each completed appointment and increase your reputation online with increased reviews.',
      },
    ],
  },
  {
    id: 21,
    template: {
      US: 'r6nlquwc5fwxl6ec',
      CA: '4svgsm8l79nwq93g',
      AU: '4svgsm8l79nwq93g',
      NZ: '18200qe584ags2lv',
    },
    categoryId: 6,
    name: 'Pest Control Services',
    thryvLogo: thryvHomeServices,
    industryLogo: logoPestControl,
    sidebarImage: sidebarPestControl,
    sidebar: [
      {
        subtitle: 'Estimates & Invoices',
        description:
          'Create customized Estimates and Invoices with detailed line items. Turn approved Estimates into Invoices and Get Paid before your Staff starts the job. Connect with ThryvPay!',
      },
      {
        subtitle: 'Review Generation',
        description:
          'Generate reviews from satisfied customers without the manual hassle. Automate review requests with each completed appointment and increase your reputation online with increased reviews.',
      },
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Manage your Appointments for customers and technicians all in one calendar. Maximize time and schedules, keeping technicians and customers updated with job details, appointment confirmations, “on the way” messages and more.',
      },
    ],
  },
  {
    id: 22,
    template: {
      US: 'xje142l5nim3gxl6',
      CA: '2fc9hwcyb466e5iz',
      AU: '2fc9hwcyb466e5iz',
      NZ: '6o22ew6b6r429rvf',
    },
    categoryId: 6,
    name: 'Cleaning Services',
    thryvLogo: thryvHomeServices,
    industryLogo: logoCleaningServices,
    sidebarImage: sidebarCleaningServices,
    sidebar: [
      {
        subtitle: 'Invoices & Payments',
        description:
          'Automate billing and payment reminders by securely saving credit card or bank information on file. Schedule recurring billing weekly, monthly or quarterly for routine cleanings connecting to ThryvPay.',
      },
      {
        subtitle: 'Job Management',
        description:
          'Keep your client information organized and available, helping you manage your day and Staff more efficiently. Organize clients by type of house or type of cleaning service. Create custom fields to collect essential details that let your clients know small details are your big priority.',
      },
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'View Scheduled Appointments by day, week, or month to better manage and organize your day. Stay on top of staff services and locations and never miss an appointment with automated reminders sent right to their phone.',
      },
    ],
  },
  {
    id: 23,
    template: {
      US: '4z1uo7b7guv9bz7p',
      CA: 'jkvpyu27dhp74md6',
      AU: 'jkvpyu27dhp74md6',
      NZ: '6egkzk5k4inbt1sz',
    },
    categoryId: 6,
    name: 'Other Home Services',
    thryvLogo: thryvHomeServices,
    industryLogo: logoOtherHomeServices,
    sidebarImage: sidebarOtherHomeService,
    sidebar: [
      {
        subtitle: 'Estimates & Invoices',
        description:
          'Secure more jobs by creating, editing and sending customized Estimates and Invoices right from your mobile device. Always have the latest Estimate or Invoice available whether you’re on-site, in the truck, or at the office.',
      },
      {
        subtitle: 'Payments',
        description:
          'Get paid faster and easier and be more profitable. Offer installment plans for big jobs or recurring payments for ongoing maintenance when connected to ThryvPay.',
      },
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Manage your Appointments for customers and technicians all in one calendar. Maximize time and schedules, keeping technicians and customers updated with job details, appointment confirmations, “on the way” messages and more.',
      },
    ],
  },
  {
    id: 24,
    template: {
      US: 'zqr7n86laenz5k57',
      CA: 'm6s9bg8wnjqegxuh',
      AU: 'm6s9bg8wnjqegxuh',
      NZ: 'umvd3ltwe7j6beg8',
    },
    categoryId: 7,
    name: 'Legal Services',
    thryvLogo: thryvLegalServices,
    industryLogo: logoLegal,
    sidebarImage: sidebarLegal,
    sidebar: [
      {
        subtitle: 'Case Management',
        description:
          'Organize critical case and client details such as documents, case details and client conversations all in one place. Manage multiple cases or multiple defendants and keep your clients as your top priority.',
      },
      {
        subtitle: 'Documents',
        description:
          'Keep case files, contracts and plea agreements stored in one place. Organize case documents for easy review and accessibility and clear the folders from your desk.',
      },
      {
        subtitle: 'Billing & Invoices',
        description:
          'Create customized Estimates and Invoices with detailed line items. Turn approved Estimates into invoices and get paid in full or in installments.',
      },
    ],
  },
  {
    id: 25,
    template: {
      US: 'l2ty5vdcwdcqv1xl',
      CA: 'y8xbwdux8nvctzu9',
      AU: 'y8xbwdux8nvctzu9',
      NZ: 'kz58ok4dlz197cfb',
    },
    categoryId: 8,
    name: 'Real Estate',
    thryvLogo: thryvRealEstate,
    industryLogo: logoRealEstate,
    sidebarImage: sidebarRealEstate,
    sidebar: [
      {
        subtitle: 'Property Management',
        description:
          'Organize property details such as buyers, sellers, and listings with custom fields to capture important information. Add notes regarding client preferences for future reference and find the perfect home or office.',
      },
      {
        subtitle: 'Documents',
        description:
          'Upload property listing details and contracts you can easily share with potential buyers and clients. Share important documents with all clients via your Client Portal page or individually as needed, safe and secure.',
      },
      {
        subtitle: 'Announcements',
        description:
          'Keep potential buyers engaged sending new listings in and add links or share documents they can review. Follow-up with previous buyers to wish them a happy anniversary and ask for a referral to increase your client roster.',
      },
    ],
  },
  {
    id: 26,
    template: {
      US: '1w9k023rhwpetn91',
      CA: 'xdh1eq0f8kglr1vm',
      AU: 'xdh1eq0f8kglr1vm',
      NZ: 'detg9ebcb45m6bk8',
    },
    categoryId: 9,
    name: 'Beauty Salons',
    thryvLogo: thryvSalons,
    industryLogo: logoBeautySalon,
    sidebarImage: sidebarBeautySalon,
    sidebar: [
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Manage your Schedule and availability in one place by allowing clients to book with you online. Automatically accept appointments, send reminders and minimize empty chairs.',
      },
      {
        subtitle: 'Client Management',
        description:
          'Save and manage client information all in one place and provide personalized service at every visit. Add notes such as service preferences, products used and keep your clients feeling beautiful and happy with every visit.',
      },
      {
        subtitle: 'Reputation Management',
        description:
          'Generate reviews and increase your reputation online. Automatically request a review after every completed appointment, including major sites like Google, FB, and Yelp.',
      },
    ],
  },
  {
    id: 27,
    template: {
      US: 'pcqjcmfqjvknm31t',
      CA: 'cvnisev081hfqjc9',
      AU: 'cvnisev081hfqjc9',
      NZ: '03p5azk5owc1u2f2',
    },
    categoryId: 10,
    name: 'Health & Wellness',
    thryvLogo: thryvWellness,
    industryLogo: logoHealthAndWellness,
    sidebarImage: sidebarHealthAndWellness,
    sidebar: [
      {
        subtitle: 'Scheduling & Appointments',
        description:
          'Manage all your client Appointments and Staff schedules in one place. Create your customized Services with assigned Staff, making it easy for your customers to book online 24/7.',
      },
      {
        subtitle: 'Client Management',
        description:
          'Organize client information and create custom fields to capture and store essential details in your CRM. Quickly review and share patient history, notes, conversations and stored documents with assigned Staff and your patients. HIPAA add-on is a great way to ensure all your client data has extra privacy and security safeguards.',
      },
      {
        subtitle: 'Review Generation',
        description:
          'Generate automatic referrals and build customer loyalty by requesting clients review your business after class or appointment. Get notified instantly and respond to reviews inside Thryv from Google, FB, Yelp and Thryv.',
      },
    ],
  },
  {
    id: 28,
    template: {
      US: '7oq01mvuszy6g54h',
      CA: 'my7dk71gox1ng9gx',
      AU: 'my7dk71gox1ng9gx',
      NZ: 'j2zyu5p37nb6melr',
    },
    categoryId: 11,
    name: 'Business Services',
    thryvLogo: thryv,
    industryLogo: logoBusinessServices,
    sidebarImage: sidebarBusinessServices,
    sidebar: [
      {
        subtitle: 'Invoices & Payments',
        description:
          'Create customized Estimates and Invoices with detailed line items. Turn approved Estimates into Invoices and Get Paid in full or installments when connected to ThryvPay.',
      },
      {
        subtitle: 'Online Appointments',
        description:
          'Drive Appointments by allowing clients to schedule online 24/7 when it’s convenient for them. Keep your day complete and maximize your staff productivity by managing all Appointments, Reminders and Follow-ups in one place.',
      },
      {
        subtitle: 'Communication',
        description:
          'Deliver professional updates and reminders to clients via text and email while keeping all conversations organized in a single thread for you, the client and your associates to reference.',
      },
    ],
  },
  {
    id: 29,
    template: {
      US: 'obbadnu7xcthjffj',
      CA: 'ndvnwwlquq3zumgx',
      AU: 'ndvnwwlquq3zumgx',
      NZ: 'e0jfhezpngouefpe',
    },
    categoryId: 11,
    thryvLogo: thryv,
    industryLogo: logoGovernmentNonProfit,
    name: 'Government/Non-Profit',
    sidebarImage: sidebarGovernmentNonProfit,
    sidebar: [
      {
        subtitle: 'Account Management',
        description:
          'Know more about your customers by capturing essential and detailed information you can use to engage with them routinely. Stay organized with tagging to create easy ways to filter, find and track your customers when sending educational docs or requesting donations.',
      },
      {
        subtitle: 'Social Media',
        description:
          'Create a Social presence, posting and sharing to engage your customers and build new fans. Schedule non-profit educational content and showcase your cause across top Social networks.',
      },
      {
        subtitle: 'Announcements',
        description:
          'Keep customers and donors up to date with easy-to-create text and email campaigns. Personalize every interaction and incorporate calls-to-action to drive donations, sign-ups and more.',
      },
    ],
  },
  {
    id: 30,
    template: {
      US: '6c75lp34kzeimq36',
      CA: 'ppzzjw7ev1iaiwyk',
      AU: 'ppzzjw7ev1iaiwyk',
      NZ: 'u8ujupnfh6qrte2b',
    },
    categoryId: 11,
    name: 'Insurance',
    thryvLogo: thryv,
    industryLogo: logoInsurance,
    sidebarImage: sidebarInsurance,
    sidebar: [
      {
        subtitle: 'Communication',
        description:
          'Deliver professional updates and reminders to clients via text and email while keeping all conversations organised in a single thread for you and your Staff to reference.',
      },
      {
        subtitle: 'Client Management',
        description:
          'Provide exceptional service every time with all claim information organised and accessible to you and your staff. Track the type of coverage, policy details and renewals with custom tags, fields and filters.',
      },
      {
        subtitle: 'Quotes & Payments',
        description:
          'Quickly send insurance and coverage estimates electronically. Share multiple estimates for comparison at once through the Client Portal. Include policy terms and conditions and other essential details for review.',
      },
    ],
  },
  {
    id: 31,
    template: {
      US: '0vwfot3yk9r5r3sx',
      CA: '482mrq3pr747hobo',
      AU: '482mrq3pr747hobo',
      NZ: '12bqoj7gq53pxnbk',
    },
    categoryId: 11,
    name: 'Generic',
    thryvLogo: thryv,
    industryLogo: logoGeneric,
    sidebarImage: sidebarGeneric,
    sidebar: [
      {
        subtitle: 'Task Management (CRM)',
        description:
          'Manage multiple tasks and keep all conversations, appointments, payments and more organized in one place. Create custom fields to track detailed information and add notes and tags to provide a personalized customer experience every time.',
      },
      {
        subtitle: 'Appointments',
        description:
          'Keep your calendar booked and organized! Allow clients to see your availability and book customized services with you online. Minimize missed or late appointments with automated reminders and allow them to cancel or reschedule up to 24 hours or more.',
      },
      {
        subtitle: 'Payments',
        description:
          'Get paid online or in person. Provide payment flexibility with online invoices and customized payment links. Allow clients to pay multiple invoices, required deposits or custom amounts from the secure Client Portal. Issue digital receipts and view all payments in one place.',
      },
    ],
  },
]

export default INDUSTRIES
