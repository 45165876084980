import React from 'react'
import Moment from 'moment'
import {ParagraphText} from '@thryvlabs/maverick'
import {PillContainer} from '../ui/account-information'
import {InvoicePreviewModal} from './invoice-preview-modal'

const STATUS = {
  past_due: {
    label: 'PAST DUE',
    color: '#DF2A2A',
    fontColor: '#FFFFFF',
  },
  open: {
    label: 'OPEN',
    color: '#FAC02B',
    fontColor: '#4D4D4D',
  },
  pending: {
    label: 'PENDING',
    color: '#FAC02B',
    fontColor: '#4D4D4D',
  },
}

const InvoiceDataRow = ({invoice}) => {
  const {number, createdAt, total, dueAt, state, id} = invoice
  return (
    <tr
      style={{
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderColor: '#E3E6E8',
      }}
    >
      <td style={{padding: '12px 0 12px 20px'}}>
        <ParagraphText className="m-0 table_data-text" variant="reg">
          {number}
        </ParagraphText>
      </td>
      <td>
        <ParagraphText
          className="m-0 table_data-text"
          variant="reg"
          style={{
            textAlign: 'center',
          }}
        >
          {Moment(createdAt).format('MMM DD YYYY')}
        </ParagraphText>
      </td>
      <td>
        <PillContainer backgroundColor={STATUS[state].color}>
          <div className="pill">
            <ParagraphText
              className="m-0"
              variant="reg"
              style={{
                fontWeight: 500,
                color: STATUS[state].fontColor,
                fontSize: '12px',
              }}
            >
              {STATUS[state].label}
            </ParagraphText>
          </div>
        </PillContainer>
      </td>
      <td style={{textAlign: 'center'}}>
        <ParagraphText className="m-0 table_data-text" variant="reg">
          {Moment(dueAt).format('MMM DD YYYY')}
        </ParagraphText>
      </td>
      <td>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <InvoicePreviewModal invoiceId={id} invoiceNumber={number} />
        </div>
      </td>
      <td style={{textAlign: 'right', paddingRight: '20px'}}>
        <ParagraphText
          className="m-0 table_data-text"
          style={{fontSize: '14px'}}
          variant="reg"
        >
          ${total.toFixed(2)}
        </ParagraphText>
      </td>
    </tr>
  )
}

export {InvoiceDataRow}
