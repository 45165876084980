import * as React from 'react'
import {useAuth0} from '@auth0/auth0-react'

function LoginRedirect() {
  const {loginWithRedirect} = useAuth0()

  React.useEffect(() => {
    ;(async function login() {
      const {search, pathname} = window.location
      const returnTo = `${pathname}${search}`
      await loginWithRedirect({appState: {returnTo}})
    })()
  }, [loginWithRedirect])

  return <div>Redirecting you to the login page...</div>
}

export {LoginRedirect}
