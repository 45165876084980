import {SCORE_TEXTS, SCORE_COLORS, SCORE_CLASS_NAMES} from './constants'

export const getScoreConfig = (score = 0) => {
  if (score < 101 && score > 79) {
    return [SCORE_TEXTS[0], SCORE_COLORS[0], SCORE_CLASS_NAMES[0]]
  }
  if (score < 81 && score > 59) {
    return [SCORE_TEXTS[1], SCORE_COLORS[1], SCORE_CLASS_NAMES[1]]
  }
  if (score < 61 && score > 29) {
    return [SCORE_TEXTS[2], SCORE_COLORS[2], SCORE_CLASS_NAMES[2]]
  }
  if (score < 31 && score > 0) {
    return [SCORE_TEXTS[2], SCORE_COLORS[2], SCORE_CLASS_NAMES[2]]
  }
  return [SCORE_TEXTS[3], SCORE_COLORS[2], SCORE_CLASS_NAMES[2]]
}

export const getScoreColorClassName = score => {
  if (score < 101 && score > 79) {
    return SCORE_CLASS_NAMES[0]
  }
  if (score < 81 && score > 59) {
    return SCORE_CLASS_NAMES[1]
  }
  if (score < 61 && score > 29) {
    return SCORE_CLASS_NAMES[2]
  }
  if (score < 31 && score > 0) {
    return SCORE_CLASS_NAMES[2]
  }
  return SCORE_CLASS_NAMES[2]
}

export const getListingAnalysisColorClassName = score => {
  if (score <= 10 && score > 0) {
    return SCORE_CLASS_NAMES[1]
  }
  if (score > 10) {
    return SCORE_CLASS_NAMES[2]
  }
  if (score === 0) {
    return SCORE_CLASS_NAMES[0]
  }
  return SCORE_CLASS_NAMES[2]
}

export const getScoreColor = score => {
  if (score < 101 && score > 79) {
    return SCORE_COLORS[0]
  }
  if (score < 81 && score > 59) {
    return SCORE_COLORS[1]
  }
  if (score < 61 && score > 29) {
    return SCORE_COLORS[2]
  }
  if (score < 31 && score > 0) {
    return SCORE_COLORS[2]
  }
  return SCORE_COLORS[2]
}
