import {Header} from '@thryvlabs/maverick'
import React from 'react'
import {Features, Wrapper} from '../components/ui/feature-summary'
import {FEATURES} from '../utils/feature-summary-data'

function FeatureTables({featureItem, i, id}) {
  const BLUE_IDS = [1, 2, 3, 4, 5, 6]
  const getColor = () => {
    if (BLUE_IDS.includes(id)) {
      return 'blue-line'
    }
    return
  }
  return (
    <Features>
      <table className="table" data-testid={`feature-table-${id}`}>
        <thead key={i} className={`${getColor()}`}>
          <tr>
            <th>{featureItem.label}</th>
          </tr>
        </thead>
        <tbody>
          {featureItem.features.map((feature, i) => (
            <tr key={i}>
              <td className="text">{feature.name}</td>
              <td align="center">{feature.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Features>
  )
}
function FeaturesTable() {
  return (
    <Wrapper>
      <Header fontWeight="normal" variant="h1" className="header">
        Package Recommendation
      </Header>
      {FEATURES.map((packages, i) => (
        <div key={i}>
          <div className="main-container">
            <div className="row mb-4">
              <img
                src={packages.icon}
                alt="plan"
                height={65}
                width={55}
                className="mr-3 ml-4"
              />
              <h1>{packages.package_title}</h1>
            </div>
            <div className="table-round">
              {packages.package_list.map((featureItem, i) => {
                return (
                  <FeatureTables
                    key={i}
                    featureItem={featureItem}
                    id={featureItem.id || 0}
                  />
                )
              })}
            </div>
          </div>
          <hr />
        </div>
      ))}
    </Wrapper>
  )
}
export {FeaturesTable}
