import dayjs from 'dayjs'
import advanced from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(timezone)
dayjs.extend(advanced)
dayjs.extend(utc)

function tzCodeConverter(timeZone) {
  let tzCode

  switch (timeZone) {
    case 'Alaska':
    case 'Alaska Daylight Time':
    case 'Alaska Standard Time':
      tzCode = 'America/Juneau'
      break
    case 'Pacific Time (US & Canada)':
    case 'Pacific Standard Time':
    case 'Pacific Daylight Time':
    case 'Pacific+Time+%28US+%26+Canada%29':
      tzCode = 'America/Los_Angeles'
      break
    case 'Eastern Time (US & Canada)':
    case 'Eastern Time (US & Canada':
    case 'Eastern Standard Time':
    case 'Eastern Daylight Time':
    case 'Eastern+Time+%28US+%26+Canada%29':
    case 'Indiana (East)':
      tzCode = 'America/New_York'
      break
    case 'Atlantic Time (Canada)':
    case 'Atlantic Standard Time':
    case 'Atlantic Daylight Time':
      tzCode = 'America/Halifax'
      break
    case 'Mountain Time (US & Canada)':
    case 'Mountain Standard Time':
    case 'Mountain Daylight Time':
    case 'Mountain+Time+%28US+%26+Canada%29':
      tzCode = 'America/Denver'
      break
    case 'Central Time (US & Canada)':
    case 'Central Standard Time':
    case 'Central Daylight Time':
    case 'Central+Time+%28US+%26+Canada%29':
    case 'Central Time (US & Canada':
      tzCode = 'America/Chicago'
      break
    case 'Newfoundland':
      tzCode = 'America/St_Johns'
      break
    case 'Midway Island':
      tzCode = 'Pacific/Midway'
      break
    case 'American Samoa':
      tzCode = 'Pacific/Pago_Pago'
      break
    case 'Hawaii':
      tzCode = 'Pacific/Honolulu'
      break
    case 'Tijuana':
      tzCode = 'America/Tijuana'
      break
    case 'Arizona':
    case 'Arizona Time':
      tzCode = 'America/Phoenix'
      break
    case 'Chihuahua':
      tzCode = 'America/Chihuahua'
      break
    case 'Mazatlan':
      tzCode = 'America/Mazatlan'
      break
    case 'Mexico City':
      tzCode = 'America/Mexico_City'
      break
    case 'Monterrey':
      tzCode = 'America/Monterrey'
      break
    case 'Central America':
      tzCode = 'America/Guatemala'
      break
    case 'Bogota':
      tzCode = 'America/Bogota'
      break
    case 'Lima':
      tzCode = 'America/Lima'
      break
    case 'Caracas':
      tzCode = 'America/Caracas'
      break
    case 'La Paz':
      tzCode = 'America/La_Paz'
      break
    case 'Santiago':
      tzCode = 'America/Santiago'
      break
    case 'Brasilia':
      tzCode = 'America/Sao_Paulo'
      break
    case 'Buenos Aires':
      tzCode = 'America/Argentina/Buenos_Aires'
      break
    case 'Montevideo':
      tzCode = 'America/Montevideo'
      break
    case 'Georgetown':
      tzCode = 'America/Guyana'
      break
    case 'Greenland':
      tzCode = 'America/Godthab'
      break
    case 'Mid-Atlantic':
      tzCode = 'Atlantic/South_Georgia'
      break
    case 'Azores':
      tzCode = 'Atlantic/Azores'
      break
    case 'Cape Verde Is.':
      tzCode = 'Atlantic/Cape_Verde'
      break
    case 'Dublin':
      tzCode = 'Europe/Dublin'
      break
    case 'Lisbon':
      tzCode = 'Europe/Lisbon'
      break
    case 'London':
      tzCode = 'Europe/London'
      break
    case 'Casablanca':
      tzCode = 'Africa/Casablanca'
      break
    case 'Monrovia':
      tzCode = 'Africa/Monrovia'
      break
    case 'Belgrade':
      tzCode = 'Europe/Belgrade'
      break
    case 'Bratislava':
      tzCode = 'Europe/Bratislava'
      break
    case 'Budapest':
      tzCode = 'Europe/Budapest'
      break
    case 'Ljubljana':
      tzCode = 'Europe/Ljubljana'
      break
    case 'Prague':
      tzCode = 'Europe/Prague'
      break
    case 'Sarajevo':
      tzCode = 'Europe/Sarajevo'
      break
    case 'Skopje':
      tzCode = 'Europe/Skopje'
      break
    case 'Warsaw':
      tzCode = 'Europe/Warsaw'
      break
    case 'Zagreb':
      tzCode = 'Europe/Zagreb'
      break
    case 'Brussels':
      tzCode = 'Europe/Brussels'
      break
    case 'Copenhagen':
      tzCode = 'Europe/Copenhagen'
      break
    case 'Madrid':
      tzCode = 'Europe/Madrid'
      break
    case 'Paris':
      tzCode = 'Europe/Paris'
      break
    case 'Amsterdam':
      tzCode = 'Europe/Amsterdam'
      break
    case 'Berlin':
      tzCode = 'Europe/Berlin'
      break
    case 'Bern':
      tzCode = 'Europe/Zurich'
      break
    case 'Rome':
      tzCode = 'Europe/Rome'
      break
    case 'Stockholm':
      tzCode = 'Europe/Stockholm'
      break
    case 'Vienna':
      tzCode = 'Europe/Vienna'
      break
    case 'West Central Africa':
      tzCode = 'Africa/Algiers'
      break
    case 'Bucharest':
      tzCode = 'Europe/Bucharest'
      break
    case 'Cairo':
      tzCode = 'Africa/Cairo'
      break
    case 'Helsinki':
      tzCode = 'Europe/Helsinki'
      break
    case 'Kyiv':
      tzCode = 'Europe/Kiev'
      break
    case 'Riga':
      tzCode = 'Europe/Riga'
      break
    case 'Sofia':
      tzCode = 'Europe/Sofia'
      break
    case 'Tallinn':
      tzCode = 'Europe/Tallinn'
      break
    case 'Vilnius':
      tzCode = 'Europe/Vilnius'
      break
    case 'Athens':
      tzCode = 'Europe/Athens'
      break
    case 'Istanbul':
      tzCode = 'Europe/Istanbul'
      break
    case 'Minsk':
      tzCode = 'Europe/Minsk'
      break
    case 'Jerusalem':
      tzCode = 'Asia/Jerusalem'
      break
    case 'Harare':
      tzCode = 'Africa/Harare'
      break
    case 'Pretoria':
      tzCode = 'Africa/Johannesburg'
      break
    case 'Moscow':
      tzCode = 'Europe/Moscow'
      break
    case 'St. Petersburg':
      tzCode = 'Europe/Moscow'
      break
    case 'Volgograd':
      tzCode = 'Europe/Moscow'
      break
    case 'Kuwait':
      tzCode = 'Asia/Kuwait'
      break
    case 'Riyadh':
      tzCode = 'Asia/Riyadh'
      break
    case 'Nairobi':
      tzCode = 'Africa/Nairobi'
      break
    case 'Baghdad':
      tzCode = 'Asia/Baghdad'
      break
    case 'Tehran':
      tzCode = 'Asia/Tehran'
      break
    case 'Abu Dhabi':
      tzCode = 'Asia/Dubai'
      break
    case 'Muscat':
      tzCode = 'Asia/Muscat'
      break
    case 'Baku':
      tzCode = 'Asia/Baku'
      break
    case 'Tbilisi':
      tzCode = 'Asia/Tbilisi'
      break
    case 'Yerevan':
      tzCode = 'Asia/Yerevan'
      break
    case 'Kabul':
      tzCode = 'Asia/Kabul'
      break
    case 'Ekaterinburg':
      tzCode = 'Asia/Yekaterinburg'
      break
    case 'Islamabad':
      tzCode = 'Asia/Karachi'
      break
    case 'Karachi':
      tzCode = 'Asia/Karachi'
      break
    case 'Tashkent':
      tzCode = 'Asia/Tashkent'
      break
    case 'Chennai':
      tzCode = 'Asia/Kolkata'
      break
    case 'Kolkata':
      tzCode = 'Asia/Kolkata'
      break
    case 'Mumbai':
      tzCode = 'Asia/Kolkata'
      break
    case 'New Delhi':
      tzCode = 'Asia/Kolkata'
      break
    case 'Kathmandu':
      tzCode = 'Asia/Kathmandu'
      break
    case 'Astana':
      tzCode = 'Asia/Almaty'
      break
    case 'Dhaka':
      tzCode = 'Asia/Dhaka'
      break
    case 'Sri Jayawardenepura':
      tzCode = 'Asia/Colombo'
      break
    case 'Almaty':
      tzCode = 'Asia/Almaty'
      break
    case 'Novosibirsk':
      tzCode = 'Asia/Novosibirsk'
      break
    case 'Rangoon':
      tzCode = 'Asia/Yangon'
      break
    case 'Bangkok':
      tzCode = 'Asia/Bangkok'
      break
    case 'Hanoi':
      tzCode = 'Asia/Bangkok'
      break
    case 'Jakarta':
      tzCode = 'Asia/Jakarta'
      break
    case 'Krasnoyarsk':
      tzCode = 'Asia/Krasnoyarsk'
      break
    case 'Beijing':
      tzCode = 'Asia/Shanghai'
      break
    case 'Chongqing':
      tzCode = 'Asia/Shanghai'
      break
    case 'Hong Kong':
      tzCode = 'Asia/Hong_Kong'
      break
    case 'Urumqi':
      tzCode = 'Asia/Urumqi'
      break
    case 'Kuala Lumpur':
      tzCode = 'Asia/Kuala_Lumpur'
      break
    case 'Singapore':
      tzCode = 'Asia/Singapore'
      break
    case 'Taipei':
      tzCode = 'Asia/Taipei'
      break
    case 'Perth':
      tzCode = 'Australia/Perth'
      break
    case 'Irkutsk':
      tzCode = 'Asia/Irkutsk'
      break
    case 'Ulaan Bataar':
      tzCode = 'Asia/Ulaanbaatar'
      break
    case 'Seoul':
      tzCode = 'Asia/Seoul'
      break
    case 'Osaka':
      tzCode = 'Asia/Tokyo'
      break
    case 'Sapporo':
      tzCode = 'Asia/Tokyo'
      break
    case 'Tokyo':
      tzCode = 'Asia/Tokyo'
      break
    case 'Yakutsk':
      tzCode = 'Asia/Yakutsk'
      break
    case 'Darwin':
      tzCode = 'Australia/Darwin'
      break
    case 'Adelaide':
    case 'Central Standard Time (ACST)':
      tzCode = 'Australia/Adelaide'
      break
    case 'Melbourne':
      tzCode = 'Australia/Melbourne'
      break
    case 'Canberra':
    case 'Sydney':
    case 'Eastern Standard Time (AEST)':
      tzCode = 'Australia/Sydney'
      break
    case 'Brisbane':
      tzCode = 'Australia/Queensland'
      break
    case 'Hobart':
      tzCode = 'Australia/Hobart'
      break
    case 'Australian Central Western Standard Time':
      tzCode = 'Australia/Eucla'
      break
    case 'Vladivostok':
      tzCode = 'Asia/Vladivostok'
      break
    case 'Guam':
      tzCode = 'Pacific/Guam'
      break
    case 'Port Moresby':
      tzCode = 'Pacific/Port_Moresby'
      break
    case 'Magadan':
      tzCode = 'Asia/Magadan'
      break
    case 'Srednekolymsk':
      tzCode = 'Asia/Magadan'
      break
    case 'Solomon Is.':
      tzCode = 'Pacific/Guadalcanal'
      break
    case 'New Caledonia':
      tzCode = 'Pacific/Noumea'
      break
    case 'Fiji':
      tzCode = 'Pacific/Fiji'
      break
    case 'Kamchatka':
      tzCode = 'Asia/Kamchatka'
      break
    case 'Marshall Is.':
      tzCode = 'Pacific/Majuro'
      break
    case 'Auckland':
    case 'Wellington':
      tzCode = 'Pacific/Auckland'
      break
    case "Nuku'alofa":
      tzCode = 'Pacific/Tongatapu'
      break
    case 'Tokelau Is.':
      tzCode = 'Pacific/Fakaofo'
      break
    case 'Chatham Is.':
      tzCode = 'Pacific/Chatham'
      break
    case 'Samoa':
      tzCode = 'Pacific/Apia'
      break
    case 'Tonga':
      tzCode = 'Pacific/Tongatapu'
      break
    case 'Line Islands':
      tzCode = 'Pacific/Kiritimati'
      break
    case 'Eastern Time (Cayman)':
      tzCode = 'America/Cayman'
      break
    case 'Atlantic Time (Barbados)':
      tzCode = 'America/Barbados'
      break
    case 'Etc/UTC':
    case 'America/New_York':
    case 'America/Chicago':
    case 'America/Los_Angeles':
    case 'America/Denver':
    case 'America/Detroit':
    case 'America/Barbados':
    case 'America/Phoenix':
    case 'America/Indiana/Indianapolis':
    case 'Australia/Melbourne':
    case 'Australia/Sydney':
    case 'Australia/Eucla':
    case 'Asia/Kolkata':
    case 'Pacific/Honolulu':
      tzCode = timeZone
      break
    default:
      tzCode = ''
  }

  return tzCode
}

function localeParser(timezone) {
  const localeMap = {
    caribbean: 'en-CB',
    America: 'en-US',
    Canada: 'en-CA',
    Australia: 'en-AU',
  }
  for (const key of Object.keys(localeMap)) {
    if (timezone.includes(key)) {
      return localeMap[key]
    }
  }
  return 'en-US' // default case
}

function gmtOffsetConverter(convertedDateTime, unixDateTime, timezone, type) {
  const millisecondUnixTime =
    type !== 'confirmation' ? parseInt(unixDateTime) * 1000 : undefined
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timezone,
    timeZoneName: 'short',
  }
  let locale
  locale = type === 'confirmation' ? 'en-AU' : localeParser(timezone)
  const formattedTime = new Intl.DateTimeFormat(locale, options).format(
    type === 'confirmation' ? unixDateTime : millisecondUnixTime,
  )
  if (type === 'datetime') {
    const date = convertedDateTime.match(/\d{4}-\d{2}-\d{2}/)[0]
    const formattedDateTime = `${date} ${formattedTime}`
    return formattedDateTime
  }
  if (type === 'time') {
    return formattedTime
  }
  const remainingString = convertedDateTime.match(/^(.*@)/)[0]
  return `${remainingString} ${formattedTime}`
}

function dateTimeConverter(
  scheduled_utc,
  demo_timezone,
  type,
  demo_datetime,
  selectedTimeZone,
) {
  const convertedTz = tzCodeConverter(demo_timezone)
  console.log({
    scheduled_utc,
    demo_timezone,
    type,
    demo_datetime,
    selectedTimeZone,
    convertedTz,
  })
  const nullified = [null, '', '""']
  if (
    type !== 'confirmation' &&
    (convertedTz === '' || nullified.includes(scheduled_utc))
  ) {
    console.log('retured from here')
    return '-'
  }
  try {
    const formatMappings = {
      confirmation: 'ddd, MMM DD, YYYY @ hh:mm A z',
      datetime: 'YYYY-MM-DD hh:mmA z',
      time: 'hh:mmA z',
      date: 'MM/DD/YYYY',
    }
    let convertedDateTime = ''
    console.log({
      objectType: Object.prototype.hasOwnProperty.call(formatMappings, type),
    })
    if (Object.prototype.hasOwnProperty.call(formatMappings, type)) {
      const timeZone =
        type === 'confirmation'
          ? demo_timezone
          : selectedTimeZone.value === 'customer'
          ? convertedTz
          : selectedTimeZone.value
      convertedDateTime = formatDateTime(
        scheduled_utc,
        timeZone,
        formatMappings[type],
      )
      if (convertedDateTime.includes('GMT')) {
        const timestamp =
          type === 'confirmation'
            ? new Date(scheduled_utc).getTime()
            : demo_datetime
        convertedDateTime = gmtOffsetConverter(
          convertedDateTime,
          timestamp,
          timeZone,
          type,
        )
      }
    }
    return convertedDateTime || '-'
  } catch (error) {
    return type === 'confirmation'
      ? 'Your time was unable to be displayed.'
      : '-'
  }
}

function formatDateTime(dateTime, timeZone, format) {
  return dayjs(dateTime).tz(timeZone).format(format)
}

export {dateTimeConverter}
