import React from 'react'
import {useParams} from 'react-router-dom'
import {Header, ParagraphText} from '@thryvlabs/maverick'

import {CompanyResults} from '../components/business-results/company-results'
import {CompanyResultsResume} from '../components/business-results/company-results-resume'
import {client} from '../utils/api-client'
import {useAsync} from '../utils/use-async'
import {useTrackLR} from '../utils/use-track-lr'

import {MainContainer, FooterContainer} from '../components/ui/business-results'

function getOutReachScore({facebookPage, twitterLink, hasInstagram}) {
  let result = 0
  if (!facebookPage && twitterLink && hasInstagram) {
    result = 66
  }
  if (hasInstagram === false && facebookPage && twitterLink) {
    result = 66
  }
  if (!twitterLink && facebookPage && hasInstagram) {
    result = 66
  }
  if (!facebookPage && !twitterLink && hasInstagram) {
    result = 33
  }
  if (!facebookPage && !hasInstagram && twitterLink) {
    result = 33
  }
  if (!twitterLink && !hasInstagram && facebookPage) {
    result = 33
  }
  if (!facebookPage && !twitterLink && hasInstagram) {
    result = 0
  }
  if (twitterLink && facebookPage && hasInstagram) {
    result = 100
  }
  return result
}

function parseResults(results) {
  const outReachOverallScore = getOutReachScore({
    facebookPage: results?.silktideJson?.facebook?.page,
    twitterLink: results?.silktideJson?.twitter?.link,
    hasInstagram: results?.silktideJson?.instagram?.hasInstagram,
  })
  const reputationOverallScore = results?.reputationScore?.overallScore
  const silktideScore = results?.silktideJson?.overallScore || 0
  const presenceOverallScore =
    Math.round(
      (silktideScore + (100 - results?.yextJson?.listingsAccuracy)) / 2,
    ) || 0
  const averageScore =
    Math.ceil(
      (outReachOverallScore + presenceOverallScore + reputationOverallScore) /
        3,
    ) || 0
  const listingAnalysisItems = results.yextJson.raw.map(
    ({
      siteId,
      name,
      address,
      phone,
      status,
      match_name: matchName,
      match_address: matchAddress,
      match_phone: matchPhone,
      review_count: reviewCount,
      url,
      city,
    }) => ({
      id: siteId,
      site: siteId,
      businessName: name,
      address,
      phone,
      reviews: reviewCount > 0,
      status,
      matchName,
      matchAddress,
      matchPhone,
      url,
      city,
    }),
  )
  const facebookImage = results?.silktideJson?.facebook?.image || null
  const twitterImage = results?.silktideJson?.twitter?.image || null
  const businessImage = facebookImage || twitterImage || ''
  return {
    outReachOverallScore,
    reputationOverallScore,
    presenceOverallScore,
    averageScore,
    businessImage,
    listingAnalysisItems,
    ...results,
  }
}

const BusinessResults = () => {
  const [companyInfo, setCompanyInfo] = React.useState({})
  const reputationRef = React.useRef(null)
  const presenceRef = React.useRef(null)
  const outReachRef = React.useRef(null)
  const {recordId} = useParams()
  const customEvent = companyInfo?.companyInfo && {
    key: 'business-scan-results',
    companyName: companyInfo.companyInfo.company,
    recordId,
  }
  useTrackLR(customEvent)

  function scrollTo(ref) {
    if (ref && ref.current) {
      window.scrollTo({behavior: 'smooth', top: ref.current.offsetTop - 100})
    }
  }

  const {
    data: result,
    isLoading,
    run,
    isSuccess,
    isError,
    error,
  } = useAsync({status: 'pending'})

  React.useEffect(() => {
    run(client(`scans/report/${recordId}`))
  }, [run, recordId])

  React.useEffect(() => {
    if (isSuccess) {
      setCompanyInfo(parseResults(result))
    }
  }, [isSuccess, result])

  React.useEffect(() => {
    if (isError) {
      throw error
    }
  }, [isError, error])

  return (
    <MainContainer data-testid="business-results-screen">
      <div className="side">
        <CompanyResultsResume
          isLoading={isLoading}
          outReachOverallScore={companyInfo.outReachOverallScore}
          presenceOverallScore={companyInfo.presenceOverallScore}
          reputationOverallScore={companyInfo.reputationOverallScore}
          companyInfo={companyInfo.companyInfo}
          businessImage={companyInfo.businessImage}
          componentRefs={{outReachRef, reputationRef, presenceRef}}
          scrollTo={scrollTo}
        />
      </div>
      <div className="content">
        <CompanyResults
          isLoading={isLoading}
          companyInfo={companyInfo}
          componentRefs={{outReachRef, reputationRef, presenceRef}}
        />
      </div>
      <FooterContainer className="footer" data-test="component-footer">
        <Header fontWeight="semibold" variant="h1">
          Improve your score today!
        </Header>
        <ParagraphText variant="lg" color="thryv-white-50">
          Sign up for a Thryv Demo with your Business Advisor and our Demo
          Specialist today. Thryv is the only all-in-one management software
          built for small business. And while it’s built for small business,
          there’s nothing small about what it can do.
        </ParagraphText>
      </FooterContainer>
    </MainContainer>
  )
}

export {BusinessResults}
