import React from 'react'
import {
  YelpLogo,
  BingLogo,
  YellowPagesLogo,
  FacebookLogo,
  GoogleLogo,
  YahooLogo,
  TwitterLogo,
  InstagramLogo,
} from '@thryvlabs/maverick'

const ICONS_LIST = {
  yelp: YelpLogo,
  bing: BingLogo,
  yellowpages: YellowPagesLogo,
  facebook: FacebookLogo,
  google: GoogleLogo,
  yahoo: YahooLogo,
  twitter: TwitterLogo,
  instagram: InstagramLogo,
  ypcom: YellowPagesLogo,
}

export const getSiteIcon = ({
  site,
  className = '',
  link = '',
  height = 25,
  width = 25,
}) => {
  const siteQuery = site.toLowerCase().trim()
  const Icon = ICONS_LIST[siteQuery]
  if (link && Icon) {
    return (
      <a href={link} target="blank">
        <Icon className={className} height={height} width={width} />
      </a>
    )
  }
  if (Icon) {
    return <Icon className={className} height={height} width={width} />
  }
  return null
}
