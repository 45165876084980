import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'

export const ResultsLayoutContainer = styled.section`
  max-width: 100vw;
  padding: 10px 20px;
  tr td:nth-of-type(-n + 3) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 17vw;
  }
  tr td:nth-of-type(n + 4) {
    white-space: nowrap;
  }
  @media screen and ${device.mobileL} {
    padding: 10px 5px;
  }
`
export const ResultsLayoutHeader = styled.div`
  display: flex;
  justify-content: ${props =>
    props.frontendSideNav ? 'space-between' : 'right'};
  @media screen and ${device.mobileL} {
    flex-direction: column;
    justify-content: center;
  }
`

export const LeftAlignedDropdowns = styled.div`
  display: flex;
  align-items: ${props =>
    props.frontendSideNav ? 'flex-start' : 'flex-start'};
  gap: 25px;
  margin-right: ${props => (props.frontendSideNav ? '15px' : '0')};
`
