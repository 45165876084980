import styled from '@emotion/styled'

export const TableContainer = styled.div`
  margin-top: 20px;
  max-width: 100%;
  max-height: 80vh;
  overflow: auto;
  & table tr td p {
    font-size: 15px;
    text-align: center;
    color: #adb5bd;
  }
  & .table thead th {
    border-top: none;
    border-bottom: 1px solid #dee2e6;
  }
`
