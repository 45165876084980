import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'

export const ScreenContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

export const ErrorContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  width: 100%;
  gap: 20%;

  @media screen and ${device.tablet} {
    grid-template-columns: 1fr;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-self: end;

  @media screen and ${device.tablet} {
    justify-self: center;
    align-items: center;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and ${device.tablet} {
    text-align: center;
  }
`

export const ImageContainer = styled.div`
  max-width: 400px;

  @media screen and ${device.tablet} {
    justify-self: center;
  }
`
