import React from 'react'
import {Button, Select} from '@thryvlabs/maverick'

function Pagination({
  totalRecords,
  recordsPerPage,
  setCurrentPage,
  currentPage,
  setRecordsPerPage,
  totalPages,
}) {
  const [PageButtons, setPageButtons] = React.useState([])
  let pages = []

  const handleSetPrevPage = () => {
    if (currentPage - 1 >= 1) {
      setCurrentPage(prev => prev - 1)
    }
  }

  const handleSetNextPage = () => {
    if (currentPage + 1 <= totalPages) {
      setCurrentPage(prev => prev + 1)
    }
  }

  for (let i = 1; i <= totalPages; i++) {
    pages.push(i)
  }
  React.useEffect(() => {
    let firstButton = currentPage - 2
    const lastButton = firstButton + 4
    firstButton = lastButton > totalPages ? totalPages - 4 : firstButton
    firstButton = firstButton <= 0 ? 1 : firstButton

    const length = totalPages < 5 ? totalPages : 5
    const buttonArray = Array.from({length}, (_, i) => i + firstButton)
    setPageButtons(buttonArray)
  }, [currentPage, totalPages])

  return (
    <div
      className={'flex flex-row gap-x-10 w-full mt-4 scale-95 justify-center'}
    >
      <div className="flex flex-nowrap gap-x-2">
        <Button
          className={`text-lg ${disabledBtnStyles} ${btnStyles}`}
          variant="secondary"
          buttonType="outlined"
          onClick={() => {
            setCurrentPage(1)
          }}
          aria-label="Set to page 1"
          aria-describedby="Click to open page 1"
        >
          &#171;
        </Button>
        <Button
          className={`text-lg ${disabledBtnStyles} ${btnStyles}`}
          variant="secondary"
          buttonType="outlined"
          onClick={handleSetPrevPage}
          aria-label="Previous page"
          aria-describedby={`Click to open page ${currentPage - 1}`}
        >
          &#8249;
        </Button>
        {PageButtons.map((page, index) => (
          <Button
            key={page}
            className={`sm:hidden min-w-min ${
              page === currentPage ? selectedBtnStyles : btnStyles
            }`}
            variant={page === currentPage ? 'primary' : 'secondary'}
            pagetype={page !== currentPage ? 'outlined' : undefined}
            onClick={() => setCurrentPage(page)}
            aria-label={`Page ${page}`}
            aria-describedby={`Click to open page ${page}`}
          >
            {page}
          </Button>
        ))}
        <Button
          className={`hidden sm:block`}
          variant={'primary'}
          buttonType={'outlined'}
        >
          {currentPage} / {pages.length}
        </Button>
        <Button
          className={`text-lg ${disabledBtnStyles} ${btnStyles}`}
          variant="secondary"
          buttonType="outlined"
          onClick={handleSetNextPage}
          aria-label={`Next Page`}
          aria-describedby={`Click to next page ${currentPage + 1}`}
        >
          &#8250;
        </Button>
        <Button
          className={`text-lg ${disabledBtnStyles} ${btnStyles}`}
          variant="secondary"
          buttonType="outlined"
          onClick={() =>
            setCurrentPage(prev => Math.ceil(totalRecords / recordsPerPage))
          }
          aria-label={`Last Page`}
          aria-describedby={`Click to last page ${Math.ceil(
            totalRecords / recordsPerPage,
          )}`}
        >
          &#187;
        </Button>
      </div>
      <Select
        options={paginationOptions}
        selectLabel="Select"
        selectedOption={paginationOptions[0]}
        setSelectedOption={e => setRecordsPerPage(parseInt(e.value))}
      />
    </div>
  )
}

const paginationOptions = [
  {name: '10', value: '10'},
  {name: '25', value: '25'},
  {name: '50', value: '50'},
]

const btnStyles = 'border-thryv-gray-medium-500 hover:bg-thryv-gray-medium-500'
const selectedBtnStyles =
  'border-thryv-gray-medium-500 hover:bg-thryv-orange-700'
const disabledBtnStyles =
  'disabled:border-thryv-gray-medium-100 disabled:text-thryv-gray-medium-200'

export default Pagination
