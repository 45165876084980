import styled from '@emotion/styled'

export const CartContainer = styled.ul`
  width: 265px;
  margin: 0;
  padding: 0px 5px;
  justify-self: center;
  & li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    & p {
      margin: 0;
    }
  }
`

export const TransitionContainer = styled.ul`
  maxwidth: 450px;
  padding: 0px 0px;
  & li {
    display: flex;
    justify-content: space-between;
    border: 2px solid lightgray;
    border-radius: 6px;
    padding-right: 20px;
    margin-top: 10px;
  }
  & p {
    margin: 8px 10px;
  }
`
