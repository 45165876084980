import React, {useState} from 'react'
import {Header, Select} from '@thryvlabs/maverick'
import {useUserRoles} from '../utils/use-user-roles'

const COLLATERAL_COUNTRIES = [
  {name: 'United States', value: 'US'},
  {name: 'Australia', value: 'AU'},
  {name: 'Canada', value: 'CA'},
  {name: 'New Zealand', value: 'NZ'},
]

const SalesCollateral = () => {
  const [selectedCountry, setSelectedCountry] = useState(
    COLLATERAL_COUNTRIES[0],
  )
  const {roles} = useUserRoles()
  const formattedRoles = roles.map(role =>
    role.toLowerCase().replace(/\s/g, ''),
  )
  const IS_AUSTRALIA_USER = formattedRoles.includes('australia')
  const IS_NZ_USER = formattedRoles.includes('newzealandsales')

  if (IS_NZ_USER || IS_AUSTRALIA_USER) {
    return (
      <>
        <iframe
          title="AU-Sales-Collateral"
          id="AU-Sales-Collateral"
          src="https://aus-collateral.thryv.info/sales-collateral"
          width="95%"
          height="900"
          style={{
            position: 'relative',
            margin: '0 auto',
          }}
        ></iframe>
      </>
    )
  }
  return (
    <>
      <div className="row pt-3 p-2 pr-5 pl-5 justify-content-center">
        <Header fontWeight="normal" variant="h1">
          Sales Collateral
        </Header>
        <div className="mx-3">
          <Select
            width="md"
            options={COLLATERAL_COUNTRIES}
            selectLabel="Country"
            selectedOption={selectedCountry}
            setSelectedOption={setSelectedCountry}
          />
        </div>
      </div>
      {['US', 'CA'].includes(selectedCountry.value) ? (
        <iframe
          title="US-Sales-Collateral"
          id="US-Sales-Collateral"
          src="https://collateral.thryv.info/sales-collateral"
          width="95%"
          height="900"
          style={{
            position: 'relative',
            display: 'block',
            margin: '0 auto',
          }}
        ></iframe>
      ) : (
        <iframe
          title="AU-Sales-Collateral"
          id="AU-Sales-Collateral"
          src="https://aus-collateral.thryv.info/sales-collateral"
          width="95%"
          height="900"
          style={{
            position: 'relative',
            display: 'block',
            margin: '0 auto',
          }}
        ></iframe>
      )}
    </>
  )
}

export {SalesCollateral}
