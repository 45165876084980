import {Modal, RadioGroup, ModalTitle} from '@thryvlabs/maverick'
import React from 'react'

const data = [
  {
    name: 'Autopay',
    value: 'Credit Card',
  },
  {
    name: 'Invoice',
    value: 'Invoice',
  },
]

const BillingOptionsModal = ({setSelectedPaymentMethod}) => {
  return (
    <Modal
      variant="default"
      btnType="text"
      footer
      action
      btnActionText="Save"
      footerCancel
      actionClose
      primaryFooterVariant="dark"
      openOnLoad
    >
      <ModalTitle variant="subtitle" size="lg">
        How would you like to process this transaction?
      </ModalTitle>
      <RadioGroup
        data={data}
        value={data[0]}
        onChange={e => {
          setSelectedPaymentMethod(e.value)
        }}
        column
        layoutStyles={{
          gap: '20px',
        }}
      />
    </Modal>
  )
}

export {BillingOptionsModal}
