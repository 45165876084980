import styled from '@emotion/styled'

export const IndustryContainer = styled.div`
  width: 100pv;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  & .button__container {
    grid-column: 1/3;
  }
  & .button__container {
    display: block;
  }
  & .button__container button {
    float: right;
    max-width: 150px;
  }
`
