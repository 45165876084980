import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'

export const CountryContainer = styled.section`
  width: auto;
  height: auto;
  max-width: 1200px;
  margin: 20px auto;
  border-radius: 10px;
  padding: 20px 5rem;
  border: 1px solid rgba(227, 227, 227, 0.1);
  text-align: center;
  color: #6f6f6f;
  @media screen and ${device.laptop} {
    width: 95vw;
  }
`

export const FlagContainer = styled.li`
  box-sizing: 0 4px 8px 0;
  padding: 20px;
  &:hover {
    cursor: pointer;
  }
  & img:hover {
    opacity: 0.3;
    transition: 0.9s;
  }
  img {
    width: 150px;
    height: 75px;
  }
`
export const FlagRow = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-row: 2;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
`
