import styled from '@emotion/styled'

export const UpcomingAppointmentContainer = styled.div`
  .vertical-line {
    border-right: 2px solid rgba(255, 80, 0, 1);
  }
  .appointment-description {
    flex-wrap: wrap !important;
    display: inline-flex !important;
    align-content: center !important;
    padding-left: 4rem;
  }
  .appointment-circle {
    display: flex;
    flex-wrap: wrap !important;
    align-content: center !important;
    justify-content: center !important;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 2px solid rgba(255, 80, 0, 1);
    color: rgba(255, 80, 0, 1);
    text-align: center;
    font-size: 18px;
  }
  .box-container {
    background-color: #fbfbfb;
  }
  .box {
    position: relative;
    background: #fbfbfb;
    border: 2px solid #e1e1e1;
    padding: 10px 20px;
  }
  .box:after,
  .box:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .box:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #fbfbfb;
    border-width: 10px;
    margin-top: -10px;
  }
  .box:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #e1e1e1;
    border-width: 13px;
    margin-top: -13px;
  }
`

export const OrderedList = styled.ol`
  list-style-position: outside;
  color: #000;
  font-size: 14px;
  list-style-type: decimal;
  margin-bottom: 0rem;
`

export const CommitmentSection = styled.div`
  margin-bottom: 2rem;
  margin-right: 3rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 2rem;
`
