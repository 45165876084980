import React from 'react'
import {Icon, Tooltip, Section} from '@thryvlabs/maverick'
import {ScorePercentage} from './score-percentage'

import {
  DropdownContent,
  RatingTitleContainer,
  RatingTitleSectionContainer,
} from '../ui/business-results'

function RatingDropdownSection(props) {
  const {
    ratingDescription,
    children,
    tooltipDescription,
    score,
    scoreColorClassName = null,
  } = props
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <RatingTitleSectionContainer>
        <Section
          collaps={true}
          open={open}
          setOpen={setOpen}
          title={
            <>
              <div>
                {ratingDescription}{' '}
                <Tooltip
                  variant="right"
                  description={tooltipDescription}
                  width="250px"
                  positioning={{
                    offsetY: '-5px',
                    offsetX: '-10px',
                  }}
                >
                  <Icon
                    type="solid"
                    variant="circleQuestion"
                    color="#808080"
                    height={15}
                    width={15}
                    className="ml-2"
                  />
                </Tooltip>
              </div>
              <>
                {(score || score === 0) && (
                  <>
                    <ScorePercentage
                      score={score}
                      scoreColorClassName={scoreColorClassName}
                    />
                  </>
                )}
              </>
            </>
          }
        >
          {children}
        </Section>
        <div className="tooltip-container"></div>
      </RatingTitleSectionContainer>
    </>
  )
}

function RatingDropdown(props) {
  const {
    ratingDescription,
    score,
    children,
    scoreColorClassName = null,
    tooltipDescription = null,
  } = props
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <RatingTitleContainer>
        <div className="dropdown__tittle__description">
          <span className={`${open ? 'active' : ''}`}>
            <Icon type="solid" variant="caretRight" height="8" width="6" />
          </span>
          <button
            aria-controls={`${ratingDescription} content`}
            aria-expanded={open}
            onClick={() => {
              setOpen(!open)
            }}
          >
            {ratingDescription}
          </button>
          {tooltipDescription && (
            <Tooltip
              variant="right"
              description={tooltipDescription}
              width="250px"
              positioning={{
                offsetY: '-5px',
                offsetX: '-10px',
              }}
            >
              <Icon
                type="solid"
                variant="circleQuestion"
                color="#808080"
                height={15}
                width={15}
                className="ml-2"
              />
            </Tooltip>
          )}
        </div>
        {(score || score === 0) && (
          <ScorePercentage
            score={score}
            scoreColorClassName={scoreColorClassName}
          />
        )}
      </RatingTitleContainer>
      <DropdownContent
        className={`dropdown__content ${open ? 'active' : ''}`}
        role="group"
        aria-hidden={!open}
        aria-label={`${ratingDescription} Content`}
        id={`${ratingDescription} content`}
      >
        {children}
      </DropdownContent>
    </>
  )
}

export {RatingDropdown, RatingDropdownSection}
