import React from 'react'

const inviteModalContext = React.createContext()

function InviteModalProvider({children}) {
  const [isInviteModalOpen, setIsInviteModalOpen] = React.useState(false)

  const value = React.useMemo(() => {
    return {isInviteModalOpen, setIsInviteModalOpen}
  }, [isInviteModalOpen])

  return (
    <inviteModalContext.Provider value={value}>
      {children}
    </inviteModalContext.Provider>
  )
}

export {InviteModalProvider, inviteModalContext}
