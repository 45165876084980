import dayjs from 'dayjs'

export function getRemainingTimeFromMS(timestampinMS) {
  const timestampDayJS = dayjs(timestampinMS)
  const now = dayjs()
  return {
    minutes: getRemainingMinutes(now, timestampDayJS),
    seconds: getRemainingSeconds(now, timestampDayJS),
  }
}

function getRemainingSeconds(now, timestampDayJS) {
  const seconds = timestampDayJS.diff(now, 'seconds') % 60
  return padWithZeros(seconds)
}

function getRemainingMinutes(now, timestampDayJS) {
  const minutes = timestampDayJS.diff(now, 'minutes') % 60
  return padWithZeros(minutes)
}

function padWithZeros(num) {
  let numString = String(num)
  if (numString.length === 1) {
    numString = 0 + numString
  }
  return numString
}
