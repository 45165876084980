import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'

export const MainContainer = styled.section`
  width: auto;
  height: auto;
  max-width: 1200px;
  margin: 20px auto;
  border-radius: 10px;
  padding: 20px 2rem;
  border: 1px solid rgba(227, 227, 227, 0.1);
  background-color: rgba(227, 227, 227, 0.1);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.09);
  & .react-loading-skeleton {
    max-width: 1100px;
  }
  & .select_container,
  .input_container {
    margin-bottom: 1em;
  }
  .input_container_email_address {
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: 80% 20%;
  }
  .validate-email-button {
    margin-left: 15px;
    display: flex;
    justify-content: center;
    max-height: 35px;
  }
  & div.relative.select ul {
    margin-top: -1.5em !important;
  }
  & div[role='radiogroup'] {
    & > div {
      margin-bottom: 1.5em;
    }
    & label {
      font-size: 14px;
      --tw-text-opacity: 1;
      color: rgba(128, 128, 128, var(--tw-text-opacity));
    }
  }
  & .plans_price {
    position: absolute !important;
    margin-top: -1.5em;
    margin-left: 15em;
  }
  & .custom-mt {
    margin-top: 0.7em !important;
  }
  & .selects-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  @media screen and ${device.laptop} {
    width: 95vw;
  }
`
export const SectionContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: 2em 0;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #a9a9a9;
  & > p {
    padding-left: 10px;
    margin-top: -25px;
    font-size: 1.5em;
    font-family: 'News Cycle', 'Arial Narrow Bold', sans-serif;
    font-weight: 600;
    color: #a9a9a9;
    background-color: white;
  }
  &.basic_info_section {
    & > p.section_title--basic_info {
      max-width: 110px;
    }
  }
  & > p.section_title--business_address {
    max-width: 190px;
  }
  & .span-cols {
    grid-column: 1 / 3;
  }
`
