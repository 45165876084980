import styled from '@emotion/styled'

export const MainContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 400px 1fr;
  width: 100%;
  & .back__arrow:hover {
    cursor: pointer;
  }
  & h2 {
    font-weight: 600;
  }
  .button__container {
    grid-column: 1/3;
    display: flex;
    justify-content: end;
  }
`

export const TitleContainer = styled.div`
  grid-column: 1/3;
  display: flex;
  position: ${props => (props.centeredHeader ? 'relative' : 'static')};
  height: ${props => (!props.title ? '42px' : 'auto')};
  justify-content: ${props => (props.centeredHeader ? 'center' : 'flex-start')};
`
