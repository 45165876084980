import styled from '@emotion/styled'
import {Header} from '@thryvlabs/maverick'

export const ItemsContainer = styled.section`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 300px 300px 300px 300px;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  & > div {
    min-width: 100%;
    padding: 0 20px;
    border-radius: 10px;
  }
  /* & > div:hover {
    background-color: rgba(0, 0, 0, 0.1);
  } */
  & img {
    margin: 0 auto;
    width: 100px;
    height: 100px;
  }
  & div label a {
    text-decoration: none;
  }
  & div label a p {
    color: #666;
    font-size: 16px;
    font-weight: 300;
  }
  @media (max-width: 1350px) {
    grid-template-columns: 300px 300px 300px;
  }
  @media (max-width: 1050px) {
    grid-template-columns: 300px 300px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 200px 200px;
    & > div {
      padding: 0 5px;
    }
    p.text-black {
      font-size: 13px;
    }
  }
`
export const ItemsContainerTitle = styled(Header)`
  color: #666;
  font-size: 36px;
  font-weight: 400;
`

export const ToolItemContainer = styled.div`
  p {
    color: #666;
    font-size: 16px;
    font-weight: 300;
  }
`

export const ThryvPayDemoIcon = styled.span`
  background-image: url("../../assets/thryv-pay-demo-icon.png")
  height: 12px;
  width: 12px;
  display: inline;
`
