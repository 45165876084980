import React from 'react'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

function SkeletonLoader({height = '100%', width = '100%'}) {
  return (
    <SkeletonTheme color="#e6e6e6" highlightColor="#fff">
      <Skeleton
        containerTestId="new-order-loader"
        height={height}
        width={width}
      />
    </SkeletonTheme>
  )
}

export default SkeletonLoader
