import React from 'react'
import dayjs from 'dayjs'
import {Button, ParagraphText} from '@thryvlabs/maverick'
import {useAuth0} from '@auth0/auth0-react'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'

import {useAsync} from '../utils/use-async'
import {useAuthClient} from '../utils/use-auth-client'
import {useUserRoles} from '../utils/use-user-roles'
import {ScansHeader} from '../components/ui/business-scans'
import {ResultsTable} from '../components/results-table'
import {ResultsLayoutContainer} from '../components/ui/results-layout'
import {SearchButton} from '../components/search-button'
import {useFlags} from 'launchdarkly-react-client-sdk'

dayjs.extend(require('dayjs/plugin/timezone'))
dayjs.extend(require('dayjs/plugin/utc'))

function parseResults(results) {
  if (Object.keys(results).length === 0) {
    return []
  }
  return results.data.map(
    ({
      created_at,
      business_name,
      address,
      state,
      city,
      zip,
      phone,
      record_id,
      email,
      bizdomain,
    }) => ({
      id: record_id,
      date: dayjs(created_at).format('MM/DD/YYYY'),
      company: business_name,
      address: `${address ? address + ',' : ''} ${state ? state + ',' : ''} ${
        city ? city + ',' : ''
      } ${zip ? zip : ''}`,
      phoneNo: phone,
      email: email,
      bizdomain: bizdomain,
      zip: zip,
      city: city,
      state: state,
      baddress: address,
      twoMinutesOld: twoMinutes(created_at),
    }),
  )
}

function twoMinutes(timeCreated) {
  const formattedTimeCreated = dayjs.tz(
    timeCreated,
    'YYY-MM-DDTHH:mm:ss',
    'America/Chicago',
  )
  const formattedToUtc = formattedTimeCreated.utc()
  const currentUtcTime = dayjs.utc()
  const diff = currentUtcTime.diff(formattedToUtc, 'minute')
  return diff >= 2
}

function Header({frontendSideNav, onHandleNewScan, searchScans}) {
  if (frontendSideNav) {
    return (
      <ScansHeader frontendSideNav={frontendSideNav}>
        <Button variant="primary" className="mr-4" onClick={onHandleNewScan}>
          New Scan
        </Button>

        <div className="d-flex">
          <ParagraphText variant="reg" className="scans-search-tips mr-2">
            Please Search by company name for older than 30 days
          </ParagraphText>
          <SearchButton
            placeholder="Company Search"
            onSearch={searchScans}
            searchOnUsserType={true}
          />
        </div>
      </ScansHeader>
    )
  }
  return (
    <ScansHeader>
      <ParagraphText variant="reg" className="scans-search-tips">
        Please Search by company name for older than 30 days
      </ParagraphText>

      <div className="d-flex">
        <Button variant="primary" className="mr-4" onClick={onHandleNewScan}>
          New Scan
        </Button>
        <SearchButton
          placeholder="Company Search"
          onSearch={searchScans}
          searchOnUsserType={true}
        />
      </div>
    </ScansHeader>
  )
}

function BusinessScans() {
  const navigate = useNavigate()
  const client = useAuthClient()
  const [tableData, setTableData] = React.useState([])
  const [lastQuery, setLastQuery] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const {data, run, isSuccess, isError, error} = useAsync({
    status: 'pending',
  })
  const {user} = useAuth0()
  const {roles} = useUserRoles()
  const {frontendSideNav} = useFlags()

  const {network_id = '', email = '', name = ''} = user || {}
  const IS_AUS_USER = roles.includes('australia')
  const IS_YELLO_USER = roles.includes('yello')

  const onHandleNewScan = () => {
    navigate('/scan-form')
  }

  const searchScans = query => {
    const searchQuery = query
    let url = 'scans'
    setLastQuery(searchQuery)
    setLoading(true)
    if (searchQuery.length > 0) {
      url = `scans?company_name=${searchQuery}`
    }
    run(client(url))
  }

  React.useEffect(() => {
    run(client('scans/'))
  }, [client, run])

  React.useEffect(() => {
    if (isSuccess) {
      setTableData(parseResults(data))
      setLoading(false)
    }
  }, [isSuccess, data])

  React.useEffect(() => {
    if (isError) {
      throw error
    }
  }, [isError, error])

  const onViewReport = record_id => {
    navigate('/scan-results/' + record_id)
  }

  const onUpdateScan = record_id => {
    navigate('/scan-form/' + record_id)
  }

  const onScheduleDemo = recordId => {
    navigate('/new-demo-request', {state: {recordId}})
  }

  const onRemove = async id => {
    setLoading(true)
    await client(`scans/${id}`, {method: 'DELETE'})
    toast.success('Record deleted successfully')
    await run(client('scans/'))
    setLoading(false)
  }

  const newThryvProposal = (company, address, city, state, zip, bizdomain) => {
    const url =
      `https://form.jotform.com/211044041218035?businessAdvisor=${email}` +
      `&baName=${name}&baNetwork=${network_id}&businessName=${company}` +
      `&businessAddress=${address}&city=${city}&state=${state}` +
      `&postal=${zip}&businessWebsite=${bizdomain}`
    window.location.assign(url)
  }

  return (
    <>
      <ResultsLayoutContainer>
        <Header
          frontendSideNav={frontendSideNav}
          onHandleNewScan={onHandleNewScan}
          searchScans={searchScans}
        />
        <ResultsTable
          cols={['company', 'address', 'phone', 'date']}
          noDataFoundMessage={`Sorry, we could not find any results matching "${lastQuery}"`}
          isLoading={loading}
          hasRecords={tableData.length > 0}
        >
          {tableData.map(
            ({
              id,
              date,
              company,
              phoneNo,
              address,
              city,
              state,
              zip,
              bizdomain,
              baddress,
              twoMinutesOld,
            }) => (
              <tr key={id}>
                <td>
                  <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                    {company}
                  </span>
                </td>
                <td>
                  {baddress ? (
                    <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                      {address}
                    </span>
                  ) : (
                    <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                      ...Retrieving Address
                    </span>
                  )}
                </td>
                <td>
                  <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                    {phoneNo}
                  </span>
                </td>
                <td>
                  <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                    {date}
                  </span>
                </td>
                <td>
                  {twoMinutesOld && baddress ? (
                    <Button
                      variant="form"
                      level={3}
                      className="w-[138px]"
                      onClick={() => onViewReport(id)}
                    >
                      View Report
                    </Button>
                  ) : (
                    <Button
                      variant="form"
                      level={3}
                      className="w-[138px]"
                      disabled
                    >
                      In Progress
                    </Button>
                  )}
                </td>
                <td>
                  {twoMinutesOld && baddress ? (
                    <Button
                      variant="form"
                      level={3}
                      className="w-[138px]"
                      onClick={() => onUpdateScan(id)}
                    >
                      Update Scan
                    </Button>
                  ) : (
                    <Button
                      variant="form"
                      level={3}
                      className="w-[138px]"
                      disabled
                    >
                      In Progress
                    </Button>
                  )}
                </td>
                <td>
                  {twoMinutesOld && baddress ? (
                    <Button
                      variant="form"
                      level={3}
                      className="w-[138px]"
                      onClick={() => onScheduleDemo(id)}
                    >
                      Schedule Demo
                    </Button>
                  ) : (
                    <Button
                      variant="form"
                      level={3}
                      className="w-[138px]"
                      disabled
                    >
                      In Progress
                    </Button>
                  )}
                </td>
                {!IS_AUS_USER &&
                  !IS_YELLO_USER &&
                  (twoMinutesOld && baddress ? (
                    <td>
                      <Button
                        variant="form"
                        level={3}
                        className="w-[138px]"
                        onClick={() =>
                          newThryvProposal(
                            company,
                            address,
                            city,
                            state,
                            zip,
                            bizdomain,
                          )
                        }
                      >
                        New Thryv Lead Proposal
                      </Button>
                    </td>
                  ) : (
                    <td>
                      <Button
                        variant="form"
                        level={3}
                        className="w-[138px]"
                        disabled
                      >
                        In Progress
                      </Button>
                    </td>
                  ))}
                <td>
                  <Button
                    variant="form"
                    level={3}
                    className="w-[138px]"
                    onClick={() => onRemove(id)}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            ),
          )}
        </ResultsTable>
      </ResultsLayoutContainer>
    </>
  )
}

export {BusinessScans}
