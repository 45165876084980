import React from 'react'
import {ParagraphText} from '@thryvlabs/maverick'
import {client} from '../utils/api-client'
import {useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import io from 'socket.io-client'

import {OverlayLoader} from '../components/ui/payment-information'
import {Spinner} from '../components/loader-spinner'
import {useViewport} from '../utils/useViewport'
import {DesktopLayout} from '../components/payment-information-components/desktop-layout'
import MobileLayout from '../components/payment-information-components/mobile-layout'
import NotFoundPage from '../components/not-found-page'
import {
  commandCenterIcon,
  marketingCenterIcon,
  reportingCenterIcon,
  businessCenterIcon,
} from '../assets'
import ErrorPage from '../components/error-page'
import {useFlags} from 'launchdarkly-react-client-sdk'

const PAYMENT_METHODS = [
  {name: 'Card', value: 'card', icon: 'creditCard'},
  {name: 'Bank Transfer with Plaid', value: 'ach', icon: 'landmark'},
]
const {REACT_APP_OOE_SERVICE_URL} = process.env

const PLANS_ICONS = [
  {
    name: 'command center',
    icon: commandCenterIcon,
  },
  {
    name: 'business center',
    icon: businessCenterIcon,
  },
  {
    name: 'marketing center',
    icon: marketingCenterIcon,
  },
  {
    name: 'reporting center',
    icon: reportingCenterIcon,
  },
]

const ONE_TIME_FEES = [
  '5 extra website pages',
  'professional designed logo',
  'onboarding fee',
  'website design fee',
  'custom video shoot',
]

const createTransitionCoupon = durationAmount => {
  return {
    name: `25% off for ${durationAmount} month${durationAmount > 1 ? 's' : ''}`,
    isValid: true,
    description: `25% off for ${durationAmount} month${
      durationAmount > 1 ? 's' : ''
    }`,
    type: 'percent',
    duration: 'temporal',
    durationUnit: 'month',
    durationAmount: durationAmount,
    appliesToAllPlans: false,
    appliesToPlans: [
      'professional-4-dexyp-m2m',
      'unlimited-4-dexyp-m2m',
      'plus-4-dexyp-m2m',
      'unlimited-4-barbados-m2m',
      'professional-4-barbados-m2m',
      'plus-4-barbados-m2m',
      'plus-4-caymanislands-m2m',
      'professional-4-caymanislands-m2m',
      'unlimited-4-caymanislands-m2m',
      'addon-4-mc-m2m',
      'cc_plus-5-thryv-m2m',
      'cc_pro-5-thryv-m2m',
      'bc_plus-5-thryv-m2m',
      'bc_pro-5-thryv-m2m',
      'bc_unlm-5-thryv-m2m',
      'mc_pro-5-thryv-m2m',
      'mc_plus-5-thryv-m2m',
    ],
    isPercentDiscount: true,
    discountAmount: 0,
    discountPercent: 25,
    code: `25month${durationAmount}`,
  }
}

const INITIAL_PAYMENT_STATE = {
  paymentMethod: PAYMENT_METHODS[0],
  paymentInfo: undefined,
  calculatedTaxes: null,
  totalPaymentAmount: 0,
  totalPaymentAmountNoTaxes: 0,
  semiAnnuallyAmount: 0,
  monthlyAmount: 0,
  oneTimeAmount: 0,
  monthlyAddons: [],
  semiAnnuallyAddons: [],
  oneTimeAddons: [],
  taxes: {
    monthly: null,
    semiAnnually: null,
    oneTime: null,
  },
}

const getPlanIcon = name => {
  const planItem = PLANS_ICONS.filter(item =>
    name.toLowerCase()?.includes(item.name),
  )
  return planItem[0]?.icon
}

function PaymentInformation() {
  const [recurlyScriptLoaded, setRecurlyScriptLoaded] = React.useState(false)
  const [paymentState, setPaymentState] = React.useState(INITIAL_PAYMENT_STATE)
  const [isLoading, setIsLoading] = React.useState(true)
  const [calculatingTaxes, setCalculatingTaxes] = React.useState(false)
  const [uuid, setUuid] = React.useState(null)
  const [finalStep, setFinalStep] = React.useState(false)
  const [connected, setConnected] = React.useState(false)
  const [checkBoxesValues, setCheckBoxesValues] = React.useState({
    agreedTerms: false,
    agreedPromotionalInfo: false,
    useBusinessAddressInfo: false,
    updateBillingAddress: false,
  })
  const [httpError, setHttpError] = React.useState(null)
  const [paymentRequestState, setPaymentRequestState] = React.useState({
    hasErrors: false,
    requested: false,
  })
  const [isAddressUpdated, setIsAddressUpdated] = React.useState(false)
  const {width} = useViewport()
  const {pathname, search} = useLocation()
  const nextStepCalled = React.useRef({
    step1: false,
    step2: false,
    step3: false,
  })
  const {frontendPreviewPayment} = useFlags()

  const randomId = pathname.split('/')[2]
  const is5Order = search.includes('?version=cc')
  const isAusNZ = ['AU', 'NZ'].includes(paymentState.paymentInfo?.country)

  const handleChangePaymentMethod = paymentMethod => {
    setPaymentState(prevState => ({...prevState, paymentMethod}))
  }
  const getAddonTaxes = (addonsList, subscriptions) => {
    const addonsTaxes = addonsList.map(addon => {
      const {tax} = subscriptions.find(
        suscription => suscription.planCode === addon.addonPlanCode,
      ) || {tax: 0}
      return tax
    })
    return parsePrices(addonsTaxes)
  }

  const addPlanAsAddon = (plan, monthlyAddons, semiAnnuallyAddons) => {
    const planAsAddon = {addonPlanCode: plan.planCode}
    if (plan.planUnit === 'monthly') {
      monthlyAddons.push(planAsAddon)
    } else {
      semiAnnuallyAddons.push(planAsAddon)
    }
  }

  const getBillingSaveData = paymentResponse => {
    let result
    if (paymentState.paymentMethod.value === PAYMENT_METHODS[0].value) {
      result = {
        orderUuid: randomId,
        billingMethod: 'CC',
        recurlyToken: paymentResponse.id,
      }
      return result
    }
    const {metadata, public_token, billingInfo} = paymentResponse
    const {state, country} = billingInfo
    result = {
      orderUuid: randomId,
      billingMethod: 'ACH',
      plaidToken: public_token,
      plaidAccountId: metadata.account_id,
      billingInfo: {
        ...billingInfo,
        state: state.name,
        country: country.value,
      },
    }
    return result
  }

  const calculateTaxes = async paymentResponse => {
    try {
      const {flow} = paymentState.paymentInfo
      const isUpgrade = flow === 'upgrade'
      const data = getBillingSaveData(paymentResponse)
      let url = isUpgrade
        ? 'orders/process/upgrade/billing/save'
        : 'orders/process/billing/save'
      const result = await client(url, {data})
      const {total, tax, subscriptions} = result
      const upgradeSubscriptions = isUpgrade
        ? [...result.updatedSubscriptions, ...result.newSubscriptions]
        : []
      const plan = paymentState.paymentInfo?.plan
      const monthlyAddons = []
      const semiAnnuallyAddons = []
      addPlanAsAddon(plan, monthlyAddons, semiAnnuallyAddons)
      monthlyAddons.push(...paymentState.monthlyAddons)
      semiAnnuallyAddons.push(...paymentState.semiAnnuallyAddons)

      setPaymentState(prevState => ({
        ...prevState,
        totalPaymentAmount: total,
        calculatedTaxes: tax,
        taxes: {
          monthly: getAddonTaxes(
            monthlyAddons,
            isUpgrade ? upgradeSubscriptions : subscriptions,
          ),
          semiAnnually: getAddonTaxes(
            semiAnnuallyAddons,
            isUpgrade ? upgradeSubscriptions : subscriptions,
          ),
          oneTime: getAddonTaxes(
            paymentState.oneTimeAddons,
            isUpgrade ? upgradeSubscriptions : subscriptions,
          ),
        },
      }))
      setCalculatingTaxes(false)
      setPaymentRequestState({
        hasErrors: false,
        requested: true,
      })
    } catch (error) {
      setCalculatingTaxes(false)
      if (error.status !== 400) {
        toast.error(error.message)
        throw error
      }
      setPaymentRequestState({
        hasErrors: true,
        requested: true,
      })
      if (error.errors) {
        error.errors.forEach(({message}) => toast.error(message))
      } else {
        toast.error(error.message)
      }
    }
  }

  const calculateTaxesCC = async paymentResponse => {
    try {
      const data = getBillingSaveData(paymentResponse)
      let url = 'cc/order/save-billing'
      const result = await client(url, {data})
      const {total, tax, newSubscriptions, updatedSubscriptions} = result
      const apps =
        updatedSubscriptions.find(
          ({planCode}) => planCode === 'appmarket-5-thryv-m2m',
        )?.addons || []
      const subscriptions = [...newSubscriptions, ...apps]
      const monthlyAddons = []
      monthlyAddons.push(...paymentState.monthlyAddons)

      setPaymentState(prevState => ({
        ...prevState,
        totalPaymentAmount: total,
        calculatedTaxes: tax,
        taxes: {
          monthly: getAddonTaxes(monthlyAddons, subscriptions),
          oneTime: getAddonTaxes(paymentState.oneTimeAddons, subscriptions),
        },
      }))
      setCalculatingTaxes(false)
      setPaymentRequestState({
        hasErrors: false,
        requested: true,
      })
    } catch (error) {
      setCalculatingTaxes(false)
      if (error.status !== 400) {
        toast.error(error.message)
        throw error
      }
      setPaymentRequestState({
        hasErrors: true,
        requested: true,
      })
      if (error.errors) {
        error.errors.forEach(({message}) => toast.error(message))
      } else {
        toast.error(error.message)
      }
    }
  }

  const parsePrices = prices => {
    let totalPrice = 0
    for (const i in prices) {
      totalPrice += prices[i]
    }
    return totalPrice
  }

  const getPaymentAmount = ({includePlanPrice = false, listOfAddons}) => {
    let currency
    if (!is5Order) {
      const {plan} = paymentState.paymentInfo
      currency = plan.currency
      currency.amount = Number(currency.amount)
      currency.onboarding = Number(currency.onboarding)
    }
    let planPrice = includePlanPrice ? parsePrices([currency.amount]) : 0

    const addonsPrices = listOfAddons.map(
      addon => addon.quantity * addon.currency.unitAmount,
    )
    return parsePrices([planPrice, ...addonsPrices])
  }

  const getListOfAddons = (
    {intervalUnit, intervalLength},
    flow,
    oldSubAddons,
  ) => {
    const {addons} = paymentState.paymentInfo

    const filteredAddons = addons.filter(
      addon =>
        addon.intervalUnit === intervalUnit &&
        addon.intervalLength === intervalLength &&
        addon.currency.unitAmount > 0,
    )

    if (intervalUnit === 'days' && intervalLength === 1) {
      const addonsWithSetupFee = addons
        .filter(addon => {
          const isOldAddon = oldSubAddons?.some(({name}) => name === addon.name)
          if (flow === 'upgrade' && isOldAddon) return false
          return addon.currency.setupFee > 0
        })
        .map(addon => ({
          ...addon,
          planCode: `${addon.planCode}-setupfee`,
          name: `${addon.name} (Setup Fee)`,
          currency: {
            ...addon.currency,
            unitAmount: addon.currency.setupFee,
          },
        }))

      return [...addonsWithSetupFee, ...filteredAddons]
    }
    return filteredAddons
  }

  const formatPlanAndAddon = plan => {
    let formattedPlanAndAddOns = []
    formattedPlanAndAddOns.push(plan)
    for (let addon of plan.addons) {
      formattedPlanAndAddOns.push(formatMarketPlaceAddon(addon))
    }
    return formattedPlanAndAddOns
  }

  const formatMarketPlaceAddon = addon => {
    const {id, name, code, currencies, quantity} = addon
    const {curr} = paymentState.paymentInfo
    let currency
    if (currencies.length > 0) {
      currency = currencies.find(({currency}) => currency === curr)
    } else {
      currency = {
        unitAmount: 0,
        currency: curr,
      }
    }
    return {
      id,
      name,
      code,
      currency,
      quantity:
        code === 'addon-5-seokey-m2m' || code === 'addon-5-seopb-m2m'
          ? quantity
          : 1,
    }
  }

  const getListOfCentersAndApps = ({intervalLength}) => {
    const {plans} = paymentState.paymentInfo
    const filteredPlans = plans
      .filter(plan =>
        !intervalLength && plan.name === 'Thryv Marketplace'
          ? true
          : plan.intervalLength === intervalLength,
      )
      .flatMap(plan => {
        if (plan?.name === 'Thryv Marketplace') {
          return plan.addons.map(addon => formatMarketPlaceAddon(addon))
        } else if (
          plan?.name === 'Marketing Center Plus' ||
          plan?.name === 'Marketing Center Pro' ||
          plan?.name === 'SEO'
        ) {
          return formatPlanAndAddon(plan)
        } else {
          return plan
        }
      })
      .filter(plan =>
        (intervalLength &&
          ONE_TIME_FEES.some(fee => plan.name.toLowerCase().includes(fee))) ||
        (!intervalLength &&
          !ONE_TIME_FEES.some(fee => plan.name.toLowerCase().includes(fee)))
          ? false
          : true,
      )
    const filteredPlansWithImg = filteredPlans.map(plan => {
      return {
        ...plan,
        img: getPlanIcon(plan.name),
        ...(!plan.quantity && {quantity: 1}),
      }
    })
    return filteredPlansWithImg
  }

  const handleOnCheckBoxChange = ({target: {name}}) => {
    setCheckBoxesValues(prevState => ({
      ...prevState,
      [name]: !prevState[name],
    }))
  }

  const savePaymentContinue = () => {
    setFinalStep(true)
  }

  const handleMarketingCenterEnhanced = (payload, is5Order) => {
    // This is some ugly object mutation but it hides MC enhanced using a coupon from the rest of the page
    const mcEnhancedCouponIndex = payload.coupons.findIndex(c =>
      c.appliesToPlans?.includes('mc_pro_enh-5-thryv-m2m'),
    )
    const mcEnhancedCoupon = payload.coupons[mcEnhancedCouponIndex]
    const mcEnhancedPlan = is5Order
      ? payload.plans.find(p => p.code === 'mc_pro_enh-5-thryv-m2m')
      : payload.addons.find(p => p.code === 'addon-4-mcproenh-m2m')

    if (mcEnhancedCoupon && mcEnhancedPlan) {
      const couponAmount = mcEnhancedCoupon.discountAmount
      mcEnhancedPlan.currency.unitAmount -= couponAmount
      payload.coupons.splice(mcEnhancedCouponIndex, 1)
    }

    if (!payload.paymentPreview) return payload

    const mcEnhancedPreview = payload.paymentPreview.centers.find(
      ({planCode}) => planCode === 'mc_pro_enh-5-thryv-m2m',
    )

    if (mcEnhancedPreview) {
      mcEnhancedPreview.subtotal -= mcEnhancedPreview.discount
      payload.paymentPreview.discount -= mcEnhancedPreview.discount
      mcEnhancedPreview.discount = 0
    }

    return payload
  }

  React.useEffect(() => {
    const recurlyStyles = document.createElement('link')
    const script = document.createElement('script')

    recurlyStyles.href = 'https://js.recurly.com/v4/recurly.css'
    recurlyStyles.rel = 'stylesheet'
    document.body.appendChild(recurlyStyles)

    script.src = 'https://js.recurly.com/v4/recurly.js'
    script.async = true
    script.id = 'recurlyScript'

    document.body.appendChild(script)
    document.getElementById('recurlyScript').addEventListener('load', () => {
      setRecurlyScriptLoaded(true)
    })
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script)
      }
      if (document.body.contains(recurlyStyles)) {
        document.body.removeChild(recurlyStyles)
      }
    }
  }, [])

  React.useEffect(() => {
    const getPaymentInfo = async () => {
      if (randomId) {
        try {
          let url
          if (is5Order) {
            url = `cc/order/purchase-plan/${randomId}`
          } else {
            url = `orders/purchase-plan/${randomId}`
          }
          const result = await client(url)
          console.log(result)

          if (is5Order) {
            const isAlsphaSeoPresent = result.plans.some(({code}) =>
              ['seobase-5-thryv-m2m'].includes(code),
            )

            if (isAlsphaSeoPresent) {
              for (let plan of result.plans) {
                if (plan.code === 'seobase-5-thryv-m2m') {
                  plan.currency.unitAmount = plan.quantity
                  plan.currency.unitAmount = 1
                }
              }
            }
          }

          const isTransitionCouponPresent = result.coupons.some(({code}) =>
            ['25month1', '25month2', '25month3'].includes(code),
          )

          handleMarketingCenterEnhanced(result, is5Order)

          if (
            is5Order &&
            result.flow === 'upgrade' &&
            !isTransitionCouponPresent
          ) {
            const transitionApplies = result.plans.some(
              ({transitionApplies}) => transitionApplies,
            )
            if (
              transitionApplies &&
              result.coupons.find(({code}) => code === 'transition75')
            ) {
              const transitionCoupons = [1, 2, 3].map(createTransitionCoupon)
              result.coupons.push(...transitionCoupons)
            }
          }
          setPaymentState(prevState => ({
            ...prevState,
            paymentInfo: result,
          }))
          setIsLoading(false)
        } catch (error) {
          if (error.status) {
            setHttpError(error)
          } else {
            throw error
          }
        }
      }
    }
    getPaymentInfo()
  }, [is5Order, randomId])

  React.useEffect(() => {
    if (paymentState.paymentInfo && is5Order) {
      const centersAndAppsConfig = [
        {intervalLength: 1},
        {intervalLength: undefined},
      ]
      const listOfCentersAndApps = centersAndAppsConfig.map(config =>
        getListOfCentersAndApps(config),
      )
      const paymentAmounts = centersAndAppsConfig.map((config, index) =>
        getPaymentAmount({listOfAddons: listOfCentersAndApps[index]}),
      )
      const [monthly, oneTime] = paymentAmounts

      const updatedPaymentState = {
        totalPaymentAmountNoTaxes: parsePrices(paymentAmounts),
        monthlyAmount: monthly,
        monthlyAddons: listOfCentersAndApps[0],
        oneTimeAmount: oneTime,
        oneTimeAddons: listOfCentersAndApps[1],
      }

      setPaymentState(prevState => ({
        ...prevState,
        ...updatedPaymentState,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is5Order, paymentState.paymentInfo])

  React.useEffect(() => {
    if (paymentState.paymentInfo && !is5Order) {
      const {planUnit} = paymentState.paymentInfo.plan
      const {flow, oldSubAddons} = paymentState.paymentInfo
      const addonsConfig = [
        {intervalUnit: 'months', intervalLength: 1},
        {intervalUnit: 'months', intervalLength: 6},
        {intervalUnit: 'days', intervalLength: 1},
      ]
      const listOfAddons = addonsConfig.map(config =>
        getListOfAddons(config, flow, oldSubAddons),
      )

      const paymentAmounts = addonsConfig.map((config, index) => {
        const includePlanPrice =
          (planUnit === 'monthly' && index === 0) ||
          (planUnit === 'semi-annually' && index === 1)
        return getPaymentAmount({
          includePlanPrice,
          listOfAddons: listOfAddons[index],
        })
      })

      const [monthly, semiAnnually, oneTime] = paymentAmounts

      const updatedPaymentState = {
        totalPaymentAmountNoTaxes: parsePrices(paymentAmounts),
        monthlyAmount: parseFloat(monthly.toFixed(2)),
        monthlyAddons: listOfAddons[0],
        semiAnnuallyAmount: parseFloat(semiAnnually.toFixed(2)),
        semiAnnuallyAddons: listOfAddons[1],
        oneTimeAmount: oneTime,
        oneTimeAddons: listOfAddons[2],
      }

      setPaymentState(prevState => ({
        ...prevState,
        ...updatedPaymentState,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentState.paymentInfo, is5Order])

  React.useEffect(() => {
    setUuid(randomId)
  }, [randomId])
  React.useEffect(() => {
    const socket = io(REACT_APP_OOE_SERVICE_URL, {
      transports: ['websocket'],
    })
    const isMobile = width <= 768
    const isTaxAvailable =
      paymentState.taxes.monthly ||
      paymentState.taxes.semiAnnually ||
      paymentState.taxes.oneTime
    socket.on('connect', () => {
      setConnected(true)
    })
    if (!connected) {
      return
    }

    function handleSocket({step, callbackFn = () => {}}) {
      const data = {uuid}
      if (step) {
        data.step = step
        nextStepCalled.current[`step${step}`] = true
      }
      socket.emit(step ? 'nextStep' : 'join', data, callbackFn)
    }

    if (uuid && !nextStepCalled.current.step1) {
      handleSocket({callbackFn: () => handleSocket({step: 1})})
    }

    if (
      !isMobile &&
      (isAusNZ ||
        (frontendPreviewPayment && paymentState.paymentInfo?.paymentPreview))
    ) {
      if (isTaxAvailable !== null && !nextStepCalled.current.step2) {
        handleSocket({step: 2, callbackFn: () => handleSocket({step: 3})})
      }
    } else {
      if (isTaxAvailable !== null && !nextStepCalled.current.step2) {
        handleSocket({step: 2})
      }

      if (finalStep && !nextStepCalled.current.step3) {
        if (nextStepCalled.current[`step${2}`] === false) {
          handleSocket({step: 2})
        }

        handleSocket({step: 3})
      }
    }

    if (paymentState?.paymentInfo?.isInvoiceBilled && isAddressUpdated) {
      handleSocket({step: 2, callbackFn: () => handleSocket({step: 3})})
    }

    return () => {
      socket.close()
    }
  }, [
    connected,
    uuid,
    paymentState.taxes,
    finalStep,
    width,
    frontendPreviewPayment,
    paymentState.paymentInfo?.paymentPreview,
    isAusNZ,
    paymentState.paymentInfo?.isInvoiceBilled,
    isAddressUpdated,
  ])

  const updateParentAddress = async (
    addressInformation,
    bizphone,
    handleCompleteOrder,
  ) => {
    setCalculatingTaxes(true)
    try {
      const data = {
        orderUuid: uuid,
        firstName: addressInformation.firstName,
        lastName: addressInformation.lastName,
        company: addressInformation.company,
        address: {
          address: addressInformation.address,
          city: addressInformation.city,
          state: addressInformation.state,
          zipCode: addressInformation.postalCode,
          country: 'US',
          phone: bizphone.toString(),
        },
      }
      await client('accounts/address', {data, method: 'PUT'})
      setIsAddressUpdated(true)
      handleCompleteOrder()
    } catch (err) {
      toast.error(err.message)
    }
    setCalculatingTaxes(false)
  }

  if (!recurlyScriptLoaded) {
    return null
  }
  if (httpError?.status === 404) {
    return <NotFoundPage />
  }
  if (httpError) {
    return <ErrorPage httpError={httpError} />
  }
  return (
    <div className="p-4" style={{position: 'relative'}}>
      {calculatingTaxes && (
        <OverlayLoader
          is5Order={is5Order}
          className="d-flex justify-content-center align-items-center w-100 position-fixed"
        >
          <Spinner />
          <ParagraphText
            variant="lg"
            color="thryv-white-50"
            className="font-weight-bold mt-4"
          >
            {(!isAusNZ && !frontendPreviewPayment) ||
            (!isAusNZ &&
              frontendPreviewPayment &&
              !paymentState.paymentInfo?.paymentPreview)
              ? 'Calculating taxes'
              : 'Processing'}
            ...
          </ParagraphText>
        </OverlayLoader>
      )}
      {width <= 768 ? (
        <MobileLayout
          paymentState={paymentState}
          isLoading={isLoading}
          calculateTaxes={is5Order ? calculateTaxesCC : calculateTaxes}
          calculatingTaxes={calculatingTaxes}
          setCalculatingTaxes={setCalculatingTaxes}
          handleChangePaymentMethod={handleChangePaymentMethod}
          paymentMethods={isAusNZ ? [PAYMENT_METHODS[0]] : PAYMENT_METHODS}
          checkBoxesValues={checkBoxesValues}
          handleOnCheckBoxChange={handleOnCheckBoxChange}
          savePaymentContinue={savePaymentContinue}
          uuid={uuid}
          paymentRequestState={paymentRequestState}
          is5Order={is5Order}
          setIsAddressUpdated={setIsAddressUpdated}
          bizphone={paymentState.paymentInfo?.businessAddress?.phone}
          isAddressUpdated={isAddressUpdated}
          updateParentAddress={updateParentAddress}
        />
      ) : (
        <DesktopLayout
          paymentState={paymentState}
          setPaymentState={setPaymentState}
          isLoading={isLoading}
          calculateTaxes={is5Order ? calculateTaxesCC : calculateTaxes}
          calculatingTaxes={calculatingTaxes}
          setCalculatingTaxes={setCalculatingTaxes}
          handleChangePaymentMethod={handleChangePaymentMethod}
          paymentMethods={isAusNZ ? [PAYMENT_METHODS[0]] : PAYMENT_METHODS}
          checkBoxesValues={checkBoxesValues}
          handleOnCheckBoxChange={handleOnCheckBoxChange}
          savePaymentContinue={savePaymentContinue}
          uuid={uuid}
          paymentRequestState={paymentRequestState}
          is5Order={is5Order}
          isAddressUpdated={isAddressUpdated}
          setIsAddressUpdated={setIsAddressUpdated}
          updateParentAddress={updateParentAddress}
        />
      )}
    </div>
  )
}

export {PaymentInformation}
