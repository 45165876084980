import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Modal, ModalTitle, Input, Button} from '@thryvlabs/maverick'

const ChangeSubscriptionModal = ({subId, thryvId}) => {
  const [subscriptionId, setSubscriptionId] = useState('')
  const navigate = useNavigate()

  function handleChange(e) {
    setSubscriptionId(e.target.value)
  }
  function handleClick(actionType) {
    if (actionType === 'override' && !subscriptionId) return
    navigate('/order/upgrade', {
      state: {
        order: {
          billingAccountId:
            actionType === 'useCurrent' ? subId : subscriptionId,
          thryvId,
        },
        currentStep: 1,
      },
    })
  }

  return (
    <Modal
      id="change-subscription-modal"
      variant="default"
      btnText="Manage"
      btnType="secondary"
    >
      <div className="text-center">
        <ModalTitle variant="primary">Override Subscription ID</ModalTitle>
      </div>
      <div className="d-flex flex-column align-items-center">
        <Input
          onChange={handleChange}
          placeholder="Subscription ID"
          aria-label="Subscription ID"
          type="text"
          variant="default"
          className="align-self-stretch"
        />
        <div className="pt-3" style={{display: 'flex', columnGap: '10px'}}>
          <Button variant="secondary" onClick={() => handleClick('useCurrent')}>
            USE CURRENT
          </Button>
          <Button variant="primary" onClick={() => handleClick('override')}>
            OVERRIDE
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export {ChangeSubscriptionModal}
