import styled from '@emotion/styled'
// import {device} from '../../styles/media-queries'

export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  .table-round {
    border: solid #c0c0c0 1px;
    border-radius: 6px;
    box-shadow: 0 0 12px 1px rgb(0 0 0 / 10%);
  }
  .main-container {
    display: grid;
    grid-template-rows: 2;
    grid-template-columns: 70vw;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    //     border-bottom: solid #c0c0c0 1px;
  }
  .header {
    margin-left: 15rem !important;
    margin-top: 2rem !important;
  }
`
export const Features = styled.div`
  thead {
    font-size: 19px;
    border-bottom: 3px solid #ff5000 !important;
  }
  th {
    border-top: 0px !important;
  }
  tbody {
    background: linear-gradient(#0000, #f3f3f3);
  }
  .text {
    width: 70%;
    font-size: 18px;
    border-right: solid #c0c0c0 1px;
  }
  .blue-line {
    border-bottom: 3px solid #057aff !important;
  }
`
