import React from 'react'
import {useAuth0} from '@auth0/auth0-react'

function useTrackLR(config) {
  const {user} = useAuth0()

  React.useEffect(() => {
    if (!config) return

    const finalConfig = {
      name: user?.name,
      email: user?.email,
      roles: user?.user_roles,
      ...config,
    }
    if (finalConfig.key === 'business-scan-results' && !user) {
      delete finalConfig.name
      delete finalConfig.email
      delete finalConfig.roles
    }
    delete finalConfig.key
  }, [user, config])
}

export {useTrackLR}
