import React from 'react'
import {ScorePercentage} from './score-percentage'
import {Header, ParagraphText, Icon} from '@thryvlabs/maverick'

import {
  ThryvAdviceContainer,
  ProTipContainer,
  CriteriaMainContainer,
  CriteriaScoreContainer,
  CriteriaDescriptionContainer,
} from '../ui/business-results'

import {lightIcon} from '../../assets'

function ThryvAdvice({thryvAdvice, image}) {
  return (
    <ThryvAdviceContainer data-testid="component-thryv-advice">
      <img src={image} alt="office" />
      <p>
        <strong>With Thryv! </strong>
        <br />
        <span>{thryvAdvice}</span>
      </p>
    </ThryvAdviceContainer>
  )
}

function ProTip({proTip}) {
  return (
    <ProTipContainer data-test="component-pro-tip">
      <img src={lightIcon} alt="light icon" />
      <p>
        <strong>Pro Tip: </strong>
        <span data-test="pro-tip-message">{proTip}</span>
      </p>
    </ProTipContainer>
  )
}

function CriteriaContainer(props) {
  const {
    criteria,
    criteriaDescription,
    icon,
    proTip,
    thryvAdvice,
    score,
    children,
    image,
    refProp,
    className = '',
  } = props
  return (
    <CriteriaMainContainer
      data-test="component-criteria-container"
      className={`${className}`}
      ref={refProp}
    >
      <CriteriaScoreContainer>
        <CriteriaDescriptionContainer>
          {icon && (
            <span data-test="criteria-icon">
              <Icon type="regular" variant={icon} height="30" width="30" />
            </span>
          )}
          <Header fontWeight="semibold" variant="h4" className="ml-3">
            <span className="font-bold">{criteria}</span>
          </Header>
        </CriteriaDescriptionContainer>
        <ScorePercentage score={score} />
      </CriteriaScoreContainer>
      <ParagraphText variant="lg" color="thryv-black-700">
        <span data-test="criteria-description">{criteriaDescription}</span>
      </ParagraphText>
      {proTip && <ProTip proTip={proTip} />}
      <ul aria-label={`${criteria} Control Group Buttons`}>{children}</ul>
      {thryvAdvice && <ThryvAdvice thryvAdvice={thryvAdvice} image={image} />}
    </CriteriaMainContainer>
  )
}

export {CriteriaContainer}
