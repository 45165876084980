import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'

export const MainContainer = styled.section`
  @media screen and ${device.laptop} {
    width: 95vw;
  }
  width: 100vw;

  & .back__arrow:hover {
    cursor: pointer;
  }
`
export const SelectionContainer = styled.section`
  padding-left: 50px;
  display: grid;
  grid-template-columns: 55% 45%;
  gap: 4%;
  justify-content: center;
  margin: 0;
  .category--selection-left {
    display: grid;
    grid-template-rows: 70px auto;
  }
  .category--selection-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 250px);
    align-items: flex-start;
  }
  @media screen and ${device.laptop} {
    padding: 25px;
    grid-template-columns: 50% 50%;
    gap: 5px;
    .category--selection-container {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 250px);
    }
  }
  @media screen and ${device.tablet} {
    padding: 0;
    grid-template-columns: 35% 65%;
    .category--selection-container {
      grid-template-columns: 100%;
      grid-template-rows: auto;
    }
  }
`
export const CategorySelection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  & .categoryLogo-container {
    width: 112px;
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid #e4e4e4;
    padding: 25px;
  }
  & .categoryLogo-container.active {
    border: 1px solid #ee7b45;
  }
  & .categoryLogo-container:hover {
    border: 1px solid #ee7b45;
    cursor: pointer;
  }
  .categoryLogo {
    width: 60px;
    height: 57.86px;
  }
  .caption {
    display: flex;
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;
  }
`
export const BenefitsContainer = styled.div`
  width: 500pv;
  height: 100%;
  margin-right: 2rem;
  .sideBarImg {
    width: auto;
    height: auto;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #eceeef;
  }
  .industryImg {
    border-radius: 5px;
  }
  .textSideBar {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
  }
  .sideBarTitle {
    font-weight: normal;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.615385px;
    color: #ff5000;
    padding-bottom: 30px;
    padding-top: 40px;
    padding-left: 20px;
  }
  & .button__container {
    display: flex;
  }
  & .button__container {
    justify-content: center;
    margin-bottom: 1rem;
  }
  .button {
    box-shadow: 0px 2px 4px rgb(0 0 0 / 23%);
    border-radius: 15px;
  }
`
export const TextSideBar = styled.div`
  .sideBarTitle {
    font-weight: normal;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.615385px;
    color: #ff5000;
    padding-bottom: 30px;
    padding-top: 40px;
    padding-left: 20px;
  }
  .sideBarSubTitle {
    padding: 10px;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.692308px;
    color: #000000;
  }
  .sideBarDescription {
    text-align: justify;
    height: 76px;
    left: 905px;
    top: 466px;
    padding-left: 45px;
    font-family: 'Open Sans';
    font-size: 12px;
    line-height: 18px;
    padding-right: 15px;
    letter-spacing: 0.5px;
    color: #000000;
    opacity: 0.43;
  }
`
