import React from 'react'
import {ParagraphText, Modal} from '@thryvlabs/maverick'
import SkeletonLoader from '../skeleton-loader'

const AutoPayModal = ({hasOpenInvoices, setWasModalOpen, isLoading}) => {
  function handleClose() {
    setWasModalOpen(true)
  }
  return (
    <Modal
      openOnLoad
      variant="lg"
      width="40%"
      disableBackdropClick
      onClose={handleClose}
    >
      {isLoading ? (
        <div style={{height: '350px'}}>
          <SkeletonLoader />
        </div>
      ) : (
        <>
          <ParagraphText
            variant="reg"
            className="font-weight-bold"
            style={{fontSize: '20px', lineHeight: '30px'}}
          >
            We&apos;re thrilled to enhance your Thryv experience! Before moving
            forward, there are two simple tasks to finish:
          </ParagraphText>
          <ParagraphText
            variant="lg"
            style={{fontSize: '16px', marginTop: '30px'}}
          >
            <span style={{color: '#FF5000'}}>Step 1.</span>{' '}
            {hasOpenInvoices
              ? 'Settle any outstanding invoices.'
              : 'Add your payment method'}
          </ParagraphText>
          <ParagraphText
            variant="lg"
            style={{fontSize: '16px', marginTop: '20px'}}
          >
            <span style={{color: '#FF5000'}}>Step 2.</span> Confirm your
            enrollment in autopay.
          </ParagraphText>
          <ParagraphText
            variant="reg"
            className="font-weight-bold"
            style={{fontSize: '20px', marginTop: '30px', lineHeight: '30px'}}
          >
            Your Business Advisor will email you a link to complete these two
            steps. Let&apos;s get started!
          </ParagraphText>
        </>
      )}
    </Modal>
  )
}

export {AutoPayModal}
