import styled from '@emotion/styled'
import {Accordion} from '@thryvlabs/maverick'
import {device} from '../../styles/media-queries'

export const MainContainer = styled.div`
  width: 40vw;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 2em;
  & .address_container {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-gap: 10px;
    max-width: 98%;
    & .select div div div div {
      margin-top: 0 !important;
    }
  }
  & .total-price {
    font-size: 25px;
  }
  & .recurly-element-month {
    width: auto;
  }
  & .recurly-address,
  .recurly-input {
    width: 100%;
    height: 45px;
  }
  & .recurly-element-inline,
  .recurly-element-month {
    & div {
      width: 100%;
      height: 45px;
    }
  }
  & .font-weight-bolder {
    font-weight: 600;
  }
  & .h-45 {
    height: 45px;
  }
  @media screen and ${device.laptopL} {
    width: 65vw;
    & .address_container {
      grid-template-columns: 30% 40% 30%;
    }
  }
  @media screen and ${device.tablet} {
    width: 95vw;
  }
`
export const OverlayLoader = styled.div`
  height: ${props => (props.is5Order ? '100vh' : '100%')};
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;

  & p {
    font-size: 20px;
  }
`
export const PaymentMethodContainer = styled.div`
  & .payment-method {
    min-width: ${props => (props.is5Order ? '126px' : '200px')};
    margin-right: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${props =>
      props.is5Order && props.isDesktop ? 'start' : 'center'};
    border-radius: 5px;
    background-color: ${props => props.is5Order && '#F8F9FB'};
    border: 1px solid rgba(0, 0, 0, 0.2);
    &:hover {
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
  }
  & .payment-method.active {
    border-color: rgba(230, 79, 10, 0.7);
    background-color: ${props => props.is5Order && 'white'};
  }
  @media screen and ${device.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .payment-method {
      width: 90vw;
      padding: 15px !important;
      margin-right: 0;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      background-color: #f8f9fb;
      & p {
        font-size: 15px;
        margin-left: 10px !important;
      }
      &.active {
        border: 2px solid rgba(230, 79, 10, 0.7);
      }
    }
    & .payment-method:first-of-type {
      margin-bottom: 20px;
    }
  }
`
export const MainMobileContainer = styled.div`
  & .screen__title {
    font-size: 20px;
  }
  & .step__container {
    & > p {
      font-size: 20px;
    }
  }
  & .step__container__button {
    width: 200px;
    height: 50px;
    font-size: 15px;
  }
  & .info__alert {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 10px;
    background-color: #e6f2ff;
    border: 1px solid #c1deff;
    border-radius: 5px;
    & > p {
      margin: 0;
      margin-left: 10px;
      color: #686b6d;
      font-weight: 600;
    }
  }
  & form {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  & .recurly-element-month {
    width: auto;
  }
  & .recurly-address,
  .recurly-input {
    width: 100%;
    height: 45px;
  }
  & .recurly-element-inline,
  .recurly-element-month {
    width: 100%;
    & div {
      width: 100%;
      height: 45px;
    }
  }
  & .recurly-element-month {
    width: 97%;
  }
  & .address_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .recurly-element {
      width: 90%;
    }
  }
`
export const CartDetailsContainer = styled.div`
  background-color: #f8f9fb;
  border-radius: 5px;
  & > div.with-border {
    padding-bottom: 20px;
    border-bottom: 1px dashed lightgray;
  }
  & .total__calculation {
    padding: 10px 5px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid rgba(228, 231, 233, 1);
    & p {
      font-size: 20px;
      margin: 0;
    }
  }
`
export const CartSectionDetail = styled(Accordion)`
  & button > div {
    flex-direction: row-reverse;
    justify-content: space-between;
    & svg {
      height: 20px;
    }
  }
`

export const TaxesPromptContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  border: 1px solid #82bdff;
  background-color: #e6f2ff;
  padding: 8px 0 8px 12px;
  border-radius: 4px;
  margin-top: 20px;
`
export const PaymentDetailsContainer = styled.div`
  background-color: #f8f9fb;
  padding: 16px;
  & .monthly_container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
`
export const TotalDueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e6e8;
  border-radius: 4px;
  background-color: white;
  padding: 8px;
  margin-top: 8px;
`
