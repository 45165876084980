import styled from '@emotion/styled'

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    Array(props.columnAmount).fill('1fr').join(' ')};
  width: 100%;
  padding: ${props => (props.customPadding ? props.customPadding : '0 24px')};
  & .address_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    max-width: 100%;
    & .select div div div div {
      margin-top: 0 !important;
    }
  }
  & .recurly-element-inline,
  .recurly-element-month {
    & div {
      width: 100%;
      height: 35px;
    }
  }
  & .recurly-address,
  .recurly-input {
    width: 100%;
    height: 35px;
  }
  & .back__arrow:hover {
    cursor: pointer;
  }
  & h2 {
    font-weight: 600;
  }
  & .centered-header h2 {
    grid-column: 1 / ${props => props.columnAmount + 1};
    text-align: center;
  }
`

export const CatalogSectionContainer = styled.div`
  grid-row-start: 2;

  & .plans-container {
    height: 730px;
    width: 100%;
    overflow-y: auto;
  }

  & .bottom-container {
    width: 100%;
    position: absolute;
    padding-right: 40px;
    bottom: 22px;
  }

  & .buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .domain-container {
    border: 1px solid #eceef1;
    border-radius: 4px;
    padding: 8px 12px 24px 12px;
    margin-top: 14px;
  }

  & .text-dark {
    color: #231f20;
    font-size: 14px;
  }

  & .text-error {
    color: #f57a00;
    font-size: 12px;
  }

  & .options-logo {
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-right: 16px;
  }

  & .options-info_container {
    display: flex;
    align-items: center;
    height: 64px;
    margin-bottom: 23px;
  }
`

export const PlanOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${props => (props.selected ? '2px solid #056e30' : 'none')};
  width: 100%;
  border-radius: 5px;
  padding: 18px 14px 18px 14px;
  background-color: ${props => (props.selected ? '#056E300D' : 'initial')};
  cursor: ${props => (props.isUnavailable ? 'default' : 'pointer')};
  font-size: 14px;

  & .dark-text {
    color: #231f20;
  }

  & .logo {
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin: 0 12px;
    filter: ${props => (props.isFree ? 'grayscale(1)' : '')};
  }
`

export const CartItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => (props.hasOptions ? '4px' : '16px')};
  font-size: 14px;

  & .content {
    display: flex;
    align-items: center;
  }

  & .logo-container {
    height: 32px;
    margin: 0 16px 0 4px;
  }

  & .logo {
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  $ .dark-text {
    color: #231f20;
  }

  & .light-text {
    color: #808080;
  }
`

export const OptionsContainer = styled.div`
  margin: 0 0 16px 51px;
  font-size: 14px;
`
