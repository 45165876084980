import React, {useState} from 'react'
import {Button, Icon, Navigation} from '@thryvlabs/maverick'
import {useNavigate} from 'react-router'
import {
  Container,
  QuestionTitle,
  Wrapper,
  QuestionDescriptor,
  AnswerList,
  AnswerListItem,
  BottomButtonContainer,
  AnswerButton,
  AnsweredButton,
} from '../components/ui/package-recommendation'

function PackageRecommendation() {
  const navigate = useNavigate()
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [answers, setAnswers] = useState({})

  const questions = [
    {
      text: 'How many employees do you have?',
      options: [
        {id: 0, text: '0 - 3', value: 'plus'},
        {id: 1, text: '3 - 8', value: 'professional'},
        {id: 2, text: 'More than 8', value: 'unlimited'},
      ],
      descriptor:
        'We use employee size to determine how many staff accounts you need.',
    },
    {
      text: 'Are you interested in automatically generating reviews?',
      options: [
        {id: 0, text: 'yes', value: 'professional'},
        {id: 1, text: 'no', value: 'plus'},
      ],
      descriptor:
        'Make it easier for your customers to leave you reviews using Thryv. Thryv can automatically solicit reviews from your new customers, post them to your client portal and website, and feed them automatically to Google for excellent visibility and impact.',
    },
    {
      text: 'Do you have time to respond to new reviews?',
      options: [
        {id: 0, text: 'yes', value: 'plus'},
        {id: 1, text: 'no', value: 'professional'},
      ],
      descriptor:
        'Use online ratings and reviews to your advantage. Get instant notifications when new ones are uploaded, and stay on top of what customers are saying. Respond on the spot to boost your online reputation.',
    },
    {
      text: 'Do you have time to create social media posts?',
      options: [
        {id: 0, text: 'yes', value: 'plus'},
        {id: 1, text: 'no', value: 'unlimited'},
      ],
      descriptor:
        'Connect your Facebook, Twitter, Instagram, LinkedIn and Google+ to Thryv, and post content, photos, links and videos to all your social media pages with one click.',
    },
    {
      text: 'Do you currently have any professional videos showcasing your company?',
      options: [
        {id: 0, text: 'yes', value: 'plus'},
        {id: 1, text: 'no', value: 'unlimited'},
      ],
      descriptor:
        'Through a full motion, on-site video shoot with our professional videographers, your story is captured showcasing your competitive advantage.',
    },
    {
      text: 'Does your business need a new and modern website?',
      options: [
        {id: 0, text: 'yes', value: 'professional'},
        {id: 1, text: 'no', value: 'plus'},
      ],
      descriptor:
        'Professionally designed to fit your brand and business, you’ll get a mobile responsive website viewable from any device. Easily engage visitors while capturing potential customers through Thryv’s fully integrated client login area, forms, payments, online appointment scheduling and links to your social media profiles.',
    },
  ]

  const back = () => {
    setCurrentQuestion(i => {
      if (i <= 0) return i
      return i - 1
    })
  }

  const getPackage = () => {
    const calculated = {plus: 0, professional: 0, unlimited: 0}
    let selectedPackage = null
    if (Object.values(answers).length !== questions.length) {
      return
    }
    for (let value of Object.values(answers)) {
      calculated[value] += 1
    }
    if (
      calculated['unlimited'] >= 3 ||
      calculated['unlimited'] === calculated['professional']
    ) {
      selectedPackage = 'Unlimited'
    } else if (
      calculated['professional'] >= 3 ||
      calculated['professional'] === calculated['plus']
    ) {
      selectedPackage = 'Professional'
    } else {
      selectedPackage = 'Plus'
    }
    navigate(`/package-result/${selectedPackage}`)
  }

  const optionClicked = value => {
    setAnswers({...answers, [currentQuestion]: value})
    if (currentQuestion !== questions.length - 1) {
      setCurrentQuestion(i => i + 1)
    }
  }

  return (
    <Wrapper>
      <Container>
        <QuestionTitle>{questions[currentQuestion]?.text}</QuestionTitle>
        <AnswerList>
          {questions[currentQuestion].options.map(option => (
            <AnswerListItem
              key={option?.id}
              required
              onClick={() => optionClicked(option?.value)}
            >
              {answers[currentQuestion] === option.value && (
                <AnsweredButton
                  data-testid={`answer-${option?.id}`}
                  className="text-btn-text border border-danger font-open-sans font-semibold capitalize rounded"
                >
                  {option.text}
                </AnsweredButton>
              )}
              {answers[currentQuestion] !== option.value && (
                <AnswerButton
                  data-testid={`answer-${option?.id}`}
                  className="text-thryv-orange-600 text-btn-text border font-open-sans font-semibold capitalize rounded"
                >
                  {option.text}
                </AnswerButton>
              )}
            </AnswerListItem>
          ))}
        </AnswerList>
        <QuestionDescriptor>
          <Icon
            style={{
              display: 'inline-block',
              height: '18px',
              marginRight: '1px',
              marginBottom: '3px',
            }}
            color="#666"
            type="regular"
            variant="circleQuestion"
          ></Icon>
          {questions[currentQuestion]?.descriptor}
        </QuestionDescriptor>
        <BottomButtonContainer>
          {currentQuestion !== 0 && (
            <Navigation variant="back" onClick={back}></Navigation>
          )}
          {currentQuestion === questions.length - 1 && (
            <Button
              style={{
                fontSize: '1rem',
                height: '40px',
              }}
              data-testid={'get-my-package'}
              variant="primary"
              onClick={getPackage}
            >
              Get my Package
            </Button>
          )}
        </BottomButtonContainer>
      </Container>
    </Wrapper>
  )
}

export {PackageRecommendation}
