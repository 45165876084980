import * as React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {Auth0ProviderWithRedirectCallback} from './auth0-provider-with-redirect-callback'

function AppProviders({children}) {
  return (
    <Router>
      <Auth0ProviderWithRedirectCallback
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience="https://ooe.thryv.com/"
      >
        {children}
      </Auth0ProviderWithRedirectCallback>
    </Router>
  )
}

export {AppProviders}
