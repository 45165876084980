import React, {useState} from 'react'
import {Input, Button, ParagraphText, Radio} from '@thryvlabs/maverick'
// import {toast} from 'react-toastify'

function AlphaSEO({
  alphaSEOFromRecurly,
  alphaProposalId,
  alphaDomain,
  alphaAmount,
  // categories,
  // categoryChange,
  // geos,
  // geoChange,
  isExternalDomain,
  setIsExternalDomain,
  keywordCount,
  powerboostCount,
  handleChange,
  validateProposalId,
  diableValidateAndProposalInput,
  incrementKeyword,
  decrementKeyword,
  incrementPowerboost,
  decrementPowerboost,
}) {
  const [isDomainWarningVisible, setIsDomainWarningVisible] = useState(false)
  const keyWordIncrementDisabled = keywordCount >= 3 ? true : false
  const keyWordDecrementDisabled =
    keywordCount === 0
      ? true
      : alphaSEOFromRecurly.alphaKeyCount > 0
      ? true
      : false
  const powerboostIncrementDisabled = powerboostCount >= 3 ? true : false
  const powerboostDecrementDisabled =
    powerboostCount === 0
      ? true
      : alphaSEOFromRecurly.alphaPowerboostCount > 0
      ? true
      : false

  const handleDomainTypeChange = function (e) {
    if (e.target.value === 'yes') {
      // toast.warn(
      //   'Please Note: External site requires the client to provide website content management system & hosting access at onboarding. SEO order is subject to cancellation if access is not provided at onboarding to avoid billing client for inactive SEO campaign',
      // )
      setIsDomainWarningVisible(true)
    } else {
      setIsDomainWarningVisible(false)
    }
    setIsExternalDomain(e.target.value)
  }

  return (
    <div className="d-flex flex-column" style={{rowGap: '30px'}}>
      <div className="d-flex" style={{columnGap: '5px'}}>
        <div style={{flexGrow: 1, position: 'relative'}}>
          <Input
            required
            type="text"
            placeholder="Enter Proposal ID"
            name="proposalId"
            withLabel
            labelType="static"
            variant="input-isolated"
            onChange={handleChange}
            value={alphaProposalId}
            disabled={diableValidateAndProposalInput}
          />
          <br></br>
          <Button
            variant="primary"
            disabled={diableValidateAndProposalInput}
            onClick={validateProposalId}
          >
            Validate
          </Button>
        </div>
      </div>
      <div>
        <ParagraphText variant="reg" className="text-left">
          {'External Domain ?'}
        </ParagraphText>
        <div className="d-flex gap-3">
          <Radio
            hasLabel
            id="yes"
            name="isExternalDomain"
            value="yes"
            label="Yes"
            toggled={isExternalDomain === 'yes'}
            handleOnChange={handleDomainTypeChange}
          />
          <Radio
            hasLabel
            id="no"
            name="isExternalDomain"
            value="no"
            label="No"
            toggled={isExternalDomain === 'no'}
            handleOnChange={handleDomainTypeChange}
          />
        </div>
        {isDomainWarningVisible && (
          <div>
            <ParagraphText variant="reg" className="text-left">
              <strong>Please Note:</strong>
              {
                ' External site requires the client to provide website content management system & hosting access at onboarding. SEO order is subject to cancellation if access is not provided at onboarding to avoid billing client for inactive SEO campaign'
              }
            </ParagraphText>
          </div>
        )}
      </div>
      {/* <div>
        <Input
          aria-label="Keywords"
          required
          type="text"
          placeholder="Enter Category"
          name="domain"
          withLabel
          labelType="static"
          variant="input-isolated"
          onChange={categoryChange}
          value={categories}
          disabled={false}
        />
      </div>
      <div>
        <Input
          aria-label="Geos"
          required
          type="text"
          placeholder="Enter Geo"
          name="domain"
          withLabel
          labelType="static"
          variant="input-isolated"
          onChange={geoChange}
          value={geos}
          disabled={false}
        />
      </div> */}
      <div>
        {/* <p className="m-0" style={{color: '#808080', fontSize: '12px'}}>
          Domain
        </p> */}
        <ParagraphText variant="reg" className="text-left">
          Domain
        </ParagraphText>
        <Input
          aria-label="Domain"
          required
          type="text"
          placeholder=""
          name="domain"
          withLabel
          labelType="static"
          variant="input-isolated"
          value={alphaDomain}
          disabled={true}
        />
      </div>
      <div>
        {/* <p className="m-0" style={{color: '#808080', fontSize: '12px'}}>
          Proposal Amount
        </p> */}
        <ParagraphText variant="reg" className="text-left">
          Proposal Amount
        </ParagraphText>
        <Input disabled value={alphaAmount} variant="input-isolated" />
      </div>

      <div
        key={'keyword'}
        className="d-flex align-items-center  justify-content-between"
      >
        <div>
          <ParagraphText variant="reg" className="text-center">
            {'Keywords:'}
          </ParagraphText>
        </div>
        <div className="d-flex align-items-center ">
          <Button
            disabled={keyWordDecrementDisabled}
            variant="primary"
            buttonType="outlined"
            onClick={decrementKeyword}
            className="fontSizeUpdate"
          >
            -
          </Button>
          &nbsp;
          {Number(keywordCount)}
          &nbsp;
          <Button
            disabled={keyWordIncrementDisabled}
            variant="primary"
            buttonType="outlined"
            onClick={incrementKeyword}
            className="fontSizeUpdate"
          >
            +
          </Button>
        </div>
      </div>
      <div
        key={'Powerboost'}
        className="d-flex align-items-center  justify-content-between"
      >
        <div>
          <ParagraphText variant="reg" className="text-center">
            {'Powerboost:'}
          </ParagraphText>
        </div>
        <div className="d-flex align-items-center ">
          <Button
            disabled={powerboostDecrementDisabled}
            variant="primary"
            buttonType="outlined"
            onClick={decrementPowerboost}
            className="fontSizeUpdate"
          >
            -
          </Button>
          &nbsp;
          {Number(powerboostCount)}
          &nbsp;
          <Button
            disabled={powerboostIncrementDisabled}
            variant="primary"
            buttonType="outlined"
            onClick={incrementPowerboost}
            className="fontSizeUpdate"
          >
            +
          </Button>
        </div>
      </div>
    </div>
  )
}

export {AlphaSEO}
