import styled from '@emotion/styled'
import {Header, ParagraphText, Button} from '@thryvlabs/maverick'
import {device} from '../../styles/media-queries'
import sidePane from '../../assets/side-pane.png'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100vh;
  @media screen and ${device.laptopL} {
    grid-template-columns: 40% 60%;
  }
  @media screen and ${device.tablet} {
    grid-template-columns: 100%;
    grid-template-rows: 10vh auto;
    grid-gap: 5px;
  }
`
export const SidePane = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
  background-image: url('${sidePane}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  & #analysisIllustration {
    margin: 0 auto 50px auto;
  }
  @media screen and ${device.tablet} {
    & .small-device-container {
      display: none;
    }
    & #analysisIllustration {
      margin-bottom: 50px;
    }
  }
`
export const TitleContent = styled(Header)`
  font-family: 'Montserrat';
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  padding-bottom: 20px;
`
export const SubTitleContent = styled(ParagraphText)`
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  max-width: 550px;
  @media screen and ${device.laptopL} {
    max-width: calc(100% - 20px);
  }
`
export const ScanForm = styled.div`
  margin: auto;
  height: 100%;
  width: 100%;
  max-width: 700px;
  position: relative;
  left: 0px;
  top: 0px;
  background: white;
  & .no-business-name-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    & span {
      color: #44579d;
    }
    & span:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  @media screen and ${device.mobileL} {
    max-width: 90vw;
    & .form_footer__container {
      max-width: 90vw;
    }
  }
  @media screen and ${device.laptop} {
    max-width: 500px;
  }
`
export const FormTitle = styled(Header)`
  font-family: 'Montserrat';
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
`
export const FormSubtitle = styled(ParagraphText)`
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #4d4d4d;
`
export const RequiredInformationLabel = styled(ParagraphText)`
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff5000;
`
export const RunAnalysisButton = styled(Button)`
  height: 32px;
  width: 140px;
  left: 1176px;
  top: 702px;
  border-radius: 4px;
  padding: 9px 24px 10px 24px;
  font-size: 12px;
`
export const Form = styled.form`
  width: 100%;
  max-width: 525px;
  & .search-input-container .relative {
    margin-bottom: 0;
  }
  & div > .relative {
    margin-bottom: 2rem;
  }
  & .search-input {
    margin-bottom: 0;
  }
  & .required-input ~ label::after {
    content: ' *';
    color: #ff5000;
  }
  & .search-input-container {
    position: relative;
    z-index: 1;
  }
  & .regular-inputs-container {
    position: relative;
    z-index: 0;
  }
`
export const HideContainer = styled.div`
  transition: opacity 0.5s linear, transform 0.5s linear, max-height 0.5s linear,
    padding 0.5s linear;
  opacity: ${({show}) => (show ? 1 : 0)};
  max-height: ${({show}) => (show ? '300px' : 0)};
  padding: 0;
`

export const SpinnerContainer = styled.div`
  height: 18em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
