import React, {useState, useEffect} from 'react'
import {ParagraphText, Icon, Button} from '@thryvlabs/maverick'
import {
  InvoiceInformationContainer,
  TableTitle,
} from '../ui/account-information'
import SkeletonLoader from '../../components/skeleton-loader'
import {InvoiceDataRow} from './invoice-data-row'
import {AutoPayModal} from './autopay-modal'
import {useAuthClient} from '../../utils/use-auth-client'
import {toast} from 'react-toastify'
import {Spinner} from '../loader-spinner'
import {useNavigate, useLocation} from 'react-router'

const COLUMNS = [
  'Invoice Number',
  'Sent on',
  'Status',
  'Due on',
  'Preview',
  'Amount',
]

const InvoiceInformation = ({thryvId, email, setIsInvoiceBilled}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [displayCollectionTypeButton, setDisplayCollectionTypeButton] =
    useState(false)
  const [displayUpdateBillingButton, setDisplayUpdateBillingButton] =
    useState(false)
  const [isUpdateBillingEmailSent, setIsUpdateBillingEmailSent] =
    useState(false)
  const [invoiceEmailSent, setInvoiceEmailSent] = useState(false)
  const [invoiceInfo, setInvoiceInfo] = useState({})
  const [invoicePromptVisible, setInvoicePromptVisible] = useState(false)
  const [wasInvoicePromptVisible, setWasInvoicePromptVisible] = useState(false)
  const [wasModalOpen, setWasModalOpen] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const client = useAuthClient()
  const navigate = useNavigate()
  const {pathname} = useLocation()

  const hasBilling = invoiceInfo.hasBilling
  const hasOpenInvoices = invoiceInfo.openInvoices?.length > 0

  function closeInvoicePrompt() {
    setInvoicePromptVisible(false)
    setWasInvoicePromptVisible(true)
  }

  function getInvoiceTotal() {
    return invoiceInfo.openInvoices?.length > 0
      ? invoiceInfo.openInvoices?.reduce(
          (acc, invoice) => acc + invoice.total,
          0,
        )
      : 0
  }

  function handleNavigate(emailResponse) {
    if (emailResponse.accountCanUpgrade && pathname === '/order/new') {
      navigate('/order/upgrade', {
        state: {
          order: {
            billingAccountId: emailResponse.subscriptionId,
            thryvId: thryvId,
            v5upgrade: emailResponse.v5upgrade,
            ...(emailResponse.v5upgrade && {
              CCAccountInfo: {thryvId},
            }),
          },
          currentStep: 1,
        },
      })
    } else {
      setIsInvoiceBilled(false)
    }
  }

  async function getInvoiceInfo({showRefreshToast}) {
    try {
      if (!thryvId) return
      setIsLoading(true)
      const {openInvoices, hasBilling} = await client(`invoices/${thryvId}`)
      setInvoiceInfo({openInvoices, hasBilling})
      if (showRefreshToast) toast.success('Invoices refreshed successfully')
      if (!openInvoices?.length) {
        setInvoiceEmailSent(false)
        const emailResponse = await client(`orders/validate-email/${email}`)
        if (emailResponse.isInvoiceBilled) {
          if (hasBilling) {
            setDisplayCollectionTypeButton(true)
            setDisplayUpdateBillingButton(false)
            setIsUpdateBillingEmailSent(false)
            toast.warn('Update account to automatic billing')
          } else {
            setDisplayCollectionTypeButton(false)
            setDisplayUpdateBillingButton(true)
            toast.warn('Billing information update needed')
          }
        } else {
          setIsUpdateBillingEmailSent(false)
          setDisplayCollectionTypeButton(false)
          setDisplayUpdateBillingButton(false)
          handleNavigate(emailResponse)
        }
        if (showRefreshToast) toast.success('No pending invoices found')
      }
      setIsLoading(false)
    } catch (e) {
      toast.error('Error fetching invoices')
    }
  }

  async function sendInvoiceEmail() {
    try {
      setShowSpinner(true)
      const result = await client('invoices', {
        data: {thryvId, email},
      })
      if (result.length > 0) {
        setInvoiceEmailSent(true)
        toast.success(
          !invoiceEmailSent
            ? 'Invoice email sent successfully'
            : 'Invoice email resent successfully',
        )
      }
      setShowSpinner(false)
    } catch (e) {
      setShowSpinner(false)
      if (e.message === 'No open invoices on account') {
        toast.error(e.message)
      } else {
        toast.error('Error sending invoice email')
      }
    }
  }

  const updateCollectionType = async () => {
    try {
      setShowSpinner(true)
      await client(`invoices/convert/${thryvId}`, {
        method: 'POST',
      })
      getInvoiceInfo({showRefreshToast: false})
      setShowSpinner(false)
      toast.success('Collection type updated successfully')
    } catch (e) {
      setShowSpinner(false)
      toast.error('Error updating collection type')
    }
  }

  const sendUpdateBillingEmail = async () => {
    try {
      setShowSpinner(true)
      const data = {email, thryvId}
      await client(`invoices/billing-info`, {data})
      setIsUpdateBillingEmailSent(true)
      setShowSpinner(false)
      toast.success(
        !isUpdateBillingEmailSent
          ? 'Update billing email sent successfully'
          : 'Update billing email resent successfully',
      )
    } catch (e) {
      setShowSpinner(false)
      toast.error('Error sending update billing email')
    }
  }

  useEffect(() => {
    if (invoiceInfo.openInvoices?.length > 0 && !wasInvoicePromptVisible) {
      setInvoicePromptVisible(true)
    }
  }, [invoiceInfo.openInvoices?.length, wasInvoicePromptVisible])

  useEffect(() => {
    getInvoiceInfo({showRefreshToast: false})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thryvId])

  const invoiceButtons = [
    {
      condition: !displayCollectionTypeButton,
      label: 'Refresh Invoices',
      onClick: () => getInvoiceInfo({showRefreshToast: true}),
      disabled: isLoading,
    },
    {
      condition: !displayCollectionTypeButton && !displayUpdateBillingButton,
      label: !invoiceEmailSent ? 'Send Payment Email' : 'Resend Payment Email',
      onClick: sendInvoiceEmail,
      disabled: isLoading || showSpinner,
    },
    {
      condition: displayCollectionTypeButton,
      label: 'Update to Automatic Billing',
      onClick: updateCollectionType,
      disabled: showSpinner,
    },
    {
      condition: displayUpdateBillingButton,
      label: !isUpdateBillingEmailSent
        ? 'Send Update Billing Email'
        : 'Resend Update Billing Email',
      onClick: sendUpdateBillingEmail,
      disabled: showSpinner,
    },
  ]

  return (
    <>
      <InvoiceInformationContainer>
        {invoicePromptVisible && (
          <div className="prompt_container">
            <div
              style={{display: 'flex', alignItems: 'center', columnGap: '6px'}}
            >
              <Icon
                type="solid"
                variant="triangleExclamation"
                color="#f6bd29"
                height="16"
                width="20"
              />
              <ParagraphText variant="reg" className="m-0">
                You have pending invoices. Please select one or multiple
                invoices to continue to payment
              </ParagraphText>
            </div>
            <Icon
              type="regular"
              variant="x"
              color="#808080"
              height="12"
              width="12"
              onClick={closeInvoicePrompt}
              style={{cursor: 'pointer'}}
            />
          </div>
        )}
        {isLoading ? (
          <div style={{height: '400px'}}>
            <SkeletonLoader />
          </div>
        ) : (
          <table
            style={{width: '100%', marginTop: '50px', tableLayout: 'fixed'}}
          >
            <tr>
              {COLUMNS.map((col, i, arr) => (
                <th key={col}>
                  <TableTitle variant="reg" index={i} length={arr.length}>
                    {col}
                  </TableTitle>
                </th>
              ))}
            </tr>
            {invoiceInfo.openInvoices?.map(invoice => (
              <InvoiceDataRow key={invoice.id} invoice={invoice} />
            ))}
            <tr>
              <td style={{padding: '12px 0 12px 20px'}} colSpan={5}>
                <ParagraphText
                  className="m-0 table_data-text"
                  style={{fontSize: '14px'}}
                  variant="reg"
                >
                  Total
                </ParagraphText>
              </td>
              <td style={{textAlign: 'end', paddingRight: '20px'}}>
                <ParagraphText
                  className="m-0 table_data-text"
                  variant="reg"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  ${getInvoiceTotal().toFixed(2)}
                </ParagraphText>
              </td>
            </tr>
          </table>
        )}
        {!wasModalOpen && (!hasBilling || hasOpenInvoices) && (
          <AutoPayModal
            hasOpenInvoices={hasOpenInvoices}
            setWasModalOpen={setWasModalOpen}
            isLoading={isLoading}
          />
        )}
      </InvoiceInformationContainer>
      <div className="button__container mt-5" style={{columnGap: '10px'}}>
        {invoiceButtons
          .filter(({condition}) => condition)
          .map(({label, onClick, disabled}) => (
            <Button
              key={label}
              disabled={disabled}
              onClick={onClick}
              variant="primary"
            >
              {label}
            </Button>
          ))}
      </div>
      {showSpinner && (
        <>
          <div
            style={{
              backgroundColor: 'rgba(128, 128, 128, 0.5)',
              position: 'absolute',
              width: '100%',
              height: '100%',
              borderRadius: '4px',
            }}
          />
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              width: '100%',
              height: '100%',
            }}
          >
            <Spinner />
          </div>
        </>
      )}
    </>
  )
}

export {InvoiceInformation}
