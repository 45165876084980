import React, {useState, useEffect} from 'react'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {getAuthByRole} from '../../utils/get-screen-auth-by-role'
import {useUserRoles} from '../../utils/use-user-roles'
import {AUTHORIZED_ROLES_BY_SCREENS} from '../../utils/constants'
import {OldHeaderContainer, HeaderNavigationMenu} from '../ui/header'
import {thryvSalesLogo} from '../../assets'
import {Link, NavLink, useLocation} from 'react-router-dom'
import {UserDropdown} from './user-dropdown'

function setAccountsSubLinks(roles) {
  const accountsSubLinks = [
    {
      name: 'My Accounts',
      url: '/my-accounts',
    },
  ]
  const {allAccount} = AUTHORIZED_ROLES_BY_SCREENS
  if (getAuthByRole(allAccount, roles)) {
    accountsSubLinks.push({
      name: 'All Accounts',
      url: '/all-accounts',
    })
  }
  return accountsSubLinks
}

function setAppointmentsSubLinks(roles) {
  const appointmentsSubLinks = []
  const {demoRequests, appointments} = AUTHORIZED_ROLES_BY_SCREENS

  if (getAuthByRole(demoRequests, roles)) {
    appointmentsSubLinks.push({
      name: 'Demo Requests',
      url: '/demo-requests',
    })
  }
  if (getAuthByRole(appointments, roles)) {
    appointmentsSubLinks.push({
      name: 'Onboarding',
      url: '/appointments',
    })
  }
  return appointmentsSubLinks
}

const OldHeader = () => {
  const [activeItemId, setActiveItemId] = useState(null)
  const {roles} = useUserRoles()
  const appointmentsSubLinks = setAppointmentsSubLinks(roles)
  const accountsSublinks = setAccountsSubLinks(roles)
  const location = useLocation()
  const {frontEndUtilityPage, frontendShowScans, frontendDisplayNewOrder} =
    useFlags()

  let linksArray = [
    {
      name: 'Accounts',
      link: '/my-accounts',
      subLinks: accountsSublinks,
    },
    {
      name: 'Invitations',
      link: '/invitations',
      url: 'invitations',
    },
    {
      name: 'Product Showcase',
      link: '/productshowcase',
      url: 'productshowcase',
    },
    {
      name: 'Tools',
      link: '/tools',
      url: 'tools',
    },
  ]
  if (frontendShowScans) {
    linksArray.push({
      name: 'Business Scans',
      link: '/scans',
      url: 'scans',
    })
  }
  if (frontendDisplayNewOrder) {
    linksArray.push({
      name: 'Orders',
      link: '/orders',
      url: 'orders',
    })
  }
  if (roles.includes('yello')) {
    linksArray[0].name = 'My Accounts'
    delete linksArray[0].subLinks
  }
  if (appointmentsSubLinks.length > 0) {
    linksArray.splice(1, 0, {
      name: 'Appointments',
      link: '/demo-requests',
      subLinks: appointmentsSubLinks,
    })
  }

  const isAppointmentLocation = location.pathname === '/new-demo-request'
  const isAccountsLocation =
    location.pathname === '/' || location.pathname === '/all-accounts'

  useEffect(() => {
    if (isAppointmentLocation) {
      setActiveItemId(linksArray[1].name)
    }
    if (isAccountsLocation) {
      setActiveItemId(linksArray[0].name)
    }

    // eslint-disable-next-line
  }, [isAppointmentLocation, isAccountsLocation])

  return (
    <OldHeaderContainer className="header p-2 pr-5 pl-5 d-flex justify-content-between align-content-center border-b border-thryv-gray-light-400">
      <Link
        to={'/'}
        onClick={() => setActiveItemId(linksArray[0].name)}
        className="mt-4"
      >
        <img src={thryvSalesLogo} alt="Thryv Sales Logo" />
      </Link>
      <HeaderNavigationMenu>
        <nav className="nav-bar-collapse">
          {linksArray.map((link, i) => (
            <li
              key={i}
              className={`nav-link-container ${
                link.name === activeItemId ? 'mb-4' : ''
              }`}
            >
              <NavLink
                className={`nav-link menu-item ${
                  link.name === activeItemId ? 'active' : ''
                }`}
                to={link.link}
                style={{color: '#6F6F6F'}}
                onClick={() =>
                  setActiveItemId(link.name === activeItemId ? null : link.name)
                }
              >
                {link.name}
                {link.subLinks && (
                  <i
                    className={`fa fa-angle-${
                      link.name === activeItemId ? 'down' : 'up'
                    } ml-2`}
                  />
                )}
              </NavLink>
              <ul
                className={`nav-item ${
                  link.name === activeItemId ? 'show' : 'hide'
                }`}
              >
                {link?.subLinks && (
                  <>
                    {link?.subLinks.map((subLink, i) => (
                      <li className="menu-item-sublink m-2" key={i}>
                        <NavLink
                          className="block px-2 py-1 menu-item"
                          to={subLink.url}
                          style={{color: '#6F6F6F'}}
                        >
                          {subLink.name}
                        </NavLink>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </li>
          ))}
        </nav>
      </HeaderNavigationMenu>
      <div className="d-flex justify-content-around align-items-center headerdiv">
        <UserDropdown isRedirectEnabled={frontEndUtilityPage} />
      </div>
    </OldHeaderContainer>
  )
}

export {OldHeader}
