/* eslint-disable react/display-name */
import React from 'react'
import NumberFormat from 'react-number-format'
import {Input} from '@thryvlabs/maverick'

const CurrencyInput = React.forwardRef(
  ({onChange, name, value, id, errors, ...rest}, ref) => {
    return (
      <NumberFormat
        data-testid="component-currency-input"
        customInput={Input}
        value={value}
        thousandSeparator={true}
        decimalScale={2}
        onValueChange={target => {
          onChange(target.floatValue)
        }}
        isNumericString
        className="w-100"
        type="text"
        placeholder="Annual Business Revenue"
        withLabel
        labelType="floating"
        variant="default"
        name={name}
        id={id || name}
        errors={errors || null}
        {...rest}
      />
    )
  },
)

export {CurrencyInput}
