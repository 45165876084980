import {datadogRum} from '@datadog/browser-rum'
const {
  REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_ID,
  REACT_APP_DATADOG_SERVICE,
  REACT_APP_DATADOG_ENV,
} = process.env

export const InitDataDogRum = ({name, email}) => {
  datadogRum.init({
    applicationId: REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: REACT_APP_DATADOG_CLIENT_ID,
    site: 'us5.datadoghq.com',
    service: REACT_APP_DATADOG_SERVICE,
    env: REACT_APP_DATADOG_ENV, // Specify a version number to identify the deployed version of your application in Datadog //
    version: '1.0.0',
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'allow',
    silentMultipleInit: true,
  })
  datadogRum.setUser({name, email})
}

export const DataDogStartSession = () => {
  datadogRum.startSessionReplayRecording()
}

export const DataDogEndSession = () => {
  datadogRum.stopSessionReplayRecording()
  datadogRum.stopSession()
}
