import React, {useRef, useState, useEffect} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {useNavigate} from 'react-router'
import {useAuthClient} from '../../utils/use-auth-client'
import {DataDogEndSession} from '../../dataDogConfig'
import {UserProfile} from './user-profile'
import {toast} from 'react-toastify'
import {Icon, Tooltip} from '@thryvlabs/maverick'
import {HeaderDropdown} from '../ui/header'

function UserDropdown({isRedirectEnabled}) {
  const [isDropdownActive, setIsDropDownActive] = useState(false)
  const [userData, setUserData] = useState(null)
  const {user, logout} = useAuth0()
  const navigate = useNavigate()
  const client = useAuthClient()
  const profileRef = useRef(null)

  const handleLogout = () => {
    DataDogEndSession()
    logout({returnTo: window.location.origin})
  }

  const fetchUserData = async () => {
    try {
      const data = await client('user/profile')
      if (data) {
        setUserData(data)
      }
    } catch (e) {
      toast.error('Failed to fetch profile data')
    }
  }

  const fetchQrCode = async () => {
    try {
      const data = await client('user/profile/qr')
      const imageUrl = URL.createObjectURL(data)
      setUserData(data => {
        return {...data, qrCode: imageUrl}
      })
    } catch (e) {
      toast.error('Failed to fetch QR code')
    }
  }

  useEffect(() => {
    fetchUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleClickOutside = e => {
      if (profileRef.current && !profileRef.current.contains(e.target)) {
        setIsDropDownActive(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [profileRef])

  return (
    <HeaderDropdown className="dropdown">
      {isRedirectEnabled ? (
        <button className="adminLink" onClick={() => navigate('admin')}>
          {user.name}
        </button>
      ) : (
        <span>{user.name}</span>
      )}
      <Icon
        style={{cursor: 'pointer', width: '15px'}}
        type="solid"
        color="#a9a9a9"
        variant={!isDropdownActive ? 'caretDown' : 'caretUp'}
        className="ml-2"
        onClick={() => setIsDropDownActive(prev => !prev)}
      />
      <Tooltip variant="bottom" title="Sign Out">
        <Icon
          aria-label="test"
          type="solid"
          variant="signOut"
          color="#a9a9a9"
          className="mr-2 ml-3"
          onClick={handleLogout}
        />
      </Tooltip>
      {isDropdownActive && (
        <UserProfile
          data={userData}
          profileRef={profileRef}
          fetchUserData={fetchUserData}
          fetchQrCode={fetchQrCode}
        />
      )}
    </HeaderDropdown>
  )
}

export {UserDropdown}
