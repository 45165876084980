import React, {useState} from 'react'
import {Input} from '@thryvlabs/maverick'

const ERROR_MESSAGES = ['This field is required', 'Please enter a valid domain']
const FORBIDDEN_DOMAINS = ['google', 'bing', 'twitter', 'facebook', 'amazon']

function DomainValidation({domainConfig, setDomainConfig}) {
  const [errorMessageConfig, setErrorMessageConfig] = useState({
    showMessage: false,
    message: 'hola',
  })

  const isValidDomain = domainString => {
    ERROR_MESSAGES[1] = `Please enter a valid domain`
    for (const domain of FORBIDDEN_DOMAINS) {
      if (
        domainString.includes(`.${domain}.`) ||
        domainString.startsWith(`${domain}.`) ||
        domainString.endsWith('yelp.com')
      ) {
        ERROR_MESSAGES[1] += `. The domain ${domain} is not allowed.`
        return false
      }
    }
    const splitUrl = domainString.split('www.')
    const plainURL = splitUrl[splitUrl.length - 1]
    const label = plainURL.slice(0, plainURL.indexOf('.'))
    if (
      label.length > 63 ||
      label.startsWith('-') ||
      domainString.startsWith('-')
    ) {
      ERROR_MESSAGES[1] += `.Max domain length is 63. The domain cannot start or end with '-'`
      return false
    }
    const regex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,6}|((yelp|yellowpages)\.com(\.au)?)\/[a-zA-Z0-9\-/?=.+]+)$/
    if (!regex.test(domainString)) {
      ERROR_MESSAGES[1] += `. No text is allowed after '.com' or similar.`
    }
    return regex.test(domainString)
  }

  const handleErrorValidation = ({value, isValid}) => {
    if (value.length === 0) {
      setErrorMessageConfig({showMessage: true, message: ERROR_MESSAGES[0]})
      return
    }
    if (value.length >= 3) {
      if (isValid) {
        setErrorMessageConfig({showMessage: false, message: ''})
        return
      }
      setErrorMessageConfig({showMessage: true, message: ERROR_MESSAGES[1]})
      return
    }
  }

  const handleDomainValue = ({target: {value}}) => {
    const isValid = isValidDomain(value)
    handleErrorValidation({value, isValid})
    setDomainConfig(prevState => ({...prevState, value, isValid}))
  }

  return (
    <div className="domain-container mb-4">
      <p className="m-0 text-dark pb-3">Enter domain to continue to purchase</p>
      <Input
        aria-label="Domain"
        required
        type="text"
        placeholder="Enter your website domain, YP, or Yelp URL"
        name="domain"
        withLabel
        labelType="static"
        variant="input-isolated"
        onChange={handleDomainValue}
        value={domainConfig.value}
      />
      {errorMessageConfig.showMessage && (
        <p className="m-0 text-error pt-2">{errorMessageConfig.message}</p>
      )}
    </div>
  )
}

export {DomainValidation}
