import React from 'react'
import {Features, Wrapper} from '../components/ui/feature-summary'
import {FEATURES} from '../utils/feature-summary-data'
import {useParams} from 'react-router'
import {Button} from '@thryvlabs/maverick'

function FeatureTables({featureItem, i, id}) {
  const BLUE_IDS = [1, 2, 3, 4, 5, 6]
  const getColor = () => {
    if (BLUE_IDS.includes(id)) {
      return 'blue-line'
    }
    return
  }
  return (
    <Features>
      <table className="table" data-testid={`feature-table-${id}`}>
        <thead key={i} className={`${getColor()}`}>
          <tr>
            <th>{featureItem.label}</th>
          </tr>
        </thead>
        <tbody>
          {featureItem.features.map((feature, i) => (
            <tr key={i}>
              <td className="text">{feature.name}</td>
              <td align="center">{feature.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Features>
  )
}
function PackageResult() {
  const {selectedPackage} = useParams()
  const currentPackage = FEATURES.find(x => x.package_title === selectedPackage)

  return (
    <Wrapper>
      <div key={0}>
        <div className="main-container">
          <div className="d-flex justify-content-center mb-3">
            <h1>We recommend the</h1>
            <img
              src={currentPackage.icon}
              alt="plan"
              height={65}
              width={55}
              className="mr-3 ml-3 mb-2"
            />
            <h1 style={{fontWeight: 'bold'}}>
              {currentPackage.package_title} Package
            </h1>
          </div>
          <h5 className="text-center mb-5">{currentPackage.package_details}</h5>
          <span className="d-flex justify-content-center mb-5">
            <Button variant="primary">Buy Now</Button>
          </span>
          <div className="table-round">
            {currentPackage.package_list.map((featureItem, i) => {
              return (
                <FeatureTables
                  key={i}
                  featureItem={featureItem}
                  id={featureItem.id || 0}
                />
              )
            })}
          </div>
        </div>
        <hr />
      </div>
    </Wrapper>
  )
}

export {PackageResult}
