import React, {useState} from 'react'
import {CartItemContainer, OptionsContainer} from '../ui/command-center-layout'
import {Icon} from '@thryvlabs/maverick'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {formatPrice} from '../../utils/currency-format'

function CartItem({
  title,
  img,
  content = {},
  isCenter,
  isCanAusNz,
  crossedOut,
}) {
  const [open, setOpen] = useState(true)
  const {frontendListingsBcMc} = useFlags()

  const {name, entitlements, amount, quantity} = content
  const isThryvLeads = title === 'Thryv Leads'
  const isFree = name?.toLowerCase().includes('basic')
  const hasOptions = entitlements?.length > 0
  const planCategory =
    title && title === 'SEO'
      ? ''
      : name?.replace(
          /.*?(Pro Enhanced|Professional|Pro|Plus|Unlimited).*/g,
          '$1',
        )

  const isCC = title === 'Command Center'

  if (title.includes('Fee')) {
    img = undefined
  }

  if (isCenter) {
    return (
      <>
        <CartItemContainer
          hasOptions={hasOptions}
          data-testid={`cart-item-${title}`}
        >
          <div className="content">
            <div className="logo-container">
              <img
                src={img}
                height={32}
                width={32}
                alt="center logo"
                className="logo"
              />
            </div>
            <div>
              <p className="m-0 dark-text">{title}</p>
              {content ? (
                <p className="m-0 light-text">
                  {isFree ? 'Free' : isThryvLeads ? '' : planCategory}
                </p>
              ) : (
                <p className="m-0 light-text">None Selected</p>
              )}
            </div>
          </div>
          {content ? (
            <p className="m-0 dark-text">
              {isFree ? 'Included' : formatPrice(amount, quantity, title)}
            </p>
          ) : (
            <p className="m-0 dark-text">None</p>
          )}
        </CartItemContainer>
        {!isCC && hasOptions && (
          <OptionsContainer>
            <button
              className="w-full"
              onClick={() => setOpen(prevVal => !prevVal)}
              aria-describedby={`Click to access cart content below.`}
            >
              <div className={`w-full flex cursor-pointer`}>
                <p className="m-0 mr-1" style={{color: '#A3A5A7'}}>
                  OPTIONS
                </p>
                <div className="flex items-center text-center gap-4">
                  <Icon
                    type={'solid'}
                    variant={`caretDown`}
                    className={`fill-current text-thryv-black-500 -rotate-90 ${
                      open && 'rotate-0'
                    } transition-all duration-300 ease-in-out cursor-pointer`}
                    height={'8'}
                    width={'8'}
                  />
                </div>
              </div>
            </button>
            <div
              className={`${
                !open
                  ? 'hidden opacity-0 scale-0 transition-all duration-500 ease-in-out '
                  : 'visible opacity-100 scale-100 transition-all duration-700 ease-in-out '
              } mt-[8px]`}
            >
              {entitlements.map(option => (
                <React.Fragment key={option.id}>
                  <div className="d-flex justify-content-between">
                    <p className="m-0 dark-text">
                      {isCanAusNz && option.name === 'Custom Video'
                        ? 'QuickStart Video'
                        : option.name}
                    </p>
                    <p className="m-0 dark-text">
                      {formatPrice(
                        option.amount || 0,
                        option.quantity,
                        option.name,
                      )}
                    </p>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </OptionsContainer>
        )}
      </>
    )
  }
  return (
    <CartItemContainer data-testid={`cart-item-${title}`}>
      <div className="content">
        <div className="logo-container">
          {img !== undefined ? (
            <img
              src={img}
              height={32}
              width={32}
              alt="center logo"
              className="logo"
            />
          ) : (
            <span style={{paddingRight: '33.5px'}} />
          )}
        </div>
        <p
          className="m-0 dark-text"
          style={{
            color: crossedOut && frontendListingsBcMc ? '#808080' : '',
            textDecorationLine:
              crossedOut && frontendListingsBcMc ? 'line-through' : 'none',
          }}
        >
          {title} {quantity > 1 && !title.includes('SEO') && `(${quantity}x)`}
        </p>
      </div>
      <p className="m-0 dark-text">
        {amount !== 0
          ? formatPrice(crossedOut ? 0 : amount, quantity, title)
          : 'Included'}
      </p>
    </CartItemContainer>
  )
}

export {CartItem}
