import styled from '@emotion/styled'

const StatusText = styled.span`
  &.text-active {
    color: green;
  }

  &.text-converted {
    color: #ff8a57;
  }

  &.text-expired {
    color: grey;
  }
`

export {StatusText}
