import React, {useContext, useState} from 'react'
import {
  sidebarToolsIcon,
  sidebarAppointmentsIcon,
  sidebarOrdersIcon,
  sidebarInvitationsIcon,
  sidebarBusinessScansIcon,
  sidebarProductShowcaseIcon,
  sidebarAccountsIcon,
  thryvSalesLogo,
} from '../assets'
import {Icon, Button} from '@thryvlabs/maverick'
import {useLocation} from 'react-router'
import {useUserRoles} from '../utils/use-user-roles'
import {getAuthByRole} from '../utils/get-screen-auth-by-role'
import {AUTHORIZED_ROLES_BY_SCREENS} from '../utils/constants'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {headerContext} from '../context/header-context'
import {Link} from 'react-router-dom'
import {MobileSidebar, Sidebar, SidebarNavigationMenu} from './ui/sidebar'
import {NavLink} from 'react-router-dom'
import {useWindowDimensions} from '../utils/use-window-dimensions'

function setAppointmentsSubLinks(roles) {
  const appointmentsSubLinks = []
  const {demoRequests, appointments} = AUTHORIZED_ROLES_BY_SCREENS

  if (getAuthByRole(demoRequests, roles)) {
    appointmentsSubLinks.push({
      name: 'Demo Requests',
      url: '/demo-requests',
    })
  }
  if (getAuthByRole(appointments, roles)) {
    appointmentsSubLinks.push({
      name: 'Onboarding',
      url: '/appointments',
    })
  }
  return appointmentsSubLinks
}

function SideNavBar({
  isSidebarOpen,
  setIsSidebarOpen,
  isMobileSidebarOpen,
  setIsMobileSidebarOpen,
}) {
  const location = useLocation()
  const {roles} = useUserRoles()
  const [activeItemId, setActiveItemId] = useState(null)
  const lDFlags = useFlags()
  const {width: windowWidth} = useWindowDimensions()
  const appointmentsSubLinks = setAppointmentsSubLinks(roles)
  const {isHeaderVisible} = useContext(headerContext)

  let linksArray = [
    {
      name: 'Accounts',
      link: '/my-accounts',
      src: sidebarAccountsIcon,
    },
    {
      name: 'Invitations',
      link: '/invitations',
      src: sidebarInvitationsIcon,
      url: 'invitations',
    },
    {
      name: 'Product Showcase',
      link: '/productshowcase',
      url: 'productshowcase',
      src: sidebarProductShowcaseIcon,
    },
    {
      name: 'Tools',
      link: '/tools',
      url: 'tools',
      src: sidebarToolsIcon,
    },
  ]
  if (lDFlags.frontendShowScans) {
    linksArray.push({
      name: 'Business Scans',
      link: '/scans',
      src: sidebarBusinessScansIcon,
      url: 'scans',
    })
  }
  if (lDFlags.frontendDisplayNewOrder) {
    linksArray.push({
      name: 'Orders',
      link: '/orders',
      url: 'orders',
      src: sidebarOrdersIcon,
    })
  }
  if (roles.includes('yello')) {
    linksArray[0].name = 'My Accounts'
    delete linksArray[0].subLinks
  }
  if (appointmentsSubLinks.length > 0) {
    linksArray.splice(1, 0, {
      name: 'Appointments',
      link: '/demo-requests',
      src: sidebarAppointmentsIcon,
    })
  }

  const isAppointmentLocation = location.pathname === '/new-demo-request'
  const isAccountsLocation =
    location.pathname === '/' || location.pathname === '/all-accounts'

  React.useEffect(() => {
    if (isAppointmentLocation) {
      setActiveItemId(linksArray[1].name)
    }
    if (isAccountsLocation) {
      setActiveItemId(linksArray[0].name)
    }

    // eslint-disable-next-line
  }, [isAppointmentLocation, isAccountsLocation])

  if (!isHeaderVisible) {
    return null
  }

  if (windowWidth > 835) {
    return (
      <Sidebar>
        {isSidebarOpen ? (
          <div
            style={{
              marginTop: '20px',
              width: '244px',
              height: '100%',
            }}
          >
            <div className="d-flex" style={{marginBottom: '22px'}}>
              <div className="toggle-button">
                <Icon
                  onClick={() => setIsSidebarOpen(prev => !prev)}
                  type="regular"
                  variant={'bars'}
                  className={isSidebarOpen ? 'visible' : 'hidden'}
                  style={{
                    margin: '10px 20px 11px 16px',
                    width: '17px',
                  }}
                  color={'gray'}
                  id="sidebar-toggle"
                />
                <Icon
                  onClick={() => setIsSidebarOpen(prev => !prev)}
                  type="regular"
                  variant={'chevronLeft'}
                  className={isSidebarOpen ? 'hidden' : 'visible'}
                  style={{
                    margin: '10px 20px 11px 16px',
                    width: '17px',
                  }}
                  color={'gray'}
                  id="sidebar-toggle"
                />
              </div>
              <Link
                to={'/'}
                onClick={() => setActiveItemId(linksArray[0].name)}
              >
                <img
                  src={thryvSalesLogo}
                  alt="Thryv Sales Logo"
                  style={{height: '40px', marginBottom: '3px'}}
                />
              </Link>
            </div>
            <SidebarNavigationMenu>
              <div
                className="d-flex"
                style={{
                  marginTop: '13px',
                  flexDirection: 'column',
                  position: 'relative',
                  height: 'calc(100% - 100px)',
                }}
              >
                <nav
                  className={
                    isSidebarOpen ? 'sidebar-open' : 'sidebar-collapse'
                  }
                >
                  {linksArray.map((link, i) => {
                    return (
                      <li
                        key={i}
                        className={`nav-link-container${
                          link.name === activeItemId ? 'mb-4' : ''
                        }`}
                      >
                        <NavLink
                          className={`nav-link menu-item ${
                            link.name === activeItemId ? 'active' : ''
                          }`}
                          to={link.link}
                          style={{color: '#6F6F6F'}}
                          onClick={() =>
                            setActiveItemId(
                              link.name === activeItemId ? null : link.name,
                            )
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'center',
                              height: '20px',
                              fontSize: '12px',
                            }}
                          >
                            <img
                              alt={`${link.name} icon`}
                              src={link.src}
                              style={{
                                marginRight: '10px',
                                width: '14px',
                              }}
                              color={'gray'}
                              id="sidebar-toggle"
                            />
                            {link.name}
                          </div>
                        </NavLink>
                      </li>
                    )
                  })}
                </nav>
                <a
                  target="_blank"
                  href="http://help.thryv.info"
                  rel="noreferrer"
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    textDecoration: 'none',
                    margin: '0 auto',
                    width: 'max-content',
                  }}
                >
                  <Button variant="secondary">Open Ticket</Button>
                </a>
              </div>
            </SidebarNavigationMenu>
          </div>
        ) : (
          <div>
            <div
              className="toggle-button"
              style={{marginTop: '46px', marginBottom: '9px'}}
            >
              <Icon
                onClick={() => setIsSidebarOpen(prev => !prev)}
                type="regular"
                variant={'bars'}
                className={isSidebarOpen ? 'hidden' : 'visible'}
                style={{margin: '18px', width: '17px', marginTop: '-16px'}}
                color={'gray'}
                id="sidebar-toggle"
              />
              <Icon
                onClick={() => setIsSidebarOpen(prev => !prev)}
                type="regular"
                variant={'chevronRight'}
                className={isSidebarOpen ? 'visible' : 'hidden'}
                style={{margin: '18px', width: '17px', marginTop: '-16px'}}
                color={'gray'}
                id="sidebar-toggle"
              />
            </div>
            <SidebarNavigationMenu>
              <div
                className="d-flex mt-4"
                style={{
                  flexDirection: 'column',
                  position: 'relative',
                  height: 'calc(100% - 100px)',
                }}
              >
                <nav
                  className={
                    isSidebarOpen ? 'sidebar-open' : 'sidebar-collapse'
                  }
                >
                  {linksArray.map((link, i) => {
                    return (
                      <li
                        key={i}
                        className={`nav-link-container${
                          link.name === activeItemId ? 'mb-4' : ''
                        }`}
                      >
                        <NavLink
                          className={`nav-link menu-item ${
                            link.name === activeItemId ? 'active' : ''
                          }`}
                          to={link.link}
                          style={{color: '#6F6F6F'}}
                          onClick={() =>
                            setActiveItemId(
                              link.name === activeItemId ? null : link.name,
                            )
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'center',
                              height: '20px',
                            }}
                          >
                            <img
                              alt={`${link.name} icon`}
                              src={link.src}
                              style={{
                                width: '17px',
                                margin: '0px 2px',
                              }}
                              color={'gray'}
                              id="sidebar-toggle"
                            />
                          </div>
                        </NavLink>
                      </li>
                    )
                  })}
                </nav>
                <a
                  target="_blank"
                  href="http://help.thryv.info"
                  rel="noreferrer"
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    textDecoration: 'none',
                    margin: '0 auto',
                    width: 'max-content',
                  }}
                >
                  <Icon
                    variant="circleExclamation"
                    type="regular"
                    color="rgba(128,128,128)"
                  />
                </a>
              </div>
            </SidebarNavigationMenu>
          </div>
        )}
      </Sidebar>
    )
  } else {
    return (
      <MobileSidebar>
        {isMobileSidebarOpen && (
          <div
            style={{
              marginTop: '10px',
              width: '244px',
              height: '100%',
            }}
          >
            <div className="d-flex">
              <div className="toggle-button">
                <Icon
                  onClick={() => setIsMobileSidebarOpen(prev => !prev)}
                  type="regular"
                  variant={isMobileSidebarOpen ? 'x' : 'bars'}
                  className={isMobileSidebarOpen ? 'visible' : 'hidden'}
                  style={{
                    margin: '10px 20px 11px 16px',
                    width: '17px',
                  }}
                  color={'gray'}
                  id="sidebar-toggle"
                />
              </div>
              <Link
                to={'/'}
                onClick={() => setActiveItemId(linksArray[0].name)}
              >
                <img
                  src={thryvSalesLogo}
                  alt="Thryv Sales Logo"
                  style={{height: '40px', marginBottom: '3px'}}
                />
              </Link>
            </div>
            <SidebarNavigationMenu>
              <div
                className="d-flex"
                style={{
                  marginTop: '13px',
                  flexDirection: 'column',
                  position: 'relative',
                  height: 'calc(100% - 100px)',
                }}
              >
                <nav
                  className={
                    isMobileSidebarOpen ? 'sidebar-open' : 'sidebar-collapse'
                  }
                >
                  {linksArray.map((link, i) => {
                    return (
                      <li
                        key={i}
                        className={`nav-link-container${
                          link.name === activeItemId ? 'mb-4' : ''
                        }`}
                      >
                        <NavLink
                          className={`nav-link menu-item ${
                            link.name === activeItemId ? 'active' : ''
                          }`}
                          to={link.link}
                          style={{color: '#6F6F6F'}}
                          onClick={() => {
                            setActiveItemId(
                              link.name === activeItemId ? null : link.name,
                            )
                            setIsMobileSidebarOpen(false)
                          }}
                        >
                          <div
                            className="sidebar-toggle"
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'center',
                              height: '20px',
                            }}
                          >
                            <img
                              alt={`${link.name} icon`}
                              src={link.src}
                              style={{
                                marginRight: '10px',
                                width: '14px',
                              }}
                              color={'gray'}
                              id="sidebar-toggle"
                            />
                            {link.name}
                          </div>
                        </NavLink>
                      </li>
                    )
                  })}
                </nav>
                <a
                  target="_blank"
                  href="http://help.thryv.info"
                  rel="noreferrer"
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    textDecoration: 'none',
                    margin: '0 auto',
                    width: 'max-content',
                  }}
                >
                  <Button variant="secondary">Open Ticket</Button>
                </a>
              </div>
            </SidebarNavigationMenu>
          </div>
        )}
      </MobileSidebar>
    )
  }
}

export {SideNavBar}
