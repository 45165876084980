import React, {useState, useEffect} from 'react'
import {Input, Button, Icon, ParagraphText, Select} from '@thryvlabs/maverick'
import {useAuthClient} from '../../utils/use-auth-client'
import {toast} from 'react-toastify'
import {AddOnsDatePickerModal} from '../add-on-components/addons-datepicker-modal'
import {SEO_MBG_PRICES} from '../../utils/constants'
import {DomainValidation} from './domain-validation'

const COUNTRY_KEYS = {
  US: 'USA',
  CA: 'CAN',
  AU: 'AUS',
}

function SEOValidation({
  proposalId,
  setProposalId,
  proposalValidation,
  setProposalValidation,
  seoBillingDate,
  setSeoBillingDate,
  isNonMBG,
  selectedCountry,
  isSEOMBGDisabled,
  setIsSEOMBGDisabled,
  selectedCentersAndApps,
  recommendedSEOPrice,
  convertSeoNg,
  seoDomainConfig,
  setSeoDomainConfig,
}) {
  const [error, setError] = useState(false)
  const [selectedSeoMbgPrice, setSelectedSeoMbgPrice] = useState({
    name: '',
    value: 0,
  })
  const [validateButtonDisabled, setValidateButtonDisabled] = useState(false)
  const client = useAuthClient()

  const validateProposalId = async () => {
    try {
      setValidateButtonDisabled(true)
      const validation = await client(`orders/validate-proposal/${proposalId}`)
      setError(false)
      setProposalValidation(validation)
      recommendedSEOPrice.current = validation.price
      if (validation.isValidProposal) {
        toast.success('Valid proposal ID provided.')
        const seoMbgPrice = {
          name: '$' + String(validation.price),
          value: validation.price,
        }
        setSelectedSeoMbgPrice(seoMbgPrice)
      }
    } catch (error) {
      setProposalValidation(null)
      setError(true)
      if (error.status === 404) {
        toast.error('Error: The proposal ID entered was not found.')
      } else if (error.status === 500) {
        toast.error('Error: The proposal ID entered was not found.')
      }
    }
    setValidateButtonDisabled(false)
  }

  const handleChange = e => {
    setProposalId(e.target.value)
  }

  useEffect(() => {
    if (
      proposalValidation?.price >= recommendedSEOPrice.current &&
      isSEOMBGDisabled
    ) {
      setIsSEOMBGDisabled(false)
    }
  }, [
    isSEOMBGDisabled,
    proposalValidation?.price,
    recommendedSEOPrice,
    setIsSEOMBGDisabled,
  ])

  useEffect(() => {
    if (
      isNonMBG &&
      proposalValidation?.price < recommendedSEOPrice.current &&
      selectedCentersAndApps.centers.find(({code}) => code.includes('seo'))
    ) {
      setIsSEOMBGDisabled(true)
    }
  }, [
    isNonMBG,
    proposalValidation?.price,
    recommendedSEOPrice,
    selectedCentersAndApps.centers,
    setIsSEOMBGDisabled,
  ])

  useEffect(() => {
    if (selectedSeoMbgPrice.value > 0) {
      setProposalValidation({
        ...proposalValidation,
        price: selectedSeoMbgPrice.value,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeoMbgPrice.value])

  const removeSeoProposal = () => {
    setProposalValidation({})
  }

  return (
    <div className="d-flex flex-column" style={{rowGap: '30px'}}>
      <div className="d-flex" style={{columnGap: '5px'}}>
        <div style={{flexGrow: 1, position: 'relative'}}>
          <Input
            required
            type="text"
            placeholder="Enter Proposal ID"
            name="proposalId"
            withLabel
            labelType="static"
            variant="input-isolated"
            onChange={handleChange}
            value={proposalId}
          />
          {!error && proposalValidation?.isValidProposal && (
            <Icon
              type="regular"
              variant="circleCheck"
              style={{
                position: 'absolute',
                right: 5,
                top: 0,
                bottom: 0,
                margin: 'auto 0',
              }}
              color="#16A085"
            />
          )}
          {error && !proposalValidation?.isValidProposal && (
            <Icon
              type="regular"
              variant="circleExclamation"
              style={{
                position: 'absolute',
                right: 5,
                top: 0,
                bottom: 0,
                margin: 'auto 0',
              }}
              color="#DF2A2A"
            />
          )}
        </div>
        <Button
          variant="primary"
          disabled={validateButtonDisabled}
          onClick={validateProposalId}
        >
          Validate
        </Button>
      </div>
      {proposalValidation?.isValidProposal && !error && (
        <>
          <DomainValidation
            domainConfig={seoDomainConfig}
            setDomainConfig={setSeoDomainConfig}
          />
          <div>
            <p className="m-0" style={{color: '#808080', fontSize: '12px'}}>
              Proposal Amount
            </p>
            <Input
              disabled
              value={`$${proposalValidation.price.toFixed(2)}`}
              variant="input-isolated"
            />
          </div>
          <div>
            <div className="d-flex align-items-center">
              <Select
                options={
                  SEO_MBG_PRICES[
                    COUNTRY_KEYS[
                      selectedCountry === 'NZ' ? 'AU' : selectedCountry
                    ]
                  ]
                }
                selectLabel={
                  isNonMBG
                    ? 'SEO Non-Guaranteed Price'
                    : 'SEO Money Back Guarantee Price'
                }
                width="md"
                selectedOption={selectedSeoMbgPrice}
                setSelectedOption={setSelectedSeoMbgPrice}
              />
              <Icon
                style={{cursor: 'pointer', marginTop: '10px'}}
                className="ml-3"
                type="regular"
                onClick={removeSeoProposal}
                variant="circleX"
                color="#FF5000"
                height="27"
                width="27"
              />
            </div>
            {selectedCountry === 'AU' && (
              <ParagraphText
                variant="sm"
                style={{color: '#4D4D4D', marginBottom: 0}}
              >
                This amount does not include GST
              </ParagraphText>
            )}
            {convertSeoNg && (
              <ParagraphText
                variant="reg"
                style={{color: '#4D4D4D', marginTop: '10px'}}
              >
                *Proposal Amount is below recommended level for SEO Money Back
                Guarantee. It will convert to SEO Non-Guaranteed upon adding.
              </ParagraphText>
            )}
          </div>
          <div>
            <ParagraphText variant="reg" className="m-0">
              Do you want to start the billing on a different date?
            </ParagraphText>
            <AddOnsDatePickerModal
              setSelectedDate={setSeoBillingDate}
              billingDate={seoBillingDate}
              maxDate={30}
            />
          </div>
        </>
      )}
    </div>
  )
}

export {SEOValidation}
