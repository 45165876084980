import React, {useState} from 'react'
import {Input, Button, Icon, ParagraphText} from '@thryvlabs/maverick'
import {useAuthClient} from '../../utils/use-auth-client'
import {toast} from 'react-toastify'
import {AddOnsDatePickerModal} from '../add-on-components/addons-datepicker-modal'
import {EaidValidation} from './eaid-validation'

function ThryvLeadsValidation({
  thryvLeadsId,
  setThryvLeadsId,
  thryvLeadsValidation,
  setThryvLeadsValidation,
  thryvLeadsBillingDate,
  setThryvLeadsBillingDate,
  setIsEaidFound,
  isEaidFound,
  cohortEaid,
  setCohortEaid,
  country,
  setAllowInvoice,
  setIsCohort,
  thryvId,
}) {
  const [error, setError] = useState(false)
  const [validateButtonDisabled, setValidateButtonDisabled] = useState(false)
  const client = useAuthClient()

  const validateLeadsId = async () => {
    setValidateButtonDisabled(true)
    const validation = await client(
      `plans/validate-thryv-leads/${thryvLeadsId}`,
    )
    setError(false)
    setThryvLeadsValidation(validation)
    if (validation.passed) toast.success('Valid campaign ID provided.')
    if (!validation.passed) {
      setThryvLeadsValidation(null)
      setError(true)
      toast.error('Error: The campaign ID entered was not found.')
    }
    setValidateButtonDisabled(false)
  }

  const handleChange = e => {
    setThryvLeadsId(e.target.value)
  }

  return (
    <div className="d-flex flex-column" style={{rowGap: '30px'}}>
      <div className="d-flex" style={{columnGap: '5px'}}>
        <div style={{flexGrow: 1, position: 'relative'}}>
          <Input
            required
            type="text"
            placeholder="Enter Leads ID"
            aria-label="Thryv Leads"
            name="thryvLeadsId"
            withLabel
            labelType="static"
            variant="input-isolated"
            onChange={handleChange}
            value={thryvLeadsId}
          />
          {!error && thryvLeadsValidation?.passed && (
            <Icon
              type="regular"
              variant="circleCheck"
              style={{
                position: 'absolute',
                right: 5,
                top: 0,
                bottom: 0,
                margin: 'auto 0',
              }}
              color="#16A085"
            />
          )}
          {error && !thryvLeadsValidation?.passed && (
            <Icon
              type="regular"
              variant="circleExclamation"
              style={{
                position: 'absolute',
                right: 5,
                top: 0,
                bottom: 0,
                margin: 'auto 0',
              }}
              color="#DF2A2A"
            />
          )}
        </div>
        <Button
          variant="primary"
          disabled={validateButtonDisabled}
          onClick={validateLeadsId}
          data-testid="validate-leads-id"
        >
          Validate
        </Button>
      </div>
      {country === 'US' && (
        <EaidValidation
          cohortEaid={cohortEaid}
          isEaidFound={isEaidFound}
          isOptional={true}
          setCohortEaid={setCohortEaid}
          setIsEaidFound={setIsEaidFound}
          setAllowInvoice={setAllowInvoice}
          setIsCohort={setIsCohort}
          thryvId={thryvId}
        />
      )}
      {thryvLeadsValidation?.passed && !error && (
        <div>
          <ParagraphText variant="reg" className="m-0">
            Do you want to start the billing on a different date?
          </ParagraphText>
          <AddOnsDatePickerModal
            setSelectedDate={setThryvLeadsBillingDate}
            billingDate={thryvLeadsBillingDate}
            maxDate={30}
          />
        </div>
      )}
    </div>
  )
}

export {ThryvLeadsValidation}
