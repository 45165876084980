import React from 'react'
import {toast} from 'react-toastify'
import {RatingLabel} from './rating-label'
import {getScoreColor} from '../../utils/get-score-config'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {Icon, ParagraphText, Button, Header} from '@thryvlabs/maverick'

import {
  ScoreResumeContainer,
  CompanyResultsResumeLoader,
  ScoreBar,
  ScoreResumeMainContainer,
  ActionsContainer,
  CompanyInfoContainer,
} from '../ui/business-results'

function ScoreResume({icon, label, scrollTo, score, items}) {
  return (
    <ScoreResumeContainer data-test="company-score-resume">
      <Icon type="regular" variant={icon} height="40" width="35" />
      <div
        className="score__percentage"
        onClick={scrollTo}
        role="button"
        tabIndex={0}
      >
        <RatingLabel label={label} score={score} />
        <ScoreBar score={score} color={getScoreColor(score)}>
          <div role="status" />
        </ScoreBar>
      </div>
      <ul className="score__items">
        {items.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </ScoreResumeContainer>
  )
}

function CompanyResultsResume(props) {
  const {
    outReachOverallScore,
    presenceOverallScore,
    reputationOverallScore,
    companyInfo: {address, city, company, phone, state, zip, website} = {},
    isLoading,
    componentRefs,
    scrollTo,
    businessImage,
  } = props

  const copyRoute = () => {
    toast.info('Copied!')
    navigator.clipboard.writeText(window.location.href)
  }

  if (isLoading) {
    return (
      <CompanyResultsResumeLoader data-testid="company-results-resume-loader">
        <SkeletonTheme color="#e6e6e6" highlightColor="#fff">
          <Skeleton height="100%" width="100%" borderRadius="1rem" />
        </SkeletonTheme>
      </CompanyResultsResumeLoader>
    )
  }
  return (
    <ScoreResumeMainContainer
      className="company__results__resume__container"
      data-testid="company-results-resume"
    >
      <CompanyInfoContainer className="company-info-component">
        {businessImage && <img src={businessImage} alt="company logo" />}
        <Header fontWeight="semibold" variant="h2" className="mt-3">
          <span>{company}</span>
        </Header>
        <div className="company__info">
          <ParagraphText variant="reg" color="thryv-night">
            <Icon type="solid" variant="mapMarker" height="15" width="15" />
            <span>{`${address || '-'}, ${city || '-'}, ${state || '-'} - ${
              zip || '-'
            }`}</span>
          </ParagraphText>
          <ParagraphText variant="reg" color="thryv-night">
            <Icon type="regular" variant="altPhone" height="15" width="15" />
            <span data-test="company-phone">{phone || '-'}</span>
          </ParagraphText>
          {website && (
            <ParagraphText variant="reg" color="thryv-night">
              <Icon type="regular" variant="globe" height="15" width="15" />
              <span data-test="company-website-info">{website}</span>
            </ParagraphText>
          )}
        </div>
      </CompanyInfoContainer>
      <ScoreResume
        icon="award"
        label="Reputation"
        score={reputationOverallScore}
        scrollTo={() => {
          scrollTo(componentRefs.reputationRef)
        }}
        items={['Review Activity', 'Sentiment Analysis', 'Revenue Impact']}
      />
      <ScoreResume
        icon="mapMarker"
        label="Presence"
        score={presenceOverallScore}
        scrollTo={() => {
          scrollTo(componentRefs.presenceRef)
        }}
        items={['Website Analysis', 'Listing Analysis']}
      />
      <ScoreResume
        icon="handshake"
        scrollTo={() => {
          scrollTo(componentRefs.outReachRef)
        }}
        label="Outreach"
        score={outReachOverallScore}
        items={['Review Activity', 'Sentiment Analysis', 'Revenue Impact']}
      />
      <ActionsContainer>
        <Button variant="text" level={1} textVariant="dark" onClick={copyRoute}>
          <Icon type="solid" variant="link" height="15" width="15" />
          <ParagraphText variant="reg" color="thryv-night">
            <span>Share Link</span>
          </ParagraphText>
        </Button>
        <Button
          variant="text"
          level={1}
          textVariant="dark"
          onClick={() => {
            window.print()
          }}
        >
          <Icon type="solid" variant="download" height="15" width="15" />
          <ParagraphText variant="reg" color="thryv-night">
            Download PDF
          </ParagraphText>
        </Button>
      </ActionsContainer>
    </ScoreResumeMainContainer>
  )
}

export {CompanyResultsResume}
