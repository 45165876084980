import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'

export const FooterContainer = styled.footer`
  max-width: 100vw;
  & > span {
    color: #a9a9a9;
    align-self: center;
  }
  @media screen and ${device.tablet} {
    padding: 10px !important;
  }
  @media screen and ${device.mobileL} {
    flex-direction: column;
    & > span {
      margin-bottom: 10px;
    }
  }
  @media print {
    display: none !important;
  }
`
export const FooterLinksContainer = styled.div`
  & a {
    color: #a9a9a9;
    align-self: center;
    text-decoration: none;
  }
  & .with-border {
    border-right: 2px solid #a9a9a9;
  }
  @media screen and ${device.mobileL} {
    & .with-border {
      padding-right: 5px !important;
      margin-right: 5px !important;
    }
  }
`
