import styled from '@emotion/styled'
import {ParagraphText} from '@thryvlabs/maverick'

export const MainContainer = styled.div`
  & .section_title {
    grid-column: 1/3;
    margin: 0;
    text-align: left;
    font-weight: 700;
    &::after {
      content: ' *';
      color: #d8544f;
    }
  }
  & .input-container {
    margin-top: 1.5em;
  }
  & .address_container {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-gap: 10px;
    max-width: 98%;
    & .select div div div div {
      margin-top: 0 !important;
    }
  }
  & .reset-button:hover {
    cursor: pointer;
  }
`
export const BasicInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  width: 100%;
  max-width: 95%;
  grid-gap: 20px;
  box-sizing: border-box;
  & .input_container_email_address {
    display: flex;
    justify-content: space-between;
    & > div {
      width: 70%;
    }
  }
  & .abn-link {
    text-decoration: none;
    color: #ff5000;
    font-weight: 700;
  }
`
export const InvoiceInformationContainer = styled.div`
  grid-column: 1/3;
  width: 96%;
  margin: 0 auto;
  min-height: 400px;

  & .prompt_container {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background-color: #fff9e9;
    border-width: 1px;
    border-color: #fddf95;
    border-radius: 4px;
  }

  & .pill_container {
    max-width: min-content;
    max-height: min-content;
    white-space: nowrap;
    padding: 2px 8px;
    border-radius: 22px;
  }

  & .table_data-text {
    font-size: 12px;
    color: #4d4d4d;
    font-weight: bold;
  }
`

export const PillContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  & .pill {
    background-color: ${props => props.backgroundColor};
    max-width: min-content;
    max-height: min-content;
    white-space: nowrap;
    padding: 2px 8px;
    border-radius: 22px;
  }
`

export const TableTitle = styled(ParagraphText)`
  color: #808080;
  font-weight: bold;
  padding-left: ${props => (props.index === 0 ? '20px' : 0)};
  text-align: ${props =>
    props.index === props.length - 1
      ? 'right'
      : props.index === 0
      ? 'left'
      : 'center'};
  font-size: 14px;
  padding-right: ${props => (props.index === props.length - 1 ? '20px' : 0)};
`
