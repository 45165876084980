import React from 'react'
import {ParagraphText, Button, Icon} from '@thryvlabs/maverick'
import {thryvGuyExcited, thryvGuySparklers} from '../assets'
import {useNavigate, useLocation} from 'react-router-dom'
import {headerContext} from '../context/header-context'
import {WizardContainer, MainContainer} from '../components/ui/new-order'
import dayjs from 'dayjs'
import {useFlags} from 'launchdarkly-react-client-sdk'

const benefits = [
  'Appoint a Thryv Success Manager',
  'Go Mobile! Download our App',
  'Update, fix & lock your listings across the web',
  'Build out your CRM',
]

function getFormattedTime(dateStr) {
  return dayjs(dateStr.startDateTime).tz(dateStr.timeZoneId).format('h:mm A z')
}
function getFormattedDate(dateStr) {
  return dayjs(dateStr.startDateTime)
    .tz(dateStr.timeZoneId)
    .format('D MMM YYYY')
}

const Benefit = ({benefit}) => (
  <div className="d-flex align-items-center" style={{columnGap: '12px'}}>
    <Icon
      type="regular"
      variant="check"
      color="#16A085"
      width="11px"
      height="8px"
    />
    <ParagraphText variant="reg" className="m-0" color="#4D4D4D">
      {benefit}
    </ParagraphText>
  </div>
)

const Appointment = ({appointment, title}) => (
  <div
    className="d-flex flex-column w-100"
    style={{
      borderRadius: '4px',
      border: '1px solid #F5BC9E',
      padding: '12px',
      rowGap: '4px',
    }}
  >
    <ParagraphText
      variant="reg"
      className="m-0"
      style={{fontWeight: 600}}
      color="#231F20"
    >
      {title}
    </ParagraphText>
    <ParagraphText variant="reg" className="m-0" style={{fontSize: '14px'}}>
      Your call is scheduled on{' '}
      <span style={{color: '#3D5199'}}>
        {getFormattedDate(appointment)} at {getFormattedTime(appointment)}
      </span>
    </ParagraphText>
  </div>
)

const Container = ({children, frontendSideNav}) => {
  if (frontendSideNav) {
    return <div className="d-flex">{children}</div>
  }
  return children
}

const Success = () => {
  const {isHeaderVisible, setIsHeaderVisible} = React.useContext(headerContext)
  const navigate = useNavigate()
  const location = useLocation()

  function navigateTo(url) {
    navigate(url)
  }
  const clientEmailAddress = location.state?.email || null
  const flow = location.state?.flow ? location.state.flow : null
  const onboardingCallDate = location.state?.onboardingCallDate ?? null
  const creativeCallDate = location.state?.creativeCallDate ?? null
  const isSEOIncluded = location.state?.isSEOIncluded ?? false
  const isCC = location.state?.isCC ?? false
  const softwareUpgraded = location.state?.softwareUpgraded ?? false
  const isKickoffScheduled = location.state?.onboardingCallDate ?? false
  const {frontendDisplayNewOrder, frontendSideNav} = useFlags()

  const upgradeSEOContent = flow === 'upgrade' && isSEOIncluded
  const upgradeSoftwareContent =
    flow === 'upgrade' && !isSEOIncluded && softwareUpgraded
  React.useEffect(() => {
    if (!isHeaderVisible) {
      setIsHeaderVisible(true)
      if (frontendSideNav) {
        let elem = document.getElementById('app-container')
        elem.style.gridTemplateAreas =
          '"header header" "sidebar main-content" "sidebar footer"'
      }
    }
  }, [isHeaderVisible, setIsHeaderVisible, frontendSideNav])

  if (!isCC) {
    return (
      <section className="container mt-5">
        <h1
          className="text-center font-open-sans font-normal mb-4 pt-5"
          style={{
            fontSize: '35px',
            fontWeight: '600',
            color: 'rgb(111, 111, 111)',
          }}
        >
          Thank you for purchasing Thryv!
        </h1>

        <div className="d-flex justify-content-center align-content-center mb-3">
          <img className="mx-4 my-3" src={thryvGuyExcited} alt="Thryv Guy" />
        </div>
        <div className="d-flex justify-content-center align-content-center mb-4">
          <ParagraphText
            className="d-flex"
            variant="lg"
            color="thryv-gray-medium-500"
            style={{
              fontSize: '18px',
              fontWeight: '600',
              marginLeft: '60px',
              marginRight: '60px',
              alignContent: 'center',
            }}
          >
            {clientEmailAddress ? (
              <span>
                Thank you for purchasing Thryv. We have sent an email to&nbsp;
                <span style={{color: '#FF5000', fontWeight: '900'}}>
                  {clientEmailAddress}
                </span>
                &nbsp;with your login instructions!
              </span>
            ) : (
              <span>
                You have successfully upgraded the client&apos;s account!
              </span>
            )}
          </ParagraphText>
        </div>
        {clientEmailAddress && frontendDisplayNewOrder ? (
          <div className="d-flex justify-content-center align-content-center mb-2">
            <Button
              className=""
              type="button"
              variant="primary"
              onClick={() => navigateTo('/order/new')}
            >
              New Order
            </Button>
          </div>
        ) : null}
        <div className="d-flex justify-content-center align-content-center mb-2">
          {clientEmailAddress ? (
            <Button
              className=""
              type="button"
              variant="secondary"
              onClick={() => navigateTo('/all-accounts  ')}
            >
              Back to All Accounts
            </Button>
          ) : (
            <Button
              className=""
              type="button"
              variant="primary"
              onClick={() => navigateTo('/all-accounts  ')}
            >
              Back to All Accounts
            </Button>
          )}
        </div>
      </section>
    )
  }
  return (
    <Container frontendSideNav={frontendSideNav}>
      <MainContainer>
        <WizardContainer>
          <div className="d-flex flex-column" style={{rowGap: '16px'}}>
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{rowGap: '16px'}}
            >
              <img
                style={{width: '160px', height: '160px'}}
                src={thryvGuySparklers}
                alt="Thryv Guy"
              />
              <ParagraphText
                variant="lg"
                style={{fontWeight: 600, fontSize: '18px'}}
                className="m-0"
              >
                Thank you for purchasing Thryv!
              </ParagraphText>
              <ParagraphText
                className="d-flex m-0"
                variant="reg"
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                }}
              >
                <span>
                  We have sent an email with your login instructions to&nbsp;
                  <span style={{color: '#FF5000'}}>{clientEmailAddress}</span>
                </span>
              </ParagraphText>
            </div>

            <div
              style={{width: '800px', margin: '0 auto', rowGap: '16px'}}
              className="d-flex flex-column"
            >
              {upgradeSoftwareContent && (
                <ParagraphText
                  variant="lg"
                  style={{color: '#4D4D4D'}}
                  className="m-0 mt-5 mb-5 font-weight-bold"
                >
                  Looks like you’ve had a recent change in your software
                  offerings. Not all of the businesses we support need
                  additional help or training on the new tools, but there may be
                  some things that would be best reviewed with a Software
                  expert! We&apos;ll take a look at what you&apos;ve got going
                  on, and if we think you may benefit from some additional
                  information, we&apos;ll reach out!
                </ParagraphText>
              )}

              {(onboardingCallDate || creativeCallDate) && (
                <>
                  <div
                    className="d-flex align-items-center"
                    style={{columnGap: '12px'}}
                  >
                    <Icon
                      type="regular"
                      variant="calendarClock"
                      height="20px"
                      width="22px"
                      color="#FF5000"
                    />
                    <ParagraphText
                      variant="reg"
                      style={{fontWeight: 600, fontSize: '16px'}}
                      className="m-0"
                    >
                      Upcoming Appointments
                    </ParagraphText>
                  </div>

                  <div className="d-flex" style={{columnGap: '20px'}}>
                    {onboardingCallDate && (
                      <Appointment
                        appointment={onboardingCallDate}
                        title="Thryv Kickoff Call"
                      />
                    )}
                    {creativeCallDate && (
                      <Appointment
                        appointment={creativeCallDate}
                        title="Thryv Creative Call"
                      />
                    )}
                  </div>
                </>
              )}

              {upgradeSEOContent && !isKickoffScheduled && (
                <ParagraphText
                  variant="lg"
                  style={{color: '#4D4D4D'}}
                  className="m-0 mt-5 mb-5 font-weight-bold"
                >
                  We&apos;re so excited to partner with you in your Search
                  Engine Optimization efforts! We work with experts to build
                  your program to ensure we are targeting the appropriate area
                  and people with whom you want to do business. We&apos;ll
                  connect with you soon to schedule time to get you started.
                </ParagraphText>
              )}

              <div className="d-flex flex-column" style={{rowGap: '8px'}}>
                <ParagraphText
                  variant="reg"
                  className="m-0"
                  style={{fontWeight: 600}}
                >
                  To ensure you get the most value from Thryv we will
                </ParagraphText>
                {benefits.map((benefit, index) => (
                  <Benefit key={index} benefit={benefit} />
                ))}
              </div>
            </div>

            <div
              className="d-flex justify-content-end"
              style={{
                margin: '203px 0 25px 0',
                paddingRight: '24px',
                columnGap: '10px',
              }}
            >
              <Button
                variant="secondary"
                onClick={() => navigate('/my-accounts')}
              >
                Back to Accounts
              </Button>
              {frontendDisplayNewOrder && (
                <Button
                  variant="primary"
                  onClick={() => navigate('/order/new')}
                >
                  New Order
                </Button>
              )}
            </div>
          </div>
        </WizardContainer>
      </MainContainer>
    </Container>
  )
}

export {Success}
