/**
@jsxRuntime
classic */
/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Routes, Route} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {BusinessResults} from './screens/business-results'
import {BusinessScan} from './screens/business-form'
import {BusinessScans} from './screens/business-scans'
import {DemoRequests} from './screens/demo-requests'
import {Appointments} from './screens/appointments'
import {ProductShowcase} from './screens/product-showcase'
import {MyAccounts} from './screens/my-accounts'
import {AllAccounts} from './screens/all-accounts'
import {ToolsPage} from './screens/tools-page'
import {NewDemo} from './screens/new-demo'
import {HeaderTitle} from './components/header-title'
import {Orders} from './screens/orders'
import NotFoundPage from './components/not-found-page'
import {NewOrder} from './screens/new-order'
import {UpgradeOrder} from './screens/upgrade-order'
import {NewOrderShortCut} from './screens/new-order-short-cut'
import {PackageRecommendation} from './screens/package-recommendation'
import {PackageResult} from './screens/package-result'
import {FeaturesTable} from './screens/feature-summary'
import {Success} from './screens/success'
import {Support} from './screens/support'
import {Invitations} from './screens/invitations'
import Admin from './screens/admin'
import {SalesCollateral} from './screens/sales-collateral'
import {
  ErrorContainer,
  TextContainer,
  ImageContainer,
  ItemContainer,
  ScreenContainer,
} from './components/ui/error-fallback'
import {thryvGuyConfused} from './assets'
import {ParagraphText, Button} from '@thryvlabs/maverick'
import {useFlags} from 'launchdarkly-react-client-sdk'

function FullPageErrorFallback({error}) {
  const handleClick = () => window.location.reload()

  return (
    <ScreenContainer>
      <ErrorContainer>
        <ItemContainer>
          <TextContainer>
            <ParagraphText
              variant="reg"
              style={{
                color: '#ff5101',
                fontWeight: 'bold',
                fontSize: '2rem',
                marginBottom: '20px',
              }}
            >
              Uh Oh!
            </ParagraphText>
            <ParagraphText
              variant="reg"
              className="m-0"
              style={{fontWeight: 'bold', fontSize: '1rem'}}
            >
              There&apos;s a problem. Try refreshing the app.
            </ParagraphText>
          </TextContainer>
          <Button
            onClick={handleClick}
            variant="primary"
            style={{marginTop: '50px'}}
          >
            Refresh Now
          </Button>
        </ItemContainer>
        <ImageContainer>
          <img src={thryvGuyConfused} alt="confused Thryv Guy" />
        </ImageContainer>
      </ErrorContainer>
    </ScreenContainer>
  )
}

function AuthenticatedApp() {
  const lDFlags = useFlags()
  return (
    <Sentry.ErrorBoundary fallback={FullPageErrorFallback}>
      <Routes>
        <Route
          path="/orders"
          element={
            <HeaderTitle title="Orders">
              <Orders />
            </HeaderTitle>
          }
        />
        <Route
          path="/feature-summary"
          element={
            <HeaderTitle title="Feature Summary">
              <FeaturesTable />
            </HeaderTitle>
          }
        />
        {lDFlags.frontendShowScans && (
          <Route
            path="/scan-form"
            element={
              <HeaderTitle title="Business Scan">
                <BusinessScan />
              </HeaderTitle>
            }
          />
        )}
        <Route
          path="/package-recommendation"
          element={
            <HeaderTitle title="Package Recommendation">
              <PackageRecommendation />
            </HeaderTitle>
          }
        />
        <Route
          path="/package-result/:selectedPackage"
          element={
            <HeaderTitle title="Package Result">
              <PackageResult />
            </HeaderTitle>
          }
        />
        {lDFlags.frontendShowScans && (
          <Route
            path="/scan-form/:recordId"
            element={
              <HeaderTitle title="Business Scan">
                <BusinessScan />
              </HeaderTitle>
            }
          />
        )}
        {lDFlags.frontendShowScans && (
          <Route
            path="/scan-results/:recordId"
            element={
              <HeaderTitle title="Business Results">
                <BusinessResults />
              </HeaderTitle>
            }
          />
        )}
        {lDFlags.frontendShowScans && (
          <Route
            path="/scans"
            element={
              <HeaderTitle title="Business Scans">
                <BusinessScans />
              </HeaderTitle>
            }
          />
        )}
        <Route
          path="/demo-requests"
          element={
            <HeaderTitle title="Demo Requests">
              <DemoRequests />
            </HeaderTitle>
          }
        />
        <Route
          path="/appointments"
          element={
            <HeaderTitle title="Appointments">
              <Appointments />
            </HeaderTitle>
          }
        />
        <Route
          path="/productshowcase"
          element={
            <HeaderTitle title="Product Showcase">
              <ProductShowcase />
            </HeaderTitle>
          }
        />
        <Route
          path="/my-accounts"
          element={
            <HeaderTitle title="My Accounts">
              <MyAccounts />
            </HeaderTitle>
          }
        />
        <Route
          path="/all-accounts"
          element={
            <HeaderTitle title="All Accounts">
              <AllAccounts />
            </HeaderTitle>
          }
        />
        <Route
          path="/tools"
          element={
            <HeaderTitle title="Tools">
              <ToolsPage />
            </HeaderTitle>
          }
        />
        <Route
          path="/new-demo-request"
          element={
            <HeaderTitle title="New Demo Request">
              <NewDemo />
            </HeaderTitle>
          }
        />
        <Route
          path="/"
          element={
            <HeaderTitle title="My Accounts">
              <MyAccounts />
            </HeaderTitle>
          }
        />
        <Route
          path="/order/new"
          element={
            <HeaderTitle title="New Order">
              <NewOrder />
            </HeaderTitle>
          }
        />
        <Route
          path="/order/upgrade"
          element={
            <HeaderTitle title="Upgrade Order">
              <UpgradeOrder />
            </HeaderTitle>
          }
        />
        <Route
          path="/new-order"
          element={
            <HeaderTitle title="New Order">
              <NewOrderShortCut />
            </HeaderTitle>
          }
        />
        <Route
          path="/success"
          element={
            <HeaderTitle title="Success">
              <Success />
            </HeaderTitle>
          }
        />
        <Route
          path="/support"
          element={
            <HeaderTitle title="Support">
              <Support />
            </HeaderTitle>
          }
        />
        <Route
          path="/invitations"
          element={
            <HeaderTitle title="Invitations">
              <Invitations />
            </HeaderTitle>
          }
        />
        {lDFlags.frontEndUtilityPage && (
          <Route
            path="/admin"
            element={
              <HeaderTitle title="Admin">
                <Admin />
              </HeaderTitle>
            }
          />
        )}
        <Route
          path="*"
          element={
            <HeaderTitle title="404 - Not Found">
              <NotFoundPage />
            </HeaderTitle>
          }
        />
        <Route path="/sales-collateral" element={<SalesCollateral />} />
      </Routes>
    </Sentry.ErrorBoundary>
  )
}

export default AuthenticatedApp
