import * as React from 'react'
import {Routes, Route} from 'react-router-dom'
import {BusinessResults} from './screens/business-results'
import {LoginRedirect} from './login-redirect-app'
import {HeaderTitle} from './components/header-title'
import {PaymentInformation} from './screens/payment-information'
import NotFoundPage from './components/not-found-page'
import {ToastContainer} from 'react-toastify'

function UnauthenticatedApp() {
  return (
    <Routes>
      <Route path="/scan-results/:recordId" element={<BusinessResults />} />
      <Route
        path="/payment-information/:randomId"
        element={
          <HeaderTitle title="Payment information">
            <PaymentInformation />
            <ToastContainer />
          </HeaderTitle>
        }
      />
      <Route path="/payment-information" element={<NotFoundPage />} />
      <Route path="*" element={<LoginRedirect />} />
    </Routes>
  )
}

export default UnauthenticatedApp
