import React, {useEffect} from 'react'
import {
  Button,
  Icon,
  ParagraphText,
  Checkbox,
  Select,
  Input,
} from '@thryvlabs/maverick'
import rawCountryStates from 'iso3166-2-db/i18n/en'
import {TaxesPromptContainer} from '../ui/payment-information'

const InvoicePaymentForm = ({
  calculateTaxes,
  calculatingTaxes,
  setCalculatingTaxes,
  isMobile = false,
  checkBoxesValues,
  handleOnCheckBoxChange,
  setName,
  businessAddress,
  country,
  handleCompleteOrder,
  setIsAddressUpdated,
  bizphone,
  is5Order,
  isAddressUpdated,
  uuid,
  addressInformation,
  setAddressInformation,
  updateParentAddress,
}) => {
  const [selectedState, setSelectedState] = React.useState({
    name: '',
    value: '',
  })
  const [listOfStates, setListOfStates] = React.useState([])
  const [isTaxesButtonEnabled, setIsTaxesButtonEnabled] = React.useState(true)
  const formRef = React.useRef()
  const [stateLabel, setStateLabel] = React.useState('State')
  const {useBusinessAddressInfo} = checkBoxesValues
  const selectedCountry = {name: 'United States', value: 'US'}
  const isSaveInfoButtonDisabled = !checkBoxesValues.terms

  const handleOnchange = ({target: {value, name}}) => {
    setAddressInformation(prevState => ({
      ...prevState,
      [name]: value,
    }))
    setIsTaxesButtonEnabled(true)
  }

  useEffect(() => {
    if (selectedState.value !== '') {
      handleOnchange({target: {value: selectedState.value, name: 'state'}})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState.value])

  React.useEffect(() => {
    const getStates = () => {
      const states = rawCountryStates[selectedCountry.value].regions
        .map(({iso, name}) => ({
          name,
          value: iso,
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1))
      setListOfStates(states)
    }
    if (selectedCountry.value) {
      getStates(selectedCountry.value)
    }
    if (['US', 'AU', 'NZ'].includes(selectedCountry.value)) {
      setStateLabel('State')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (useBusinessAddressInfo) {
      const state = listOfStates.find(
        ({value}) => value === businessAddress?.state,
      )
      setSelectedState(state)
      if (checkBoxesValues.updateBillingAddress) {
        let fakeEvent = {target: {name: 'updateBillingAddress'}}
        handleOnCheckBoxChange(fakeEvent)
      }
      setAddressInformation({...businessAddress})
    } else {
      setSelectedState({
        name: '',
        value: '',
      })
      setAddressInformation({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        postalCode: '',
        state: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useBusinessAddressInfo, businessAddress, listOfStates])
  if (isMobile) {
    return (
      <form ref={formRef}>
        <>
          <ParagraphText
            variant="lg"
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              margin: '36px 0 0 0',
            }}
          >
            Billing Address
          </ParagraphText>
          <ParagraphText
            variant="sm"
            style={{fontSize: '14px', margin: '12px 0 0 0'}}
          >
            We will mail the invoice to this address, please confirm that it is
            correct or make updates if needed.
          </ParagraphText>
        </>
        {is5Order && isAddressUpdated && (
          <TaxesPromptContainer>
            <Icon type="regular" variant="infoCircle" color="#057AFF" />
            <ParagraphText variant="reg" className="m-0">
              The current estimated taxes are based on your business address.
            </ParagraphText>
          </TaxesPromptContainer>
        )}
        <div
          className="d-flex align-items-center mt-3 mb-3"
          style={{columnGap: '8px'}}
        >
          <Checkbox
            name="useBusinessAddressInfo"
            value={checkBoxesValues.useBusinessAddressInfo}
            defaultChecked={checkBoxesValues.useBusinessAddressInfo}
            onChange={handleOnCheckBoxChange}
          />
          <ParagraphText variant="reg" style={{margin: '0 0 0.7rem 0'}}>
            Same as my business address
          </ParagraphText>
        </div>
        <div
          className="d-flex"
          style={{flexDirection: 'column', rowGap: '15px'}}
        >
          <Input
            className="w-100 mb-2"
            variant="default"
            onChange={handleOnchange}
            data-recurly="first_name"
            placeholder="First Name"
            name="firstName"
            value={addressInformation.firstName}
          />
          <Input
            className="w-100 mb-2"
            variant="default"
            data-recurly="last_name"
            placeholder="Last Name"
            name="lastName"
            value={addressInformation.lastName}
            onChange={handleOnchange}
          />
          <Input
            variant="default"
            labelType="floating"
            className="w-100 mb-2"
            withLabel
            type="text"
            data-recurly="company"
            placeholder="Company"
            name="company"
            value={addressInformation.company}
            onChange={handleOnchange}
          />
          <Input
            variant="default"
            labelType="floating"
            className="w-100 mb-2"
            withLabel
            type="text"
            data-recurly="address1"
            placeholder="Street Address"
            name="address"
            value={addressInformation.address}
            onChange={handleOnchange}
          />
          <Input
            variant="default"
            labelType="floating"
            withLabel
            className="w-100 mb-2"
            type="text"
            data-recurly="city"
            placeholder="City"
            name="city"
            value={addressInformation.city}
            onChange={handleOnchange}
          />
          <div className="d-flex" style={{columnGap: '8px', marginTop: '-5px'}}>
            <Select
              options={listOfStates}
              selectLabel={stateLabel}
              width="full"
              name="state"
              data-recurly="state"
              selectedOption={selectedState}
              setSelectedOption={setSelectedState}
            />
            <input
              hidden
              readOnly
              value={selectedState?.value}
              type="text"
              name="state"
              className="recurly-element"
              data-recurly="state"
              placeholder="State"
            />
            <div style={{marginTop: '7px'}}>
              <Input
                variant="default"
                labelType="floating"
                withLabel
                className="w-100"
                type="text"
                data-recurly="postal_code"
                placeholder="Postal Code"
                name="postalCode"
                value={addressInformation.postalCode}
                onChange={handleOnchange}
              />
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center mb-4 mt-4"
          style={{
            position: 'absolute',
            left: is5Order ? '540px' : 0,
            right: 0,
            marginLeft: is5Order ? '0' : 'auto',
            marginRight: is5Order ? '0' : 'auto',
            bottom: is5Order ? '-50px' : '50px',
          }}
        >
          <div>
            <Checkbox
              hasLabel
              name="agreedTerms"
              value={checkBoxesValues.agreedTerms}
              defaultChecked={checkBoxesValues.agreedTerms}
              onChange={handleOnCheckBoxChange}
              label={
                <label style={{marginTop: '5px'}}>
                  By checking this box, you agree to our{' '}
                  <a
                    target="_blank"
                    href="https://corporate.thryv.com/terms/"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </label>
              }
            />
            <ParagraphText variant="sm">
              By processing your payment, you agree that your personal
              information will be shared with Thryv’s payment processor—Adyen,
              N.V. and will be subject to Adyen’s{' '}
              <a
                target="_blank"
                href="https://www.adyen.com/policies-and-disclaimer/privacy-policy"
                rel="noreferrer"
              >
                privacy statement
              </a>
              .
            </ParagraphText>
          </div>
        </div>
        <Button
          variant="primary"
          onClick={() => {
            updateParentAddress(
              addressInformation,
              bizphone,
              handleCompleteOrder,
            )
          }}
          disabled={
            calculatingTaxes ||
            isSaveInfoButtonDisabled ||
            !isTaxesButtonEnabled ||
            !checkBoxesValues?.agreedTerms
          }
        >
          Continue
        </Button>
      </form>
    )
  } else {
    return (
      <form onSubmit={updateParentAddress} ref={formRef}>
        <>
          <ParagraphText
            variant="lg"
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              margin: '36px 0 0 0',
            }}
          >
            Billing Address
          </ParagraphText>
          <ParagraphText
            variant="sm"
            style={{fontSize: '14px', margin: '12px 0 0 0'}}
          >
            We will mail the invoice to this address, please confirm that it is
            correct or make updates if needed.
          </ParagraphText>
        </>
        {is5Order && isAddressUpdated && (
          <TaxesPromptContainer>
            <Icon type="regular" variant="infoCircle" color="#057AFF" />
            <ParagraphText variant="reg" className="m-0">
              The current estimated taxes are based on your business address.
            </ParagraphText>
          </TaxesPromptContainer>
        )}
        <div
          className="d-flex align-items-center mt-3 mb-1"
          style={{columnGap: '8px'}}
        >
          <Checkbox
            name="useBusinessAddressInfo"
            value={checkBoxesValues.useBusinessAddressInfo}
            defaultChecked={checkBoxesValues.useBusinessAddressInfo}
            onChange={handleOnCheckBoxChange}
          />
          <ParagraphText variant="reg" style={{margin: '0 0 0.7rem 0'}}>
            Same as my business address
          </ParagraphText>
        </div>
        <div className="d-flex justify-content-between">
          <input
            style={{width: ' 49%'}}
            onChange={handleOnchange}
            className="recurly-element recurly-input"
            data-recurly="first_name"
            placeholder="First Name"
            name="firstName"
            value={addressInformation.firstName}
          />
          <input
            style={{width: '49%'}}
            className="recurly-element recurly-input"
            data-recurly="last_name"
            placeholder="Last Name"
            name="lastName"
            value={addressInformation.lastName}
            onChange={handleOnchange}
          />
        </div>
        <input
          type="text"
          className="recurly-element recurly-address h-45"
          data-recurly="company"
          placeholder="Company"
          style={{
            fontSize: '18px',
          }}
          name="company"
          value={addressInformation.company}
          onChange={handleOnchange}
        />
        <input
          type="text"
          className="recurly-element recurly-address h-45"
          data-recurly="address1"
          placeholder="Street Address"
          style={{
            fontSize: '18px',
          }}
          name="address"
          value={addressInformation.address}
          onChange={handleOnchange}
        />
        <div
          className={`input-container ${!is5Order && 'pr-1 address_container'}`}
          style={
            is5Order
              ? {
                  display: 'grid',
                  gridTemplateColumns: '30% 35% 31%',
                  columnGap: '10px',
                  width: '100%',
                }
              : {}
          }
        >
          <input
            type="text"
            className="recurly-element h-45"
            data-recurly="city"
            placeholder="City"
            style={{
              fontSize: '18px',
            }}
            name="city"
            value={addressInformation.city}
            onChange={handleOnchange}
          />
          <Select
            options={listOfStates}
            selectLabel={stateLabel}
            width="full"
            className="recurly-element recurly-address h-45"
            data-recurly="state"
            selectedOption={selectedState}
            setSelectedOption={setSelectedState}
          />
          <input
            hidden
            readOnly
            value={selectedState?.value}
            type="text"
            className="recurly-element"
            data-recurly="state"
            placeholder="State"
          />
          <input
            type="text"
            className="recurly-element h-45"
            data-recurly="postal_code"
            placeholder="Postal Code"
            style={{
              fontSize: '18px',
            }}
            name="postalCode"
            value={addressInformation.postalCode}
            onChange={handleOnchange}
          />
        </div>
        <div
          className="d-flex justify-content-center mb-4 mt-4"
          style={{
            position: 'absolute',
            left: is5Order ? '540px' : 0,
            right: 0,
            marginLeft: is5Order ? '0' : 'auto',
            marginRight: is5Order ? '0' : 'auto',
            bottom: is5Order ? '-50px' : '40px',
          }}
        >
          <Button
            variant="primary"
            onClick={() => {
              updateParentAddress(
                addressInformation,
                bizphone,
                handleCompleteOrder,
              )
            }}
            disabled={
              calculatingTaxes ||
              isSaveInfoButtonDisabled ||
              !isTaxesButtonEnabled
            }
          >
            Update Billing Information
          </Button>
        </div>
      </form>
    )
  }
}

export {InvoicePaymentForm}
