import React, {useContext} from 'react'
import {Button, Icon} from '@thryvlabs/maverick'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {headerContext} from '../context/header-context'
import {OldHeader} from './header-components/old-header'
import {UserDropdown} from './header-components/user-dropdown'
import {useNavigate, useLocation} from 'react-router'
import {useWindowDimensions} from '../utils/use-window-dimensions'
import {inviteModalContext} from '../context/invite-modal-context'

import {HeaderContainer} from './ui/header'

function Header({isMobileSidebarOpen, setIsMobileSidebarOpen, isSidebarOpen}) {
  const {isHeaderVisible} = useContext(headerContext)
  const {setIsInviteModalOpen} = useContext(inviteModalContext)
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const {width: windowWidth} = useWindowDimensions()
  const {frontEndUtilityPage, frontendSideNav, frontendDisplayNewOrder} =
    useFlags()

  const isMobile = windowWidth < 835
  const routeButtonData = {
    orders: {
      route: '/order/new',
      text: 'New Order',
      condition: frontendDisplayNewOrder,
    },
    'demo-requests': {
      route: '/new-demo-request',
      text: 'New Demo',
      condition: true,
    },
    invitations: {
      text: 'New Invite',
      action: () => setIsInviteModalOpen(true),
      condition: true,
    },
  }
  const buttonInfo = routeButtonData[pathname.split('/')[1]]

  const handleButtonClick = () => {
    const {route, action} = buttonInfo
    if (route) {
      navigate(route)
    }
    if (action) {
      action()
    }
  }

  if (!isHeaderVisible) {
    return null
  }

  if (!frontendSideNav) {
    return <OldHeader />
  }

  return (
    <HeaderContainer
      className="header pr-5 d-flex border-b border-thryv-gray-light-400 justify-content-between"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        paddingLeft: isSidebarOpen ? '100px' : '80px',
      }}
    >
      <div className="d-flex align-items-center">
        {isMobile && buttonInfo && buttonInfo?.condition && (
          <Button
            variant="primary"
            className="mr-4 ml-3"
            onClick={handleButtonClick}
          >
            {buttonInfo.text}
          </Button>
        )}
        <UserDropdown isRedirectEnabled={frontEndUtilityPage} />
      </div>
      {!isMobile && buttonInfo && buttonInfo?.condition && (
        <div
          style={{
            paddingLeft: isSidebarOpen ? '180px' : '0px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button variant="primary" onClick={handleButtonClick}>
            {buttonInfo.text}
          </Button>
        </div>
      )}
      {isMobile && (
        <div
          className="d-flex align-items-center"
          style={{marginLeft: '-30px'}}
        >
          <Icon
            className="toggle-button"
            onClick={() => setIsMobileSidebarOpen(prev => !prev)}
            type="regular"
            variant={isMobileSidebarOpen ? 'x' : 'bars'}
            style={{
              width: '17px',
            }}
            color={'gray'}
            id="sidebar-toggle"
          />
        </div>
      )}
    </HeaderContainer>
  )
}

export {Header}
