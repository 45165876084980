/**
@jsxRuntime
classic */
/** @jsx jsx */
import React from 'react'
import Moment from 'moment'
import {useState} from 'react'
import NumberFormat from 'react-number-format'
import {useNavigate} from 'react-router-dom'
import {jsx} from '@emotion/core'
import {Button, Select} from '@thryvlabs/maverick'

import {ResultsTable} from '../components/results-table'
import {
  ResultsLayoutContainer,
  ResultsLayoutHeader,
} from '../components/ui/results-layout'
import {HealthScore} from '../components/health-score'
import {DetailsModal} from '../components/details-modal'

import {useAsync} from '../utils/use-async'
import {useAuthClient} from '../utils/use-auth-client'
import {useUserRoles} from '../utils/use-user-roles'
import {SearchButton} from '../components/search-button'
import {Spinner} from '../components/loader-spinner'
import {TabOptions} from '../components/tab-options'
import {useFlags} from 'launchdarkly-react-client-sdk'

const OPTIONS = [
  {
    name: 'Company',
    value: 'company',
  },
  {
    name: 'Thryv ID',
    value: 'thryv_id',
  },
  {
    name: 'EID',
    value: 'salesforce_id',
  },
  {
    name: 'Command Center ID',
    value: 'cc_id',
  },
]

function Container({children, frontendSideNav}) {
  if (frontendSideNav) {
    return (
      <div>
        <TabOptions screen={'accounts'} />
        {children}
      </div>
    )
  }
  return children
}

function MyAccounts() {
  const firstRowRef = React.useRef(null)
  const [selectedOption, setSelectedOption] = useState({
    name: 'Company',
    value: 'company',
  })
  const client = useAuthClient()
  const {data, setData, isError, error} = useAsync()
  const {roles} = useUserRoles()
  const navigate = useNavigate()
  const [offSet, setOffSet] = useState(0)
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [searchParam, setSearchParam] = useState('')
  const [loadingMore, setLoadingMore] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {frontendSideNav} = useFlags()

  const formattedRoles = roles.map(role =>
    role.toLowerCase().replace(/\s/g, ''),
  )
  const IS_YELLO_USER = formattedRoles.includes('yello')
  const IS_AUSTRALIA_USER = formattedRoles.includes('australia')
  const COLUMNS_TO_DISPLAY =
    IS_YELLO_USER || IS_AUSTRALIA_USER
      ? ['Company', 'Client Name', 'Email', 'Phone']
      : ['Company', 'Thryv Id', 'Date Created']

  const searchAccounts = async (term, offSet) => {
    let response
    if (term) {
      response = await client(
        `accounts/mine?offset=${offSet}&${selectedOption.value}=${term}`,
      )
    } else {
      response = await client(`accounts/mine?offset=${offSet}`)
    }
    setOffSet(offSet)
    return response
  }

  const getAccounts = async value => {
    setSearchParam(value)
    setIsLoading(true)
    const response = await searchAccounts(value, 0)
    setIsLoading(false)
    setData(response)
    setShowLoadMore(response?.row_count >= 50)
  }

  const loadMore = async () => {
    setLoadingMore(true)
    const response = await searchAccounts(searchParam, offSet + 50)
    setShowLoadMore(response?.row_count >= 50)
    response.rows[0].firstRow = true
    const newData = {
      rows: [...data.rows, ...response.rows],
      row_count: data.row_count + response.row_count,
    }
    setData(newData)
    setLoadingMore(false)
    firstRowRef?.current?.scrollIntoView({behavior: 'smooth'})
  }

  React.useEffect(() => {
    const firstSearch = async () => {
      setIsLoading(true)
      const response = await searchAccounts(null, 0)
      setIsLoading(false)
      setData(response)
      setShowLoadMore(response?.row_count >= 50)
    }
    firstSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (isError) {
      throw error
    }
  }, [isError, error])

  const setDetailsData = async (
    subscriptionId,
    businessName,
    account_id,
    eId,
    ccId,
  ) => {
    let addOnsArray
    let response = {}
    try {
      response = await client(`subscription/uuid-${subscriptionId}`)
      addOnsArray = response.addOns.reduce((acc, {name, quantity}, index) => {
        const addOn = `${name} (Qty: ${quantity})\n`
        return [...acc, addOn]
      }, [])
    } catch (err) {
      if (err.status !== 404) {
        throw err
      }
    }

    const section1 = {
      sectionType: 'object',
      sectionName: businessName,
      sectionData: {
        'Thryv Plan': response.planName || '-',
        'Add Ons':
          response.addOns && response.addOns.length > 0 ? addOnsArray : '-',
        'Current Total': response.planPrice ? (
          <NumberFormat
            value={response.planPrice}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        ) : (
          '-'
        ),
        Status: response.status || '-',
        'Start Date': response.recurlyDate
          ? Moment(response.recurlyDate).format('MMMM DD, YYYY, LT')
          : '-',
        'Thryv Id': Object.keys(response).length === 0 ? '-' : account_id,
        EID: eId || '-',
        'Command Center Id': ccId || '-',
        Email: response.recurlyEmail || '-',
      },
    }

    return [section1]
  }

  return (
    <Container frontendSideNav={frontendSideNav}>
      <ResultsLayoutContainer>
        <ResultsLayoutHeader
          frontendSideNav={frontendSideNav}
          style={
            frontendSideNav
              ? {
                  justifyContent: 'flex-end',
                }
              : {}
          }
        >
          <Select
            options={OPTIONS}
            width="md"
            selectLabel="Select"
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            className="mr-4"
          />
          <div>
            <SearchButton
              placeholder="Search Accounts"
              onSearch={getAccounts}
              searchOnUsserType={true}
            />
          </div>
        </ResultsLayoutHeader>
        <ResultsTable
          cols={COLUMNS_TO_DISPLAY}
          isLoading={isLoading}
          hasRecords={data?.row_count > 0}
          bottomLineRef={firstRowRef}
        >
          {data?.rows?.map(
            (
              {
                CREATED_DATE,
                BUSINESS_NAME,
                THRYV_ID,
                FREQUENCY,
                demo_sale,
                cust_first_name,
                cust_last_name,
                cust_email,
                cust_phone,
                cust_company,
                RECURLY_SUBSCRIPTION_ID,
                EID,
                CCID,
                cust_industry,
                account_id,
                createdAt,
                company,
                fieldData,
                firstRow,
              },
              index,
            ) => (
              <tr key={index} ref={firstRow && firstRowRef}>
                {roles.includes('yello') || roles.includes('australia') ? (
                  <>
                    <td className="">
                      <span className="text-btn-links font-normal font-montserrat text-thryv-black-500 mr-4">
                        {cust_company}
                      </span>
                    </td>
                    <td>
                      <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                        {`${cust_first_name} ${cust_last_name}`}
                      </span>
                    </td>
                    <td>
                      <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                        {cust_email}
                      </span>
                    </td>
                    <td>
                      <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                        {cust_phone}
                      </span>
                    </td>

                    <td>
                      {demo_sale && demo_sale !== 'no' && (
                        <i className="fa fa-trophy" aria-hidden="true"></i>
                      )}
                    </td>
                  </>
                ) : (
                  <>
                    <td className="">
                      <span className="text-btn-links font-normal font-montserrat text-thryv-black-500 mr-4">
                        {BUSINESS_NAME || company}
                      </span>
                      {!roles.includes('reseller') && (
                        <HealthScore score={FREQUENCY} />
                      )}
                    </td>
                    <td>
                      <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                        {THRYV_ID || account_id}
                      </span>
                    </td>
                    <td>
                      <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                        {Moment(CREATED_DATE || createdAt).format('YYYY-MM-DD')}
                      </span>
                    </td>
                    <td>
                      <div className="d-flex justify-content-around">
                        <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                          <DetailsModal
                            getData={() =>
                              setDetailsData(
                                RECURLY_SUBSCRIPTION_ID || fieldData?.uuid,
                                BUSINESS_NAME || company,
                                THRYV_ID || account_id,
                                EID,
                                CCID,
                              )
                            }
                            title="Subscription Details"
                            buttonName="Details"
                            height={'20em'}
                          />
                        </span>
                        <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                          <Button
                            variant="secondary"
                            onClick={() =>
                              navigate('/order/upgrade', {
                                state: {
                                  order: {
                                    billingAccountId:
                                      RECURLY_SUBSCRIPTION_ID ||
                                      fieldData?.uuid,
                                    thryvId: THRYV_ID || account_id,
                                    template: cust_industry,
                                  },
                                  currentStep: 1,
                                },
                              })
                            }
                          >
                            Manage
                          </Button>
                        </span>
                      </div>
                    </td>
                  </>
                )}
              </tr>
            ),
          )}
        </ResultsTable>
        {data?.row_count > 0 &&
          !roles.includes('yello') &&
          !roles.includes('australia') &&
          !roles.includes('reseller') && (
            <div className="d-flex justify-content-center">
              {showLoadMore && (
                <>
                  {loadingMore ? (
                    <Spinner />
                  ) : (
                    <Button
                      className="mt-5"
                      variant="primary"
                      onClick={loadMore}
                    >
                      Load More...
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
      </ResultsLayoutContainer>
    </Container>
  )
}

export {MyAccounts}
