import styled from '@emotion/styled'

export const ProfileContainer = styled.div`
  width: 270px;
  background-color: #fff;
  position: absolute;
  top: 44px;
  right: 40px;
  border: 1px solid #f0f0f0;
  padding: 25px 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

  .contact-info {
    width: 100%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .contact-info > div {
    display: flex;
    justify-content: space-between;
    gap: 6px;
  }

  .link-info {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }

  .code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #f0f0f0;
    padding: 0 0 10px 0;
  }

  .link-wrapper {
    padding-top: 10px;
    display: flex;
    justify-content: center;
  }
`

export const InitialsContainer = styled.div`
  width: 25px;
  height: 25px;
  background-color: #bd4a20;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`
