import React from 'react'
import dayjs from 'dayjs'
import {Button, Header, Modal, ModalTitle} from '@thryvlabs/maverick'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'

import {useAsync} from '../utils/use-async'
import {useAuthClient} from '../utils/use-auth-client'
import {countryIsoCodeAlpha3} from '../utils/country-codes'
import {ScansHeader} from '../components/ui/business-scans'
import {ResultsTable} from '../components/results-table'
import {ResultsLayoutContainer} from '../components/ui/results-layout'
import {DetailsModal} from '../components/details-modal'
import {useFlags} from 'launchdarkly-react-client-sdk'

function parseResults(data) {
  return data.map(order => ({
    id: order.id,
    date: dayjs(order.created_date).format('MM/DD/YYYY'),
    company: order.company,
    email: order.email,
    firstName: order.first_name,
    lastName: order.last_name,
    repId: order.repId,
    phonenum: order.phonenum,
    country: order.country,
    industry: order.industry,
    software: order.software,
    package: order.package,
    bizphone: order.bizphone,
    postal_code: order.postal_code,
    address1: order.address1,
    city: order.city,
    state1: order.state1,
    curr: order.curr,
    xpCode: order.xp_code,
    behalf: order.behalf,
    salesRepName: order.sales_rep_name,
  }))
}

function Orders() {
  const navigate = useNavigate()
  const client = useAuthClient()
  const [tableData, setTableData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const {data, run, isSuccess, isError, error} = useAsync({
    status: 'pending',
  })

  const {frontendDisplayNewOrder, frontendSideNav} = useFlags()

  const onHandleNewOrder = () => {
    navigate('/order/new')
  }
  React.useEffect(() => {
    run(client('orders'))
  }, [client, run])
  React.useEffect(() => {
    if (isSuccess) {
      setTableData(parseResults(data))
      setLoading(false)
    }
  }, [isSuccess, data])
  React.useEffect(() => {
    if (isError) {
      throw error
    }
  }, [isError, error])

  const onRemove = async id => {
    setLoading(true)
    await client(`orders/${id}`, {method: 'DELETE'})
    toast.success('Record deleted successfully')
    await run(client('orders'))
    setLoading(false)
  }
  const setDetailsData = async id => {
    const response = await client(`orders/${id}`)
    const orderDetails = response || {}
    const clientName = orderDetails.first_name + ' ' + orderDetails.last_name
    const behalfName = orderDetails.behalf_first + orderDetails.behalf_last
    const section1 = {
      sectionType: 'object',
      sectionName: '',
      sectionData: {
        Name: clientName || '-',
        Email: orderDetails.email || '-',
        'Phone Number': orderDetails.phonenum || '-',
        Country: orderDetails.country || '-',
        State: orderDetails.state1 || '-',
        City: orderDetails.city || '-',
        Address: orderDetails.address1 || '-',
        'Postal Code': orderDetails.postal_code || '-',
        Company: orderDetails.company || '-',
        Industry: orderDetails.industry_name || '-',
        Software: orderDetails.software || '-',
        RepId: orderDetails.repID || '-',
        Behalf: orderDetails.behalf || '-',
        'Behalf Email': orderDetails.behalf_email || '-',
        'Behalf Name': behalfName || '-',
        'XP Code': orderDetails.xpCode || orderDetails.xp_code,
      },
    }

    return [section1]
  }

  const resumeOrder = async order => {
    navigate('/order/new', {
      state: {
        order: {
          selectedCountry: {
            isoValue: order.country,
            value: countryIsoCodeAlpha3(order.country),
          },
          industryCategoryType: order.software,
          template: order.industry,
          accountInformation: {
            companyPhone: order.bizphone,
            mobile: order.phonenum,
            state: order.state1,
            city: order.city,
            address: order.address1,
            postCode: String(order.postal_code),
            company: order.company,
            email: order.email,
            lastName: order.lastName,
            firstName: order.firstName,
            country: order.country,
            industryId: order.industry,
            software: order.software,
            package: order.package,
            behalf: order.behalf,
            salesRepName: order.salesRepName,
            xpCode: order.xpCode,
          },
          savedOrder: true,
        },
        currentStep: 4,
        showBackButton: false,
      },
    })
  }

  return (
    <>
      <ResultsLayoutContainer>
        <ScansHeader>
          <div className="d-flex">
            {frontendDisplayNewOrder && !frontendSideNav && (
              <Button
                variant="primary"
                className="mr-4"
                onClick={onHandleNewOrder}
              >
                New Order
              </Button>
            )}
          </div>
        </ScansHeader>
        <Header fontWeight="normal" variant="h1">
          Saved Orders
        </Header>
        <ResultsTable
          cols={['first name', 'last name', 'company', 'email', 'phone']}
          noDataFoundMessage={'Sorry, we could not find any results.'}
          isLoading={loading}
          hasRecords={tableData.length > 0}
        >
          {tableData.map((order, index) => (
            <tr key={index}>
              <td>
                <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                  {order.firstName}
                </span>
              </td>
              <td>
                <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                  {order.lastName}
                </span>
              </td>
              <td>
                <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                  {order.company}
                </span>
              </td>
              <td>
                <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                  {order.email}
                </span>
              </td>
              <td>
                <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                  {order.phonenum}
                </span>
              </td>
              <td>
                <DetailsModal
                  getData={() => setDetailsData(order.id)}
                  title="Saved Order"
                  buttonName="Details"
                  height={'30em'}
                />
              </td>
              <td>
                <Button
                  className="ml-4"
                  variant="secondary"
                  onClick={() => {
                    resumeOrder(order)
                  }}
                >
                  Complete Order
                </Button>
              </td>
              <td>
                <Modal
                  variant="default"
                  title="Delete Order"
                  btnText="Delete"
                  btnType="secondary"
                  actionClose
                  footer
                  action
                  btnActionText="Confirm"
                  btnAction={() => onRemove(order.id)}
                  primaryFooterVariant="dark"
                >
                  <ModalTitle variant="subtitle" size="md">
                    Please confirm to delete.
                  </ModalTitle>
                </Modal>
              </td>
            </tr>
          ))}
        </ResultsTable>
      </ResultsLayoutContainer>
    </>
  )
}

export {Orders}
