import React from 'react'
import {CriteriaContainer} from './criteria-container'
import {Icon, ParagraphText, Tooltip} from '@thryvlabs/maverick'

import {getSiteIcon} from '../../utils/get-site-icon'

import {
  OutreachMainContainer,
  OutreachItemContainer,
} from '../ui/business-results'
import {analysisIllustration3} from '../../assets'

const INSTAGRAM_URL = 'https://instagram.com'
const iconsHeight = '20'
const iconsWidth = '20'
function OutreachItem({
  site,
  followers,
  avgInteraction,
  frequency,
  found,
  tooltipDescription,
  link,
}) {
  return (
    <OutreachItemContainer data-test="component-outreach-item">
      <div className="site__item">
        <div className="d-flex  align-items-center">
          {getSiteIcon({
            site,
            className: 'mr-2',
            height: '20',
            width: '20',
            link,
          })}
          <ParagraphText
            variant="reg"
            color="thryv-black-600"
            data-test="outreach-site-name"
          >
            {site}
          </ParagraphText>
        </div>
        {tooltipDescription && (
          <Tooltip
            variant="right"
            description={tooltipDescription}
            width="250px"
            positioning={{
              offsetY: '-10px',
              offsetX: '-10px',
            }}
          >
            <Icon
              type="solid"
              variant="circleQuestion"
              color="#808080"
              height={iconsHeight}
              width={iconsWidth}
              className="ml-2"
            />
          </Tooltip>
        )}
      </div>
      {found ? (
        <>
          {site === 'Instagram' ? (
            <div className="w-full">
              <ParagraphText
                variant="reg"
                color="thryv-black-600"
                data-test="outreach-instagram-item"
              >
                Connected
              </ParagraphText>
            </div>
          ) : (
            <>
              <div className="d-flex  align-items-center">
                <Icon
                  type="regular"
                  variant="users"
                  color="#808080"
                  height={iconsHeight}
                  width={iconsWidth}
                  className="mr-2"
                />
                <ParagraphText
                  variant="reg"
                  color="thryv-black-600"
                  data-test="outreach-followers"
                >
                  {followers} Followers
                </ParagraphText>
              </div>
              <div className="d-flex  align-items-center">
                <Icon
                  type="regular"
                  variant="circleCheck"
                  color="#808080"
                  height={iconsHeight}
                  width={iconsWidth}
                  className="mr-2"
                />
                <ParagraphText
                  variant="reg"
                  color="thryv-black-600"
                  data-test="outreach-likes"
                >
                  {avgInteraction} likes
                </ParagraphText>
              </div>
              <div className="d-flex  align-items-center">
                <Icon
                  type="regular"
                  variant="analytics"
                  color="#808080"
                  height={iconsHeight}
                  width={iconsWidth}
                  className="mr-2"
                />
                <ParagraphText
                  variant="reg"
                  color="thryv-black-600"
                  data-test="outreach-post-frequency"
                >
                  Post frequency {frequency}
                </ParagraphText>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="w-full">
          <ParagraphText
            variant="reg"
            color="thryv-black-600"
            data-test="outreach-not-found"
          >
            Not found
          </ParagraphText>
        </div>
      )}

      <Icon
        type="solid"
        variant={found ? 'circleCheck' : 'circleX'}
        height={iconsHeight}
        width={iconsWidth}
        color={found ? '#16A085' : '#DF2A2A'}
        data-test="outreach-status-icon"
      />
    </OutreachItemContainer>
  )
}

function OutreachContainer(props) {
  const {score, facebook = {}, twitter = {}, instagram = {}, refProp} = props
  return (
    <CriteriaContainer
      criteria="Outreach"
      criteriaDescription="Your outreach is important to your business. A substantial amount of leads and customers come straight from your social media platforms."
      icon="handshake"
      proTip="Posting social content that will spark engagement and interest is key to building a following."
      thryvAdvice="Connect your Facebook, Twitter, Instagram, LinkedIn and Google My Business to Thryv, and post content, photos, links and videos to all your social media pages with one click. With Thryv, you can be social via your mobile device."
      score={score}
      image={analysisIllustration3}
      refProp={refProp}
      className="break-page--print-style"
    >
      <OutreachMainContainer className="mb--print-style">
        <OutreachItem
          site="Facebook"
          followers={facebook.pageFollows || 0}
          avgInteraction={facebook.pageLikes || 0}
          frequency={facebook.daysSinceLastPost || 0}
          found={facebook?.page}
          link={facebook?.link}
          tooltipDescription={
            'A recent Pew Research Center study found that 68% of American adults are Facebook users. - Pew Research https://www.pewresearch.org/internet/2018/03/01/social-media-use-in-2018/'
          }
        />
        <OutreachItem
          site="Twitter"
          followers={twitter.followers || 0}
          avgInteraction={twitter.averageInteractionsPerPost || 0}
          frequency={twitter.tweetFrequency || 0}
          found={twitter?.hasTwitter}
          link={twitter?.link}
          tooltipDescription={
            'Among 18 to 24-year-olds, 45% are on Twitter. - Pew Research https://www.pewresearch.org/internet/2018/03/01/social-media-use-in-2018/'
          }
        />
        <OutreachItem
          site="Instagram"
          followers={0}
          avgInteraction={0}
          frequency={0}
          found={instagram?.hasInstagram}
          link={
            instagram?.hasInstagram && instagram?.userName
              ? `${INSTAGRAM_URL}/${instagram.userName}`
              : null
          }
          tooltipDescription={
            'Among 18 to 24-year-olds, 78% use Instagram. - Pew Research https://www.pewresearch.org/internet/2018/03/01/social-media-use-in-2018/'
          }
        />
      </OutreachMainContainer>
    </CriteriaContainer>
  )
}

export {OutreachContainer}
