import * as React from 'react'
import {Auth0Provider} from '@auth0/auth0-react'
import {useNavigate} from 'react-router-dom'

const Auth0ProviderWithRedirectCallback = ({children, ...props}) => {
  const navigate = useNavigate()

  const onRedirectCallback = appState => {
    navigate((appState && appState.returnTo) || '/')
  }

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  )
}

export {Auth0ProviderWithRedirectCallback}
