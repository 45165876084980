import React from 'react'
import {
  CardNumberElement,
  CardMonthElement,
  CardYearElement,
  CardCvvElement,
  useRecurly,
} from '@recurly/react-recurly'
import {toast} from 'react-toastify'
import {
  Button,
  Icon,
  ParagraphText,
  Checkbox,
  Select,
} from '@thryvlabs/maverick'
import {RecurlyProvider, Elements} from '@recurly/react-recurly'
import rawCountryStates from 'iso3166-2-db/i18n/en'
import {TaxesPromptContainer} from '../ui/payment-information'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {AutopayTerms} from './autopay-terms'

const {REACT_APP_RECURLY_PUBLIC_KEY} = process.env
const COUNTRIES = [
  {name: 'United States', value: 'US'},
  {name: 'Australia', value: 'AU'},
  {name: 'Cayman Islands', value: 'KY'},
  {name: 'Barbados', value: 'BB'},
  {name: 'Canada', value: 'CA'},
  {name: 'New Zealand', value: 'NZ'},
]

const RecurlyPaymentForm = ({
  saveRecurlyToken,
  calculatingTaxes,
  setCalculatingTaxes,
  isMobile,
  showFirstSection,
  showSecondSection,
  nextStep,
  checkBoxesValues,
  handleOnCheckBoxChange,
  setName,
  businessAddress,
  country,
  handleCompleteOrder,
  setIsAddressUpdated,
  bizphone,
  is5Order,
  isAddressUpdated,
  paymentPreview,
}) => {
  const [selectedCountry, setSelectedCountry] = React.useState(
    COUNTRIES.find(({value}) => value === country),
  )
  // const [selectedState, setSelectedState] = React.useState({
  //   name: '',
  //   value: '',
  // })
  const [isValidForm, setIsValidForm] = React.useState({
    cardNumber: false,
    cardCVV: false,
    cardYear: false,
    cardMonth: false,
    postalCode: false,
    country: false,
  })
  const [addressInformation, setAddressInformation] = React.useState({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    postalCode: '',
    state: '',
  })
  const [listOfStates, setListOfStates] = React.useState([])
  const [isTaxesButtonEnabled, setIsTaxesButtonEnabled] = React.useState(true)
  const formRef = React.useRef()
  // const [stateLabel, setStateLabel] = React.useState('State')
  const {useBusinessAddressInfo} = checkBoxesValues
  const recurly = useRecurly()
  const {frontendPreviewPayment, frontendUpdatedTerms} = useFlags()

  const isAusNZ = ['AU', 'NZ'].includes(country)
  //const isSaveInfoButtonDisabled = !checkBoxesValues.terms

  const handleSubmit = event => {
    setCalculatingTaxes(true)
    event.preventDefault()
    console.log('formRef pre recurly token func', formRef)
    recurly.token(formRef.current, (err, token) => {
      if (err) {
        console.log(err)
        toast.error(err.message)
        setCalculatingTaxes(false)
        return
      }

      saveRecurlyToken(token)
      setName(addressInformation.firstName)
      console.log(frontendPreviewPayment)
      // if (
      //   !isMobile &&
      //   (isAusNZ || (frontendPreviewPayment && paymentPreview))
      // ) {
      //   // handleCompleteOrder()
      // } else {
      setIsTaxesButtonEnabled(false)
      if (!isMobile) setIsAddressUpdated(true)
      // }
    })
  }
  const cardInfoOnChange = (e, name) => {
    setIsValidForm(prevState => ({
      ...prevState,
      [name]: e.valid,
    }))
  }

  const handleOnchange = ({target: {value, name}}) => {
    setAddressInformation(prevState => ({
      ...prevState,
      [name]: value,
    }))
    setIsTaxesButtonEnabled(true)
    if (!isMobile) setIsAddressUpdated(false)
  }

  React.useEffect(() => {
    const getStates = () => {
      let stateLabel = ''
      if (['US', 'AU', 'NZ'].includes(selectedCountry.value)) {
        stateLabel = 'State'
      } else if (selectedCountry.value === 'KY') {
        stateLabel = 'City'
      } else if (selectedCountry.value === 'CA') {
        stateLabel = 'Province'
      } else if (selectedCountry.value === 'BB') {
        stateLabel = 'Parish'
      }
      let blankOption = [{name: 'Select ' + stateLabel, value: ''}]
      const states = rawCountryStates[selectedCountry.value].regions
        .map(({iso, name}) => ({
          name,
          value: iso,
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1))
      setListOfStates([...blankOption, ...states])
    }
    if (selectedCountry.value) {
      getStates(selectedCountry.value)
      setIsValidForm(prevState => ({
        ...prevState,
        country: true,
      }))
    }
    // if (['US', 'AU', 'NZ'].includes(selectedCountry.value)) {
    //   setStateLabel('State')
    // } else if (selectedCountry.value === 'KY') {
    //   setStateLabel('City')
    // } else if (selectedCountry.value === 'CA') {
    //   setStateLabel('Province')
    // } else if (selectedCountry.value === 'BB') {
    //   setStateLabel('Parish')
    // }
  }, [selectedCountry])

  React.useEffect(() => {
    if (useBusinessAddressInfo) {
      // const state = listOfStates.find(
      //   ({value}) => value === businessAddress?.state,
      // )
      // setSelectedState(state)
      if (checkBoxesValues.updateBillingAddress) {
        let fakeEvent = {target: {name: 'updateBillingAddress'}}
        handleOnCheckBoxChange(fakeEvent)
      }
      setAddressInformation({...businessAddress})
    } else {
      // setSelectedState({
      //   name: '',
      //   value: '',
      // })
      setAddressInformation({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        postalCode: '',
        state: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useBusinessAddressInfo, businessAddress, listOfStates])
  React.useEffect(() => {
    if (addressInformation.postalCode !== 0) {
      setIsValidForm(prevState => ({
        ...prevState,
        postalCode: addressInformation.postalCode.length !== 0,
      }))
    }
  }, [addressInformation.postalCode])
  if (isMobile) {
    return (
      <form onSubmit={handleSubmit} ref={formRef}>
        <div style={{display: showFirstSection ? 'block' : 'none'}}>
          <CardNumberElement
            className="recurly-element-inline"
            style={{
              fontSize: '18px',
              width: '100%',
              placeholder: {content: 'Card number'},
            }}
            onChange={e => {
              cardInfoOnChange(e, 'cardNumber')
            }}
            displayIcon={true}
          />
          <div className="d-flex justify-content-between">
            <CardMonthElement
              className="recurly-element-month"
              inputType="mobileSelect"
              style={{fontSize: '18', placeholder: {content: 'MM'}}}
              onChange={e => {
                cardInfoOnChange(e, 'cardMonth')
              }}
            />
            <CardYearElement
              className="recurly-element-inline ml-2"
              inputType="mobileSelect"
              style={{fontSize: '18', placeholder: {content: 'YY'}}}
              onChange={e => {
                cardInfoOnChange(e, 'cardYear')
              }}
            />
          </div>
          <div className="d-flex justify-content-between">
            <CardCvvElement
              className="recurly-element-inline mr-2"
              style={{
                fontSize: '18',
                placeholder: {content: 'CVV'},
              }}
              onChange={e => {
                cardInfoOnChange(e, 'cardCVV')
              }}
            />
            <input
              type="text"
              placeholder="Postal Code"
              data-recurly="postal_code"
              style={{
                fontSize: '18',
                width: '96%',
              }}
              onChange={e => {
                handleOnchange(e)
                setIsValidForm(prevState => ({
                  ...prevState,
                  postalCode: e.target.value.length !== 0,
                }))
              }}
              className="recurly-element recurly-address"
              name="postalCode"
              value={addressInformation.postalCode}
            />
          </div>
          <Select
            options={COUNTRIES}
            selectLabel="Country"
            width="full"
            className="recurly-element recurly-address mt-3"
            data-recurly="country"
            onChange={e => {
              setIsValidForm(prevState => ({
                ...prevState,
                country: e.value.length !== 0,
              }))
            }}
            selectedOption={selectedCountry}
            setSelectedOption={setSelectedCountry}
          />
          <input
            hidden
            readOnly
            type="text"
            placeholder="Country"
            value={selectedCountry.value}
            style={{
              fontSize: '18px',
            }}
            className="recurly-element recurly-address ml-2"
            data-recurly="country"
          />
          <input
            hidden
            readOnly
            type="text"
            placeholder="Phone"
            value={bizphone}
            style={{
              fontSize: '18px',
            }}
            className="recurly-element recurly-address ml-2"
            data-recurly="phone"
          />
          <div className="d-flex justify-content-center mb-4 mt-4">
            <Button
              variant="primary"
              onClick={nextStep}
              className="step__container__button"
              disabled={Object.values(isValidForm).some(
                value => value === false,
              )}
            >
              Continue
            </Button>
          </div>
        </div>
        {showSecondSection && (
          <>
            {!isAusNZ && (
              <div className="info__alert">
                <Icon type="solid" variant="infoCircle" color="#057aff" />
                <ParagraphText variant="reg">
                  The current estimated taxes are based on your business
                  address.
                </ParagraphText>
              </div>
            )}
            <div className="mt-4 mb-2">
              <Checkbox
                hasLabel
                name="useBusinessAddressInfo"
                value={checkBoxesValues.useBusinessAddressInfo}
                defaultChecked={checkBoxesValues.useBusinessAddressInfo}
                onChange={handleOnCheckBoxChange}
                label="Same as my business address"
              />
            </div>
            <div className="d-flex justify-content-between">
              <input
                style={{width: ' 49%'}}
                onChange={handleOnchange}
                className="recurly-element recurly-input"
                data-recurly="first_name"
                placeholder="First Name"
                name="firstName"
                value={addressInformation.firstName}
              />
              <input
                style={{width: '49%'}}
                className="recurly-element recurly-input"
                data-recurly="last_name"
                placeholder="Last Name"
                name="lastName"
                value={addressInformation.lastName}
                onChange={handleOnchange}
              />
            </div>
            <input
              type="text"
              className="recurly-element recurly-address"
              data-recurly="address1"
              placeholder="Address"
              style={{
                fontSize: '18px',
              }}
              name="address"
              value={addressInformation.address}
              onChange={handleOnchange}
            />
            <input
              type="text"
              className="recurly-element recurly-input mt-2.8"
              data-recurly="city"
              placeholder="City"
              style={{
                fontSize: '18px',
              }}
              name="city"
              value={addressInformation.city}
              onChange={handleOnchange}
            />
            {/* <Select
              options={listOfStates}
              selectLabel={stateLabel}
              width="full"
              className="recurly-element recurly-address mt-2.8"
              data-recurly="state"
              selectedOption={selectedState}
              setSelectedOption={setSelectedState}
            /> */}
            <select
              className="recurly-element recurly-address mt-2.8"
              value={addressInformation?.state}
              onChange={e => {
                handleOnchange({
                  target: {
                    name: 'state',
                    value: e.target.selectedOptions[0].value,
                  },
                })
              }}
            >
              {listOfStates.map(({name, value}) => {
                return (
                  <option key={value} value={value}>
                    {name}
                  </option>
                )
              })}
            </select>
            <input
              hidden
              readOnly
              value={addressInformation?.state}
              type="text"
              className="recurly-element"
              data-recurly="state"
              placeholder="State"
              style={{
                fontSize: '18px',
              }}
            />

            <div className="mt-4 mb-2">
              <Checkbox
                hasLabel
                name="updateBillingAddress"
                value={checkBoxesValues.updateBillingAddress}
                defaultChecked={checkBoxesValues.updateBillingAddress}
                onChange={handleOnCheckBoxChange}
                label="Update my billing address"
                disabled={useBusinessAddressInfo}
              />
            </div>
            <div className="d-flex justify-content-center mb-4 mt-4">
              <Button
                variant="primary"
                type="submit"
                className="step__container__button"
                disabled={calculatingTaxes}
              >
                Calculate Price
              </Button>
            </div>
          </>
        )}
      </form>
    )
  }

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <div
        className="d-flex justify-content-between mb-1"
        style={{
          marginTop: is5Order && !isAusNZ ? '42px' : '0',
        }}
      >
        <input
          className="recurly-element recurly-input mr-2 w-100"
          style={{height: is5Order ? '35px' : '45px'}}
          data-recurly="first_name"
          placeholder="First Name"
          name="firstName"
          onChange={handleOnchange}
          value={addressInformation.firstName}
        />
        <input
          className="recurly-element recurly-input w-100"
          style={{height: is5Order ? '35px' : '45px'}}
          data-recurly="last_name"
          placeholder="Last Name"
          name="lastName"
          onChange={handleOnchange}
          value={addressInformation.lastName}
        />
      </div>
      <div
        className={`${is5Order && 'd-flex'}`}
        style={{columnGap: is5Order ? '10px' : '0'}}
      >
        <CardNumberElement
          className="recurly-element-inline h-45"
          style={{
            fontSize: '16px',
            width: '100%',
            placeholder: {content: 'Card number'},
          }}
          displayIcon={true}
        />
        <div
          className={`input-container address_container w-100 h-45 ${
            !is5Order && 'pr-1'
          }`}
        >
          <CardMonthElement
            className="recurly-element-month w-100 h-45"
            style={{fontSize: '18', placeholder: {content: 'MM'}}}
          />
          <CardYearElement
            className="recurly-element-inline w-100 h-45"
            style={{fontSize: '18', placeholder: {content: 'YY'}}}
          />
          <CardCvvElement
            className="recurly-element-inline recurly-element-cvv w-100 h-45"
            style={{fontSize: '18', placeholder: {content: 'CVV'}}}
          />
        </div>
      </div>
      {is5Order && frontendUpdatedTerms && <AutopayTerms />}
      {is5Order && (
        <ParagraphText
          variant="lg"
          style={{
            fontWeight: 'bold',
            fontSize: '14px',
            margin: frontendUpdatedTerms ? '20px 0 0 0' : '36px 0 0 0',
          }}
        >
          Billing Address
        </ParagraphText>
      )}
      {is5Order && isAddressUpdated && (
        <TaxesPromptContainer>
          <Icon type="regular" variant="infoCircle" color="#057AFF" />
          <ParagraphText variant="reg" className="m-0">
            The current estimated taxes are based on your business address.
          </ParagraphText>
        </TaxesPromptContainer>
      )}
      {is5Order && (
        <div
          className="d-flex align-items-center mt-3 mb-1"
          style={{columnGap: '8px'}}
        >
          <Checkbox
            name="useBusinessAddressInfo"
            value={checkBoxesValues.useBusinessAddressInfo}
            defaultChecked={checkBoxesValues.useBusinessAddressInfo}
            onChange={handleOnCheckBoxChange}
          />
          <ParagraphText variant="reg" style={{margin: '0 0 0.7rem 0'}}>
            Same as my business address
          </ParagraphText>
        </div>
      )}
      {!is5Order && (
        <Select
          options={COUNTRIES}
          selectLabel="Country"
          width="full"
          className="recurly-element recurly-address mt-3"
          data-recurly="country"
          onChange={e => {
            setIsValidForm(prevState => ({
              ...prevState,
              country: e.value.length !== 0,
            }))
          }}
          selectedOption={selectedCountry}
          setSelectedOption={setSelectedCountry}
        />
      )}
      <input
        hidden
        readOnly
        type="text"
        placeholder="Country"
        value={selectedCountry.value}
        style={{
          fontSize: '18px',
        }}
        className="recurly-element recurly-address ml-2"
        data-recurly="country"
      />
      <input
        type="text"
        className="recurly-element recurly-address h-45"
        data-recurly="address1"
        placeholder="Street Address"
        style={{
          fontSize: '18px',
        }}
        name="address"
        value={addressInformation.address}
        onChange={handleOnchange}
      />
      <div
        className={`input-container ${!is5Order && 'pr-1 address_container'}`}
        style={
          is5Order && {
            display: 'grid',
            gridTemplateColumns: '30% 35% 31%',
            columnGap: '10px',
            width: '100%',
          }
        }
      >
        <input
          type="text"
          className="recurly-element h-45"
          data-recurly="city"
          placeholder="City"
          style={{
            fontSize: '18px',
          }}
          name="city"
          value={addressInformation.city}
          onChange={handleOnchange}
        />
        {/* <Select
          options={listOfStates}
          selectLabel={stateLabel}
          width="full"
          className="recurly-element recurly-address h-45"
          data-recurly="state"
          selectedOption={selectedState}
          setSelectedOption={setSelectedState}
        /> */}
        <select
          className="recurly-element recurly-address h-45"
          value={addressInformation?.state}
          onChange={e => {
            handleOnchange({
              target: {
                name: 'state',
                value: e.target.selectedOptions[0].value,
              },
            })
          }}
        >
          {listOfStates.map(({name, value}) => {
            return (
              <option key={value} value={value}>
                {name}
              </option>
            )
          })}
        </select>
        <input
          hidden
          readOnly
          value={addressInformation?.state}
          type="text"
          className="recurly-element"
          data-recurly="state"
          placeholder="State"
        />
        <input
          type="text"
          className="recurly-element h-45"
          data-recurly="postal_code"
          placeholder="Postal Code"
          style={{
            fontSize: '18px',
          }}
          name="postalCode"
          value={addressInformation.postalCode}
          onChange={handleOnchange}
        />
      </div>
      {is5Order && (
        <Select
          options={COUNTRIES}
          selectLabel="Country"
          width="full"
          className="recurly-element recurly-address mt-3"
          data-recurly="country"
          onChange={e => {
            setIsValidForm(prevState => ({
              ...prevState,
              country: e.value.length !== 0,
            }))
          }}
          selectedOption={selectedCountry}
          setSelectedOption={setSelectedCountry}
        />
      )}
      {!is5Order && (
        <div className="mt-4 mb-2">
          <Checkbox
            hasLabel
            name="useBusinessAddressInfo"
            value={checkBoxesValues.useBusinessAddressInfo}
            defaultChecked={checkBoxesValues.useBusinessAddressInfo}
            onChange={handleOnCheckBoxChange}
            label="Same as my business address"
          />
          {frontendUpdatedTerms && <AutopayTerms styles={{marginTop: 0}} />}
        </div>
      )}
      <div className="d-flex justify-content-center mb-4 mt-4">
        <Button
          variant="primary"
          type="submit"
          disabled={calculatingTaxes || !isTaxesButtonEnabled}
        >
          {'Calculate Price'}
        </Button>
      </div>
    </form>
  )
}

const PaymentForm = ({
  nextStep,
  calculateTaxes,
  calculatingTaxes,
  setCalculatingTaxes,
  isMobile = false,
  showFirstSection = false,
  showSecondSection = false,
  checkBoxesValues,
  handleOnCheckBoxChange,
  setName,
  businessAddress,
  country,
  handleCompleteOrder,
  setIsAddressUpdated,
  bizphone,
  is5Order,
  isAddressUpdated,
  paymentPreview,
}) => {
  return (
    <RecurlyProvider publicKey={REACT_APP_RECURLY_PUBLIC_KEY}>
      <Elements>
        <RecurlyPaymentForm
          saveRecurlyToken={calculateTaxes}
          calculatingTaxes={calculatingTaxes}
          setCalculatingTaxes={setCalculatingTaxes}
          isMobile={isMobile}
          showFirstSection={showFirstSection}
          showSecondSection={showSecondSection}
          nextStep={nextStep}
          checkBoxesValues={checkBoxesValues}
          handleOnCheckBoxChange={handleOnCheckBoxChange}
          setName={setName}
          businessAddress={businessAddress}
          country={country}
          handleCompleteOrder={handleCompleteOrder}
          setIsAddressUpdated={setIsAddressUpdated}
          bizphone={bizphone}
          is5Order={is5Order}
          isAddressUpdated={isAddressUpdated}
          paymentPreview={paymentPreview}
        />
      </Elements>
    </RecurlyProvider>
  )
}

export {PaymentForm}
