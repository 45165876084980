const currencyCodes = {
  US: 'USD',
  BB: 'USD',
  KY: 'USD',
  CA: 'CAD',
  AU: 'AUD',
}

const ccCurrencyCodes = {
  US: 'USD',
  BB: 'BBD',
  KY: 'KYD',
  CA: 'CAD',
  AU: 'AUD',
  NZ: 'NZD',
}

const currencyFormat = (price, country, isCcOrder) => {
  return new Intl.NumberFormat(`en-${country}`, {
    style: 'currency',
    currency: isCcOrder
      ? `${ccCurrencyCodes[country]}`
      : `${currencyCodes[country]}`,
  }).format(price)
}

const formatPrice = (amount, quantity = 1, title = '') => {
  if (typeof amount === 'string') return amount
  if (
    (title.includes('SEO') &&
      title !== 'SEO Keywords' &&
      title !== 'SEO PowerBoost' &&
      title !== 'SEO') ||
    title.includes('Thryv Leads')
  ) {
    quantity = 1
  }

  if (amount === undefined) {
    return ''
  }

  if (amount < 0) {
    return `-$${(amount * quantity * -1).toFixed(2)}`
  }
  return `$${(amount * quantity).toFixed(2)}`
}

export {currencyFormat, formatPrice}
