import styled from '@emotion/styled'

export const PlanItem = styled.li`
  border: 1px solid #f1f1f1;
  padding: 20px;
  border-radius: 6px;
  &:hover {
    cursor: pointer;
  }
  &.active {
    border: 1px solid red;
  }
`
