/**
@jsxRuntime
classic */
/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useEffect} from 'react'

function HeaderTitle({title, children}) {
  useEffect(() => {
    document.title = 'OOE - ' + title
  }, [title])

  return <div>{children}</div>
}

export {HeaderTitle}
