import React from 'react'
import {CriteriaContainer} from './criteria-container'
import {Icon, Tooltip, Navigation, Header} from '@thryvlabs/maverick'
import {RatingDropdown, RatingDropdownSection} from './rating-dropdown'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from 'recharts'

import {getSiteIcon} from '../../utils/get-site-icon'
import {SCORE_COLORS} from '../../utils/constants'

import {officePicture} from '../../assets'
import {
  ReviewActivityItemContainer,
  SentimentAnalysisContainer,
  SentimentAnalysisTabs,
  SentimentAnalysisItemContainer,
  RevenueContainer,
  RevenueGraphTitleContainer,
  RevenueGraphContainer,
} from '../ui/business-results'

const sentimentAnalysisTabs = [
  {name: 'Impactful Reviews'},
  {name: 'Google'},
  {name: 'Yelp'},
  {name: 'YP.com'},
]
function formatter({round = false, amount}) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: round ? 0 : 1,
  })
  return formatter.format(amount)
}
function SentimentAnalysisItems({description, source = '', status}) {
  return (
    <SentimentAnalysisItemContainer
      status={status}
      className={`${status ? 'positive' : 'negative'}`}
    >
      {getSiteIcon({site: source, height: 25, width: 25})}
      <Header fontWeight="semibold" variant="h8">
        <span data-test="sentiment-analysis-item-description">
          {description}
        </span>
      </Header>
    </SentimentAnalysisItemContainer>
  )
}
function ReviewActivityItem({description, additionalInfo, status}) {
  return (
    <ReviewActivityItemContainer aria-label="review activity item">
      <div>
        <span>{description}</span>
        {additionalInfo && (
          <Tooltip
            variant="right"
            description={additionalInfo}
            width="250px"
            positioning={{
              offsetY: '15px',
              offsetX: '-10px',
            }}
          >
            <Icon type="regular" variant="infoCircle" height="12" width="12" />
          </Tooltip>
        )}
      </div>
      <span>
        <Icon
          type="regular"
          variant={status ? 'arrowCircleUp' : 'arrowCircleDown'}
          color={status ? SCORE_COLORS[0] : SCORE_COLORS[2]}
          data-test="review-activity-item-icon"
        />
      </span>
    </ReviewActivityItemContainer>
  )
}
function NonPositiveInfo() {
  return (
    <div className="none-info positive">
      <p>You have 0 positive review snippets. Let Thryv help you!</p>
      {/* <a href="http://sales.thryv.store/">I want help!</a> */}
      {/* REMOVE LINK */}
    </div>
  )
}
function NonNegativeInfo() {
  return (
    <div className="none-info negative">
      Congrats! You have 0 negative review snippets.
    </div>
  )
}
function RevenueImpactItems({
  graphTitle,
  graphData,
  graphSubTitle,
  graphDescription,
  graphColor,
}) {
  return (
    <div data-test="component-revenue-impact-item">
      <RevenueGraphTitleContainer>
        <Header
          fontWeight="semibold"
          variant="h8"
          data-test="revenue-impact-item-title"
        >
          <span>{graphTitle}</span>
        </Header>
      </RevenueGraphTitleContainer>
      <RevenueGraphContainer>
        <div className="rotate">
          <Header fontWeight="semibold" variant="h9">
            <span data-test="revenue-impact-item-sub-title">
              {graphSubTitle}
            </span>
          </Header>
        </div>
        <div className="graph" style={{height: '350px', width: '100%'}}>
          <ResponsiveContainer width="100%" height="100%" debounce={3000}>
            <BarChart
              data={graphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="name" />
              <YAxis />
              <RechartsTooltip />
              <Bar barSize={70} dataKey="value" fill={graphColor} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="description">
          <Header fontWeight="semibold" variant="h9">
            <span data-test="revenue-impact-item-description">
              {graphDescription}
            </span>
          </Header>
        </div>
      </RevenueGraphContainer>
    </div>
  )
}
function ReputationContainer(props) {
  const {
    score,
    reputationScore: {
      countScore,
      sentimentScore,
      recencyScore,
      reviewScore,
    } = {},
    yext: {
      googleReviewScorePercentile,
      googleReviewCountPercentile,
      googleReviewRating1,
      googleReviewRating5,
    } = {},
    overallPhrases: {
      positivePhrasesList = [],
      negativePhrasesList = [],
      overallPhraseBuckets = {},
    } = {},
    companyName,
    country = 'usa',
    revenue,
    revenueDetailInfo: {
      google5starImpact3Revenuechange,
      google5starImpactReviews,
      google5starImpactRevenuechange,
      google5starImpact2Reviews,
      google5starImpact2Revenuechange,
      google5starImpact3Reviews,
      google1starImpactReviews,
      google1starImpact2Reviews,
      google1starImpact3Reviews,
      google1starImpactRevenuechange,
      google1starImpact2Revenuechange,
      google1starImpact3Revenuechange,
    } = {},
    refProp,
  } = props

  const {
    googlePositivePhrases = [],
    googleNegativePhrases = [],
    yelpPositivePhrases = [],
    yelpNegativePhrases = [],
    ypPositivePhrases = [],
    ypNegativePhrases = [],
  } = overallPhraseBuckets || {}

  const showYellowPages = country === 'aus'
  const [tab, setTab] = React.useState(0)

  const setTabs = () => {
    let result = sentimentAnalysisTabs
    if (showYellowPages) {
      result = sentimentAnalysisTabs.filter(
        ({name}) => name !== sentimentAnalysisTabs[3].name,
      )
    }
    return result
  }
  return (
    <CriteriaContainer
      criteria="Reputation"
      criteriaDescription="When it comes to your business, your reputation matters. With Thryv, you can receive immediate help to boost your reputation"
      icon="award"
      proTip="Stand out from your competition by routinely responding to positive & negative reviews to show future prospects that you care and take customer service seriously."
      thryvAdvice="Monitor what customers are saying the second new reviews are posted on top sites. Thryv’s live review acknowledgment service will respond to reviews on Google, Yelp and Facebook within 1 business day, even if you’re too busy to do it yourself."
      score={score}
      image={officePicture}
      refProp={refProp}
      className="mt-3 break-page-before-avoid--print-style"
    >
      <li>
        <RatingDropdownSection
          ratingDescription="Review Activity"
          tooltipDescription={`We’ve pulled some key statements from popular review sites like Google, Yelp and Yellow. Below you will see some of the good, the bad, and the ugly. If you have bad reviews, don’t fret, everyone does. No one is perfect, and your customers don’t you to be either.

          Pro Tip: Responding to your reviews shows future prospects that you care, are on top of things, and take customer service seriously. 53% of consumers expect businesses to respond to reviews, but 63% of those survey responded that none of their reviews have ever been responded to.1

          Stand out from your competition and routinely respond to both positive and negative reviews online.

          https://www.reviewtrackers.com/online-reviews-survey/`}
        >
          <ul>
            <ReviewActivityItem
              description="Amount of reviews"
              additionalInfo={
                'Consumers want to read, on average, 10 reviews before feeling able to trust a business.1 Additionally, 49% of consumers responded that quantity of reviews is what they pay attention to. Second only to overall rating at 56%. The quantity of reviews is important for any business online. Not only does it build trust with consumers, but it also can protect your business from wide swings in overall performance. 1. https://www.brightlocal.com/research/local-consumer-review-survey/'
              }
              status={countScore >= 50}
            />
            <ReviewActivityItem
              description="Sentiment score"
              additionalInfo={
                'While your overall review score is very important, it is also important to read what your customers have written about your business. Your overall sentiment score analyzes individual phrases to identify how positive, or negative your customers have spoken about your business online.'
              }
              status={sentimentScore >= 50}
            />
            <ReviewActivityItem
              description="Recent Review Activity"
              additionalInfo="40% of consumers only take into account reviews written with the past 2 weeks – up from 18% last year.1 Consumers want to see a steady stream of reviews. This shows continued interest in your business, and that your recent work is thought highly of. If the last review you have is from a few months ago, it makes consumers start to question your business. 1. https://www.brightlocal.com/research/local-consumer-review-survey/"
              status={recencyScore >= 50}
            />
            <ReviewActivityItem
              description="Average score review"
              additionalInfo="In 2018, over 56% of consumers survey responded that your review score is the first thing they see.1 Take control of your review score. Investing to build up a large base of high quality reviews can protect your business long term from the occasional bad review. A business with 1,000 online reviews fluctuates far less then a business with 100. 1. https://www.brightlocal.com/research/local-consumer-review-survey/"
              status={reviewScore >= 50}
            />
            {googleReviewScorePercentile && (
              <ReviewActivityItem
                description={`${googleReviewScorePercentile}% of similar businesses like ${companyName} have a higher online Reputation on Google`}
                status={googleReviewScorePercentile < 10}
              />
            )}
            {googleReviewCountPercentile && (
              <ReviewActivityItem
                description={`${googleReviewCountPercentile}% of similar businesses like ${companyName} have more online reviews on Google`}
                status={googleReviewCountPercentile < 10}
              />
            )}
            {googleReviewRating1 && (
              <ReviewActivityItem
                description={`${googleReviewRating1} is what ${companyName} Google rating would fall to with one 1-star review.`}
                status={false}
              />
            )}
            {googleReviewRating5 && (
              <ReviewActivityItem
                description={`${googleReviewRating5} is what ${companyName} Google rating would rise to with one 5-star review.`}
                status={true}
              />
            )}
          </ul>
        </RatingDropdownSection>
      </li>
      <SentimentAnalysisContainer
        data-test="component-sentiment-analysis-container"
        className="break-page-before--print-style"
      >
        <RatingDropdown
          ratingDescription="Sentiment Analysis"
          tooltipDescription={
            'We’ve pulled some key statements from popular review sites like Google, Yelp and Yellow. Below you will see some of the good, the bad, and the ugly. If you have bad reviews, don’t fret, everyone does. Pro Tip: Responding to your reviews shows future prospects that you care, are on top of things, and take customer service seriously. 53% of consumers expect businesses to respond to reviews, but 63% of those survey responded that none of their reviews have ever been responded to. 1 Stand out from your competition and routinely respond to both positive and negative reviews online. https://www.reviewtrackers.com/online-reviews-survey/'
          }
        >
          <Navigation
            linksArray={setTabs()}
            variant="tabs"
            selected={tab}
            setSelectedOption={setTab}
          />
          <SentimentAnalysisTabs>
            {tab === 0 && (
              <ul
                aria-label={`${sentimentAnalysisTabs[0].name} Tab Content`}
                role="tabpanel"
                aria-hidden={!tab === 0}
              >
                {!positivePhrasesList || positivePhrasesList?.length === 0 ? (
                  <NonPositiveInfo />
                ) : (
                  <>
                    {positivePhrasesList.map(({sentence, source}, i) => (
                      <SentimentAnalysisItems
                        key={`${sentence}${source}`}
                        source={source}
                        description={`"${sentence}"`}
                        status={true}
                      />
                    ))}
                  </>
                )}
                {!negativePhrasesList || negativePhrasesList?.length === 0 ? (
                  <NonNegativeInfo />
                ) : (
                  <>
                    {negativePhrasesList.map(({sentence, source}) => (
                      <SentimentAnalysisItems
                        key={`${source}${sentence}`}
                        source={source}
                        description={`"${sentence}"`}
                        status={false}
                      />
                    ))}
                  </>
                )}
              </ul>
            )}
            {tab === 1 && (
              <ul
                aria-label={`${sentimentAnalysisTabs[1].name} Tab Content`}
                role="tabpanel"
                aria-hidden={!tab === 1}
              >
                {!googlePositivePhrases ||
                googlePositivePhrases?.length === 0 ? (
                  <NonPositiveInfo />
                ) : (
                  <>
                    {googlePositivePhrases.map(({sentence, source}) => (
                      <SentimentAnalysisItems
                        key={`${source}${sentence}`}
                        source={source || 'google'}
                        description={`"${sentence}"`}
                        status={true}
                      />
                    ))}
                  </>
                )}
                {googleNegativePhrases?.length === 0 ? (
                  <NonNegativeInfo />
                ) : (
                  <>
                    {googleNegativePhrases.map(({sentence, source}) => (
                      <SentimentAnalysisItems
                        key={`${source}${sentence}`}
                        source={source || 'google'}
                        description={`"${sentence}"`}
                        status={false}
                      />
                    ))}
                  </>
                )}
              </ul>
            )}
            {tab === 2 && (
              <ul
                aria-label={`${sentimentAnalysisTabs[2].name} Tab Content`}
                role="tabpanel"
                aria-hidden={!tab === 2}
              >
                {!yelpPositivePhrases || yelpPositivePhrases?.length === 0 ? (
                  <NonPositiveInfo />
                ) : (
                  <>
                    {yelpPositivePhrases.map(({sentence, source}) => (
                      <SentimentAnalysisItems
                        key={`${source}${sentence}`}
                        source={source || 'yelp'}
                        description={`"${sentence}"`}
                        status={true}
                      />
                    ))}
                  </>
                )}
                {!yelpNegativePhrases || yelpNegativePhrases?.length === 0 ? (
                  <NonNegativeInfo />
                ) : (
                  <>
                    {yelpNegativePhrases.map(({sentence, source}) => (
                      <SentimentAnalysisItems
                        key={`${source}${sentence}`}
                        source={source || 'yelp'}
                        description={`"${sentence}"`}
                        status={false}
                      />
                    ))}
                  </>
                )}
              </ul>
            )}
            {tab === 3 && (
              <ul
                aria-label={`${sentimentAnalysisTabs[3].name} Tab Content`}
                role="tabpanel"
                aria-hidden={!tab === 3}
              >
                {!ypPositivePhrases || ypPositivePhrases?.length === 0 ? (
                  <NonPositiveInfo />
                ) : (
                  <>
                    {ypPositivePhrases.map(({sentence, source}) => (
                      <SentimentAnalysisItems
                        key={`${source}${sentence}`}
                        source={source || 'yellowpages'}
                        description={`"${sentence}"`}
                        status={true}
                      />
                    ))}
                  </>
                )}
                {!ypNegativePhrases || ypNegativePhrases?.length === 0 ? (
                  <NonNegativeInfo />
                ) : (
                  <>
                    {ypNegativePhrases.map(({sentence, source}) => (
                      <SentimentAnalysisItems
                        key={`${source}${sentence}`}
                        source={source || 'yellowpages'}
                        description={`"${sentence}"`}
                        status={false}
                      />
                    ))}
                  </>
                )}
              </ul>
            )}
          </SentimentAnalysisTabs>
          <section className="d-flex justify-end pb-4">
            <div className="mr-5 d-flex align-items-center">
              <div className="dot positive__review--dot" />
              <Header fontWeight="semibold" variant="h8" className="m-0">
                <span>Positive Review</span>
              </Header>
            </div>
            <div className="d-flex align-items-center">
              <div className="dot negative__review--dot" />
              <Header fontWeight="semibold" variant="h8" className="m-0">
                <span>Negative Review</span>
              </Header>
            </div>
          </section>
        </RatingDropdown>
      </SentimentAnalysisContainer>
      {revenue > 0 && (
        <RevenueContainer>
          <RatingDropdown
            ratingDescription="Revenue Impact"
            tooltipDescription={
              'Every 1 star increase in your online reputation can increase your revenue between 5-9%. 1 This shouldn’t come as a shock. Consumers want to do business with highly reputable companies. Consumers will click on businesses further down the page, if their reputation is better. https://www.forbes.com/sites/ryanerskine/2017/09/19/20-online-reputation-statistics-that-every-business-owner-needs-to-know/#172eca60cc5c'
            }
          >
            <Header
              fontWeight="semibold"
              variant="h6"
              className="text-center m-4"
            >
              <span>
                Current Revenue :
                {formatter({
                  amount: parseFloat(revenue, 10) || 0,
                  round: false,
                })}
              </span>
            </Header>
            <RevenueImpactItems
              graphTitle={`5 Star Revenue Impact: +${formatter({
                amount: parseFloat(google5starImpact3Revenuechange, 10) || 0,
                round: true,
              })}`}
              graphData={[
                {
                  name: google5starImpactReviews
                    ? `${google5starImpactReviews} more`
                    : '-',
                  value: parseFloat(google5starImpactRevenuechange) || 0,
                },
                {
                  name: google5starImpact2Reviews
                    ? `${google5starImpact2Reviews} more`
                    : '-',
                  value: parseFloat(google5starImpact2Revenuechange) || 0,
                },
                {
                  name: google5starImpact3Reviews
                    ? `${google5starImpact3Reviews} more`
                    : '-',
                  value: parseFloat(google5starImpact3Revenuechange) || 0,
                },
              ]}
              graphSubTitle="Increased Annual Revenue"
              graphDescription="No of Additional 5-Star Review"
              graphColor="#5CBDAA"
            />
            <RevenueImpactItems
              graphTitle={`1 Star Revenue Impact : -${formatter({
                amount: parseFloat(google1starImpact3Revenuechange, 10) || 0,
                round: true,
              })}`}
              graphData={[
                {
                  name: google1starImpactReviews
                    ? `${google1starImpactReviews} more`
                    : '-',
                  value: parseFloat(google1starImpactRevenuechange) || 0,
                },
                {
                  name: google1starImpact2Reviews
                    ? `${google1starImpact2Reviews} more`
                    : '-',
                  value: parseFloat(google1starImpact2Revenuechange) || 0,
                },
                {
                  name: google1starImpact3Reviews
                    ? `${google1starImpact3Reviews} more`
                    : '-',
                  value: parseFloat(google1starImpact3Revenuechange) || 0,
                },
              ]}
              graphSubTitle="Decreased Annual Revenue"
              graphDescription="No of Additional 1-Star Review"
              graphColor="#EF9494"
            />
          </RatingDropdown>
        </RevenueContainer>
      )}
    </CriteriaContainer>
  )
}

export {ReputationContainer}
