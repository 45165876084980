import React, {useState} from 'react'
import {useAuthClient} from '../../utils/use-auth-client.js'
import {useAsync} from '../../utils/use-async'
import {IndustryContainer} from '../ui/industry-dropdown.js'
import {ContainerTitle} from '../ui/industry-category.js'
import {Button, Icon, Select} from '@thryvlabs/maverick'
import {CancelOrderModal} from '../cancel-order-modal'

function IndustryDropdown({
  prevStep,
  nextStep,
  selectedCountry,
  industryCategoryType,
}) {
  const [industries, setIndustries] = useState([])
  const [selectedOption, setSelectedOption] = useState({
    name: '',
    template: '',
    identity: '',
  })
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const client = useAuthClient()
  const {run} = useAsync({
    status: 'pending',
  })

  const fetchIndustries = React.useCallback(async () => {
    let url = `industries/${
      selectedCountry === 'cayman' || selectedCountry === 'barbados'
        ? 'usa'
        : selectedCountry
    }`
    const response = await run(client(url))
    setIndustries(response)
  }, [client, run, selectedCountry])

  React.useEffect(() => {
    fetchIndustries()
  }, [fetchIndustries])

  React.useEffect(() => {
    if (!selectedOption.name || !selectedOption) {
      setIsButtonDisabled(true)
    } else if (selectedOption.name) {
      setIsButtonDisabled(false)
    }
  }, [selectedOption])

  return (
    <div
      className="wrapper py-0 w-100 h-100"
      style={{paddingTop: '0 !important'}}
    >
      <Icon
        type="solid"
        variant="arrowLeft"
        className="back__arrow mb-5 ml-4 mt-2"
        style={{cursor: 'pointer'}}
        onClick={prevStep}
      />
      <div className="d-flex justify-content-center align-content-center">
        <div style={{width: '900px'}}>
          <ContainerTitle
            fontWeight="medium"
            className="text-center"
            variant="h2"
            text="Prospecting Tools"
          >
            To continue, select your industry
          </ContainerTitle>
          <IndustryContainer>
            <div style={{width: '650px'}}>
              <Select
                options={industries}
                selectLabel="Industry"
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                width="full"
                largeHeight
              />
              <div
                className="m-5 d-flex justify-content-center"
                style={{columnGap: '10px'}}
              >
                <CancelOrderModal cancelUrl="/orders" />
                <Button
                  disabled={isButtonDisabled}
                  variant="primary"
                  onClick={() => nextStep({template: selectedOption.template})}
                >
                  Confirm & Start Order
                </Button>
              </div>
            </div>
          </IndustryContainer>
        </div>
      </div>
    </div>
  )
}

export {IndustryDropdown}
