import {Button, Icon, Input} from '@thryvlabs/maverick'
import React, {useState} from 'react'
import {EaidValidationContainer} from '../ui/eaid-validation'
import {useAuthClient} from '../../utils/use-auth-client'
import {toast} from 'react-toastify'

function EaidValidation({
  cohortEaid,
  isEaidFound,
  isOptional,
  setCohortEaid,
  setIsEaidFound,
  setAllowInvoice,
  isMCProEnhanced,
  setIsLegacyUpgradeAllowed,
  thryvId,
  setIsCohort,
}) {
  const [eaidInput, setEaidInput] = useState(cohortEaid || '')
  const [isLoading, setIsLoading] = useState(false)

  const client = useAuthClient()

  const handleEaidValidation = async ({
    eaid,
    thryvId = '',
    setEaidInput,
    setIsLoading = () => {},
  }) => {
    setIsLoading(true)
    const {isValid, allowInvoice, allowLegacyUpgrade, isCohort} = await client(
      `cc/order/validate-eaid?eaid=${eaid}&thryvId=${thryvId}`,
    )

    setAllowInvoice(allowInvoice)
    setIsCohort(isCohort)

    if (isValid) {
      if (isMCProEnhanced) {
        if (!allowLegacyUpgrade) {
          toast.warning('Invalid EAID - For Legacy Upgrades Only')
          setIsLoading(false)
          return
        }
        setIsLegacyUpgradeAllowed(true)
      }
      toast.success('EAID is valid')
      setCohortEaid(eaid)
      setIsEaidFound(true)
    } else {
      toast.error('EAID is not valid')
      setEaidInput('')
      setCohortEaid('')
      setIsEaidFound(false)
      setIsLegacyUpgradeAllowed(false)
    }
    setIsLoading(false)
  }

  const handleResetEaid = async setEaidInput => {
    setIsEaidFound(false)
    setCohortEaid('')
    setEaidInput('')
    setIsLegacyUpgradeAllowed(false)
    toast.warning('EAID unlinked')
    setAllowInvoice(false)
    setIsCohort(false)
  }

  return (
    <EaidValidationContainer>
      <div className="d-flex" style={{columnGap: '5px', width: '100%'}}>
        <div style={{flexGrow: 2, position: 'relative'}}>
          <Input
            type="text"
            placeholder={`EAID ${isOptional ? '(optional)' : ''}`}
            aria-label="EAID"
            name="eaidInput"
            disabled={isEaidFound}
            value={eaidInput}
            onChange={e => {
              setEaidInput(e.target.value)
            }}
            withLabel
            labelType="static"
            variant="default"
            style={{height: '30px', flexGrow: 1}}
          />
        </div>
        {!isEaidFound ? (
          <Button
            style={{marginTop: '2px'}}
            variant="primary"
            type="button"
            name="Validate"
            onClick={() => {
              handleEaidValidation({
                eaid: eaidInput,
                thryvId,
                setEaidInput,
                setIsLoading,
              })
            }}
            disabled={
              eaidInput?.length < 1 || eaidInput === undefined || isLoading
            }
          >
            Validate
          </Button>
        ) : (
          <Icon
            className="reset-button"
            type="regular"
            variant="x"
            style={{
              width: '10px',
              height: '10px',
              marginTop: '3px',
              color: 'grey',
              alignSelf: 'center',
            }}
            color="gray"
            onClick={() => {
              handleResetEaid(setEaidInput)
            }}
          />
        )}
      </div>
    </EaidValidationContainer>
  )
}

export {EaidValidation}
