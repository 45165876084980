import React from 'react'

const AutopayTerms = ({styles = {}}) => {
  return (
    <p
      style={{color: '#7f7f7f', fontSize: '13px', marginTop: '20px', ...styles}}
    >
      By providing us a credit card, debit card, bank account number, or PayPal
      account, you authorize us to charge the card or account for outstanding
      invoices and automatically at the start of each monthly billing period for
      the amount of your monthly recurring charges for Thryv Services, plus
      applicable taxes, and less and account credits, until you notify us to
      cancel your automatic recurring payments in accordance with the Notice
      section of our Terms and Conditions applicable to your country. See
      Sections 6 - 8 of our{' '}
      <a
        target="_blank"
        href="https://corporate.thryv.com/terms/"
        rel="noreferrer"
      >
        Terms and Conditions
      </a>{' '}
      for additional details.
    </p>
  )
}

export {AutopayTerms}
