import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'

export const AppContainer = styled.article`
  height: 100%;
  display: grid;
  gap: 1rem;
  grid-template-rows: 70px 100%;
  grid-template-columns: ${props => (props.isSidebarOpen ? '245px' : '54px')};
  grid-template-areas:
    'header header'
    'sidebar main-content'
    'sidebar footer';

  @media (max-width: 835px) {
    grid-template-areas:
      'header header'
      'main-content main-content'
      'footer footer';
    & main {
      padding-left: 15px;
    }
  }

  & main {
    grid-area: main-content;
    min-width: 100%;
    min-height: calc(100vh - 195px);
  }
  & footer {
    grid-area: footer;
  }
  /* @media screen and ${device.tablet} {
    grid-template-rows: auto calc(100vh - 200px) auto;
  } */
`

export const OldAppContainer = styled.article`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'main-content'
    'footer';
  grid-template-columns: 100%;
  grid-template-rows: auto auto 130px;
  & header {
    grid-area: header;
  }
  & main {
    grid-area: main-content;
    min-width: 100%;
    min-height: calc(100vh - 195px);
  }
  & footer {
    grid-area: footer;
  }
  /* @media screen and ${device.tablet} {
  grid-template-rows: auto calc(100vh - 200px) auto;
} */
`
