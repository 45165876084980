import React, {useState, useEffect, useCallback} from 'react'
import {
  Input,
  Modal,
  ModalTitle,
  ParagraphText,
  RadioGroup,
  Select,
} from '@thryvlabs/maverick'
import {useForm, Controller} from 'react-hook-form'
import * as yup from 'yup'
import validator from 'validator'
import {yupResolver} from '@hookform/resolvers/yup'
import rawCountryStates from 'iso3166-2-db/i18n/en'

const REQUIRED_FIELD_MESSAGE = 'This field is required'
const REQUIRED_OPTION_MESSAGE = 'Please select an option'
const MIN_CHARACTERS_REQUIRED = 'Must be at least 3 characters'
const MAX_CHARACTERS_REQUIRED = 'Must be at most 120 characters'

const SCHEMA = yup.object().shape({
  prospectsStreet: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  prospectsCity: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  prospectsState: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
  prospectsCountry: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
  prospectsPostalCode: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      'validate-postCode-format',
      'Incorrect post code format',
      function (value) {
        if (!value) {
          return false
        }
        const country =
          this.options.context.componentProps.selectedCountry.value
        if (country === 'BB') {
          return value.match(/^[A-Z]{2}\d{5}$/)
        }
        if (country === 'KY') {
          return value.match(/^[A-Za-z]{2}\d-?\d{4}$/)
        }
        return validator.isPostalCode(value, country)
      },
    ),
})

const RADIO_COUNTRY_SELECTION = [
  {name: 'United States', value: 'US'},
  {name: 'Canada', value: 'CA'},
  {name: 'Australia', value: 'AU'},
  {name: 'New Zealand', value: 'NZ'},
  {name: 'Barbados', value: 'BB'},
  {name: 'Cayman Islands', value: 'KY'},
]

const DEFAULT_VALUES = {
  prospectsStreet: '',
  prospectsCity: '',
  prospectsState: {},
  prospectsCountry: {},
  prospectsPostalCode: '',
}

const UpdateBusinessInfoModal = ({
  nextStep,
  isBusinessInfoUpdated,
  setIsBusinessInfoUpdated,
}) => {
  const [listOfStates, setListOfStates] = useState([])
  const [selectedState, setSelectedState] = useState({
    name: '',
    value: '',
  })
  const [resetSelectedState, setResetSelectedState] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState({
    name: 'United States',
    value: 'US',
  })
  const {
    register,
    handleSubmit,
    control,
    formState: {errors, isValid},
    setValue,
    trigger,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(SCHEMA),
    context: {componentProps: {selectedCountry}},
  })
  const getStates = useCallback(
    countryIso => {
      const states = rawCountryStates[countryIso].regions
        .map(({iso, name}) => ({
          name,
          value: iso || name,
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1))
      setListOfStates(states)
    },
    [setListOfStates],
  )
  const setState = (value, reset) => {
    setSelectedState(value)
    setValue('prospectsState', {...value})
    if (reset) {
      setResetSelectedState(true)
    } else {
      trigger('prospectsState')
    }
  }
  const handleContinue = formData => {
    const data = {
      streetAddress: formData.prospectsStreet,
      prospectsCity: formData.prospectsCity,
      prospectsState: formData.prospectsState,
      prospectsPostalCode: formData.prospectsPostalCode,
      prospectsCountry: formData.prospectsCountry,
    }
    setIsBusinessInfoUpdated(true)
    nextStep(data)
  }
  useEffect(() => {
    if (selectedCountry.value.length > 0) {
      getStates(selectedCountry.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry])
  useEffect(() => {
    setValue('prospectsCountry', selectedCountry)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry])

  return (
    <Modal
      btnText={isBusinessInfoUpdated ? '' : 'Update Business Information'}
      btnType="primary"
      variant="xl"
      width="800px"
      action
      footer
      btnActionText="Save"
      btnActionDisabled={!isValid}
      actionClose={true}
      btnAction={handleSubmit(handleContinue)}
    >
      <div className="text-center">
        <ModalTitle variant="subtitle" size="md">
          Please update address information
        </ModalTitle>
      </div>
      <ParagraphText
        variant="reg"
        className="h5"
        style={{marginBottom: '-1.5rem', marginTop: '1rem'}}
      >
        Select country
      </ParagraphText>
      <Controller
        control={control}
        name="prospectsCountry"
        render={({field: {onChange, name}}) => (
          <RadioGroup
            data={RADIO_COUNTRY_SELECTION}
            value={selectedCountry}
            onChange={selectedObject => {
              const selectedCountry = RADIO_COUNTRY_SELECTION.find(
                option => option.value === selectedObject.value,
              )
              onChange(selectedCountry)
              setSelectedCountry(selectedCountry)
            }}
            name={name}
            row
            layoutStyles={{
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: '-5px',
            }}
            register={register}
          />
        )}
      />
      <div
        style={{
          marginTop: '1rem',
          display: 'grid',
          rowGap: '30px',
        }}
      >
        <Input
          className="w-100"
          type="text"
          placeholder="Address"
          aria-label="Address"
          name="prospectsStreet"
          withLabel
          labelType="floating"
          variant="default"
          register={register}
          errors={errors}
        />
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
          <Input
            className="w-50"
            type="text"
            placeholder="City"
            aria-label="City"
            name="prospectsCity"
            withLabel
            labelType="floating"
            variant="default"
            register={register}
            errors={errors}
          />
          <Input
            className="w-50"
            type="text"
            placeholder="Zip"
            aria-label="Zip"
            name="prospectsPostalCode"
            withLabel
            labelType="floating"
            variant="default"
            register={register}
            errors={errors}
          />
        </div>
        <Controller
          control={control}
          name="prospectsState"
          render={({field: {onChange, name}}) => (
            <Select
              positioning="relative"
              options={listOfStates}
              width="md"
              selectLabel="State"
              selectedOption={selectedState}
              setSelectedOption={setState}
              errors={errors}
              onChange={onChange}
              name={name}
              cleared={resetSelectedState}
              setCleared={setResetSelectedState}
              withLabel={selectedState.value}
              register={register}
              largeHeight={true}
            />
          )}
        />
      </div>
    </Modal>
  )
}

export {UpdateBusinessInfoModal}
