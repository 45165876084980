import styled from '@emotion/styled'
import {ParagraphText} from '@thryvlabs/maverick'

export const CouponContainer = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: 90%;
  }
`

export const SendPaymentLinkText = styled(ParagraphText)`
  &::after {
    content: ' *';
    color: #d8544f;
  }
`

export const LinkOptions = styled.div`
  width: 70%;
  display: grid;
  grid-template-columns: 50% 50%;
  & .link_title {
    grid-column: 1/3;
    justify-self: left;
  }
`

export const LinkPrompt = styled.div`
  display: flex;
  border: 1px solid ${props => (props.success ? '#82bdff' : '#EF9595')};
  background-color: ${props => (props.success ? '#e6f2ff' : '#FCEAEA')};
  border-radius: 4px;
  max-width: 340px;
  padding: 8px 12px;
  margin-top: 32px;
`

export const TimelineContainer = styled.div`
  margin-right: ${props => (props.frontendCcPaymentDetails ? '30px' : '0')};
`

export const BillingPromptContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  left: 30px;
  border: 1px solid #e3e6e8;
  border-radius: 4px;
  background-color: #f8f9fb;
  padding: 12px;
  width: 93%;
`
export const CopyTextContainer = styled.div`
  position: relative;
  height: 80px;
  max-height: 80px;
  min-height: 80px;
  overflow: hidden;
  margin: 25px 0px 40px;
  border-radius: 4px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  p {
    font-family: 'Montserrat' sans-serif;
    font-size: 15px;
  }
  button {
    position: absolute;
    bottom: 0px;
    right: 0px;
    max-width: 100%;
    display: flex;
    z-index: 1;
    margin: 0px 0px 0px -1px;
    border-width: 1px 0px 0px 1px;
    border-style: solid none none solid;
    border-right-color: initial;
    border-bottom-color: initial;
    padding: 4px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: rgb(51, 51, 51);
    background: rgb(255, 255, 255);
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    border-top-color: rgba(0, 0, 0, 0.1);
    border-left-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px 0px 0px;
  }
`
