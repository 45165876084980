import React, {useState} from 'react'
import {Header, Button, Icon, ButtonGroup} from '@thryvlabs/maverick'
import {useAuthClient} from '../../utils/use-auth-client'
import {toast} from 'react-toastify'
import AsyncSelect from 'react-select/async'

function CouponModal({
  close,
  code,
  setCode,
  ccCouponInfo,
  setCcCouponInfo,
  selectedCountry,
  selectedCentersAndApps,
}) {
  const [coupon, setCoupon] = useState(code)
  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const client = useAuthClient()
  const handleChange = e => {
    setSelectedCoupon({
      label: e.value,
      value: e.value,
    })
    setCoupon(e.value)
  }

  const loadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length >= 3) {
      fetchNameOptions(inputValue).then(options => {
        callback(options)
      })
    } else if (!inputValue) {
      callback([])
    }
  }

  const fetchNameOptions = async inputValue => {
    const response = await client(
      `cc/order/fetchCoupons/${selectedCountry}/${inputValue}`,
    )
    return response.map(item => ({
      label: item.coupon_code,
      value: item.coupon_code,
    }))
  }

  const validateCoupon = async () => {
    if (coupon && ccCouponInfo?.code !== coupon) {
      setCoupon(coupon.trim())
      try {
        const codes = []
        for (const key in selectedCentersAndApps) {
          codes.push(...selectedCentersAndApps[key].map(x => x.code))
        }
        let url = `orders/coupon/validate?couponCode=${coupon}&country=${selectedCountry}&addonsString=${codes.join()}`
        const response = await client(url)
        if (response.isValid) {
          toast.success('Coupon applied.')
          setCcCouponInfo({...response, code: coupon.trim()})
          setCode(coupon)
        }
        if (!response.isValid) {
          setCode('')
          toast.error('Invalid coupon.')
        }
      } catch (error) {
        setCode('')
        toast.error('Invalid coupon.')
        if (error.status === 404) {
          setCcCouponInfo({isValid: false})
        }
      }
    }
  }

  const handleSubmit = async () => {
    await validateCoupon()
  }

  const resetCoupon = async () => {
    setSelectedCoupon({label: '', name: ''})
    setCoupon(null)
  }

  return (
    <div
      data-testid="coupon-modal"
      className="d-flex flex-column"
      style={{width: '500px', height: '400px'}}
    >
      <div className="w-100 d-flex align-items-center justify-content-between">
        <Header variant="h2" fontWeight="semibold">
          Coupons
        </Header>
        <Icon
          style={{cursor: 'pointer'}}
          onClick={close}
          type="regular"
          variant="x"
          height="18"
          width="18"
          color="#A3A5A7"
        />
      </div>
      <AsyncSelect
        cacheOptions
        placeholder="Enter a Coupon"
        loadOptions={loadOptions}
        onChange={handleChange}
        value={selectedCoupon}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'silver',
            primary: 'black',
          },
        })}
      />
      {selectedCoupon && selectedCoupon.label && selectedCoupon.label.length ? (
        <>
          <Icon
            className="reset-button"
            type="regular"
            variant="circleX"
            color="#FF5000"
            style={{
              width: '18px',
              height: '18px',
              marginTop: '25px',
              marginLeft: '10px',
              marginRight: '10px',
              alignSelf: 'center',
            }}
            onClick={resetCoupon}
          />
        </>
      ) : null}
      <ButtonGroup
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-end',
          columnGap: '16px',
          marginTop: '295px',
        }}
      >
        <Button variant="text" level={1} textVariant="light" onClick={close}>
          CANCEL
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Apply
        </Button>
      </ButtonGroup>
    </div>
  )
}

export {CouponModal}
