const {REACT_APP_GOOGLE_API_KEY: API_KEY} = process.env

const div = document.createElement('div')

let googleServices

function loadGooglePlaceLibScript() {
  if (document.querySelector('#google-maps-place')) {
    return
  }

  const position = document.querySelector('head')
  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', 'google-maps-place')
  script.onload = () => {
    _initService()
  }
  script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&language=en`
  position.appendChild(script)
}

function getPlacePredictions(request) {
  return new Promise((resolve, reject) => {
    googleServices.autocomplete.getPlacePredictions(
      request,
      _handleCB(resolve, reject),
    )
  })
}

function getPlaceDetails(request) {
  return new Promise((resolve, reject) => {
    googleServices.place.getDetails(request, _handleCB(resolve, reject))
  })
}

function getPlaceId(request) {
  return new Promise((resolve, reject) => {
    googleServices.place.findPlaceFromQuery(request, _handleCB(resolve, reject))
  })
}

function _initService() {
  if (!googleServices) {
    googleServices = {
      autocomplete: new window.google.maps.places.AutocompleteService(),
      place: new window.google.maps.places.PlacesService(div),
    }
  }
}

function _handleCB(resolve, reject) {
  return (result, status) => {
    if (
      status === window.google.maps.places.PlacesServiceStatus.OK ||
      status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
    ) {
      return resolve(result)
    }
    return reject(
      new Error('Error querying google. See console error for more infomation'),
    )
  }
}

export {
  loadGooglePlaceLibScript,
  getPlacePredictions,
  getPlaceDetails,
  _initService,
  getPlaceId,
}
