/**
@jsxRuntime
classic */
/** @jsx jsx */
import {jsx} from '@emotion/core'
import {ItemContainer, SelectionAdds} from '../ui/addons-main-components'
import {AddOnItem} from './add-on-item'
import {Header} from '@thryvlabs/maverick'
import {ADD_ON} from '../../utils/addons-data'
import {ADDONS_TYPES, BILLING_PREFERENCES} from '../../utils/constants'
import React from 'react'
import {SliderRange} from '../slider-range'

function OneTime({
  data,
  addProduct,
  removeProduct,
  updateProduct,
  currency,
  listOfProducts,
  selectedCountry,
  currentAddOns,
}) {
  const [sliderStates, setSliderStates] = React.useState({
    Logo: true,
    '5 Extra Website Pages': true,
  })
  const [addedProducts, setAddedProducts] = React.useState([])
  const [includeProduct, setIncludeProduct] = React.useState(false)
  const radioButtonOptions = [
    {label: 'No', value: 'No', default: true},
    {label: 'Yes', value: 'Yes'},
  ]

  const checkPrice = product => {
    if (product.months === undefined) {
      return product.days
    }
    return product.months
  }
  const handleOnChange = (quantity, productItem) => {
    if (quantity !== 0) {
      if (addedProducts.includes(productItem.name)) {
        upsertProduct(productItem, 'update', quantity)
      } else {
        upsertProduct(productItem, 'add', quantity)
      }
    } else {
      removeProduct(
        productItem.months ? productItem.months.id : productItem.days.id,
      )
      setAddedProducts(prev => prev.filter(x => x !== productItem.name))
    }
  }

  const upsertProduct = (productItem, action, quantity) => {
    const prices = checkPrice(productItem)
    const price = prices.currencies.find(x => x.currency === currency)
    let productToUpsert
    if (price.setupFee) {
      productToUpsert = {
        id: productItem.months.id,
        product:
          ADD_ON.one_time[productItem.name]?.setupFeeLabel || productItem.name,
        type: ADDONS_TYPES['ONE_TIME'],
        price: `$${price.setupFee
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
        billingPreference: BILLING_PREFERENCES[2],
        planCode: prices.code,
      }
    } else {
      productToUpsert = {
        id: productItem.days.id,
        product: productItem.name,
        type: ADDONS_TYPES['ONE_TIME'],
        price: `$${(price.unitAmount * (quantity || 1))
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
        billingPreference: BILLING_PREFERENCES[2],
        planCode: prices.code,
        quantity: quantity || 1,
      }
    }
    if (action === 'add') {
      addProduct([productToUpsert])
      setAddedProducts(prev => [...prev, productToUpsert.product])
    } else {
      updateProduct(productToUpsert)
    }
  }
  const handleAddProduct = item => {
    const product = data.find(x => x.name === item.name)
    if (item.value === 'Yes') {
      setIncludeProduct(true)
      setSliderStates({...sliderStates, [product.name]: false})
      upsertProduct(product, 'add')
    } else {
      setIncludeProduct(false)
      setSliderStates({...sliderStates, [product.name]: true})
      removeProduct(product.months ? product.months.id : product.days.id)
      setAddedProducts(prev => prev.filter(x => x !== product.name))
    }
  }
  const setItemContainer = item => {
    const addOnDataItem = ADD_ON.one_time[item.name]
    const code = item?.days?.code

    if (item.name === ADD_ON.one_time['Photo Package'].label) {
      return
    }

    if (
      item.name === ADD_ON.one_time['5 Extra Website Pages'].label &&
      listOfProducts.find(
        x => x.product === ADD_ON.entitlements['Website'].label,
      )
    ) {
      return (
        <AddOnItem
          key={item.name}
          title={item.name}
          code={code}
          description={addOnDataItem?.description}
          options={radioButtonOptions}
          setState={handleAddProduct}
          listOfProducts={listOfProducts}
          addonType={ADDONS_TYPES['ONE_TIME']}
          currentAddOns={currentAddOns}
        >
          <div className="mb-4">
            <SliderRange
              key={item.name}
              product={item}
              handleOnChange={handleOnChange}
              isDisabled={sliderStates[item.name]}
              maxValue={addOnDataItem.maxValue}
              range={addOnDataItem.range}
              symbolTemplate={
                addOnDataItem.symbol ? `{0} ${addOnDataItem.symbol}` : ''
              }
              includeProduct={includeProduct}
            />
          </div>
        </AddOnItem>
      )
    } else if (item.name === ADD_ON.one_time['5 Extra Website Pages'].label) {
      return null
    }
    if (
      (item.name === ADD_ON.one_time['Photo Package'].label &&
        selectedCountry !== 'can') ||
      (item.name === ADD_ON.one_time['Custom Video'].label &&
        selectedCountry === 'usa')
    ) {
      return (
        <AddOnItem
          key={item.name}
          title={item.name}
          code={code}
          description={addOnDataItem?.description}
          options={radioButtonOptions}
          setState={handleAddProduct}
          listOfProducts={listOfProducts}
          addonType={ADDONS_TYPES['ONE_TIME']}
          currentAddOns={currentAddOns}
        />
      )
    } else if (
      item.name === ADD_ON.one_time['Logo'].label ||
      item.name === ADD_ON.one_time['5 Extra Website Pages'].label
    ) {
      return (
        <AddOnItem
          key={item.name}
          title={item.name}
          code={code}
          description={addOnDataItem?.description}
          options={radioButtonOptions}
          setState={handleAddProduct}
          listOfProducts={listOfProducts}
          addonType={ADDONS_TYPES['ONE_TIME']}
          currentAddOns={currentAddOns}
        >
          <div className="mb-4">
            <SliderRange
              key={item.name}
              product={item}
              handleOnChange={handleOnChange}
              isDisabled={sliderStates[item.name]}
              maxValue={addOnDataItem.maxValue}
              range={addOnDataItem.range}
              symbolTemplate={
                addOnDataItem.symbol ? `{0} ${addOnDataItem.symbol}` : ''
              }
              includeProduct={includeProduct}
            />
          </div>
        </AddOnItem>
      )
    }
  }

  return (
    <SelectionAdds>
      <Header fontWeight="bold" variant="h6" className="ml-3">
        One-Time Purchases
      </Header>
      <ItemContainer>{data?.map(item => setItemContainer(item))}</ItemContainer>
    </SelectionAdds>
  )
}
export {OneTime}
