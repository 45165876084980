/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {getAuthByRole} from '../utils/get-screen-auth-by-role'
import {AUTHORIZED_ROLES_BY_SCREENS} from '../utils/constants'
import {useUserRoles} from '../utils/use-user-roles'
import {NavLink} from 'react-router-dom'
import {TabOptionsContainer} from './ui/sidebar'

function setAccountsSubLinks(roles) {
  const accountsSubLinks = [
    {
      name: 'My Accounts',
      url: '/my-accounts',
    },
  ]
  const {allAccount} = AUTHORIZED_ROLES_BY_SCREENS
  if (getAuthByRole(allAccount, roles)) {
    accountsSubLinks.push({
      name: 'All Accounts',
      url: '/all-accounts',
    })
  }
  return accountsSubLinks
}

function setAppointmentsSubLinks(roles) {
  const appointmentsSubLinks = []
  const {demoRequests, appointments} = AUTHORIZED_ROLES_BY_SCREENS

  if (getAuthByRole(demoRequests, roles)) {
    appointmentsSubLinks.push({
      name: 'Demo Requests',
      url: '/demo-requests',
    })
  }
  if (getAuthByRole(appointments, roles)) {
    appointmentsSubLinks.push({
      name: 'Onboarding',
      url: '/appointments',
    })
  }
  return appointmentsSubLinks
}

const TabOptions = ({screen}) => {
  const [options, setOptions] = useState([])
  const {roles} = useUserRoles()
  const stringifiedRoles = JSON.stringify(roles)

  useEffect(() => {
    if (screen === 'accounts') {
      setOptions(setAccountsSubLinks(JSON.parse(stringifiedRoles)))
    } else if (screen === 'appointments') {
      setOptions(setAppointmentsSubLinks(JSON.parse(stringifiedRoles)))
    }
  }, [screen, stringifiedRoles])
  return (
    <div
      style={{
        borderBottom: '1px solid lightgrey',
        marginLeft: '-17px',
      }}
    >
      <TabOptionsContainer>
        {options.map(option => (
          <NavLink
            key={option.name}
            className={`px-2 py-1 menu-item`}
            to={option.url}
            style={{
              color: '#6F6F6F',
              fontSize: '14px',
              fontWeight: '500',
              marginRight: '15px',
            }}
          >
            {option.name}
          </NavLink>
        ))}
      </TabOptionsContainer>
    </div>
  )
}

export {TabOptions}
