import React, {useState, useEffect} from 'react'
import {ParagraphText, Input, Icon} from '@thryvlabs/maverick'
import {InitialsContainer, ProfileContainer} from '../ui/user-profile'
import SkeletonLoader from '../skeleton-loader'
import {useForm} from 'react-hook-form'
import {toast} from 'react-toastify'
import {useAuthClient} from '../../utils/use-auth-client'
import {thryvLean} from '../../assets'

const UserProfile = ({data, profileRef, fetchUserData, fetchQrCode}) => {
  const [isPhoneInputVisible, setIsPhoneInputVisible] = useState(false)
  const {register, handleSubmit, errors} = useForm({
    mode: 'onSubmit',
    defaultValues: {phoneNumber: data?.phoneNumber || ''},
  })
  const client = useAuthClient()

  const initials = data?.firstName[0] + data?.lastName[0]

  useEffect(() => {
    if (data?.qrCode) return
    fetchQrCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleSave(formData) {
    try {
      if (formData.phoneNumber === data?.phoneNumber || !formData.phoneNumber) {
        handleInputVisibility()
        return
      }
      const response = await client('user/profile/phone-number', {
        method: 'PUT',
        data: formData,
      })
      if (response === 'Profile updated') {
        await fetchUserData()
        handleInputVisibility()
        toast.success('Phone number saved successfully')
      }
    } catch (e) {
      toast.error('Failed to save phone number')
    }
  }

  function handleInputVisibility() {
    setIsPhoneInputVisible(!isPhoneInputVisible)
  }

  return (
    <ProfileContainer ref={profileRef} style={{cursor: 'default'}}>
      <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
        <InitialsContainer>
          <ParagraphText
            variant="sm"
            className="m-0"
            style={{fontWeight: 'bold', color: 'white', padding: '3px'}}
          >
            {initials}
          </ParagraphText>
        </InitialsContainer>
        <div style={{width: '85%'}}>
          <ParagraphText
            variant="lg"
            className="m-0"
            style={{fontWeight: 'bold', wordBreak: 'break-all'}}
          >
            {data?.firstName} {data?.lastName}
          </ParagraphText>
        </div>
      </div>
      <div className="contact-info">
        <div>
          <ParagraphText
            variant="reg"
            className="m-0"
            style={{fontWeight: 'bold'}}
          >
            Email
          </ParagraphText>
          <ParagraphText
            variant="reg"
            className="m-0"
            style={{wordBreak: 'break-all'}}
          >
            {data?.email}
          </ParagraphText>
        </div>
        <div>
          <ParagraphText
            variant="reg"
            className="m-0"
            style={{fontWeight: 'bold'}}
          >
            Cell
          </ParagraphText>
          {!isPhoneInputVisible ? (
            <ParagraphText variant="reg" className="m-0">
              {data?.phoneNumber}
            </ParagraphText>
          ) : (
            <Input
              register={register}
              errors={errors}
              variant="default"
              name="phoneNumber"
              type="text"
              placeholder="Phone Number"
            />
          )}
        </div>
        <ParagraphText
          onClick={
            !isPhoneInputVisible
              ? handleInputVisibility
              : handleSubmit(handleSave)
          }
          variant="reg"
          className="m-0"
          style={{color: '#097cff', alignSelf: 'self-end', cursor: 'pointer'}}
        >
          {!isPhoneInputVisible ? 'Edit' : 'Save'}
        </ParagraphText>
      </div>
      <div className="link-info">
        <ParagraphText
          variant="lg"
          className="m-0"
          style={{fontWeight: 'bold', paddingBottom: '15px'}}
        >
          Invitation QR Code
        </ParagraphText>
        <div className="code-container">
          <div style={{height: '100px', width: '100px'}}>
            {data?.qrCode ? (
              <div
                className="d-flex justify-center"
                style={{marginLeft: '-15px', marginRight: '15px'}}
              >
                <img
                  style={{
                    marginRight: '-30px',
                    marginTop: '20px',
                    height: '80px',
                  }}
                  alt="Thryv Guy"
                  src={thryvLean}
                />
                <img
                  style={{
                    boxShadow:
                      'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                    height: '90px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                  }}
                  alt="QR Code"
                  src={data?.qrCode}
                />
              </div>
            ) : (
              <SkeletonLoader />
            )}
          </div>
          <ParagraphText
            variant="reg"
            style={{fontWeight: 'bold', margin: '10px 0px 0px 0px'}}
          >
            Join from your Mobile device
          </ParagraphText>
        </div>
      </div>
      <div className="link-wrapper">
        {data?.qrCode && (
          <a href={data?.qrCode} download="qrCode.png">
            <Icon type="solid" variant="cloudDownload" color="#ff5000" />
          </a>
        )}
      </div>
    </ProfileContainer>
  )
}

export {UserProfile}
