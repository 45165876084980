import React from 'react'
import {ParagraphText} from '@thryvlabs/maverick'
import {currencyFormat, formatPrice} from '../utils/currency-format'
import {ADD_ON} from '../utils/addons-data'
import {CartContainer} from './ui/cartProducts'

function CartProducts({
  listOfProducts,
  selectedCountry,
  currentStep,
  currentPlanName,
  currentPlanPrice,
}) {
  const getColor = highlighted => {
    if (highlighted) return 'thryv-black-500'
    return 'thryv-gray-medium-500'
  }
  return (
    <CartContainer className={currentStep === 9 ? 'ml-5 mt-4' : ''}>
      {listOfProducts.map(
        (
          {product, elements, price, highlighted, quantity, type, planCode},
          i,
        ) => {
          price =
            price && !price?.includes('$') && selectedCountry?.length === 2
              ? currencyFormat(price, selectedCountry)
              : price
          if (price && !price.includes('$')) {
            price = '$' + price
          }
          if (
            (quantity && product === 'Logo') ||
            product === '5 Extra Website Pages'
          ) {
            quantity = ' x ' + quantity
          } else {
            quantity = ''
          }
          if (
            (ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(
              planCode,
            ) ||
              product === 'Thryv Leads') &&
            type === 'selectedPlan'
          ) {
            return (
              <div key={i}>
                <li key={i}>
                  <ParagraphText
                    variant="reg"
                    color={getColor(highlighted)}
                    style={{marginRight: '2rem'}}
                  >
                    {currentPlanName}
                  </ParagraphText>
                  <ParagraphText variant="reg" color={getColor(highlighted)}>
                    {formatPrice(currentPlanPrice)}
                  </ParagraphText>
                </li>
              </div>
            )
          }
          return (
            <div key={i}>
              {product === 'Due Monthly' &&
              listOfProducts
                .find(x => x.product === 'Incentive')
                ?.elements?.find(coupon =>
                  coupon.code?.includes('transition'),
                ) ? null : (
                <li key={i}>
                  <ParagraphText
                    variant="reg"
                    color={getColor(highlighted)}
                    style={{marginRight: '2rem'}}
                  >
                    {highlighted ? (
                      <strong>{product}</strong>
                    ) : quantity ? (
                      `${product}${quantity}`
                    ) : (
                      product
                    )}
                  </ParagraphText>
                  {product === 'Incentive' ? (
                    <ParagraphText variant="reg">
                      <strong>-{price}</strong>
                    </ParagraphText>
                  ) : (
                    <ParagraphText variant="reg" color={getColor(highlighted)}>
                      {highlighted ? <strong>{price}</strong> : price}
                    </ParagraphText>
                  )}
                </li>
              )}

              {product === 'Incentive' &&
                elements.map(({description, name, discountAmount}, i) => (
                  <li style={{paddingLeft: '15px'}} key={i}>
                    <ParagraphText variant="sm">
                      {discountAmount ? (
                        <strong>{description || name}</strong>
                      ) : (
                        <strong>{description}</strong>
                      )}
                    </ParagraphText>
                  </li>
                ))}
            </div>
          )
        },
      )}
    </CartContainer>
  )
}

export {CartProducts}
