/**
@jsxRuntime
classic */
/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from 'react'
import {Icon, Radio, Header, Tooltip, ParagraphText} from '@thryvlabs/maverick'
import {useEffect} from 'react'
import {ADDONS_TYPES} from '../../utils/constants'
import {ADD_ON} from '../../utils/addons-data'
import {useUserRoles} from '../../utils/use-user-roles'

function AddOnItem({
  title,
  description,
  options,
  children,
  setState,
  code,
  addonType = '',
  listOfProducts,
  radioButtonDisable,
  isDisabled,
  addBorderBottom = true,
  isMCProInCart,
  isMCPlusInCart,
  wasMCPlusSelected,
  isStarterGrowthInCart,
  isExpandedGrowthInCart,
  wasStarterPkgSelected,
  currentAddOns,
}) {
  const defaultOption = options.find(x => x.default)
  const [optionValue, setOptionValue] = React.useState(defaultOption.value)
  const [hadMCPlusbeenAdded, setHadMCPlusbeenAdded] = React.useState(false)
  const {roles} = useUserRoles()
  const isMarketingCenterPlan =
    listOfProducts?.some(
      x =>
        x.product === ADD_ON.add_ons['Marketing Center Plus'].label &&
        x.type === ADDONS_TYPES['SELECTED_PLAN'],
    ) &&
    (roles.includes('PremisePilot') ||
      roles.includes('LeadConverter') ||
      roles.includes('Admin'))
  const hasMCAddon = listOfProducts?.some(
    x =>
      x.product === ADD_ON.add_ons['Marketing Center Plus'].label &&
      x.type === ADDONS_TYPES['ADD_ON'],
  )
  const hasGMBOEnt = listOfProducts?.some(
    x =>
      x.product === ADD_ON.add_ons['Google My Business Optimization'].label &&
      x.type === ADDONS_TYPES['ENTITLEMENTS'],
  )
  useEffect(() => {
    const isAlreadyInAccount = currentAddOns?.some(
      ({addOnCode, name: addOnName}) =>
        addOnCode === code || addOnName === title,
    )

    const isAlreadyInCart = listOfProducts?.some(
      ({planCode, product}) => planCode === code || product === title,
    )

    const isGMB = code?.includes('gmb')

    if (
      defaultOption?.label === 'Yes' &&
      !isAlreadyInAccount &&
      !isAlreadyInCart &&
      !isGMB
    ) {
      setState({value: 'Yes', name: title, planCode: code})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnChange = e => {
    setOptionValue(e.target.value)
    if (setState) {
      setState({value: e.target.value, name: title, planCode: code})
    }
  }
  useEffect(() => {
    if (
      title === ADD_ON.entitlements['Google My Business Optimization'].label
    ) {
      const gmbSelected = listOfProducts.some(
        product =>
          product.product ===
          ADD_ON.entitlements['Google My Business Optimization'].label,
      )
      if (gmbSelected) {
        setOptionValue('Yes')
      } else {
        setOptionValue('No')
      }
    }
  }, [listOfProducts, title])
  useEffect(() => {
    if (!isMarketingCenterPlan) return

    const isMCProIncluded = currentAddOns.some(({addOnCode}) =>
      ADD_ON.add_ons['Marketing Center Pro'].addOnCodes.includes(addOnCode),
    )

    if (
      title === ADD_ON.add_ons['Marketing Center Plus'].label &&
      !hasMCAddon &&
      isMarketingCenterPlan &&
      !hadMCPlusbeenAdded &&
      !isMCProIncluded
    ) {
      setState({value: 'Yes', name: title, planCode: code})
      setHadMCPlusbeenAdded(true)
    }
    if (
      title === ADD_ON.add_ons['Google My Business Optimization'].label &&
      hasMCAddon &&
      isMarketingCenterPlan &&
      !hasGMBOEnt
    ) {
      setState({value: 'Yes', name: title, planCode: code})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, listOfProducts, setState, title])

  // MCboost addons
  useEffect(() => {
    const isStarterPkg =
      title === ADD_ON.add_ons['Starter Growth Package'].label

    if (isStarterPkg && isExpandedGrowthInCart) {
      setOptionValue('No')
    }
    if (isStarterPkg && !wasStarterPkgSelected && isStarterGrowthInCart) {
      setOptionValue('Yes')
    }
  }, [
    isExpandedGrowthInCart,
    title,
    isStarterGrowthInCart,
    wasStarterPkgSelected,
  ])

  useEffect(() => {
    const isMCPlus = title === ADD_ON.add_ons['Marketing Center Plus'].label

    if (isMCPlus && isMCProInCart) {
      setOptionValue('No')
    }
    if (isMCPlus && !wasMCPlusSelected && isMCPlusInCart) {
      setOptionValue('Yes')
    }
  }, [isMCProInCart, title, isMCPlusInCart, wasMCPlusSelected])

  const displayedPrice = listOfProducts
    ?.find(
      x =>
        x.product === title &&
        x.type !== ADDONS_TYPES['SELECTED_PLAN'] &&
        x.type !== ADDONS_TYPES['ENTITLEMENTS'],
    )
    ?.price.toString()
  return (
    <div
      className="ml-3"
      style={{borderBottom: addBorderBottom ? '1px solid #eee' : 'none'}}
    >
      <div className="row" style={{margin: '1rem 0rem', alignItems: 'center'}}>
        <div className="col-lg-6">
          <div className="float-left d-flex" style={{position: 'relative'}}>
            <Header className="mr-2" fontWeight="normal" variant="h4">
              {title}
              {listOfProducts &&
                addonType !== ADDONS_TYPES['ENTITLEMENTS'] &&
                listOfProducts?.find(x => x.product === title) && (
                  <ParagraphText variant="reg" color="thryv-gray-medium-500">
                    {displayedPrice && '$'}
                    {displayedPrice?.includes('$')
                      ? displayedPrice.slice(1)
                      : displayedPrice}
                  </ParagraphText>
                )}
            </Header>
            {description && (
              <Tooltip
                variant="right"
                description={description}
                positioning={{offsetY: '-10px'}}
                width="400px"
              >
                <Icon
                  type="regular"
                  variant="infoCircle"
                  color="#FF5000"
                  width="17"
                  height="30"
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="float-right" style={{position: 'relative'}}>
            <div className="row">
              {options?.map((option, key) => (
                <Radio
                  key={key}
                  className="ml-6"
                  hasLabel
                  id={`${title}-${option.value}-${addonType}`}
                  name={`${title}-${addonType}`}
                  value={option.value}
                  label={option.label}
                  toggled={optionValue === option.value}
                  handleOnChange={handleOnChange}
                  disabled={radioButtonDisable || isDisabled}
                  data-testid={`${title}-${option.value}-${addonType}-${
                    optionValue === option.value
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">{children}</div>
      </div>
    </div>
  )
}

export {AddOnItem}
