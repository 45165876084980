import React from 'react'

import {DefaultContainer, MainContainer} from './ui/loader-spinner'

function LoaderSpinner() {
  return (
    <div className="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

function AppSpinner() {
  return (
    <MainContainer>
      <LoaderSpinner />
    </MainContainer>
  )
}

function Spinner() {
  return (
    <DefaultContainer>
      <LoaderSpinner />
    </DefaultContainer>
  )
}

export {AppSpinner, Spinner}
