export const large = '@media (min-width: 1200px)'
export const medium = '@media (min-width: 992px) and (max-width: 1199px)'
export const small = '@media (max-width: 991px)'

export const device = {
  mobileS: '(max-width: 320px)',
  mobileM: '(max-width: 375px)',
  mobileL: '(max-width: 425px)',
  tablet: '(max-width: 768px)',
  laptop: '(max-width: 1024px)',
  laptopL: '(max-width: 1440px)',
  desktop: '(max-width: 2560px)',
  desktopL: '(max-width: 2560px)',
}
