import React from 'react'

const headerContext = React.createContext()

function HeaderProvider({children}) {
  const [isHeaderVisible, setIsHeaderVisible] = React.useState(true)

  const value = React.useMemo(() => {
    return {isHeaderVisible, setIsHeaderVisible}
  }, [isHeaderVisible])

  return (
    <headerContext.Provider value={value}>{children}</headerContext.Provider>
  )
}

export {HeaderProvider, headerContext}
