const checkIfIncentiveApplies = (
  currentCenter,
  isUpgrade,
  eligibleTransitionCenters,
  incentiveCoupon,
) => {
  const isValidAmount = currentCenter.amount > 0
  const centerApplies = incentiveCoupon?.appliesToPlans?.includes(
    currentCenter.code,
  )
  if (
    isUpgrade &&
    eligibleTransitionCenters?.includes(currentCenter.name.toLowerCase())
  ) {
    return true
  }
  if (!isUpgrade && isValidAmount && centerApplies) {
    return true
  }
  if (!isUpgrade && isValidAmount) {
    return true
  }
  return false
}

export default checkIfIncentiveApplies
