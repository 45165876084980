/**
@jsxRuntime
classic */
/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Button, Select} from '@thryvlabs/maverick'
import {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import dayjs from 'dayjs'
import NumberFormat from 'react-number-format'

import {useAuthClient} from '../utils/use-auth-client'
import {useAsync} from '../utils/use-async'
import {useUserRoles} from '../utils/use-user-roles'
import {getAuthByRole} from '../utils/get-screen-auth-by-role'
import {AUTHORIZED_ROLES_BY_SCREENS} from '../utils/constants'
import {ResultsTable} from '../components/results-table'
import {
  ResultsLayoutContainer,
  ResultsLayoutHeader,
} from '../components/ui/results-layout'
import {HealthScore} from '../components/health-score'
import {DetailsModal} from '../components/details-modal'
import NotFoundPage from '../components/not-found-page'
import {SearchButton} from '../components/search-button'
import {ChangeSubscriptionModal} from '../components/change-subscription-modal'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {Spinner} from '../components/loader-spinner'
import {TabOptions} from '../components/tab-options'

const OPTIONS = [
  {
    name: 'Company',
    value: 'company',
  },
  {
    name: 'Thryv ID',
    value: 'thryv_id',
  },
  {
    name: 'EID',
    value: 'salesforce_id',
  },
  {
    name: 'Command Center ID',
    value: 'cc_id',
  },
]

const ENDPOINT = 'accounts'

function Container({children, frontendSideNav}) {
  if (frontendSideNav) {
    return (
      <div>
        <TabOptions screen={'accounts'} />
        {children}
      </div>
    )
  }
  return children
}

function AllAccounts() {
  const firstRowRef = useRef(null)
  const [selectedOption, setSelectedOption] = useState({
    name: 'Company',
    value: 'company',
  })
  const [searchParam, setSearchParam] = useState('')
  const [accountsList, setAccountsList] = useState([])
  const client = useAuthClient()
  const {data, setData, isError, error} = useAsync()
  const [tableMessage, setTableMessage] = useState(
    'Please search for an account.',
  )
  const {roles} = useUserRoles()
  const navigate = useNavigate()
  const {frontendSubscriptionOverride, frontendSideNav} = useFlags()
  const [offSet, setOffSet] = useState(0)
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const searchAccounts = async (term, offSet) => {
    if (term) {
      const response = await client(
        `${ENDPOINT}?${selectedOption.value}=${term}&offset=${offSet}`,
      )
      setAccountsList(response.rows)
      setShowLoadMore(response?.row_count >= 50)
      if (response.row_count === 0) {
        setTableMessage('')
      }
      setOffSet(offSet)
      return response
    } else {
      setAccountsList([])
      setTableMessage('Please search for an account.')
      setShowLoadMore(false)
    }
  }

  const getAccounts = async value => {
    setSearchParam(value)
    setIsLoading(true)
    const response = await searchAccounts(value, 0)
    setIsLoading(false)
    setData(response)
  }

  const loadMore = async () => {
    if (searchParam) {
      setLoadingMore(true)
      const response = await searchAccounts(searchParam, offSet + 50)
      setAccountsList([...accountsList, response.rows])
      setShowLoadMore(response?.row_count >= 50)
      response.rows[0].firstRow = true
      const newData = {
        rows: [...data.rows, ...response.rows],
        row_count: data.row_count + response.row_count,
      }
      setData(newData)
      if (response.length === 0) {
        setTableMessage('')
      }
      setLoadingMore(false)
      firstRowRef?.current?.scrollIntoView({behavior: 'smooth'})
    }
  }

  useEffect(() => {
    if (isError && error && error.error) {
      throw error
    }
  }, [isError, error])

  const setDetailsData = async (
    subscriptionId,
    businessName,
    thryvId,
    eId,
    ccId,
  ) => {
    let addOnsArray
    let response = {}
    try {
      response = await client(`subscription/uuid-${subscriptionId}`)
      addOnsArray = response.addOns.reduce((acc, {name, quantity}, index) => {
        const addOn = `${name} (Qty: ${quantity})\n`
        return [...acc, addOn]
      }, [])
    } catch (err) {
      if (err.status !== 404) {
        throw err
      }
    }

    const section1 = {
      sectionType: 'object',
      sectionName: businessName,
      sectionData: {
        'Thryv Plan': response.planName || '-',
        'Add Ons':
          response.addOns && response.addOns.length > 0 ? addOnsArray : '-',
        'Current Total': response.planPrice ? (
          <NumberFormat
            value={response.planPrice}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        ) : (
          '-'
        ),
        Status: response.status || '-',
        'Start Date': response.recurlyDate
          ? dayjs(response.recurlyDate).format('MMMM DD, YYYY, LT')
          : '-',
        'Thryv Id': thryvId || '-',
        EID: eId || '-',
        'Command Center Id': ccId || '-',
        Email: response.recurlyEmail || '-',
      },
    }

    return [section1]
  }
  if (!getAuthByRole(AUTHORIZED_ROLES_BY_SCREENS.allAccount, roles)) {
    return <NotFoundPage />
  }

  return (
    <Container frontendSideNav={frontendSideNav}>
      <ResultsLayoutContainer>
        <ResultsLayoutHeader
          frontendSideNav={frontendSideNav}
          style={
            frontendSideNav
              ? {
                  justifyContent: 'flex-end',
                }
              : {}
          }
        >
          <Select
            options={OPTIONS}
            width="md"
            selectLabel="Select"
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            className="mr-4"
          />
          {frontendSideNav ? (
            <SearchButton
              placeholder="Search Accounts"
              onSearch={getAccounts}
              searchOnUsserType={true}
            />
          ) : (
            <div>
              <SearchButton
                placeholder="Search Accounts"
                onSearch={getAccounts}
                searchOnUsserType={true}
              />
            </div>
          )}
        </ResultsLayoutHeader>
        <ResultsTable
          cols={['Company', 'Thryv Id', ' Date Created']}
          isLoading={isLoading}
          hasRecords={accountsList.length > 0}
          noDataFoundMessage={tableMessage}
        >
          {data?.rows?.map(
            (
              {
                THRYV_ID,
                BUSINESS_NAME,
                CREATED_DATE,
                FREQUENCY,
                RECURLY_SUBSCRIPTION_ID,
                EID,
                CCID,
                cust_industry,
                firstRow,
              },
              i,
            ) => (
              <tr key={i} ref={firstRow && firstRowRef}>
                <td className="">
                  <span className="text-btn-links font-normal font-montserrat text-thryv-black-500 mr-4">
                    {BUSINESS_NAME}
                  </span>
                  <HealthScore score={FREQUENCY} />
                </td>
                <td>
                  <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                    {THRYV_ID}
                  </span>
                </td>
                <td>
                  <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                    {dayjs(CREATED_DATE).format('YYYY-MM-DD')}
                  </span>
                </td>
                <td>
                  <div className="d-flex justify-content-around">
                    <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                      <DetailsModal
                        getData={() =>
                          setDetailsData(
                            RECURLY_SUBSCRIPTION_ID,
                            BUSINESS_NAME,
                            THRYV_ID,
                            EID,
                            CCID,
                          )
                        }
                        title="Subscription Details"
                        buttonName="Details"
                        height={'20em'}
                      />
                    </span>
                    <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                      {!frontendSubscriptionOverride && (
                        <Button
                          variant="secondary"
                          onClick={() =>
                            navigate('/order/upgrade', {
                              state: {
                                order: {
                                  billingAccountId: RECURLY_SUBSCRIPTION_ID,
                                  thryvId: THRYV_ID,
                                  template: cust_industry,
                                },
                                currentStep: 1,
                              },
                            })
                          }
                        >
                          Manage
                        </Button>
                      )}
                      {frontendSubscriptionOverride && (
                        <ChangeSubscriptionModal
                          subId={RECURLY_SUBSCRIPTION_ID}
                          thryvId={THRYV_ID}
                        />
                      )}
                    </span>
                  </div>
                </td>
              </tr>
            ),
          )}
        </ResultsTable>
        {data?.row_count > 0 && (
          <div className="d-flex justify-content-center">
            {showLoadMore && (
              <div>
                {loadingMore ? (
                  <Spinner />
                ) : (
                  <Button className="mt-5" variant="primary" onClick={loadMore}>
                    Load More...
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </ResultsLayoutContainer>
    </Container>
  )
}

export {AllAccounts}
