import React, {useState} from 'react'
import {ParagraphText, Icon} from '@thryvlabs/maverick'
import {CartItem} from './cart-item'
import {TotalDueContainer} from '../ui/payment-information'
import {formatPrice} from '../../utils/currency-format'

const CCDesktopMonthlySummary = ({
  getProducts,
  paymentState,
  itemComparator,
  isAusNZ,
  hasSupportFee,
  supportFee,
}) => {
  const [monthlyTaxesAndFeesOpen, setMonthlyTaxesAndFeesOpen] = useState(true)

  return (
    <>
      <ParagraphText
        variant="lg"
        style={{fontSize: '16px', fontWeight: 'bold'}}
      >
        Due Monthly
      </ParagraphText>
      <div className="monthly_container">
        {getProducts(paymentState.monthlyAddons)
          .filter(item => item.code !== '247support-m2m')
          .sort(itemComparator)
          .map((item, index) => (
            <CartItem key={index} item={item} />
          ))}

        {(!isAusNZ || (isAusNZ && hasSupportFee)) && (
          <div className="d-flex justify-content-between align-items-center">
            <ParagraphText
              variant="reg"
              className="m-0 font-weight-bold"
              style={{color: '#4D4D4D'}}
            >
              {isAusNZ ? 'Fees' : 'Tax & Fees'}
            </ParagraphText>
            <button
              className="w-11/12 mr-3"
              onClick={() => setMonthlyTaxesAndFeesOpen(prevVal => !prevVal)}
              aria-describedby={`Click to access the charges due today below.`}
            >
              <div
                className={`flex row flex-row-reverse justify-content-between cursor-pointer border-thryv-gray-light-400`}
              >
                <div className="flex flex-row-reverse text-center gap-4">
                  <Icon
                    type={'solid'}
                    variant={`caretDown`}
                    className={`fill-current text-thryv-black-500 -rotate-90 ${
                      monthlyTaxesAndFeesOpen && 'rotate-0'
                    } transition-all duration-300 ease-in-out cursor-pointer`}
                    height={'10'}
                    width={'10'}
                  />
                  <ParagraphText variant="reg" className="m-0">
                    &nbsp;
                  </ParagraphText>
                </div>
              </div>
            </button>
          </div>
        )}

        <div
          className={`${
            !monthlyTaxesAndFeesOpen
              ? 'hidden opacity-0 scale-0 transition-all duration-500 ease-in-out '
              : 'visible opacity-100 scale-100 transition-all duration-700 ease-in-out '
          }`}
        >
          {!isAusNZ && (
            <div className="d-flex justify-content-between">
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                Tax
              </ParagraphText>
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                To be calculated
              </ParagraphText>
            </div>
          )}
          {hasSupportFee ? (
            <div className="d-flex justify-content-between">
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                24/7 Service & Support Fee
              </ParagraphText>
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                {supportFee
                  ? formatPrice(supportFee.currency.unitAmount)
                  : '$0.00'}
              </ParagraphText>
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                Fees
              </ParagraphText>
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                $0.00
              </ParagraphText>
            </div>
          )}
        </div>
      </div>
      <TotalDueContainer>
        <ParagraphText
          variant="reg"
          style={{fontWeight: 'bold'}}
          className="m-0"
        >
          Total Due Monthly
        </ParagraphText>
        <ParagraphText
          variant="reg"
          style={{fontWeight: 'bold'}}
          className="m-0"
        >
          {isAusNZ
            ? formatPrice(paymentState.monthlyAmount)
            : `${formatPrice(paymentState.monthlyAmount)} + tax*`}
        </ParagraphText>
      </TotalDueContainer>
    </>
  )
}

export {CCDesktopMonthlySummary}
