import React from 'react'
import {CriteriaContainer} from './criteria-container'
import {RatingDropdown} from './rating-dropdown'
import {Header, Icon, Tooltip, ParagraphText as Text} from '@thryvlabs/maverick'

import {getSiteIcon} from '../../utils/get-site-icon'
import {SCORE_CLASS_NAMES, SCORE_COLORS} from '../../utils/constants'

import {
  WebsiteAnalysisContainer,
  WebsiteAnalysisItemContainer,
  WebsiteAnalysisRow,
  ListingAnalysisContainer,
  ListingAnalysisItemRow,
  ListingAnalysisItemCellContainer,
} from '../ui/business-results'
import {analysisIllustration2} from '../../assets'

export const WEBSITE_ANALYSIS_ICON_CONFIG = [
  {icon: 'circleCheck', color: SCORE_COLORS[0]},
  {icon: 'infoCircle', color: SCORE_COLORS[3]},
  {icon: 'circleX', color: SCORE_COLORS[2]},
]

function setWebsiteItems({
  alternativeText,
  analytics,
  brokenLinks,
  ssl,
  titlesDescriptions,
  mobileSpeed,
}) {
  const result = []
  const hasAlternativeText = alternativeText === null || alternativeText === 0
  result.push({
    iconStatus: hasAlternativeText ? 0 : 2,
    description: 'Alternative Text',
    statusDescription: alternativeText || 'No issues',
    status: hasAlternativeText,
    extraInfo:
      'Alternative text or Alt Text is a word or phrase that tells website viewers the nature or contents of an image. Alt text should include relevant keywords which can improve your website’s search engine optimization (SEO).',
  })
  result.push({
    iconStatus: analytics === 'Multiple found' ? 0 : 2,
    description: 'Analytics',
    statusDescription:
      analytics === 'Multiple found' ? analytics : 'None detected',
    status: analytics === 'Multiple found',
    extraInfo:
      'Analyzes the behavior of visitors to a website, including page views, clicks, bounce rate, average session duration, and more.',
  })
  result.push({
    iconStatus: brokenLinks ? 2 : 0,
    description: 'Broken links',
    statusDescription: brokenLinks || 'None detected',
    status: !!brokenLinks,
    extraInfo:
      'These are links on a web page that lead to a non-existent page, websites or image. When a broken link is clicked, an error message is displayed, which create bad impressions and unprofessional images.',
  })
  result.push({
    iconStatus: ssl ? 0 : 2,
    description: 'SSL',
    statusDescription: ssl ? 'Detected' : 'Not found',
    status: ssl,
    extraInfo:
      'SSL (Secure Sockets Layer) is the standard security technology for establishing an encrypted link between a web server and a browser. Detects whether the SSL certificate on the web server is correctly installed, valid, trusted, and does not give any errors to users.',
  })
  result.push({
    iconStatus: titlesDescriptions ? 2 : 0,
    description: 'Titles and descriptions',
    statusDescription: titlesDescriptions
      ? 'Duplicates detected'
      : 'None detected',
    status: !!titlesDescriptions,
    extraInfo:
      'A website title, or title tag, is an HTML element that specifies the content of a webpage. The title and description are crucial to setting your website apart in search results. Including same title and description on every page creates duplicate content which search engines may penalize.',
  })
  result.push({
    iconStatus: mobileSpeed < 4 ? 0 : 2,
    description: 'Mobile Speed',
    statusDescription: `${mobileSpeed || 0} seconds`,
    status: mobileSpeed < 4,
    extraInfo:
      'The Site Speed reports show how quickly users can see and interact with content. 47% of consumers expect a web page to load in 2 seconds or less. A mobile speed under 4 seconds is a good speed.',
  })
  return result
}
function setResponsiveItems({tabletFriendly, mobileFriendly}) {
  const result = []
  result.push({
    iconStatus: tabletFriendly ? 0 : 2,
    description: 'Tablet Friendly',
    statusDescription: tabletFriendly ? 'Optimized' : 'Not detected',
    status: tabletFriendly,
    extraInfo:
      'Means your website’s information, including images, texts, videos, links are viewable and site is easy to navigate in tablet format.',
  })
  result.push({
    iconStatus: mobileFriendly ? 0 : 2,
    description: 'Mobile Friendly',
    statusDescription: mobileFriendly ? 'Optimized' : 'Not detected',
    status: mobileFriendly,
    extraInfo:
      'Means your website’s information, including images, texts, videos, links are viewable and site is easy to navigate in mobile format.',
  })
  return result
}
const setOrganicSearchItems = ({
  averageMonthlyTraffic,
  searchTerms,
  wordsContent,
  pagesTested,
  backlinks,
  domainAge,
  monthName,
  lastUpdate,
  websiteSize,
}) => {
  const result = []
  result.push({
    iconStatus: averageMonthlyTraffic > 0 ? 0 : 2,
    description: 'Organic Referrals',
    statusDescription:
      averageMonthlyTraffic > 0
        ? `${averageMonthlyTraffic} / month approx.`
        : 'None detected',
    status: averageMonthlyTraffic > 0,
    extraInfo:
      'Detects any organic traffic and estimates the number of organic referrals to the website within the last month. Organic Referrals or Organic Traffic includes any visitors that arrive from search engines.',
  })
  result.push({
    iconStatus: 1,
    description: 'Search Terms',
    statusDescription:
      searchTerms && searchTerms.length > 0 ? null : 'None detected',
    status: searchTerms && searchTerms.length > 0,
    extraInfo:
      'Identifies any organic search terms/keywords associated to the website.',
  })
  result.push({
    iconStatus: wordsContent > 0 ? 0 : 2,
    description: 'Amount of content',
    statusDescription:
      wordsContent > 0
        ? `${wordsContent} word on ${pagesTested} pages`
        : 'None detected',
    status: wordsContent > 0,
    extraInfo:
      'The amount of content needed on a website can vary depending on the industry, audience, and purpose of the website. Content should include most relevant and important information above the fold, with clear calls to action, short bullets and sentences, images and videos. Informative content can help improve the end user experience and length of visitors site duration.',
  })
  result.push({
    iconStatus: backlinks > 0 ? 0 : 2,
    description: 'Back Links',
    statusDescription:
      backlinks > 0 ? `${backlinks} websites approx.` : 'Not detected',
    status: backlinks > 0,
    extraInfo: 'Back Links',
  })
  result.push({
    iconStatus: domainAge ? 0 : 2,
    description: 'Domain age',
    statusDescription: domainAge ? `${monthName} ${domainAge}` : 'Not detected',
    status: !!domainAge,
    extraInfo:
      'Refers to length of time that a website has been registered and active. Domain age conveys trust to website visitors and search engines.',
  })
  result.push({
    iconStatus: lastUpdate > 0 ? 0 : 2,
    description: 'Last Update',
    statusDescription:
      lastUpdate > 0 ? `${lastUpdate} days ago` : 'Not detected',
    status: lastUpdate > 0,
    extraInfo:
      'Identifies the last time the site was updated. Keeping a website updated with fresh content is important, helping with search engine organic traffic, website security and visitor’s user experience.',
  })
  result.push({
    iconStatus: websiteSize === 0 ? 2 : 0,
    description: 'Website size',
    statusDescription:
      websiteSize === 0
        ? 'No issues found'
        : `${websiteSize}+ pages discovered (${pagesTested} pages analyzed)`,
    status: !(websiteSize === 0),
    extraInfo:
      'Website size refers to the number of pages that make up a website. Finding a balance between enough content and pages is important, ensuring consumers are finding the right information, as well as search engines and site ranking.',
  })
  return result
}
const setPaidSearchItems = ({paidSearch, paidKeywords, displayAdds}) => {
  const result = []
  result.push({
    iconStatus: paidSearch ? 0 : 2,
    description: 'Paid search',
    statusDescription: paidSearch ? 'Detected' : 'Not detected',
    status: paidSearch,
    extraInfo: 'Detects any found paid search associated to the website.',
  })
  result.push({
    iconStatus: 1,
    description: 'Paid keywords',
    statusDescription:
      paidKeywords && paidKeywords.length > 0 ? paidKeywords : 'None detected',
    status: true,
    extraInfo:
      'Detects and displays any found paid keywords associated to the website.',
  })
  result.push({
    iconStatus: displayAdds ? 0 : 2,
    description: 'Display Ads',
    statusDescription: displayAdds ? 'Yes' : 'Not detected',
    status: displayAdds,
    extraInfo:
      'Detects any found display ads or programs associated to the website.',
  })
  return result
}
const setLocalItems = ({googleMyBusiness, localPresence}) => {
  const result = []
  result.push({
    iconStatus: googleMyBusiness ? 0 : 2,
    description: 'Google My Business',
    statusDescription: googleMyBusiness ? 'Yes' : 'No listing found',
    status: !!googleMyBusiness,
    extraInfo:
      'Detects if a claimed Google My Business listing is associated to the website.',
  })
  result.push({
    iconStatus: localPresence.includes('GoogleMaps') ? 0 : 2,
    description: 'Google Maps',
    statusDescription: localPresence.includes('GoogleMaps')
      ? 'Found'
      : 'None detected',
    status: localPresence.includes('GoogleMaps'),
    extraInfo:
      'Detects if a claimed Google Maps listing is associated to the website.',
  })
  return result
}
const setItems = (items, tooltipVariant = 'right') => {
  return items.map(
    ({iconStatus, description, statusDescription, status, extraInfo}) => {
      const {icon, color} = WEBSITE_ANALYSIS_ICON_CONFIG[iconStatus]
      return (
        <WebsiteAnalysisRow key={`${description}${statusDescription}`}>
          <Icon
            type="solid"
            variant={icon}
            color={color}
            height="12"
            width="12"
          />
          <Text variant="reg" color="thryv-black-600">
            {description}{' '}
          </Text>
          <Text
            className="status__description"
            variant="sm"
            color={
              status || iconStatus === 0
                ? 'thryv-steel'
                : 'utility-error-danger'
            }
          >
            {statusDescription}
          </Text>
          {extraInfo && (
            <Tooltip
              variant={tooltipVariant}
              description={extraInfo}
              width="250px"
            >
              <Icon
                type="solid"
                variant="circleQuestion"
                color="#808080"
                height="12"
                width="12"
              />
            </Tooltip>
          )}
        </WebsiteAnalysisRow>
      )
    },
  )
}
function WebsiteAnalysisItem({title, items = [], className}) {
  return (
    <WebsiteAnalysisItemContainer className={className}>
      <Header fontWeight="semibold" variant="h8">
        {title}
      </Header>
      <ul aria-label={`${title} Items`}>{setItems(items)}</ul>
    </WebsiteAnalysisItemContainer>
  )
}
function getListingAnalysisColorClassName(score) {
  if (score <= 10 && score > 0) {
    return SCORE_CLASS_NAMES[1]
  }
  if (score > 10) {
    return SCORE_CLASS_NAMES[2]
  }
  if (score === 0) {
    return SCORE_CLASS_NAMES[0]
  }
  return SCORE_CLASS_NAMES[2]
}
function ListingAnalysisItems({tableData}) {
  return (
    <>
      {tableData.map(
        ({
          id,
          site,
          businessName,
          address,
          phone,
          reviews,
          status,
          matchName,
          matchAddress,
          matchPhone,
          url,
          city,
        }) => (
          <ListingAnalysisItemRow
            key={id}
            data-test="component-listing-analysis-items"
          >
            <td>
              <div className="flex justify-start items-center">
                {getSiteIcon({
                  site,
                  className: 'mr-2',
                  height: '20',
                  width: '20',
                })}
                <p
                  style={{textTransform: 'capitalize'}}
                  title={site}
                  className="m-0"
                >
                  {site}
                </p>
              </div>
            </td>
            {status === 'NO_MATCH' ? (
              <td colSpan={5} className="pr-4">
                <ListingAnalysisItemCellContainer
                  className="align-end"
                  data-test="listing-analysis-item-not-found"
                >
                  <Icon
                    type="solid"
                    variant="circleX"
                    color="#DF2A2A"
                    className="mr-2"
                    height="21"
                  />
                  <span data-test="listing-analysis-item-not-found-text">
                    Listing Not Found
                  </span>
                </ListingAnalysisItemCellContainer>
              </td>
            ) : (
              <>
                <td>
                  <ListingAnalysisItemCellContainer
                    className="pr-3"
                    data-test="listing-analysis-item-business-name"
                  >
                    {!matchName && (
                      <Icon
                        type="solid"
                        variant="circleX"
                        color="#DF2A2A"
                        className="mr-2"
                        height="21"
                      />
                    )}
                    <p title={businessName}>{businessName || 'Not Found'}</p>
                  </ListingAnalysisItemCellContainer>
                </td>
                <td>
                  <ListingAnalysisItemCellContainer data-test="listing-analysis-item-address">
                    {matchAddress ? (
                      <span>{`${address}, ${city}` || 'Not Found'}</span>
                    ) : (
                      <>
                        <Icon
                          type="solid"
                          variant="circleX"
                          color="#DF2A2A"
                          className="mr-2"
                          height="21"
                        />
                        <span>Not Found</span>
                      </>
                    )}
                  </ListingAnalysisItemCellContainer>
                </td>
                <td>
                  <ListingAnalysisItemCellContainer data-test="listing-analysis-item-phone">
                    {!matchPhone && (
                      <Icon
                        type="solid"
                        variant="circleX"
                        color="#DF2A2A"
                        className="mr-2"
                        height="21"
                      />
                    )}
                    <span>{phone || 'Not Found'}</span>
                  </ListingAnalysisItemCellContainer>
                </td>
                <td data-test="listing-analysis-item-reviews">
                  <div className="flex justify-center items-center">
                    {reviews ? 'Yes' : 'No'}
                  </div>
                </td>
                <td>
                  {url && (
                    <a href={url}>
                      <Icon
                        type="regular"
                        variant="externalLink"
                        color="#ff5000"
                        className="m-auto"
                        height="18"
                      />
                    </a>
                  )}
                </td>
              </>
            )}
          </ListingAnalysisItemRow>
        ),
      )}
    </>
  )
}
function PresenceContainer(props) {
  const {
    score,
    silktideJson: {
      overallScore: websiteScore,
      alternativeText = 0,
      analytics,
      brokenLinks,
      ssl,
      titlesDescriptions,
      mobile: {mobileSpeed, mobileFriendly, tabletFriendly} = {},
      paidSearch,
      paidKeywords,
      displayAdds,
      averageMonthlyTraffic,
      searchTerms,
      amountOfContent: {wordsContent, pagesTested, websiteSize} = {},
      backlinks,
      domainAge,
      monthName,
      lastUpdate,
      googleMyBusiness,
      localPresence = [],
    } = {},
    yextJson: {listingsAccuracy: listingsAccuracyScore} = {},
    listingAnalysisItems = [],
    refProp,
  } = props
  const [orderedAnalysisItems, setOrderedAnalysisItems] = React.useState([])
  React.useEffect(() => {
    const PREMIUM_LISTINGS = [
      'YELP',
      'BING',
      'YPCOM',
      'FACEBOOK',
      'GOOGLEPLACES',
    ]
    listingAnalysisItems.sort(listing =>
      PREMIUM_LISTINGS.includes(listing.site) ? -1 : 1,
    )
    setOrderedAnalysisItems(listingAnalysisItems)
  }, [listingAnalysisItems])

  return (
    <CriteriaContainer
      criteria="Presence"
      criteriaDescription="Enhance your online presence. Offer customers an interactive login area online, sync your business info across 40+ listings sites, and get a fast, modern, mobile-ready website"
      icon="mapMarker"
      proTip="Captivate your audience through website personalization, analytics, photos and content that is sure to keep visitors engaged on your site longer and choosing you over the competition."
      thryvAdvice="Upload your business information once, and watch it post to 40+ trusted listings sites online. Thryv locks this informatiUpload your business information once, and watch it post to 40+ trusted listings sites online. Thryv locks this information"
      //criteriaDescription={translations.presenceDescription}
      //thryvAdvice={translations.presenceThryvTip}
      score={score}
      image={analysisIllustration2}
      refProp={refProp}
      className="break-page-before-avoid--print-style"
    >
      <li>
        <RatingDropdown
          ratingDescription="Website Analysis"
          score={websiteScore}
          tooltipDescription={`Promote your brand online with a custom website, and use compelling calls to action to get customers to book more and buy more. Thryv’s websites are also optimised to make you as visible as possible with top search engines.`}
        >
          <WebsiteAnalysisContainer>
            <WebsiteAnalysisItem
              title="Website"
              items={setWebsiteItems({
                alternativeText,
                analytics,
                brokenLinks,
                ssl,
                titlesDescriptions,
                mobileSpeed,
              })}
              className="website"
            />
            <WebsiteAnalysisItem
              title="Responsive"
              items={setResponsiveItems({tabletFriendly, mobileFriendly})}
              className="responsive"
              tooltipVariant="left"
            />
            <WebsiteAnalysisItem
              title="Organic Search"
              items={setOrganicSearchItems({
                averageMonthlyTraffic,
                searchTerms,
                wordsContent,
                pagesTested,
                backlinks,
                domainAge,
                monthName,
                lastUpdate,
                websiteSize,
              })}
              className="organicSearch"
            />
            <WebsiteAnalysisItem
              title="Paid Search"
              items={setPaidSearchItems({
                paidSearch,
                paidKeywords,
                displayAdds,
              })}
              className="paidSearch"
              tooltipVariant="left"
            />
            <WebsiteAnalysisItem
              title="Local"
              items={setLocalItems({googleMyBusiness, localPresence})}
              className="local"
              tooltipVariant="left"
            />
          </WebsiteAnalysisContainer>
        </RatingDropdown>
      </li>
      <li className="break-page-before--print-style">
        <RatingDropdown
          ratingDescription="Listing Analysis"
          score={listingsAccuracyScore}
          tooltipDescription={
            'Having incorrect information on consumer listings sites can drive your engagement down. We want to help you get this corrected fast!'
          }
          scoreColorClassName={getListingAnalysisColorClassName(
            listingsAccuracyScore,
          )}
        >
          <ListingAnalysisContainer data-test="component-listing-analysis-container">
            {orderedAnalysisItems.length === 0 ? (
              <div
                className="w-full text-center"
                data-test="listing-analysis-not-found"
              >
                No results found
              </div>
            ) : (
              <table
                cellSpacing="0"
                cellPadding="0"
                data-test="listing-analysis-table"
              >
                <thead>
                  <tr>
                    <th width="30%">
                      <div className="flex align-items-center">
                        Site
                        <Icon
                          type="solid"
                          variant="chevronDown"
                          color="#808080"
                          width="10"
                          className="ml-2"
                        />
                      </div>
                    </th>
                    <th width="35%">
                      <div className="flex align-items-center">
                        Business Name
                        <Icon
                          type="solid"
                          variant="chevronDown"
                          color="#808080"
                          width="10"
                          className="ml-2"
                        />
                      </div>
                    </th>
                    <th width="45%">
                      <div className="flex align-items-center">
                        Address
                        <Icon
                          type="solid"
                          variant="chevronDown"
                          color="#808080"
                          width="10"
                          className="ml-2"
                        />
                      </div>
                    </th>
                    <th width="20%">
                      <div className="flex align-items-center">
                        Phone
                        <Icon
                          type="solid"
                          variant="chevronDown"
                          color="#808080"
                          width="10"
                          className="ml-2"
                        />
                      </div>
                    </th>
                    <th width="10%">
                      <div className="flex align-items-center">
                        Reviews
                        <Icon
                          type="solid"
                          variant="chevronDown"
                          color="#808080"
                          width="10"
                          className="ml-2"
                        />
                      </div>
                    </th>
                    <th width="10%" />
                  </tr>
                </thead>
                <tbody>
                  <ListingAnalysisItems
                    tableData={orderedAnalysisItems}
                    data-test="listing-analysis-table-body-items"
                  />
                </tbody>
              </table>
            )}
          </ListingAnalysisContainer>
        </RatingDropdown>
      </li>
    </CriteriaContainer>
  )
}

export {PresenceContainer}
