import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'

export const HeaderContainer = styled.header`
  width: 100vw;
  max-width: 100vw;
  flex-direction: row-reverse;
  margin-bottom: -18px;
  position: fixed;
  background-color: white;
  height: 64px;
  z-index: 1;

  .toggle-button:hover {
    cursor: pointer;
  }
`

export const OldHeaderContainer = styled.header`
  max-width: 100vw;
  @media screen and ${device.tablet} {
    padding: 10px !important;
  }
  @media screen and ${device.mobileL} {
    & .new-order-button {
      margin: 0 2px 0 2px !important;
    }
  }
  @media (max-width: 1230px) {
    .nav-bar-collapse {
      flex-basis: auto;
      flex-flow: column wrap;
      align-items: center;
    }
    & .nav-item.show {
      margin-top: -20px;
    }
    & .menu-item {
      margin-top: 15px;
    }
  }
  & img,
  .headerdiv {
    max-height: 100px;
  }
  @media print {
    display: none !important;
  }
`

export const HeaderDropdown = styled.div`
  display: flex;
  align-items: center;
  & > div {
    cursor: pointer;
  }
  & .adminLink {
    color: #ff5000;
  }
  & .adminLink:hover {
    text-decoration: underline;
  }
`
export const HeaderNavigationMenu = styled.div`
  padding: 25px;
  font-size: 16px;
  align-items: center;
  font-family: Montserrat, sans-serif !important;
  font-weight: 500;
  & nav {
    display: flex;
  }
  & li {
    list-style-type: none;
  }
  .menu-item {
    text-decoration: none !important;
    gap: 4%;
  }
  & .nav-link.menu-item.active {
    position: relative;
  }
  .active {
    color: black !important;
    border-bottom: 4px solid #ff5000;
    border-radius: 2px;
  }
  .menu-item:hover {
    color: black !important;
  }
  & .nav-item.show {
    display: flex;
    position: absolute;
    margin-left: -40px;
  }
  & .nav-item.hide {
    display: none;
  }
`
