const binaryLookup = (searchTerm, array) => {
  let startIndex = 0
  let endIndex = array.length - 1

  while (startIndex <= endIndex) {
    const middleIndex = Math.floor((startIndex + endIndex) / 2)
    const middleValue = array[middleIndex].name

    const compareResult = middleValue.localeCompare(searchTerm)

    if (compareResult === 0) {
      return array[middleIndex]
    } else if (compareResult < 0) {
      startIndex = middleIndex + 1
    } else {
      endIndex = middleIndex - 1
    }
  }
}

export {binaryLookup}
