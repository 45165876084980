import {Button, ParagraphText} from '@thryvlabs/maverick'
import dayjs from 'dayjs'
import React from 'react'

export default function ConfirmedCall({
  callType,
  callDate,
  isApptSkipped,
  selectedCentersAndApps,
  cancelAppointment,
  apptId,
}) {
  const formatDate = dateStr => {
    return dayjs(dateStr.ISODate)
      .tz(dateStr.timeZoneId)
      .format('dddd, MMMM D, YYYY [at] h:mm A')
  }
  return (
    <>
      {isApptSkipped && (
        <ParagraphText
          variant="reg"
          color="thryv-gray-medium-500"
          style={selectedCentersAndApps ? {margin: 0} : {}}
        >
          {callType === 'creative'
            ? 'Thryv Creative Call'
            : 'Thryv Kickoff Call'}{' '}
          has been skipped
        </ParagraphText>
      )}
      {!isApptSkipped && callDate && (
        <ParagraphText
          variant="reg"
          color="thryv-gray-medium-500"
          style={selectedCentersAndApps ? {margin: 0} : {}}
        >
          Thank you, your Thryv{' '}
          {callType === 'creative' ? 'Creative Consulation' : 'Kickoff Call'} is
          scheduled for{' '}
          <ParagraphText
            variant="reg"
            color="thryv-black-500"
            className="inline-block"
          >
            <b>
              {formatDate(callDate)}{' '}
              {dayjs().tz(callDate.timeZoneId).format('z')}.
            </b>
          </ParagraphText>
        </ParagraphText>
      )}
      {selectedCentersAndApps && (
        <Button
          variant="text"
          onClick={() => {
            cancelAppointment(callType, apptId)
          }}
          level={1}
        >
          Edit
        </Button>
      )}
    </>
  )
}
