import styled from '@emotion/styled'

export const Container = styled.div`
  width: 786px;
  max-width: 100%;
  text-align: center;
  justify-content: center;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`
export const QuestionTitle = styled.h1`
  font-size: 38px;
  font-weight: 300;
  line-height: 45px;
  padding-left: 55px;
  font-family: Open-Sans, Helvetica Neue, Arial, Helvetica, sans-serif !important;
`
export const QuestionDescriptor = styled.p`
  font-weight: 300;
  line-height: 25px;
  color: #666;
  margin-top: 3rem !important;
`
export const QuestionMarkIcon = styled.i`
  font-weight: 300;
  line-height: 25px;
  color: #666;
`
export const AnswerList = styled.ul`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`
export const AnswerListItem = styled.li`
  margin-left: 20px;
  margin-top: 20px;
  position: relative;
`
export const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
`
export const AnswerButton = styled.button`
  width: 170px;
  height: 52px;
  font-size: 17px;
  color: black;
  font-weight: 350;
`
export const AnsweredButton = styled.button`
  width: 170px;
  height: 52px;
  font-size: 17px;
  font-weight: 350;
  &:hover {
    color: black;
    border-color: black;
  }
`
export const getPackage = styled.button``
