import React, {useEffect, useState} from 'react'
import {Modal, ModalTitle, ParagraphText} from '@thryvlabs/maverick'
import Skeleton from 'react-loading-skeleton'

const DetailsModal = ({
  getData,
  buttonName,
  title,
  onClose,
  meeting_type,
  selectedTimeZone,
  height,
}) => {
  const [data, setData] = useState([])

  useEffect(() => {
    return () => setData([])
  }, [])

  async function fetchData() {
    const data = await getData()
    setData(data)
  }

  const LINKS = ['Sales Rep Email', 'Email', 'Website', 'Google Map']
  return (
    <Modal
      id="details-modal"
      onOpen={fetchData}
      onClose={onClose}
      variant="default"
      title={title}
      btnText={buttonName}
      btnType="secondary"
    >
      {data &&
        data.map(({id, sectionType, sectionName, sectionData, Component}, i) =>
          sectionName === 'Notes' &&
          (meeting_type === 'onboarding' ||
            meeting_type === 'creative consultation' ||
            meeting_type === 'creative') ? null : (
            <div key={i}>
              <ModalTitle variant="subtitle" size="lg">
                {sectionName}
              </ModalTitle>
              {sectionType === 'object' &&
                sectionData &&
                Object.keys(sectionData).map((prop, i) =>
                  sectionData[prop] === '-' ? null : (
                    <div key={i} className="row">
                      <div className="col-5">
                        <ParagraphText variant="reg">{prop}:</ParagraphText>
                      </div>
                      {LINKS.includes(prop) ? (
                        <div className="col-5">
                          {prop === 'Sales Rep Email' && (
                            <a
                              href={`mailto:${sectionData[prop]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {sectionData[prop]}
                            </a>
                          )}
                          {prop === 'Email' && (
                            <a
                              href={`mailto:${sectionData[prop]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {sectionData[prop]}
                            </a>
                          )}
                          {prop === 'Google Map' && (
                            <a
                              href={sectionData[prop]}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View Location
                            </a>
                          )}
                          {prop === 'Website' && (
                            <a
                              href={sectionData[prop]}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {sectionData[prop]}
                            </a>
                          )}
                        </div>
                      ) : (
                        <div className="col-5">
                          <ParagraphText variant="reg">
                            {sectionData[prop]}
                          </ParagraphText>
                        </div>
                      )}
                    </div>
                  ),
                )}
              {sectionType === 'custom' && Component && <Component />}
            </div>
          ),
        )}
      {!data?.length && <Skeleton height={height} />}
    </Modal>
  )
}

export {DetailsModal}
