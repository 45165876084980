export const SCORE_TEXTS = [
  'Excellent!',
  'You are almost there!',
  'You can do better!',
  'Oops, an error occured with the scan!',
]

export const SCORE_COLORS = ['#16A085', '#FFB800', '#DF2A2A', '#057AFF']

export const SCORE_CLASS_NAMES = [
  'score-primary',
  'score-warning',
  'score-error',
]

export const AUTHORIZED_ROLES_BY_SCREENS = {
  allAccount: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'thryvonly',
    'tss',
    'premisedemo',
    'insidedemo',
    'insidedemoonly',
    'premisepilot',
    'vivialdemo',
  ],
  demoRequests: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'yello',
    'premisedemo',
    'insidedemo',
    'insidedemoonly',
    'premisepilot',
    'vivialdemo',
    'australia',
    'newzealandsales',
  ],
  appointments: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'yello',
    'premisedemo',
    'insidedemo',
    'insidedemoonly',
    'premisepilot',
    'vivialdemo',
    'australia',
    'newzealandsales',
  ],
}

export const ADDONS_TYPES = {
  SELECTED_PLAN: 'selectedPlan',
  ADD_ON: 'addon',
  ENTITLEMENTS: 'entitlements',
  ONE_TIME: 'oneTime',
  TOTAL: 'total',
}

export const BILLING_PREFERENCES = ['monthly', 'semiAnnually', 'oneTime']

export const SEO_MBG_PRICES = {
  USA: [
    {name: '$250', value: 250},
    {name: '$400', value: 400},
    {name: '$600', value: 600},
    {name: '$800', value: 800},
    {name: '$1000', value: 1000},
    {name: '$1200', value: 1200},
    {name: '$1400', value: 1400},
    {name: '$1600', value: 1600},
    {name: '$1800', value: 1800},
    {name: '$2500', value: 2500},
    {name: '$3000', value: 3000},
    {name: '$3500', value: 3500},
    {name: '$4000', value: 4000},
    {name: '$4500', value: 4500},
    {name: '$5000', value: 5000},
    {name: '$5500', value: 5500},
    {name: '$6000', value: 6000},
    {name: '$6500', value: 6500},
    {name: '$7000', value: 7000},
    {name: '$8000', value: 8000},
    {name: '$9000', value: 9000},
    {name: '$10000', value: 10000},
    {name: '$11000', value: 11000},
    {name: '$12000', value: 12000},
    {name: '$13000', value: 13000},
    {name: '$14000', value: 14000},
    {name: '$15000', value: 15000},
  ],
  CAN: [
    {name: '$325', value: 325},
    {name: '$520', value: 520},
    {name: '$780', value: 780},
    {name: '$1040', value: 1040},
    {name: '$1300', value: 1300},
    {name: '$1560', value: 1560},
    {name: '$1820', value: 1820},
    {name: '$2080', value: 2080},
    {name: '$2340', value: 2340},
    {name: '$3250', value: 3250},
    {name: '$3900', value: 3900},
    {name: '$4550', value: 4550},
    {name: '$5200', value: 5200},
    {name: '$5850', value: 5850},
    {name: '$6500', value: 6500},
    {name: '$7800', value: 7800},
    {name: '$9100', value: 9100},
  ],
  AUS: [
    {name: '$227.27', value: 227},
    {name: '$337.5', value: 338},
    {name: '$540', value: 540},
    {name: '$810', value: 810},
    {name: '$909.09', value: 909},
    {name: '$1080', value: 1080},
    {name: '$1350', value: 1350},
    {name: '$1620', value: 1620},
    {name: '$1890', value: 1890},
    {name: '$2160', value: 2160},
    {name: '$2430', value: 2430},
    {name: '$3375', value: 3375},
    {name: '$3712.5', value: 3713},
    {name: '$4050', value: 4050},
    {name: '$4387.5', value: 4388},
    {name: '$4725', value: 4725},
    {name: '$5062.5', value: 5063},
    {name: '$5400', value: 5400},
    {name: '$5737.5', value: 5738},
    {name: '$6075', value: 6075},
    {name: '$6412.5', value: 6413},
    {name: '$6750', value: 6750},
    {name: '$8100', value: 8100},
    {name: '$9450', value: 9450},
  ],
}

export const CURRENCYS = {
  usa: 'USD',
  can: 'CAD',
  aus: 'AUD',
  barbados: 'USD',
  cayman: 'USD',
}

export const CC_CURRENCIES = {
  usa: 'USD',
  can: 'CAD',
  aus: 'AUD',
  barbados: 'BBD',
  cayman: 'KYD',
  nz: 'NZD',
}

export const COUNTRIES = {
  US: {name: 'United States', value: 'usa', isoValue: 'US'},
  AU: {name: 'Australia', value: 'aus', isoValue: 'AU'},
  KY: {
    name: 'Cayman Islands',
    value: 'cayman',
    isoValue: 'KY',
  },
  BB: {name: 'Barbados', value: 'barbados', isoValue: 'BB'},
  CA: {name: 'Canada', value: 'can', isoValue: 'CA'},
  NZ: {name: 'New Zealand', value: 'nz', isoValue: 'NZ'},
}

export const SEO_BOTH_PLANS = ['unlimited', 'professional']

export const SEO_NG_PLANS = ['plus', 'sync']

export const CC_PLANS = [
  'command center pro',
  'command center plus',
  'business center',
  'marketing center',
]

export const TRANSITION_INCENTIVES = [
  {
    title: 'Month 1',
    subtitle: '(Incentive: 75%)',
    discount: 0.75,
  },
  {
    title: 'Month 2',
    subtitle: '(Incentive: 50%)',
    discount: 0.5,
  },
  {
    title: 'Month 3',
    subtitle: '(Incentive: 25%)',
    discount: 0.25,
  },
  {
    title: 'Month 4+',
  },
]

export const LIST_OF_STATES = [
  {
    name: 'Alabama',
    value: 'AL',
  },
  {
    name: 'Alaska',
    value: 'AK',
  },
  {
    name: 'Arizona',
    value: 'AZ',
  },
  {
    name: 'Arkansas',
    value: 'AR',
  },
  {
    name: 'California',
    value: 'CA',
  },
  {
    name: 'Colorado',
    value: 'CO',
  },
  {
    name: 'Connecticut',
    value: 'CT',
  },
  {
    name: 'Delaware',
    value: 'DE',
  },
  {
    name: 'District of Columbia',
    value: 'DC',
  },
  {
    name: 'Florida',
    value: 'FL',
  },
  {
    name: 'Georgia',
    value: 'GA',
  },
  {
    name: 'Hawaii',
    value: 'HI',
  },
  {
    name: 'Idaho',
    value: 'ID',
  },
  {
    name: 'Illinois',
    value: 'IL',
  },
  {
    name: 'Indiana',
    value: 'IN',
  },
  {
    name: 'Iowa',
    value: 'IA',
  },
  {
    name: 'Kansas',
    value: 'KS',
  },
  {
    name: 'Kentucky',
    value: 'KY',
  },
  {
    name: 'Louisiana',
    value: 'LA',
  },
  {
    name: 'Maine',
    value: 'ME',
  },
  {
    name: 'Maryland',
    value: 'MD',
  },
  {
    name: 'Massachusetts',
    value: 'MA',
  },
  {
    name: 'Michigan',
    value: 'MI',
  },
  {
    name: 'Minnesota',
    value: 'MN',
  },
  {
    name: 'Mississippi',
    value: 'MS',
  },
  {
    name: 'Missouri',
    value: 'MO',
  },
  {
    name: 'Montana',
    value: 'MT',
  },
  {
    name: 'Nebraska',
    value: 'NE',
  },
  {
    name: 'Nevada',
    value: 'NV',
  },
  {
    name: 'New Hampshire',
    value: 'NH',
  },
  {
    name: 'New Jersey',
    value: 'NJ',
  },
  {
    name: 'New Mexico',
    value: 'NM',
  },
  {
    name: 'New York',
    value: 'NY',
  },
  {
    name: 'North Carolina',
    value: 'NC',
  },
  {
    name: 'North Dakota',
    value: 'ND',
  },
  {
    name: 'Ohio',
    value: 'OH',
  },
  {
    name: 'Oklahoma',
    value: 'OK',
  },
  {
    name: 'Oregon',
    value: 'OR',
  },
  {
    name: 'Pennsylvania',
    value: 'PA',
  },
  {
    name: 'Rhode Island',
    value: 'RI',
  },
  {
    name: 'South Carolina',
    value: 'SC',
  },
  {
    name: 'South Dakota',
    value: 'SD',
  },
  {
    name: 'Tennessee',
    value: 'TN',
  },
  {
    name: 'Texas',
    value: 'TX',
  },
  {
    name: 'Utah',
    value: 'UT',
  },
  {
    name: 'Vermont',
    value: 'VT',
  },
  {
    name: 'Virginia',
    value: 'VA',
  },
  {
    name: 'Washington',
    value: 'WA',
  },
  {
    name: 'West Virginia',
    value: 'WV',
  },
  {
    name: 'Wisconsin',
    value: 'WI',
  },
  {
    name: 'Wyoming',
    value: 'WY',
  },
  {
    name: 'Alberta',
    value: 'AB',
  },
  {
    name: 'British Columbia',
    value: 'BC',
  },
  {
    name: 'Manitoba',
    value: 'MB',
  },
  {
    name: 'New Brunswick',
    value: 'NB',
  },
  {
    name: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    name: 'Northwest Territories',
    value: 'NT',
  },
  {
    name: 'Nova Scotia',
    value: 'NS',
  },
  {
    name: 'Nunavut',
    value: 'NU',
  },
  {
    name: 'Ontario',
    value: 'ON',
  },
  {
    name: 'Prince Edward Island',
    value: 'PE',
  },
  {
    name: 'Quebec',
    value: 'QC',
  },
  {
    name: 'Saskatchewan',
    value: 'SK',
  },
  {
    name: 'Yukon',
    value: 'YT',
  },
  {
    name: 'Australian Capital Territory',
    value: 'ACT',
  },
  {
    name: 'New South Wales',
    value: 'NSW',
  },
  {
    name: 'Northern Territory',
    value: 'NT',
  },
  {
    name: 'Queensland',
    value: 'QLD',
  },
  {
    name: 'South Australia',
    value: 'SA',
  },
  {
    name: 'Tasmania',
    value: 'TAS',
  },
  {
    name: 'Victoria',
    value: 'VIC',
  },
  {
    name: 'Western Australia',
    value: 'WA',
  },
  {
    name: 'Auckland Region',
    value: 'AUK',
  },
  {
    name: 'Bay of Plenty region',
    value: 'BOP',
  },
  {
    name: 'Canterbury',
    value: 'CAN',
  },
  {
    name: 'Chatham Islands',
    value: 'Chatham Islands',
  },
  {
    name: 'Gisborne region',
    value: 'GIS',
  },
  {
    name: "Hawke's Bay region",
    value: 'HKB',
  },
  {
    name: 'Manawatu-Wanganui',
    value: 'MWT',
  },
  {
    name: 'Marlborough Region',
    value: 'MBH',
  },
  {
    name: 'Nelson',
    value: 'NSN',
  },
  {
    name: 'Northland region',
    value: 'NTL',
  },
  {
    name: 'Otago',
    value: 'OTA',
  },
  {
    name: 'Southland',
    value: 'STL',
  },
  {
    name: 'Taranaki',
    value: 'TKI',
  },
  {
    name: 'Tasman District',
    value: 'TAS',
  },
  {
    name: 'Waikato',
    value: 'WKO',
  },
  {
    name: 'Wellington Region',
    value: 'WGN',
  },
  {
    name: 'West Coast',
    value: 'WTC',
  },
  {
    name: 'West Coast',
    value: 'WTC',
  },
  {
    name: 'Christ Church',
    value: '01',
  },
  {
    name: 'Saint Andrew',
    value: '02',
  },
  {
    name: 'Saint George',
    value: '03',
  },
  {
    name: 'Saint James',
    value: '04',
  },
  {
    name: 'Saint John',
    value: '05',
  },
  {
    name: 'Saint Joseph',
    value: '06',
  },
  {
    name: 'Saint Lucy',
    value: '07',
  },
  {
    name: 'Saint Michael',
    value: '08',
  },
  {
    name: 'Saint Peter',
    value: '09',
  },
  {
    name: 'Saint Philip',
    value: '10',
  },
  {
    name: 'Saint Thomas',
    value: '11',
  },
  {
    name: 'Bodden Town',
    value: 'Bodden Town',
  },
  {
    name: 'East End',
    value: 'East End',
  },
  {
    name: 'George Town',
    value: 'George Town',
  },
  {
    name: 'North Side',
    value: 'North Side',
  },
  {
    name: 'Sister Island',
    value: 'Sister Island',
  },
  {
    name: 'West Bay',
    value: 'West Bay',
  },
]

export const BC_PLANS = [
  'bc_plus-5-thryv-m2m',
  'bc_pro-5-thryv-m2m',
  'bc_unlm-5-thryv-m2m',
]

export const MC_PLANS = [
  'mc_plus-5-thryv-m2m',
  'mc_pro-5-thryv-m2m',
  'mc_pro_enh-5-thryv-m2m',
]

export const RC_PLANS = ['rc_plus-5-thryv-m2m']

export const NEW_CUSTOMER_INIT_MC = 'nci-mc'

export const NEW_CUSTOMER_INIT_BC = 'nci-bc'

export const NEW_RC_COUPON = 'rc_excl-intro-offer'
