import React from 'react'
import {ParagraphText} from '@thryvlabs/maverick'
import {CartDetailsContainer} from '../ui/payment-information'
import {CartDetail} from './cart-detail'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {getTodayPreviewItems} from '../../utils/get-today-preview-items'

const ONE_TIME_FEES = [
  '5 extra website pages',
  'onboarding fee',
  'website design fee',
]

function CartDetails({paymentState, is5Order}) {
  const {frontendPreviewPayment} = useFlags()

  const {
    monthlyAmount,
    semiAnnuallyAmount,
    oneTimeAmount,
    monthlyAddons,
    semiAnnuallyAddons,
    oneTimeAddons,
    paymentInfo,
    taxes,
    calculatedTaxes,
  } = paymentState
  const {flow} = paymentInfo
  const isUpgrade = flow === 'upgrade'
  const paymentPreview = paymentState.paymentInfo?.paymentPreview
  const isInvoiceBilled = paymentInfo.isInvoiceBilled
  const supportFee = monthlyAddons.find(({code}) => code === '247support-m2m')
  const todayItems = [...oneTimeAddons]
  if (semiAnnuallyAmount > 0) {
    todayItems.push(...semiAnnuallyAddons)
  } else {
    todayItems.push(...monthlyAddons)
  }
  const todayItemsPreview =
    frontendPreviewPayment &&
    paymentPreview &&
    getTodayPreviewItems({paymentState, todayItems})
  const hasSupportFees =
    !frontendPreviewPayment || !paymentPreview
      ? monthlyAddons.some(({code}) => code === '247support-m2m')
      : todayItemsPreview.some(({code}) => code === '247support-m2m')
  const monthlyTotalDue = parseFloat(
    Number(oneTimeAmount) + Number(monthlyAmount),
  ).toFixed(2)
  const semiAnnuallyTotalDue = hasSupportFees
    ? parseFloat(
        Number(oneTimeAmount) +
          Number(semiAnnuallyAmount) +
          Number(supportFee.currency.unitAmount),
      ).toFixed(2)
    : parseFloat(Number(oneTimeAmount) + Number(semiAnnuallyAmount)).toFixed(2)

  const rankItem = plan => {
    const planName = plan.name.toLowerCase()
    if (planName.includes('command')) return 1
    if (planName.includes('business')) return 2
    if (planName.includes('marketing')) return 3
    if (!ONE_TIME_FEES.includes(planName.toLowerCase())) return 4
    return 5
  }

  const itemComparator = (a, b) => {
    if (!is5Order) {
      const aCode = a.code.toLowerCase()
      const bCode = b.code.toLowerCase()

      const hasDexyp = code => code.includes('dexyp')
      const hasOnboarding = code => code.includes('onboarding')

      if (hasDexyp(aCode) && !hasDexyp(bCode)) {
        return -1
      }
      if (!hasDexyp(aCode) && hasDexyp(bCode)) {
        return 1
      }

      if (hasOnboarding(aCode) && !hasOnboarding(bCode)) {
        return -1
      }
      if (!hasOnboarding(aCode) && hasOnboarding(bCode)) {
        return 1
      }

      return b.currency.unitAmount - a.currency.unitAmount
    } else {
      const rankA = rankItem(a)
      const rankB = rankItem(b)
      return rankA - rankB
    }
  }

  const selectedPlanCodes = [
    ...monthlyAddons,
    ...oneTimeAddons,
    {
      code: paymentInfo?.plan?.planCode,
      name: paymentInfo?.plan?.name,
      currency: {unitAmount: paymentInfo?.plan?.currency.amount},
      quantity: 1,
    },
  ]
  return (
    <CartDetailsContainer className="p-2">
      {monthlyAmount > 0 && (
        <CartDetail
          title="Monthly"
          className="with-border"
          total={Number(monthlyAmount)}
          items={monthlyAddons
            .filter(addon => addon.code !== '247support-m2m')
            .sort(itemComparator)}
          selectedPlan={
            paymentInfo?.plan?.planUnit === 'monthly' && paymentInfo?.plan
          }
          open
          calculatedTaxes={taxes.monthly}
          coupons={paymentInfo?.coupons}
          country={paymentInfo.country}
          selectedPlanCodes={selectedPlanCodes}
          is5Order={is5Order}
          supportFee={supportFee || null}
        />
      )}
      {semiAnnuallyAmount > 0 && (
        <CartDetail
          title="Semi Annually"
          className="mt-3 with-border"
          total={Number(semiAnnuallyAmount)}
          items={semiAnnuallyAddons}
          selectedPlan={
            paymentInfo.plan.planUnit === 'semi-annually' && paymentInfo.plan
          }
          open
          calculatedTaxes={taxes.semiAnnually}
          coupons={paymentInfo?.coupons}
          country={paymentInfo.country}
          selectedPlanCodes={selectedPlanCodes}
          is5Order={is5Order}
        />
      )}
      <CartDetail
        title={isInvoiceBilled ? 'Next Invoice' : 'Today'}
        className="mt-3"
        items={(!frontendPreviewPayment || !paymentPreview
          ? todayItems
          : todayItemsPreview
        )
          .filter(item => item.code !== '247support-m2m')
          .sort(itemComparator)}
        total={Number(
          semiAnnuallyAmount > 0 ? semiAnnuallyTotalDue : monthlyTotalDue,
        )}
        calculatedTaxes={
          !frontendPreviewPayment || !paymentPreview
            ? calculatedTaxes
            : paymentPreview.tax
        }
        selectedPlan={paymentInfo.plan}
        open
        coupons={paymentInfo?.coupons}
        country={paymentInfo.country}
        selectedPlanCodes={selectedPlanCodes}
        is5Order={is5Order}
        supportFee={hasSupportFees ? supportFee : null}
        paymentPreview={paymentPreview}
        paymentState={paymentState}
      />
      {isUpgrade && (
        <ParagraphText
          variant="reg"
          color="thryv-gray-medium-500"
          style={{maxWidth: '360px', paddingTop: '10px'}}
        >
          *The amount due today is an estimate. The actual charge will be a
          prorated amount depending on when you upgrade and what you select.
        </ParagraphText>
      )}
    </CartDetailsContainer>
  )
}

export {CartDetails}
