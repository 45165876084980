import styled from '@emotion/styled'

export const AddonsDatePickerContainer = styled.div`
  & .calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    & .react-calendar {
      font-family: 'Montserrat';
      font-size: 15px;
    }
    & .react-calendar__navigation__label__labelText {
      font-weight: 700;
      font-size: 20px;
      text-transform: capitalize;
    }
    & .react-calendar__tile--active {
      background-color: transparent;
      color: black;
      border: 1px solid #fe7275;
      border-radius: 5px;
    }
  }
`
