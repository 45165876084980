import React from 'react'
import {ParagraphText} from '@thryvlabs/maverick'

function AccountInfo({
  data: {
    subtotal,
    addOns,
    legacyAddOns,
    recurlyCompany,
    thryvId,
    recurlyEmail,
    planName,
    thryvID,
    status,
  },
}) {
  return (
    <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)'}}>
      <ParagraphText variant="reg" className="font-weight-bold">
        CLIENT:
      </ParagraphText>
      <ParagraphText variant="reg">{recurlyCompany || '-'}</ParagraphText>

      <ParagraphText variant="reg" className="font-weight-bold">
        THRYV ID:
      </ParagraphText>
      <ParagraphText variant="reg">{thryvID || '-'}</ParagraphText>

      <ParagraphText variant="reg" className="font-weight-bold">
        EMAIL:
      </ParagraphText>
      <ParagraphText variant="reg">{recurlyEmail || '-'}</ParagraphText>

      <ParagraphText variant="reg" className="font-weight-bold">
        CURRENT PLAN:
      </ParagraphText>
      <ParagraphText variant="reg">{planName || '-'}</ParagraphText>
      <ParagraphText variant="reg" className="font-weight-bold">
        STATUS:
      </ParagraphText>
      <ParagraphText variant="reg">{status}</ParagraphText>

      <ParagraphText variant="reg" className="font-weight-bold">
        ADD ONS:
      </ParagraphText>
      <div className="d-flex flex-column">
        {addOns?.length > 0 &&
          addOns
            .filter(({addOnCode}) => addOnCode !== '247support-m2m')
            .map(({name, quantity}, i) => (
              <ParagraphText variant="reg" key={i} style={{marginBottom: '0'}}>
                {name} (Qty: {quantity})
              </ParagraphText>
            ))}
        {legacyAddOns?.length > 0 &&
          legacyAddOns.map(({name, quantity}, i) => (
            <ParagraphText variant="reg" key={i} style={{marginBottom: '0'}}>
              {name} (Qty: {quantity})
            </ParagraphText>
          ))}
        {!addOns?.length > 0 && !legacyAddOns?.length > 0 && (
          <ParagraphText variant="reg">No add-ons selected</ParagraphText>
        )}
      </div>

      <ParagraphText variant="reg" className="font-weight-bold">
        CURRENT TOTAL:
      </ParagraphText>
      <ParagraphText variant="reg">
        $
        {subtotal?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) || '-'}
      </ParagraphText>
    </div>
  )
}

export {AccountInfo}
