import styled from '@emotion/styled'

export const OnboardingContainer = styled.ul`
  width: 90%;
  display: grid;
  flex-flow: row wrap;
  align-content: center;
  padding: 2% 0;
  margin-left: 40px;
`
export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

export const TextAreaContainer = styled.div`
  & label {
    width: 100%;
    & textarea {
      min-height: 65px;
    }
  }
`

export const PurchaseIntentGroupContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-items: left;
  gap: 10px;
  margin: 20px;
`

export const PurchaseIntentContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  border: 1px dashed #c7c7c7;
  width: 260px;
  height: 110px;
  &:hover {
    cursor: pointer;
  }
`

export const ModalContainer = styled.div`
  width: 800px;
  min-height: 310px;
`

export const PurchaseIntentListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

export const PurchaseIntentItem = styled.div`
  margin: 13px;
  padding: 12px;
  width: 355px;
  min-height: 250px;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgb(41 42 46 / 24%);
  background-color: #ffffff;
  padding-top: 20px;
  & > h4 {
    font-size: 20px;
    text-align: center;
  }
  & > p {
    font-size: 15px;
    line-height: 1.6;
    text-align: center;
  }
  & .purchase_intent_button {
    cursor: pointer;
  }
`
export const PurchaseIntentInfoItem = styled.li`
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  background-color: #ffffff;
  &.selected {
    border: 1px solid #ff5000;
  }
  &.disabled {
    background-color: rgba(240, 240, 240, 0.7);
  }
  &.disabled:hover {
    cursor: initial;
    border: 1px solid #dfdfdf;
  }
  &:hover {
    cursor: pointer;
    border: 1px solid #ff5000;
  }
`

export const CallModalContainer = styled.div`
  border: ${props => (props.isCC ? '1px solid #ECEEF1' : 'none')};
  border-radius: 6px;
  width: 100%;
  margin-bottom: ${props => props.isCC && '20px'};
  min-height: ${props => props.isCC && '100px'};
  min-width: ${props => props.isCC && '300px'};
  padding: ${props => (props.isCC ? '15px' : '0')};
`
