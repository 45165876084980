import styled from '@emotion/styled'

export const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-radius: 5px;
  padding: 0;
  & button {
    height: 35px;
    padding: 2px 5px;
    border: none;
    cursor: pointer;
    outline: none;
    border-top: 1px solid #ededf2;
    border-bottom: 1px solid #ededf2;
    & svg {
      height: 8px;
    }
  }
  & button.search {
    background-color: #ededf2;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  & button:active {
    outline: none;
  }

  & input[type='text'] {
    padding: 4px 0px 5px 10px;
    border-top: 1px solid #ededf2;
    border-bottom: 1px solid #ededf2;
    border-left: 1px solid #ededf2;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: 250px;
  }
  & input:focus {
    outline: 0;
  }

  & .search svg {
    height: 15px;
  }
  & svg:hover {
    transform: scale(1.25);
  }
`
