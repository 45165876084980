/**
@jsxRuntime
classic */
/** @jsx jsx */
import {jsx} from '@emotion/core'
import {AppointmentsHeader} from '../components/ui/appointments'
import {useUserRoles} from '../utils/use-user-roles'
import {AUTHORIZED_ROLES_BY_SCREENS} from '../utils/constants'
import {getAuthByRole} from '../utils/get-screen-auth-by-role'
import NotFoundPage from '../components/not-found-page'
import {DemoAppointments} from '../components/demo-appointments'

const ENDPOINT = 'appointments'

function Appointments() {
  const {roles} = useUserRoles()

  if (!getAuthByRole(AUTHORIZED_ROLES_BY_SCREENS.appointments, roles)) {
    return <NotFoundPage />
  }

  return (
    <DemoAppointments
      DemoAppointmentsHeader={AppointmentsHeader}
      endpoint={ENDPOINT}
      showNewDemoButton={false}
      componentLabel="Appointment"
      componentLabelPlural="Appointments"
    />
  )
}

export {Appointments}
