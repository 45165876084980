import React from 'react'
import {IndustryContent} from '../components/industry-content/industry-content'
import {IndustryDropdown} from '../components/industry-content/industry-dropdown'

function IndustrySelection({
  prevStep,
  nextStep,
  selectedCountry,
  industryCategoryType,
  selectedIndustry,
}) {
  if (industryCategoryType.includes('sync')) {
    return (
      <IndustryDropdown
        prevStep={prevStep}
        nextStep={nextStep}
        selectedCountry={selectedCountry.value}
        industryCategoryType={industryCategoryType}
      />
    )
  } else if (industryCategoryType === 'thryv') {
    return (
      <IndustryContent
        prevStep={prevStep}
        nextStep={nextStep}
        selectedCountry={selectedCountry}
        industryCategoryType={industryCategoryType}
        selectedIndustry={selectedIndustry}
      />
    )
  }
}

export {IndustrySelection}
