import React, {useEffect} from 'react'
import {Icon} from '@thryvlabs/maverick'
import {SearchContainer} from './ui/search-button'
import useDebounce from '../utils/useDebounce'

function SearchButton({placeholder, onSearch, searchOnUsserType = false}) {
  const [query, setQuery] = React.useState('')

  const debouncedSearch = useDebounce(query, 600)

  useEffect(() => {
    onSearch(debouncedSearch)
    // onSearch won't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  const onEnterKeydown = key => {
    if (key === 'Enter') {
      onSearch(debouncedSearch)
    }
  }

  return (
    <SearchContainer>
      {searchOnUsserType ? (
        <input
          type="text"
          className="input-search"
          placeholder={placeholder}
          value={query}
          onChange={e => {
            setQuery(e.target.value)
          }}
        />
      ) : (
        <input
          type="text"
          className="input-search"
          placeholder={placeholder}
          value={query}
          onChange={e => {
            setQuery(e.target.value)
          }}
          onKeyDown={e => {
            onEnterKeydown(e.key)
          }}
        />
      )}
      <button
        onClick={() => {
          setQuery('')
          onSearch('')
        }}
        className="btn-clear-input"
        aria-label="clear"
      >
        <Icon type="regular" variant="x" data-testid="btn-clear" />
      </button>
      <button
        className="search"
        aria-label="search"
        onClick={() => {
          onSearch(debouncedSearch)
        }}
      >
        <Icon type="regular" variant="search" />
      </button>
    </SearchContainer>
  )
}

export {SearchButton}
