import React, {useState} from 'react'
import {Modal, Icon} from '@thryvlabs/maverick'
import {useAuthClient} from '../../utils/use-auth-client'
import SkeletonLoader from '../skeleton-loader'

const InvoicePreviewModal = ({invoiceId, invoiceNumber}) => {
  const [invoicePreview, setInvoicePreview] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const client = useAuthClient()

  async function getInvoicePdf() {
    const response = await client(`invoices/pdf/${invoiceId}`)
    setInvoicePreview(URL.createObjectURL(response))
    setIsLoading(false)
  }

  return (
    <Modal
      altBtn={
        <Icon
          type="regular"
          variant="eye"
          style={{cursor: 'pointer'}}
          color="#808080"
        />
      }
      onOpen={getInvoicePdf}
      onClose={() => setIsLoading(true)}
      title={`Invoice #${invoiceNumber}`}
    >
      <div style={{height: '80vh'}}>
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <iframe
            title="Invoice Preview"
            src={invoicePreview}
            width="100%"
            height="100%"
          />
        )}
      </div>
    </Modal>
  )
}

export {InvoicePreviewModal}
