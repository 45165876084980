/**
@jsxRuntime
classic */
/** @jsx jsx */
import {jsx} from '@emotion/core'
import * as React from 'react'
import {useParams} from 'react-router-dom'
import {useForm, Controller} from 'react-hook-form'
import * as yup from 'yup'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import {yupResolver} from '@hookform/resolvers/yup'
import {ParagraphText, Input, Select} from '@thryvlabs/maverick'
import {
  Container,
  SidePane,
  TitleContent,
  SubTitleContent,
  ScanForm,
  FormTitle,
  FormSubtitle,
  RequiredInformationLabel,
  RunAnalysisButton,
  Form,
  HideContainer,
} from '../components/ui/business-form'
import analysisIllustration from '../assets/analysis-illustration.png'
import {CurrencyInput} from '../components/currency-input'
import {
  loadGooglePlaceLibScript,
  getPlacePredictions,
  getPlaceDetails,
} from '../utils/google-places-helpers'
import {toast} from 'react-toastify'
import {useAuthClient} from '../utils/use-auth-client'
import {binaryLookup} from '../utils/binarySearch'
import {PhoneNumberUtil} from 'google-libphonenumber'
import validator from 'validator'
import {SpinnerContainer} from '../components/ui/business-form'
import {Spinner} from '../components/loader-spinner'
import rawCountryStates from 'iso3166-2-db/i18n/en'
import {useTrackLR} from '../utils/use-track-lr'

const phoneUtil = PhoneNumberUtil.getInstance()

const PLACE_DETAIL_FIELDS = [
  'name',
  'address_components',
  'formatted_phone_number',
  'website',
  'formatted_address',
]

const getPlacePredictionsDebounce = AwesomeDebouncePromise(
  getPlacePredictions,
  300,
)

const formSchema = yup.object().shape({
  businessDisplayName: yup
    .string()
    .min(3, 'Business Name must be at least 3 characters')
    .max(128, 'Business Name cannot be longer than 128 characters')
    .required(),
  businessAddress: yup
    .string()
    .min(3, 'Business Address must be at least 3 characters')
    .max(128, 'Business Address cannot be longer than 128 characters')
    .required(),
  city: yup
    .string()
    .min(3, 'City must be at least 3 characters')
    .max(128, 'City cannot be longer than 128 characters')
    .required(),
  state: yup
    .string()
    .min(2, 'State must be at least 2 characters')
    .max(128, 'State cannot be longer than 128 characters')
    .required(),
  country: yup.object().shape({
    name: yup.string().required('Country Required'),
    value: yup.string().required('Country Required'),
  }),
  zip: yup
    .string()
    .required('Post code required')
    .test(
      'validate-postCode-format',
      'Incorrect post code format for selected country',
      function (value) {
        try {
          if (!value) {
            return false
          }
          const country = this.parent.country.value
          if (country === 'BB') {
            return value.match(/^[A-Z]{2}\d{5}$/)
          }
          if (country === 'KY') {
            return value.match(/^[A-Za-z]{2}\d-?\d{4}$/)
          }
          return validator.isPostalCode(value, country)
        } catch {
          return false
        }
      },
    ),
  businessPhoneNumber: yup
    .string()
    .test(
      'validate-phone-format',
      'Incorrect Business Phone Number format for selected country',
      function (value) {
        try {
          if (!value) {
            return false
          }
          const country = this.parent.country.value
          value = value.replace(/[^\d]/g, '')
          const parsedPhone = phoneUtil.parse(value, country)
          return phoneUtil.isValidNumber(parsedPhone)
        } catch {
          return false
        }
      },
    ),
  businessWebsiteURL: yup
    .string()
    .matches(
      /^$|^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*\/)?/gm,
      'Business Website URL should be a valid URL',
    ),
  annualBusinessRevenue: yup
    .number()
    .nullable()
    .typeError('Annual Business Revenue must be a number')
    .positive(),
})

const LIST_OF_COUNTRIES = Object.entries(rawCountryStates)
  .map(country => ({
    name: country[1].name,
    value: country[1].iso,
  }))
  .sort((a, b) => (a.name > b.name ? 1 : -1))

function getCompanyLocationInfo(googleAddressComponents) {
  const result = {}
  googleAddressComponents.forEach(({types, long_name}) => {
    switch (types[0]) {
      case 'route':
        if (result['businessAddress']) {
          result[
            'businessAddress'
          ] = `${result['businessAddress']} ${long_name}`
          break
        }
        result['businessAddress'] = long_name
        break
      case 'locality':
        result['city'] = long_name
        break
      case 'street_number':
        if (result['businessAddress']) {
          result[
            'businessAddress'
          ] = `${long_name} ${result['businessAddress']}`
          break
        }
        result['businessAddress'] = long_name
        break
      case 'administrative_area_level_1':
        result['state'] = long_name
        break
      case 'country':
        result['country'] = long_name
        break
      case 'postal_code':
        result['zip'] = long_name
        break
      default:
        break
    }
  })
  return result
}

function setPhoneFormat(businessPhoneNumber, country = '') {
  let result = ''
  const ausPhoneCountry = ['australia', 'aus']
  let formatedPhoneNumber = businessPhoneNumber || ''
  formatedPhoneNumber = formatedPhoneNumber.replace(/[\s().\\-]/g, '')
  if (!ausPhoneCountry.includes((country || '').toLowerCase())) {
    if (formatedPhoneNumber.match(/^(\+)/g)) {
      formatedPhoneNumber = formatedPhoneNumber.replace(/[\\+]/g, '')
      formatedPhoneNumber = formatedPhoneNumber.substring(2)
    }
    formatedPhoneNumber = formatedPhoneNumber.split('')
    for (let i = 0; i < formatedPhoneNumber.length; i++) {
      switch (i) {
        case 0:
          result = `(${formatedPhoneNumber[i]}`
          break
        case 1:
          if (country === ausPhoneCountry) {
            result = `${result}${formatedPhoneNumber[i]}) `
          } else {
            result = `${result}${formatedPhoneNumber[i]}`
          }
          break
        case 2:
          if (country === ausPhoneCountry) {
            result = `${result}${formatedPhoneNumber[i]}`
          } else {
            result = `${result}${formatedPhoneNumber[i]}) `
          }
          break
        case 5:
          result = `${result}${formatedPhoneNumber[i]}-`
          break
        default:
          result = `${result}${formatedPhoneNumber[i]}`
          break
      }
    }
  } else {
    result = formatedPhoneNumber
  }
  return result
}

function BusinessScan() {
  const [formInfo, setFormInfo] = React.useState({})
  const [searchQuery, setSearchQuery] = React.useState()
  const [predictions, setPredictions] = React.useState([])
  const [selectedPlaceId, setSelectedPlaceId] = React.useState()
  const [showBusinessDetails, setShowBusinessDetails] = React.useState(false)
  const [loadingBusinessInfo, setLoadingBusinessInfo] = React.useState(false)
  const [selectedCountry, setSelectedCountry] = React.useState({
    name: '',
    value: '',
  })

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    reset,
    control,
    trigger,
    formState: {errors, isValid, isSubmitted},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    context: {componentProps: {formInfo}},
  })
  const {recordId} = useParams()
  const client = useAuthClient()
  const [watchBusinessDisplayName] = watch(['businessDisplayName'])
  const customEvent = watchBusinessDisplayName && {
    key: 'new-business-scan',
    companyName: watchBusinessDisplayName,
  }
  useTrackLR(customEvent)

  if (!document.querySelector('#google-maps-place')) {
    loadGooglePlaceLibScript()
  }

  const submitForm = async formData => {
    let data = {
      formInfo: {
        ...formData,
        businessName: formData.businessName || formData.businessDisplayName,
        annualBusinessRevenue: formData.annualBusinessRevenue || 0,
        country: formData.country.name,
      },
    }

    if (data.businessPhoneNumber) {
      data.businessPhoneNumber = setPhoneFormat(
        data.businessPhoneNumber,
        data.country,
      )
    }

    toast.success('Your scan is running now!')
    const resetdata = {
      businessAddress: '',
      suiteNo: '',
      city: '',
      state: '',
      zip: '',
      businessPhoneNumber: '',
      businessWebsiteURL: '',
      annualBusinessRevenue: null,
      businessDisplayName: '',
      country: {
        name: '',
        value: '',
      },
      recordId: null,
    }
    reset(resetdata)
    setFormInfo(prev => ({...prev, ...resetdata}))
    setSelectedCountry({
      name: '',
      value: '',
    })
    setSelectedPlaceId(undefined)

    try {
      if (recordId) {
        await client('scans/', {data, method: 'PUT'})
      } else {
        await client('scans/', {data})
      }
    } catch (responseError) {
      if (responseError.status !== 500) {
        if (responseError.errors) {
          responseError.errors.forEach(({message}) => toast.error(message))
          return
        }
        toast.error(responseError.message)
      }
    }
  }

  const setCountry = value => {
    setSelectedCountry(value)
    setValue('country', {...value})
    trigger('country')
  }

  const handleSearchChange = e => {
    if (e.type === 'click') {
      const companyName = e.target.innerText
      setValue('businessDisplayName', companyName)
      const {placeId} = predictions.find(
        p =>
          p.description.toLowerCase().replace(/ /g, '') ===
          companyName.toLowerCase().replace(/ /g, ''),
      )
      setSelectedPlaceId(placeId)
      return
    }
    if (selectedPlaceId) {
      setSelectedPlaceId()
      setValue('businessName', '')
    }
    const query = e.target.value
    setValue('businessDisplayName', query)
    setFormInfo(prev => ({...prev, businessDisplayName: query}))
    setSearchQuery(query)
  }
  const toggleShowBusinessDetails = React.useCallback(
    ({hasError}) => {
      if (hasError) {
        setShowBusinessDetails(true)
      } else {
        setShowBusinessDetails(!showBusinessDetails)
      }
    },
    [showBusinessDetails],
  )
  React.useEffect(() => {
    if (searchQuery?.length > 3) {
      ;(async () => {
        const predictions = await getPlacePredictionsDebounce({
          input: searchQuery,
          componentRestrictions: {country: ['us', 'ca', 'au', 'nz']},
        })
        let predictionsResult = []
        if (predictions) {
          predictionsResult = predictions.map(
            ({place_id: placeId, description}) => ({placeId, description}),
          )
          setPredictions(predictionsResult)
        }
      })()
    } else {
      setPredictions([])
    }
  }, [searchQuery])

  React.useEffect(() => {
    if (selectedPlaceId) {
      ;(async () => {
        const placeInfo = await getPlaceDetails({
          placeId: selectedPlaceId,
          fields: PLACE_DETAIL_FIELDS,
        })
        const companyInfo = {
          businessName: placeInfo.name,
          businessDisplayName: placeInfo.name,
          businessWebsiteURL: placeInfo.website,
          businessPhoneNumber: placeInfo.formatted_phone_number,
          ...getCompanyLocationInfo(placeInfo.address_components),
        }
        companyInfo.country = binaryLookup(
          companyInfo.country,
          LIST_OF_COUNTRIES,
        )
        setCountry(companyInfo.country)
        setFormInfo(prev => ({...prev, ...companyInfo}))
        reset(companyInfo)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlaceId, reset])

  React.useEffect(() => {
    if (isSubmitted && !isValid) {
      if (
        errors.businessAddress ||
        errors.city ||
        errors.state ||
        errors.zip ||
        errors.country
      ) {
        toggleShowBusinessDetails({hasError: true})
      }
    }
  }, [isSubmitted, isValid, errors, toggleShowBusinessDetails])

  React.useEffect(() => {
    if (recordId) {
      setLoadingBusinessInfo(true)
      const fetchCompanyDetails = async () => {
        const response = await client(`scans/${recordId}`)

        const data = {
          businessName: response.business_name,
          businessDisplayName: response.business_name,
          businessAddress: response.address,
          city: response.city,
          state: response.state,
          zip: response.zip,

          businessPhoneNumber: response.business_phone,
          businessWebsiteURL: response.bizdomain,
          annualBusinessRevenue: response.revenue,
          country: response.country,
          recordId: recordId,
        }
        data.country = binaryLookup(data.country, LIST_OF_COUNTRIES)
        reset(data)
        setFormInfo(data)
        setLoadingBusinessInfo(false)
      }
      fetchCompanyDetails()
    }
  }, [recordId, client, reset])

  return (
    <>
      <Container>
        <SidePane data-testid="component-side-pane">
          <div className="small-device-container">
            <img
              id="analysisIllustration"
              src={analysisIllustration}
              alt="analysis illustration"
              width="350px"
              height="350px"
            />
            <TitleContent fontWeight="normal" variant="h1">
              Get an analysis on any business.
            </TitleContent>
            <SubTitleContent variant="reg" color="thryv-white-50">
              Get your score in 30 seconds and see what Thryv can do to help.
              The way people find local business on the internet is changing, so
              don’t miss out!
            </SubTitleContent>
          </div>
        </SidePane>
        <section className="p-3" data-testid="component-scan-form">
          <ScanForm className="flex flex-col justify-center items-center">
            <FormTitle fontWeight="normal" variant="h2">
              Enter business
              <br />
              information to get started
            </FormTitle>
            <br />
            <FormSubtitle variant="reg" color="thryv-white-50" className="mb-5">
              Search for a business below to gain insights into customer
              sentiment, online listings, reviews, and more
            </FormSubtitle>
            {loadingBusinessInfo ? (
              <SpinnerContainer data-testid="scan-form-loader">
                <FormSubtitle variant="reg" color="thryv-white-50">
                  The Business Information is loading...
                </FormSubtitle>
                <Spinner />
              </SpinnerContainer>
            ) : (
              <>
                <Form role="form">
                  <div className="search-input-container">
                    <Input
                      className="w-100 search-input required-input"
                      type="text"
                      placeholder="Business Name"
                      name="businessDisplayName"
                      id="businessDisplayName"
                      data-testid="search-input"
                      variant="search"
                      withLabel
                      value={formInfo.businessDisplayName}
                      onChange={handleSearchChange}
                      searchOptions={predictions.map(p => p.description)}
                      positioning="absolute"
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <div className="regular-inputs-container">
                    <div className="no-business-name-container">
                      <ParagraphText
                        variant="reg"
                        className="mt-3 mb-1"
                        data-test="no-business-name-button"
                      >
                        <span role="button" onClick={toggleShowBusinessDetails}>
                          I can&apos;t find business
                        </span>
                      </ParagraphText>
                    </div>
                    <HideContainer
                      show={showBusinessDetails}
                      className="mt-2 show"
                      data-test="hidden-inputs-container"
                    >
                      <div className="row">
                        <div className="col">
                          <Input
                            className="w-100 required-input"
                            type="text"
                            placeholder="Business Address"
                            name="businessAddress"
                            id="businessAddress"
                            withLabel
                            labelType="floating"
                            variant="default"
                            register={register}
                            errors={errors}
                          />
                        </div>
                        <div className="col">
                          <Input
                            className="w-100"
                            type="text"
                            placeholder="Suite No"
                            name="suiteNo"
                            id="suiteNo"
                            withLabel
                            labelType="floating"
                            variant="default"
                            register={register}
                            errors={errors}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-around">
                        <div className="col">
                          <Input
                            className="w-100 required-input"
                            type="text"
                            placeholder="City"
                            name="city"
                            id="city"
                            withLabel
                            labelType="floating"
                            variant="default"
                            register={register}
                            errors={errors}
                          />
                        </div>
                        <div className="col">
                          <Input
                            className="w-100 required-input"
                            type="text"
                            placeholder="State"
                            name="state"
                            id="state"
                            withLabel
                            labelType="floating"
                            variant="default"
                            register={register}
                            errors={errors}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-around">
                        <div className="col">
                          <Controller
                            control={control}
                            name="country"
                            render={({field: {onChange, name}}) => (
                              <Select
                                options={LIST_OF_COUNTRIES}
                                width="full"
                                selectLabel="Country"
                                selectedOption={selectedCountry}
                                setSelectedOption={setCountry}
                                errors={errors}
                                onChange={onChange}
                                name={name}
                                withLabel={true}
                              />
                            )}
                          />
                        </div>
                        <div className="col">
                          <Input
                            className="w-100 required-input"
                            type="text"
                            placeholder="Zip"
                            name="zip"
                            id="zip"
                            withLabel
                            labelType="floating"
                            variant="default"
                            register={register}
                            errors={errors}
                          />
                        </div>
                      </div>
                    </HideContainer>
                    <Input
                      className="w-100 required-input"
                      type="text"
                      placeholder="Business Phone Number"
                      name="businessPhoneNumber"
                      id="businessPhoneNumber"
                      withLabel
                      labelType="floating"
                      variant="default"
                      register={register}
                      errors={errors}
                    />
                    <Input
                      className="w-100"
                      type="text"
                      placeholder="Business Website URL"
                      name="businessWebsiteURL"
                      withLabel
                      id="businessWebsiteURL"
                      labelType="floating"
                      variant="default"
                      register={register}
                      errors={errors}
                    />
                    <Controller
                      name="annualBusinessRevenue"
                      render={({field: {onChange, name, value}}) => (
                        <CurrencyInput
                          onChange={onChange}
                          name={name}
                          value={value}
                          errors={errors}
                        />
                      )}
                      control={control}
                    />
                  </div>
                </Form>
                <div
                  className="row justify-content-between mt-3 form_footer__container"
                  style={{width: '550px'}}
                >
                  <RequiredInformationLabel
                    variant="reg"
                    color="thryv-white-50"
                  >
                    * Required information
                  </RequiredInformationLabel>
                  <RunAnalysisButton
                    variant="primary"
                    disabled={false}
                    type="submit"
                    onClick={handleSubmit(submitForm)}
                  >
                    Run Analysis
                  </RunAnalysisButton>
                </div>
              </>
            )}
          </ScanForm>
        </section>
      </Container>
    </>
  )
}

export {BusinessScan}
