const checkAmountValidity = (previewAmount, normalAmount) => {
  if (!previewAmount) return
  return previewAmount >= normalAmount
}

const getPreviewItemTotal = ({upgradePaymentPreview, item, country}) => {
  if (!item?.price) return

  const isAus = country === 'AU'
  const {name, price} = item
  const {centers, apps} = upgradePaymentPreview
  const allPreviews = [...centers, ...apps]
  const itemPreview = allPreviews.find(({name: itemName}) => itemName === name)
  const previewAmount = !isAus
    ? itemPreview?.subtotal
    : itemPreview?.subtotal + itemPreview?.tax
  const isValidAmount = checkAmountValidity(previewAmount, price)

  if (itemPreview && isValidAmount) return previewAmount
}

export default getPreviewItemTotal
